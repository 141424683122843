import SvgIcon from "@material-ui/core/SvgIcon";
import {
  IconData,
  IconInput,
  IconIT,
  IconOutput,
  IconPainPoint,
} from "../components/Icons";

export type OverlayValues = Array<{
  color: string;
  id: "dataElements" | "inputs" | "itSystems" | "outputs" | "painPoints";
  name: string;
  Icon: typeof SvgIcon;
}>;

export const SESSION_TIMEOUT_DIALOG_MS = 5 * 60000; // 5 min
export const RACI_CELL_HEIGHT = 60;
export const OVERLAY_VALUES: OverlayValues = [
  {
    Icon: IconData,
    color: "#54A1D1",
    id: "dataElements",
    name: "Data Elements",
  },
  {
    Icon: IconInput,
    color: "#E9C821",
    id: "inputs",
    name: "Inputs",
  },
  {
    Icon: IconIT,
    color: "#D2D852",
    id: "itSystems",
    name: "IT Systems",
  },
  {
    Icon: IconOutput,
    color: "#669B29",
    id: "outputs",
    name: "Outputs",
  },
  {
    Icon: IconPainPoint,
    color: "#FF7400",
    id: "painPoints",
    name: "Pain Points",
  },
];

export const RACI_COLOR_PRIORITY = [
  "#B81397",
  "#7B4677",
  "#94688F",
  "#AD9BB5",
  "#D1C0D5",
];

export const TIME_UNITS = ["secs", "mins", "hrs", "days", "weeks", "months"];
export const TIME_UNITS_NUM = ["1", "60", "3600", "86400", "604800", "2592000"];

export const TIME_MAX_LENGTH = 8;
export const IGNORE_TIME_KEYCODES = [69, 187, 189];

export const RESET_PASSWORD_URL =
  "https://www.orglabsolutions.com/users/password/new";
