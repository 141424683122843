import { Box, makeStyles } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import { createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Form, Formik } from "formik";
import { Field } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { default as yup } from "yup";
import { useLogin } from "../../hooks/useLogin";
import { getAuthToken } from "../../lib/authToken";
import { State } from "../../store/types";
import { ReactComponent as Logo } from "../App/logos/logo_full_dark.svg";
import AppVersion from "../AppVersion";
import { ButtonSignIn } from "../Button/index";
import FormTextField from "../FormTextField/index";
import VerticalLayout from "../VerticalLayout";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoText: {
      fontFamily: theme.typography.fontFamily,
      fontSize: 12,
      color: theme.palette.text.primary,
      marginBottom: 25,
      marginTop: 25,
    },
    legalDisclaimer: {
      fontSize: 12,
      textAlign: "left",
      color: theme.palette.grey[500],
    },
    logo: {
      marginRight: "auto",
      marginLeft: "auto",
      marginTop: 32,
      marginBottom: 32,
      display: "block",
      width: 330,
      height: 75,
    },
    whiteButton: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.secondary.main,
      "&:hover": {
        background: "#fcfcfc",
      },
    },
    center: {
      textAlign: "center",
    },
  })
);

export default function PageLogin() {
  const { t } = useTranslation("translations");
  const classes = useStyles();
  const { submitLogin } = useLogin();
  const { authUrl } = useSelector<
    State,
    {
      authUrl: string;
    }
  >(({ appVersion }) => ({
    authUrl: appVersion.authUrl,
  }));
  const token = getAuthToken();

  return (
    <Formik
      initialValues={{
        clientId: "",
      }}
      validationSchema={yup.object().shape({
        clientId: yup.string().required("Required field"),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await submitLogin(values.clientId.toLowerCase());
        setSubmitting(false);
      }}
      render={({ isSubmitting }) => {
        return (
          <VerticalLayout>
            <Form translate="yes">
              <Logo className={classes.logo} />
              {token ? (
                <Field
                  component={FormTextField}
                  name="clientId"
                  fullWidth={true}
                  InputProps={{ autoComplete: "off" }}
                  margin="dense"
                  autoFocus={true}
                />
              ) : (
                <Box pt={3} pb={5}>
                  <Typography variant="subtitle1" className={classes.center}>
                    Welcome!
                  </Typography>
                </Box>
              )}
              <FormControl margin="dense" fullWidth={true}>
                {token ? (
                  <ButtonSignIn
                    type="submit"
                    fullWidth={true}
                    disabled={isSubmitting}
                  />
                ) : (
                  <ButtonSignIn
                    type="button"
                    fullWidth={true}
                    onClick={() =>
                      (window.location.href = buildAuthUrl(authUrl))
                    }
                  />
                )}
              </FormControl>
              <div
                className={classes.infoText}
                dangerouslySetInnerHTML={{ __html: t("loginInfo") }}
              />
            </Form>
            <Typography component="div" className={classes.legalDisclaimer}>
              {!token && (
                <>
                  <Box
                    mt={2}
                    dangerouslySetInnerHTML={{ __html: t("legalDisclaimer") }}
                  />
                  <Box mt={2}>
                    <Box fontWeight={700}>Accessibility Statement</Box>
                    We strive to provide individuals with disabilities equal
                    access to our services, including through an accessible
                    website. If you have questions, comments, or encounter any
                    difficulty in using our site, please contact:{" "}
                    <a href="mailto:McKinsey_Website_Accessibility@mckinsey.com">
                      McKinsey_Website_Accessibility@mckinsey.com
                    </a>
                  </Box>
                </>
              )}
              <Box
                mt={2}
                dangerouslySetInnerHTML={{ __html: t("bandwidthMessage") }}
              />
              <Box
                mt={2}
                className={classes.center}
                dangerouslySetInnerHTML={{
                  __html: t("copyright", { date: new Date() }),
                }}
              />
              <Box mt={2} className={classes.center}>
                <AppVersion />
              </Box>
            </Typography>
          </VerticalLayout>
        );
      }}
    />
  );
}

function buildAuthUrl(authUrl: string) {
  const url = new URL(authUrl + "/logout");
  const destination = window.location.origin + process.env.PUBLIC_URL;
  const uuidRegex = new RegExp("/sso/?((?:[a-f0-9]+-){4}[a-f0-9]+)?$");
  const uuidMatches = window.location.pathname.match(uuidRegex);
  const issuer = uuidMatches ? { issuer: uuidMatches[1] || "" } : null;
  url.search = new URLSearchParams({
    app: "decision-lab",
    destination,
    ...issuer,
  }).toString();
  return url.href;
}
