import { createStyles, Divider, Tab, Tabs, Theme } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import React, { ReactNode } from "react";

const NudgeTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      [theme.breakpoints.up("md")]: {
        fontSize: "inherit",
        minWidth: 72,
        padding: "6px 12px",
      },
    },
  })
)(Tab);

interface DiagramNudgesNavProps {
  renderNudges: () => ReactNode;
  renderManager: () => ReactNode;
}

const DiagramNudgesNav: React.SFC<DiagramNudgesNavProps> = props => {
  const { renderNudges, renderManager } = props;
  const [value, setValue] = React.useState<number>(0);

  return (
    <>
      <Tabs
        value={value}
        onChange={(_e, value) => setValue(value)}
        indicatorColor="secondary"
        textColor="primary"
        centered={true}
        variant="fullWidth"
      >
        <NudgeTab value={0} label="All Nudges" />
        <NudgeTab value={1} label="Manage" />
      </Tabs>
      <Divider />
      {value === 0 && renderNudges()}
      {value === 1 && renderManager()}
    </>
  );
};

export default DiagramNudgesNav;
