import { filter } from "lodash";
import { GetInsightDensityMapResult } from ".";
import { GetInsightDensityMap_DensityMap } from "../../generated/GetInsightDensityMap";
import { InsightsInvolvedRole } from "../../store/insights/type";
import { DiagramComponentFrameworkValues } from "../useGetDiagram/types";

interface Props {
  densityMapData: GetInsightDensityMap_DensityMap[];
  accountabilityValues: DiagramComponentFrameworkValues;
  involvedRoles: InsightsInvolvedRole[];
}

export default function normalizeDensityMapData({
  densityMapData,
  involvedRoles,
  accountabilityValues,
}: Props): GetInsightDensityMapResult[] {
  return involvedRoles.filter(_ => _.selected).map(involvedRole => ({
    ...involvedRole,
    accountabilityCount: accountabilityValues.map(({ id }) =>
      involvedRole.associatedRoles.reduce(
        (role_acc, { roleId }) =>
          role_acc +
          filter(densityMapData, {
            roleId,
            accountabilityMethodologyValueId: id,
          }).reduce((map_acc, { count }) => map_acc + (count || 0), 0),
        0
      )
    ),
    splitByProcess: accountabilityValues.map(({ id }) => {
      let processCount = {};
      involvedRole.associatedRoles.forEach(({ roleId, processId }) => {
        processCount = {
          ...processCount,
          [processId]: filter(densityMapData, {
            roleId,
            accountabilityMethodologyValueId: id,
          }).reduce((map_acc, { count }) => map_acc + (count || 0), 0),
        };
      });

      return processCount;
    }),
  }));
}
