import React from "react";
import Countdown from "react-countdown";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { compose } from "recompose";
import { logout } from "../../store/login/actions";
import { getLoginExpirationDate } from "../../store/selectors";
import { State } from "../../store/types";
import ConfirmationDialog from "../ConfirmationDialog";

interface AuthSessionTimerStateProps {
  expirationDateInMs: number;
}

interface AuthSessionTimerInnerProps
  extends AuthSessionTimerStateProps,
    RouteComponentProps<any> {
  handleLogout: () => void;
}

type AuthSessionTimerProps = AuthSessionTimerInnerProps &
  AuthSessionTimerStateProps;

export class AuthSessionTimer extends React.Component<AuthSessionTimerProps> {
  public handleConfirmationCancel = () => {
    const { handleLogout } = this.props;
    handleLogout();
  };

  public render() {
    const { expirationDateInMs } = this.props;

    return (
      <ConfirmationDialog
        handleConfirm={this.handleConfirmationCancel}
        open={true}
        props={{
          customMessage: (
            <Countdown
              date={expirationDateInMs}
              renderer={({ formatted: { minutes, seconds }, completed }) => {
                return completed ? (
                  ""
                ) : (
                  <div>
                    <p>
                      Looks like you are offline. Please check your network
                      settings.
                    </p>
                    <p>
                      Your session is going to timeout in {minutes}:{seconds}
                    </p>
                  </div>
                );
              }}
              onComplete={this.handleConfirmationCancel}
            />
          ),
          i18nKey: "sessionOffline",
        }}
      />
    );
  }
}

export const mapStateToProps = (state: State): AuthSessionTimerStateProps => ({
  expirationDateInMs: getLoginExpirationDate(),
});

const enhance = compose<AuthSessionTimerInnerProps, {}>(
  withRouter,
  connect(
    mapStateToProps,
    {
      handleLogout: logout,
    }
  )
);

export default enhance(AuthSessionTimer);
