import { useMutation } from "@apollo/react-hooks";
import {
  AddDiagramAssignment,
  AddDiagramAssignmentVariables,
} from "../../generated/AddDiagramAssignment";
import {
  RemoveDiagramAssignment,
  RemoveDiagramAssignmentVariables,
} from "../../generated/RemoveDiagramAssignment";
import {
  UpdateDiagram,
  UpdateDiagramVariables,
} from "../../generated/UpdateDiagram";
import {
  ADD_DIAGRAM_ASSIGNMENT,
  REMOVE_DIAGRAM_ASSIGNMENT,
} from "../../graphql/mutation/addDiagramAssignment";
import { UPDATE_DIAGRAM } from "../../graphql/mutation/updateDiagram";
import GET_DIAGRAMS_LIST from "../../graphql/query/getDiagramsList";
import { DiagramFormValues } from "../../types/DiagramForm";

export default function useUpdateDiagram() {
  const [updateDiagram, { loading: updateSaving }] = useMutation<
    UpdateDiagram,
    UpdateDiagramVariables
  >(UPDATE_DIAGRAM);
  const [addDiagramAssignment, { loading: addAssignmentSaving }] = useMutation<
    AddDiagramAssignment,
    AddDiagramAssignmentVariables
  >(ADD_DIAGRAM_ASSIGNMENT);
  const [
    removeDiagramAssignment,
    { loading: removeAssignmentSaving },
  ] = useMutation<RemoveDiagramAssignment, RemoveDiagramAssignmentVariables>(
    REMOVE_DIAGRAM_ASSIGNMENT
  );

  function editDiagram(values: DiagramFormValues, diagramID: string) {
    return updateDiagram({
      variables: {
        diagramID,
        accountabilityMethodologyID: values.decisionFrameworkId || "",
        structureId: values.structureId || "",
        name: values.diagramName || "",
        description: values.description,
        organizationSize: values.organizationSize,
        processArchetypeID: values.processArchetype,
        processArchetypeOther: values.processArchetypeOther,
        structurePrimaryAxisID: values.structurePrimaryAxis,
        structurePrimaryAxisOther: values.structurePrimaryAxisOther,
        industryID: values.industry,
        industryOther: values.industryOther,
        industryOrglabID: values.industryOrglabID,
        primaryOwningFunctionID: values.primaryOwningFunction,
        primaryOwningFunctionOther: values.primaryOwningFunctionOther,
        geographyID: values.geography,
      },
      fetchPolicy: "no-cache",
      refetchQueries: [
        {
          query: GET_DIAGRAMS_LIST,
        },
      ],
    });
  }

  function removeAssignment(
    operationID: string,
    roleID: string,
    assignmentID: string
  ) {
    removeDiagramAssignment({
      variables: {
        operationID,
        roleID,
        assignmentID,
      },
    });
  }

  function addAssignment(
    operationID: string,
    roleID: string,
    assignmentID: string
  ) {
    addDiagramAssignment({
      variables: {
        operationID,
        roleID,
        assignmentID,
      },
    });
  }

  return {
    editDiagram,
    addAssignment,
    removeAssignment,
    updateSaving: updateSaving || addAssignmentSaving || removeAssignmentSaving,
  };
}
