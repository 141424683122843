import React from "react";
import useCreateRole from "../../hooks/useCreateRole";
import DiagramCell from "../DiagramCell";
import { IconAdd } from "../Icons";

export interface RoleAddButtonProps {
  diagramId: string;
}

const RoleAddButton: React.SFC<RoleAddButtonProps> = ({ diagramId }) => {
  const { addRole, loading } = useCreateRole();
  return (
    <DiagramCell disabled={loading} onClick={() => addRole(diagramId)}>
      <IconAdd />
    </DiagramCell>
  );
};

export default RoleAddButton;
