import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";

const LoadingWindow = () => (
  <div
    style={{
      position: "absolute",
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      background: "rgba(255,255,255,.8)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <CircularProgress color="secondary" />
  </div>
);

export default LoadingWindow;
