import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import React from "react";
import { compose } from "recompose";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      width: "200px",
      height: "500px",
    },
    vertical: {
      position: "absolute",
      top: 1,
      right: 0,
      width: "3px",
      height: "100%",
      background: theme.palette.primary.main,
    },
    horizontal: {
      position: "absolute",
      top: "51%",
      right: 0,
      width: "100%",
      height: "2px",
      background: theme.palette.primary.main,
      marginTop: "-2px",
    },
    circle: {
      position: "absolute",
      top: "50%",
      left: 0,
      width: "8px",
      height: "8px",
      background: theme.palette.primary.main,
      borderRadius: "50%",
      margin: "-5px -5px",
    },
  });

type ShapeActionPathProps = WithStyles<typeof styles> &
  ShapeActionPathOuterProps;

interface ShapeActionPathOuterProps {
  className?: string;
  stroke?: string;
  fill?: string;
  strokeWidth?: string;
}

export const ShapeActionPath: React.SFC<ShapeActionPathProps> = ({
  classes,
  className,
  ...props
}) => (
  <div className={`${classes.root} ${className}`} {...props}>
    <div className={classes.vertical} />
    <div className={classes.horizontal} />
    <div className={classes.circle} />
  </div>
);

const enhance = compose<ShapeActionPathProps, ShapeActionPathOuterProps>(
  withStyles(styles)
);

export default enhance(ShapeActionPath);
