import MenuItem from "@material-ui/core/MenuItem";
import { Field } from "formik";
import React from "react";
import { PhaseResponse } from "../../types/Clients";
import FormSelectField from "../FormSelectField";

interface DesignSessionFieldProps {
  designSessions: PhaseResponse[];
  disabled?: boolean;
}

class DesignSessionField extends React.Component<DesignSessionFieldProps> {
  public render() {
    const { designSessions, disabled = false } = this.props;
    return (
      <Field
        component={FormSelectField}
        name="structureId"
        fullWidth={true}
        margin="dense"
        disabled={disabled}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {designSessions.filter(_ => !_.archived).map(designSession => {
          return [
            <MenuItem
              disabled={true}
              value={designSession.id}
              key={designSession.id}
            >
              {designSession.name}
            </MenuItem>,
            designSession.structures.filter(_ => !_.archived).map(structure => [
              <MenuItem
                style={{ paddingLeft: 40 }}
                value={structure.id}
                key={structure.id}
              >
                {structure.name}
              </MenuItem>,
              structure.derivates.filter(_ => !_.archived).map(derivate => (
                <MenuItem
                  style={{ paddingLeft: 60 }}
                  value={derivate.id}
                  key={derivate.id}
                >
                  {derivate.name}
                </MenuItem>
              )),
            ]),
          ];
        })}
      </Field>
    );
  }
}

export default DesignSessionField;
