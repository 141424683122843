import { State } from "@storybook/api";
import constate from "constate";
import idx from "idx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NextOperationTypes } from "../../generated/globalTypes";
import { getNextPathTypeByIndex } from "../../lib/diagramUtils";
import { notifyInfo } from "../../store/notifications/actions";
import { userSet } from "../../store/userSettings/actions";
import { DiagramComponentOperation } from "../useGetDiagram/types";
import { getInitialDecisionState, getNewPath } from "./util";

export interface DecisionState {
  [key: string]: string;
}

export type OperationsState = DiagramComponentOperation & {
  toggleDecisionState: () => void;
  nextPathType: NextOperationTypes;
};

const [GlobalDiagramPathProvider, useGlobalToggleDiagramPath] = constate(
  function() {
    const [operations, setOperations] = useState<OperationsState[]>([]);
    const {
      toggleDecisionState,
      currentPath,
      setDecisionState,
      decisionState,
    } = useLocalToggleDiagramPath(operations);

    return {
      addOperations: (operations: DiagramComponentOperation[]) => {
        setOperations(setToggleProperties(operations, toggleDecisionState));
        setDecisionState(getInitialDecisionState(operations, decisionState));
      },
      toggleDecisionState,
      currentPath,
    };
  }
);

export function useLocalToggleDiagramPath(
  operations: DiagramComponentOperation[]
) {
  const [decisionState, setDecisionState] = useState<DecisionState>(
    getInitialDecisionState(operations, {})
  );
  const [currentPath, setCurrentPath] = useState<OperationsState[]>(
    setToggleProperties(
      getNewPath(operations, decisionState),
      toggleDecisionState
    )
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const hasConfirmedDecision = useSelector<State, boolean>(
    state =>
      idx(state, _ => _.userSettings[_.login.username].hasConfirmedDecision)
        ? true
        : false
  );

  useEffect(
    () => {
      setCurrentPath(
        setToggleProperties(
          getNewPath(operations, decisionState),
          toggleDecisionState
        )
      );
    },
    [decisionState, operations]
  );

  function toggleDecisionState(id: string) {
    if (!hasConfirmedDecision) {
      dispatch(notifyInfo(t("gatewayInstructions")));
      dispatch(userSet("hasConfirmedDecision", true));
    }

    setDecisionState(state => ({
      ...state,
      [id]: state[id] === "yes" ? "no" : "yes",
    }));
  }

  return {
    toggleDecisionState,
    setDecisionState,
    decisionState,
    currentPath,
  };
}

function setToggleProperties(
  operations: DiagramComponentOperation[],
  cb: (id: string) => void
) {
  return operations.map((operation, i, src) => {
    const nextPathType = getNextPathTypeByIndex(src, i);
    return {
      ...operation,
      nextPathType,
      toggleDecisionState() {
        cb(operation.id);
      },
    };
  });
}

export { useGlobalToggleDiagramPath, GlobalDiagramPathProvider };
