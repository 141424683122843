import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import React from "react";

const styles = () =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
    },
    inner: {
      maxWidth: "330px",
      margin: "0 auto 10%",
    },
  });

type VerticalLayoutProps = WithStyles<typeof styles>;

const VerticalLayout: React.SFC<VerticalLayoutProps> = ({
  classes,
  children,
}) => (
  <div className={classes.container}>
    <div className={classes.inner}>{children}</div>
  </div>
);

export default withStyles(styles)(VerticalLayout);
