import { InputAdornment, MenuItem, Select } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { useTranslation } from "react-i18next";
import { TIME_UNITS_NUM } from "../../config/constants";
import handleNumberInput, {
  handlePasteNumberInput,
} from "../../lib/handleNumberInput";
import FormTextField, { FormTextFieldProps } from "../FormTextField";

type FildTimeProps = FormTextFieldProps;
const FieldTime: React.SFC<FildTimeProps> = (props) => {
  const { t } = useTranslation("timeConversion");
  const unitFieldName = props.field.name.replace(".0", "");
  const unitFieldValue = props.form.values[unitFieldName][1];

  return (
    <FormTextField
      {...props}
      onKeyPress={handleNumberInput}
      onPaste={handlePasteNumberInput}
      margin="normal"
      disableOnSubmitting={true}
      InputProps={{
        inputProps: { maxLength: 8 },
        endAdornment: (
          <InputAdornment position="end">
            <Select
              id={`${unitFieldName}.1`}
              name={`${unitFieldName}.1`}
              value={unitFieldValue}
              onChange={(e) => {
                localStorage.setItem("default_time_unit", e.target
                  .value as string);
                props.form.handleChange(e);
              }}
              IconComponent={() => <ExpandMoreIcon />}
              disableUnderline={true}
            >
              {TIME_UNITS_NUM.map((unit) => (
                <MenuItem value={unit} key={unit}>
                  {t(unit)}
                </MenuItem>
              ))}
            </Select>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default FieldTime;
