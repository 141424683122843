import { withTheme, WithTheme } from "@material-ui/core/styles";
import React from "react";

type ProcessElementEndProps = WithTheme;

const ProcessElementEnd: React.SFC<ProcessElementEndProps> = ({ theme }) => {
  const stroke = theme.palette.text.primary;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        width: 260,
      }}
    >
      <div
        style={{
          width: 50,
          height: 50,
          borderRadius: "50%",
          border: `1px solid ${stroke}`,
        }}
      />
      <div
        style={{
          width: 20,
          height: 1,
          backgroundColor: stroke,
        }}
      />
    </div>
  );
};

export default withTheme(ProcessElementEnd);
