import { jsPlumb, jsPlumbInstance } from "jsplumb";
import React from "react";
import { connect, MapStateToProps } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import {
  DiagramComponentFrameworkValues,
  DiagramComponentOperation,
} from "../../hooks/useGetDiagram/types";
import {
  getAssignmentRACIConnections,
  getSelectedRACIFilter,
} from "../../store/selectors";
import { State } from "../../store/types";

interface AssignmentConnectsStateProps {
  connects: string[];
  strokeColor?: string;
}

interface AssignmentConnectsOuterProps {
  currentPath: DiagramComponentOperation[];
  options: DiagramComponentFrameworkValues;
}

type AssignmentConnectsInnerProps = AssignmentConnectsStateProps;

type AssignmentConnectsProps = AssignmentConnectsOuterProps &
  AssignmentConnectsInnerProps;

class AssignmentConnects extends React.Component<AssignmentConnectsProps> {
  public jsPlumb: jsPlumbInstance = jsPlumb.getInstance();

  public componentWillUnmount() {
    this.jsPlumb.deleteEveryConnection();
  }

  public componentDidMount() {
    this.jsPlumb.setContainer("diagram");
    this.jsPlumb.bind("ready", () => {
      // this.jsPlumb.reset();
      this.drawConnections();
    });
  }

  public componentDidUpdate(nextProps: AssignmentConnectsProps) {
    this.jsPlumb.deleteEveryConnection();
    this.jsPlumb.deleteEveryEndpoint();
    // if (this.jsPlumb.anchorManager) {
    //   this.jsPlumb.deleteEveryEndpoint();
    // }
    this.drawConnections();
  }

  public drawConnections() {
    const { connects = [], strokeColor } = this.props;
    connects.forEach((nodeId, i) => {
      const previousNodeId = connects[i - 1];
      if (previousNodeId) {
        const source = nodeId;
        const target = previousNodeId;
        this.jsPlumb.connect(
          {
            source,
            target,
            detachable: true,
          },
          {
            endpoint: "Blank",
            // anchors: [
            //   [0, .5, 0, -1], // , 5, -5],
            //   [1, .5, 0, 1], // , -5, 5],
            // ],
            anchor: [
              // top center - just in case
              "TopCenter",
              "BottomCenter",

              // left side - top to bottom
              [0, 0, 0, -1],
              [0.2, 0.2, 0, -1],
              [0, 0.4, 0, -1],
              [0.1, 0.6, 0, -1],
              [0.1, 0.8, 0, -1],
              [0.2, 0.8, 0, -1],

              // right side - top to bottom
              [0.8, 0.2, 0, -1],
              [0.9, 0.2, 0, -1],
              [1, 0.4, 0, -1],
              [1, 0.6, 0, -1],
              [0.8, 0.8, 0, -1],
            ],
            paintStyle: {
              strokeWidth: 2,
              stroke: strokeColor || "rgb(0,0,0)",
            },
            overlays: [
              [
                "Arrow",
                {
                  width: 1,
                  length: 1,
                  location: 1,
                },
              ],
            ],
            connector: ["Straight"],
          }
        );
      }
    });
  }

  public render() {
    return (
      <div id="diagram" style={{ position: "relative" }}>
        {this.props.children}
      </div>
    );
  }
}

const mapStateToProps: MapStateToProps<
  AssignmentConnectsStateProps,
  AssignmentConnectsProps,
  State
> = (state, props) => {
  const raciValue = getSelectedRACIFilter(state);
  return {
    connects: getAssignmentRACIConnections(state, props),
    strokeColor: props.options
      .filter(_ => _.id === raciValue)
      .map(_ => _.color)[0],
  };
};

const enhance = compose<AssignmentConnectsProps, AssignmentConnectsOuterProps>(
  withRouter,
  connect(mapStateToProps)
);

export default enhance(AssignmentConnects);
