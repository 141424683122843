import { Badge, Box, Typography } from "@material-ui/core";
import React, { useState } from "react";
import useDiagramInteractions from "../../hooks/useDiagramInteractions";
import useGetNudges from "../../hooks/useGetNudges";
import { ButtonToggleNudges } from "../Button";
import DiagramNudge from "../DiagramNudge";
import DiagramNudgesManager from "../DiagramNudgesManager";
import { IconNudges } from "../Icons";
import WindowAnchored from "../WindowAnchored";
import DiagramNudgesNav from "./DiagramNudgesNav";

const DiagramNudgesContainer: React.SFC = () => {
  const [activeWindow, setActiveWindow] = useState<boolean>(false);
  const {
    actions: { setNudgeHighlight, resetNudgeHighlight },
  } = useDiagramInteractions();
  const { nudges, validNudges, totalNudges, nudgesOff } = useGetNudges();

  return (
    <>
      <ButtonToggleNudges
        icon={
          <Badge color="secondary" badgeContent={totalNudges} id="nudgeCounter">
            <IconNudges style={{ zIndex: 2 }} />
          </Badge>
        }
        onMouseDown={e => {
          e.preventDefault();
          setActiveWindow(!activeWindow);
          if (activeWindow) {
            resetNudgeHighlight();
          }
        }}
      />
      {activeWindow && (
        <WindowAnchored
          anchorId={"nudgeCounter"}
          placement="bottom"
          hideOverlay={true}
          onClickAway={e => {
            setActiveWindow(false);
            resetNudgeHighlight();
          }}
          windowSizeStyle={{
            width: 300,
            maxHeight: "80vh",
            height: "auto",
            overflow: "auto",
          }}
        >
          <DiagramNudgesNav
            renderManager={() =>
              nudges.length ? (
                <DiagramNudgesManager
                  nudges={nudges}
                  onSubmit={() => {
                    resetNudgeHighlight();
                  }}
                />
              ) : (
                <Box m={2}>
                  <Typography variant="body1">Nothing to manage.</Typography>
                </Box>
              )
            }
            renderNudges={() =>
              nudgesOff ? (
                <Box m={2}>
                  <Typography variant="body1">
                    Turn settings on to receive intelligent, real-time nudges
                    towards better decision-making process design.
                  </Typography>
                </Box>
              ) : totalNudges > 0 ? (
                validNudges.map(
                  (nudge, i) =>
                    nudge.positions.length > 0 ? (
                      <DiagramNudge
                        onNudgeHighlight={({ column, rows }) => {
                          column && rows && setNudgeHighlight(column, rows);
                        }}
                        key={i}
                        nudge={nudge}
                      />
                    ) : null
                )
              ) : (
                <Box m={2}>
                  <Typography variant="body1">
                    All clear. Check back as you see the counter increase to see
                    helpful process nudges.
                  </Typography>
                </Box>
              )
            }
          />
        </WindowAnchored>
      )}
    </>
  );
};

export default DiagramNudgesContainer;
