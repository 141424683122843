import { DesignSessionsLookup } from "../../store/designSessions/types";

const getStructurePathFromLookup = (
  lookup: DesignSessionsLookup,
  id?: string
) => {
  if (id) {
    const structure = lookup[id];
    if (structure) {
      return structure.path;
    }
    return "Structure Not Found";
  }
  return "";
};

export default getStructurePathFromLookup;
