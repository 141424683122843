import { DesignSessionsLookup } from "../../store/designSessions/types";
import { PhaseResponse } from "../../types/Clients";

export default function convertPhaseResponseToLookup(
  source: PhaseResponse[]
): DesignSessionsLookup {
  return source.reduce<DesignSessionsLookup>((acc, curr) => {
    const { structures, ...phase } = curr;
    acc[curr.id] = {
      ...phase,
      path: phase.name,
    };
    return {
      ...acc,
      ...structures.reduce<DesignSessionsLookup>((acc, curr) => {
        const { derivates, ...structure } = curr;
        acc[curr.id] = {
          ...structure,
          path: `${phase.name} / ${structure.name}`,
        };
        return {
          ...acc,
          ...derivates.reduce<DesignSessionsLookup>((acc, curr) => {
            acc[curr.id] = {
              ...curr,
              path: `${phase.name} / ${structure.name} / ${curr.name}`,
            };
            return acc;
          }, {}),
        };
      }, {}),
    };
  }, {});
}
