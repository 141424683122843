import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React, { ReactNode } from "react";
import { SortableContainer } from "react-sortable-hoc";
import useDiagramInteractions from "../../hooks/useDiagramInteractions";
import { DiagramComponentOperation } from "../../hooks/useGetDiagram/types";
import useUpdateOperationEdges from "../../hooks/useUpdateOperationEdges";
import { isMobileDevice, sortableProps } from "../../lib/componentProps";

const SortableList = SortableContainer<{
  id?: string;
  className?: string;
  onScroll?: (e: React.SyntheticEvent<HTMLDivElement>) => void;
}>((props: any) => (
  <div id={props.id} className={props.className} onScroll={props.onScroll}>
    {props.children}
  </div>
));

const useStyles = makeStyles({
  scrollPane: {
    position: "sticky",
    top: 0,
    backgroundColor: "white",
    zIndex: 2,
    display: "flex",
  },
  scollPaneIsMobile: {
    position: "static",
  },
  processElementHighlightHelper: {
    "&": {
      zIndex: 1000,
    },
    "& .inactiveProcess .shapeFill": {
      fill: "white",
    },
  },
});

interface Props {
  className?: string;
  currentPath: DiagramComponentOperation[];
  children: (props: { loading: boolean }) => ReactNode;
}

function ScrollHorizontal({ className, children, currentPath }: Props) {
  const classes = useStyles();
  const { updateEdges, loading } = useUpdateOperationEdges();
  const {
    actions: { setSortingX },
  } = useDiagramInteractions();

  return (
    <SortableList
      className={classNames(className, classes.scrollPane, {
        [classes.scollPaneIsMobile]: isMobileDevice(),
      })}
      axis="x"
      lockAxis="x"
      onSortStart={({ index }) => setSortingX(index)}
      onSortEnd={({ newIndex, oldIndex }) => {
        if (oldIndex !== newIndex) {
          updateEdges(currentPath, oldIndex, newIndex);
        }
        setSortingX(undefined);
      }}
      helperClass={classes.processElementHighlightHelper}
      id="ScrollHorizontal"
      hideSortableGhost={true}
      getContainer={() => document.getElementById("scrollPane") as any}
      {...sortableProps}
    >
      {children({ loading })}
    </SortableList>
  );
}

export default ScrollHorizontal;
