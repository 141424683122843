import { useQuery } from "@apollo/react-hooks";
import idx from "idx";
import { GetDiagram, GetDiagramVariables } from "../../generated/GetDiagram";
import GET_DIAGRAM from "../../graphql/query/getDiagram";
import { normalizeDiagram } from "./util";

function useGetDiagram(id: string) {
  const { data, loading } = useQuery<GetDiagram, GetDiagramVariables>(
    GET_DIAGRAM,
    {
      fetchPolicy: "network-only",
      variables: {
        id,
      },
      pollInterval: 10000,
    },
  );

  const diagram = idx(data, (_) => _.Process[0]);

  if (diagram) {
    // console.log(
    //   require("util").inspect(normalizeDiagram(diagram), {
    //     showHidden: false,
    //     depth: null
    //   })
    // );
    return {
      diagram: normalizeDiagram(diagram),
      loading,
    };
  }

  return {
    diagram: undefined,
    loading,
  };
}

export default useGetDiagram;
