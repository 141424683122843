import React from "react";
import FormTextField, { FormTextFieldProps } from "../FormTextField";

const FieldName: React.SFC<FormTextFieldProps> = props => {
  return (
    <FormTextField
      {...props}
      multiline={true}
      fullWidth={true}
      inputProps={{ maxLength: 150 }}
      margin="normal"
    />
  );
};

export default FieldName;
