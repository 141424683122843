import gql from "graphql-tag";

export const GET_INSIGHT_DIAGRAMS = gql`
  query GetInsightDiagrams($clientId: String!, $frameworkId: ID!) {
    InsightsSelectedProcess(
      clientId: $clientId
      frameworkId: $frameworkId
      orderBy: name_asc
    ) {
      id
      name
      roles {
        id
        name
        orglabID
        capacity
      }
    }
  }
`;
