import { useMutation } from "@apollo/react-hooks";
import { RoleAttachVariables } from "../../generated/RoleAttach";
import { RoleDetachVariables } from "../../generated/RoleDetach";
import { UpdateRoleNameVariables } from "../../generated/UpdateRoleName";
import {
  UPDATE_ROLE_ATTACH,
  UPDATE_ROLE_DETACH,
  UPDATE_ROLE_NAME,
} from "../../graphql/mutation/updateRole";

export default function useUpdateRole() {
  const [_attach] = useMutation<any, RoleAttachVariables>(UPDATE_ROLE_ATTACH);
  const [_detach] = useMutation<any, RoleDetachVariables>(UPDATE_ROLE_DETACH);
  const [_updateName] = useMutation<any, UpdateRoleNameVariables>(
    UPDATE_ROLE_NAME
  );

  function attachRole(id: string, name: string, orglabID?: string | null) {
    return _attach({
      variables: {
        id,
        orglabID: orglabID || "",
        name: name || "",
      },
    });
  }

  function detachRole(id: string, name: string) {
    return _detach({
      variables: {
        id,
        name: name || "",
      },
    });
  }

  function updateRoleName(id: string, name: string) {
    return _updateName({
      variables: {
        id,
        name: name || "",
      },
    });
  }
  return {
    attachRole,
    detachRole,
    updateRoleName,
  };
}
