import { makeStyles, Slider, Theme } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import React from "react";
import { insightDensityMapColors } from "../../../config/theme";

interface Props {
  max?: number;
  min?: number;
  value?: number[];
  onChange?: (value: number[]) => void;
}

export default function DensityMapSlider({
  min = 0,
  max = 10,
  value = [3, 6],
  onChange,
}: Props) {
  const theme = useTheme<Theme>();
  const railStopPercentage = (value[0] / max) * 100;
  const railGradient = [
    `${insightDensityMapColors.light} ${railStopPercentage}%
    `,
    `${insightDensityMapColors.dark} ${railStopPercentage}%`,
  ].join(",");

  return (
    <Slider
      value={value}
      min={min}
      max={max}
      valueLabelDisplay="on"
      onChange={(e, newValue) => {
        if (onChange && Array.isArray(newValue)) {
          onChange(newValue);
        }
      }}
      marks={[
        {
          value: 0,
          label: "0",
        },
        {
          value: max,
          label: `${max}`,
        },
      ]}
      ThumbComponent={(props: any) => {
        return (
          <span
            {...props}
            style={{
              ...props.style,
              borderRadius: 1,
              width: 6,
              height: 18,
              backgroundColor: theme.palette.common.white,
              boxShadow: theme.shadows[2],
            }}
          />
        );
      }}
      classes={makeStyles({
        track: {
          background: insightDensityMapColors.medium,
          height: 8,
        },
        rail: {
          height: 8,
          background: `linear-gradient(90deg, ${railGradient})`,
          opacity: 1,
        },
        valueLabel: {
          top: -22,
          "& *": {
            background: "transparent",
            color: theme.palette.text.primary,
          },
        },
        mark: {
          display: "none",
        },
      })()}
    />
  );
}
