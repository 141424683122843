import React from "react";
import UserStatus from "../UserStatus";

export const DiagramContainer: React.SFC<{}> = props => (
  <div
    {...props}
    style={{
      display: "flex",
      height: "100%",
      transform: "translateZ(0)",
    }}
  />
);

export const DiagramScrollPane: React.SFC<{}> = props => (
  <UserStatus
    render={({ isMobile }) => (
      <div
        {...props}
        id="scrollPane"
        style={{
          overflow: "auto",
          height: "100%",
          position: "relative",
          WebkitOverflowScrolling: "touch",
          flex: 1,
          zIndex: 1,
          display: isMobile ? "block" : "flex",
          flexDirection: "column",
        }}
      />
    )}
  />
);

export const DiagramInner: React.SFC<{}> = props => (
  <div
    {...props}
    style={{
      display: "flex",
      flexDirection: "row",
      position: "relative",
      flex: 1,
    }}
  />
);
