import {
  DesignSessionsActionCreatorLoad,
  DesignSessionsActionCreatorReset,
  DesignSessionsActionTypes,
} from "./types";

export const designSessionsLoad: DesignSessionsActionCreatorLoad = payload => ({
  payload,
  type: DesignSessionsActionTypes.DESIGN_SESSIONS_LOAD,
});

export const designSessionsReset: DesignSessionsActionCreatorReset = () => ({
  payload: null,
  type: DesignSessionsActionTypes.DESIGN_SESSIONS_RESET,
});
