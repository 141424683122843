import { RoleSearchResponse } from "../../types/Roles";
import {
  RoleSearchActionCreatorEnd,
  RoleSearchActionCreatorOpen,
  RoleSearchActionCreatorStart,
  RoleSearchActionTypes,
} from "./types";

export const roleSearchStart: RoleSearchActionCreatorStart = payload => ({
  payload,
  type: RoleSearchActionTypes.ROLE_SEARCH_START,
});

export const roleSearchEnd: RoleSearchActionCreatorEnd = () => ({
  payload: null,
  type: RoleSearchActionTypes.ROLE_SEARCH_END,
});

export const roleSearchOpen: RoleSearchActionCreatorOpen = roleId => dispatch => {
  return new Promise(resolve => {
    dispatch(
      roleSearchStart({
        roleId,
        onSelectRole: (orglabRole: RoleSearchResponse) => {
          resolve(orglabRole);
        },
      })
    );
  });
};
