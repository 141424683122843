import jwt_decode from "jwt-decode";
import _ from "lodash";

interface TokenObject {
  claims: string[];
  exp: number;
  iat: number;
  user: {
    id: string;
    name: string;
  };
  client?: {
    id: number,
    login: string,
    admin: boolean,
  },
}

export const getExpirationFromJWT = (jwt: string): number => {
  const tokenObj = jwt_decode<TokenObject>(jwt);
  return _.get(tokenObj, "exp", 0);
};

export const getUserNameFromJWT = (jwt?: string): string => {
  if (jwt) {
    const tokenObj = jwt_decode<TokenObject>(jwt);
    return _.get(tokenObj, "user.name", "");
  }
  return "";
};

export const getClientNameFromJWT = (jwt?: string): string | undefined => {
  if (jwt) {
    const tokenObj = jwt_decode<TokenObject>(jwt);
    return tokenObj.client && tokenObj.client.login;
  }
  return undefined;
};
