import { SvgIconProps } from "@material-ui/core/SvgIcon";
import React from "react";

const ShapeGatewayYes: React.SFC<SvgIconProps> = ({
  stroke = "#00BBD3",
  fill = "#00BBD3",
  color = "#ffffff",
  ...props
}) => (
  <svg width="40px" height="36px" viewBox="0 0 40 36" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-121.000000, -27.000000)">
        <g transform="translate(122.000000, 28.000000)">
          <path
            d="M18.0938767,34.0999985 C24.7558582,34.0999985 31.3902797,28.4166654 37.9971412,17.0499992 C31.3902797,5.68333308 24.7558582,0 18.0938767,0 C8.10090455,0 0,7.63354467 0,17.0499992 C0,26.4664538 8.10090455,34.0999985 18.0938767,34.0999985 Z"
            stroke={stroke}
            fill={fill}
          />
          <polygon
            fill={color}
            points="18.0200354 17.4943163 20.5952092 12.6657137 23.3828561 12.6657137 19.3203707 19.4964198 19.3203707 23.3828561 16.7281991 23.3828561 16.7281991 19.4964198 12.6657137 12.6657137 15.4618595 12.6657137"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ShapeGatewayYes;
