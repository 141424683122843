import { CircularProgress, makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Formik } from "formik";
import _ from "lodash";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { FileRole } from "../../types/Diagram";
import { RoleResponse } from "../../types/Roles";
import AutoresizeTextarea from "../AutoresizeTextarea/index";
import DiagramCell from "../DiagramCell/index";
import RolePanelItemWrapper from "./RolePanelItemWrapper";

const useStyles = makeStyles({
  cellContent: {
    padding: `8px 4px 8px 10px`,
  },
});

interface RolePanelItemProps {
  role: FileRole;
  orglabRole?: RoleResponse;
  focusOnRender: boolean;
  onRoleClick: () => void;
  onRoleChange: (value: string) => Promise<any>;
  selected: boolean;
  loading: boolean;
  error?: string;
  errorAction?: ReactNode;
  renderMenu?: ReactNode;
}

const RolePanelItem: React.SFC<RolePanelItemProps> = props => {
  const {
    role,
    focusOnRender,
    selected,
    onRoleChange,
    onRoleClick,
    loading,
    error,
    errorAction,
    orglabRole,
    renderMenu,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation("translations");
  const connectedToOrglab = !loading && role.orglabRoleId ? true : false;
  const LabelComponent = connectedToOrglab ? `div` : `label`;

  return (
    <RolePanelItemWrapper
      connectedToOrglab={connectedToOrglab}
      roleHasName={!loading && role.name && role.name.length > 0 ? true : false}
      hasError={error ? true : false}
    >
      <Formik
        enableReinitialize={true}
        initialValues={{ name: role.name }}
        onSubmit={(values, { setSubmitting }) => {
          onRoleChange(values.name).then(() => {
            setSubmitting(false);
          });
        }}
        render={({ values, handleSubmit, handleChange, isSubmitting }) => (
          <LabelComponent>
            <DiagramCell
              onClick={!error ? onRoleClick : undefined}
              focused={selected}
              id={role.id}
              buttonClassName={classes.cellContent}
            >
              <div style={{ flex: 1 }}>
                {!loading &&
                  (error ? (
                    <Typography style={{ fontSize: 12 }}>{error}</Typography>
                  ) : connectedToOrglab ? (
                    <Typography style={{ fontSize: 12 }}>
                      {role.name || "-"} ({(orglabRole &&
                        orglabRole.employee &&
                        orglabRole.employee.name) ||
                        "-"})
                    </Typography>
                  ) : (
                    <AutoresizeTextarea
                      focusOnRender={focusOnRender}
                      inputProps={{
                        id: `name`,
                        maxLength: 50,
                        onBlur: () => handleSubmit(),
                        onClick: onRoleClick,
                        onChange: handleChange,
                        placeholder: t("Title/Name"),
                        value: values.name,
                        disabled: isSubmitting,
                      }}
                    />
                  ))}
              </div>

              {loading ? (
                <CircularProgress
                  style={{ margin: 8 }}
                  size={24}
                  color="secondary"
                />
              ) : error ? (
                errorAction || null
              ) : (
                renderMenu
              )}
            </DiagramCell>
          </LabelComponent>
        )}
      />
    </RolePanelItemWrapper>
  );
};

export default RolePanelItem;
