import {
  createStyles,
  IconButton,
  ListItemIcon,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import { connect, Field, FormikContextType } from "formik";
import { truncate } from "lodash";
import React from "react";
import { PossibleOrglabFilters } from "../../types/OrglabFilters";
import { ButtonExpand } from "../Button";
import Chip from "../Chip";
import FormSelectField from "../FormSelectField";
import { IconArrowDropDown, IconArrowDropRight } from "../Icons";
import { RoleSearchFields } from "./RoleSearch";

const styles = (theme: Theme) =>
  createStyles({
    itemRoot: {
      fontSize: 14,
      "&:hover": {
        backgroundColor: theme.palette.grey[50],
      },
    },
    itemSelected: {
      backgroundColor: theme.palette.grey[100] + " !important",
    },
  });

interface FieldFiltersOuterProps {
  filters: PossibleOrglabFilters[];
  name: "locationFilter" | "functionFilter" | "businessUnitFilter";
}

type FieldFiltersInnerProps = {
  formik: FormikContextType<RoleSearchFields>;
} & WithStyles<typeof styles>;

type FieldFiltersProps = FieldFiltersOuterProps & FieldFiltersInnerProps;

interface FieldFiltersState {
  expanded: {
    [key: string]: boolean;
  };
}

class FieldFilters extends React.Component<FieldFiltersProps> {
  public state: FieldFiltersState = {
    expanded: {},
  };

  public renderFilters = (
    parts: PossibleOrglabFilters[],
    level: number,
    classes: any
  ): any[] => {
    const { expanded } = this.state;

    return parts.map(filter => {
      const { id, name, parts } = filter;
      const isExpanded = expanded[id] ? true : false;
      return [
        <MenuItem
          value={filter as any}
          key={id}
          style={{ paddingLeft: 20 * level + 4 }}
          classes={{ root: classes.itemRoot, selected: classes.itemSelected }}
        >
          <ListItemIcon style={{ minWidth: 40 }}>
            <IconButton
              onClick={e => {
                e.stopPropagation();
                this.setExpanded(id, !isExpanded);
              }}
              size="small"
              style={{
                visibility: parts && parts.length > 0 ? "visible" : "hidden",
              }}
              color="primary"
            >
              {isExpanded ? <IconArrowDropDown /> : <IconArrowDropRight />}
            </IconButton>
          </ListItemIcon>
          <ListItemText primary={name} />
        </MenuItem>,
        isExpanded && parts
          ? this.renderFilters(parts, level + 1, classes)
          : null,
      ];
    });
  };

  public setExpanded = (id: string, value: boolean) => {
    const { expanded } = this.state;
    this.setState({
      expanded: {
        ...expanded,
        [id]: value,
      },
    });
  };

  public handleDelete = (value: PossibleOrglabFilters) => () => {
    const { formik, name } = this.props;
    const values = formik.values[name];
    return formik.setFieldValue(name, values.filter(v => v.id !== value.id));
  };

  public renderSelectedItems = (values: PossibleOrglabFilters[]) => {
    return values.map(value => {
      return (
        <Chip
          color="secondary"
          key={value.id}
          label={truncate(value.name, {
            length: 20,
          })}
          onDelete={this.handleDelete(value)}
        />
      );
    });
  };

  public render() {
    const { filters, name, classes } = this.props;

    return (
      <Field
        component={FormSelectField}
        name={name}
        fullWidth={true}
        multiple={true}
        MenuProps={{
          MenuListProps: {
            dense: true,
          },
        }}
        renderValue={(values: PossibleOrglabFilters[]) => (
          <Grid container={true} wrap="wrap">
            {this.renderSelectedItems(values)}
          </Grid>
        )}
      >
        {this.renderFilters(filters, 0, classes)}
      </Field>
    );
  }
}

export default connect<FieldFiltersOuterProps, RoleSearchFields>(
  withStyles(styles)(FieldFilters)
);
