// import FormEditProcessElement from "./FormEditProcessElement";
import {
  AppBar,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { CheckBoxOutlined } from "@material-ui/icons";
import { Field, Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  OVERLAY_VALUES,
  TIME_UNITS,
  TIME_UNITS_NUM,
} from "../../config/constants";
import useDiagramInteractions from "../../hooks/useDiagramInteractions";
import { DiagramComponentOperation } from "../../hooks/useGetDiagram/types";
import useUpdateOperation from "../../hooks/useUpdateOperation";
import FormikAutoSave from "../FormikAutoSave";
import FormTextField from "../FormTextField";
import { IconClose } from "../Icons";
import FieldAnalytic from "./FieldAnalytic";
import FieldComment from "./FieldComment";
import FieldName from "./FieldName";
import FieldSelectSubprocess from "./FieldSelectSubprocess";
import FieldTime from "./FieldTime";

interface FormEditProcessElementProps {
  onClose?: () => void;
  tabIndex?: number;
  operation: DiagramComponentOperation;
}

function getDefaultTime(timeArry: string[]) {
  const defaultUnit =
    localStorage.getItem("default_time_unit") || TIME_UNITS_NUM[3];
  return timeArry.length <= 0 ? ["", defaultUnit] : timeArry;
}

const FormEditProcessElement: React.SFC<FormEditProcessElementProps> = ({
  onClose,
  tabIndex = 0,
  operation,
}) => {
  const { t } = useTranslation();
  const {
    actions: { openAP },
  } = useDiagramInteractions();
  const [updateOperation] = useUpdateOperation();

  if (operation) {
    const subprocess = operation.subProcess;
    const subProcessName = operation.subProcessName;
    const initialValues = {
      ...operation,
      cycleTime: getDefaultTime(operation.cycleTime),
      waitTime: getDefaultTime(operation.waitTime),
      selectType: subprocess ? "subprocess" : operation.type,
      dataElements: operation.dataElements[0] ? operation.dataElements : [""],
      inputs: operation.inputs[0] ? operation.inputs : [""],
      itSystems: operation.itSystems[0] ? operation.itSystems : [""],
      outputs: operation.outputs[0] ? operation.outputs : [""],
      painPoints: operation.painPoints[0] ? operation.painPoints : [""],
      activeAnalyticField: null,
    };

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => updateOperation(operation.id, values)}
        render={({ values, handleChange }) => {
          return (
            <Form style={{ height: "100%", overflow: "auto" }} translate="yes">
              <AppBar position="static" color="default">
                <Grid
                  container={true}
                  wrap="nowrap"
                  spacing={2}
                  alignItems="center"
                >
                  <Grid item={true} xs={true}>
                    <Tabs
                      value={tabIndex}
                      onChange={(_e, value) => openAP(value)}
                      indicatorColor="secondary"
                      textColor="primary"
                    >
                      <Tab value={0} label="Details" />
                      <Tab value={1} label="Analytics" />
                    </Tabs>
                  </Grid>
                  <Grid item={true}>
                    <IconButton onClick={onClose}>
                      <IconClose />
                    </IconButton>
                  </Grid>
                </Grid>
              </AppBar>
              <FormikAutoSave wait={600} />
              {tabIndex === 0 && (
                <Box p={2}>
                  <Field
                    component={FieldName}
                    name="name"
                    label={t("elementLabel", { context: operation.type })}
                  />
                  {operation.type === "task" && (
                    <Field
                      component={FieldSelectSubprocess}
                      name="selectType"
                      diagramName={values.name}
                      subProcessName={subProcessName}
                      operationId={operation.id}
                    />
                  )}
                  <Grid
                    container={true}
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                    wrap="nowrap"
                    spacing={2}
                  >
                    <Grid item={true}>
                      <Field component={FieldTime} name="cycleTime.0" />
                    </Grid>
                    <Grid item={true}>
                      <Field component={FieldTime} name="waitTime.0" />
                    </Grid>
                  </Grid>
                  <p>
                    <InputLabel>{t("Responsibility Assignments")}</InputLabel>
                  </p>
                  <Grid item={true}>
                    {operation &&
                      operation.roleNotes &&
                      operation.roleNotes.map((roleNote, index) => {
                        return roleNote.name ? (
                          <Field
                            key={index}
                            component={FormTextField}
                            label={`${
                              roleNote.name
                            } ${roleNote.assignmentDisplay || ""}`}
                            name={`roleNotes.${index}.text`}
                            multiline={true}
                            fullWidth={true}
                            margin="normal"
                          />
                        ) : null;
                      })}
                  </Grid>
                  <Field component={FieldComment} name="comment" />
                  {operation.type === "task" && (
                    <FormControl fullWidth={true} margin="normal">
                      <Typography>{t("Properties")}</Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="repeats"
                            checked={Boolean(values.repeats)}
                            onChange={handleChange}
                            color="default"
                            checkedIcon={<CheckBoxOutlined />}
                          />
                        }
                        label={
                          <div style={{ position: "relative", top: 10 }}>
                            {t("repeatingOperation")}
                          </div>
                        }
                      />
                    </FormControl>
                  )}
                </Box>
              )}

              {tabIndex === 1 && (
                <Box p={2}>
                  {OVERLAY_VALUES.map(({ name, id, color, Icon }) => (
                    <FieldAnalytic
                      key={id}
                      name={id}
                      label={name}
                      values={values[id] || []}
                      Icon={Icon}
                      color={color}
                    />
                  ))}
                </Box>
              )}
            </Form>
          );
        }}
      />
    );
  }
  return null;
};

export default FormEditProcessElement;
