import { PieArcDatum } from "d3";
import * as d3 from "d3";

export interface AsterPlotData {
  id: string;
  score: number;
  assignmentCount: number;
  splitByProcess: {
    [key: string]: number;
  };
  color: string;
  label: string;
  order: number;
  orglab: boolean;
  employeeName: string;
}

export default function useGetAsterPlotData(
  data: AsterPlotData[],
  width: number,
  height: number
) {
  const radius = Math.min(width, height) / 2,
    innerRadius = 0.3 * radius;

  const createPie = d3
    .pie<AsterPlotData>()
    .sort(null)
    .value(d => 1);

  const createArc = d3
    .arc<PieArcDatum<AsterPlotData>>()
    .innerRadius(innerRadius)
    .outerRadius(d => {
      return (radius - innerRadius) * (d.data.score / 100.0) + innerRadius;
    });

  const createOutlineArc = d3
    .arc<PieArcDatum<AsterPlotData>>()
    .innerRadius(innerRadius)
    .outerRadius(radius);

  const plotData = createPie(data).map(item => {
    const arcData = createArc(item) || "";
    const outlineArcData = createOutlineArc(item) || "";
    return {
      ...item.data,
      arcData,
      outlineArcData,
    };
  });

  return { plotData, radius, innerRadius };
}
