import { ReduxActionCreator } from "../types";
import * as actions from "./actions";

export enum DiagramFiltersActionTypes {
  DIAGRAM_FILTER_TOGGLE_OVERLAY = "toggle diagram analytic overlays",
  DIAGRAM_FILTER_TOGGLE_RACI = "toggle diagram raci values",
  DIAGRAM_FILTER_RESET = "reset diagram filter",
}

export interface DiagramFiltersState {
  selectedOverlays: {
    [key: string]: boolean | null;
  };
  selectedRACIValue: string | null;
}

export type DiagramFilterActionCreatorReset = ReduxActionCreator<
  DiagramFiltersActionTypes.DIAGRAM_FILTER_RESET,
  null
>;

export type DiagramFilterActionCreatorToggleOverlay = ReduxActionCreator<
  DiagramFiltersActionTypes.DIAGRAM_FILTER_TOGGLE_OVERLAY,
  string,
  [string]
>;

export type DiagramFilterActionCreatorToggleRACI = ReduxActionCreator<
  DiagramFiltersActionTypes.DIAGRAM_FILTER_TOGGLE_RACI,
  string,
  [string]
>;

export type DiagramFilterAction =
  | ReturnType<typeof actions.diagramFilterReset>
  | ReturnType<typeof actions.diagramFilterToggleOverlay>
  | ReturnType<typeof actions.diagramFilterToggleRACI>;
