import { FormLabel } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import { Field, Form, Formik } from "formik";
import React, { Component } from "react";
import {
  AutoSizer,
  Index,
  InfiniteLoader,
  List,
  ListRowProps,
} from "react-virtualized";
import { compose } from "recompose";
import { pdClient } from "../../lib/apiClient";
import {
  OrglabFilters,
  OrglabFiltersBusinessUnits,
  OrglabFiltersFunctions,
  OrglabFiltersLocations,
} from "../../types/OrglabFilters";
import {
  RoleSearchApi,
  RoleSearchResponse,
  RolesSearchResponse,
} from "../../types/Roles";
import { ButtonAssignRole, ButtonClearAll } from "../Button";
import DisplayStructurePath from "../DisplayStructurePath";
import FormikAutoSave from "../FormikAutoSave";
import FormTextField from "../FormTextField";
import LoadingWindow from "../LoadingWindow";
import RoleSearchItem from "../RoleSearchItem";
import FieldFilters from "./FieldFilters";

const styles = (theme: Theme) =>
  createStyles({
    typographyDisabled: {
      color: theme.palette.text.disabled,
    },
    searchSection: { width: "33.3%", padding: 20, paddingTop: 10 },
    resultsSection: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      position: "relative",
    },
    switchBase: {
      color: theme.palette.secondary.main,
    },
    switchTrack: {
      backgroundColor: theme.palette.secondary.main,
    },
  });

const initialValues = {
  searchText: "",
  locationFilter: [],
  functionFilter: [],
  businessUnitFilter: [],
};

export interface RoleSearchFields {
  searchText: string;
  locationFilter: OrglabFiltersLocations[];
  functionFilter: OrglabFiltersFunctions[];
  businessUnitFilter: OrglabFiltersBusinessUnits[];
}

interface RoleSearchOuterProps {
  structureId: string;
  getRoles?: (searchApi: RoleSearchApi) => Promise<RolesSearchResponse>;
  onSelect: (orglabRole: RoleSearchResponse) => void;
  filters: OrglabFilters;
}

type RoleSearchInnerProps = WithStyles<typeof styles>;

type RoleSearchProps = RoleSearchInnerProps & RoleSearchOuterProps;

interface RoleSearchState {
  roles: RoleSearchResponse[];
  rowCount: number;
  pageNumber: number;
  newFilter: boolean;
  totalCount: number;
  searchByRole: boolean;
  selectedRole?: RoleSearchResponse;
}

export class RoleSearch extends Component<RoleSearchProps, RoleSearchState> {
  public static defaultProps = {
    structureId: "",
    getRoles: pdClient.getRoles,
  };
  constructor(props: any) {
    super(props);

    this.state = {
      roles: [],
      rowCount: 0,
      pageNumber: 1,
      newFilter: false,
      totalCount: 0,
      searchByRole: true,
      selectedRole: undefined,
    };
  }

  public searchByRoleSwitched = (values: RoleSearchFields) => () => {
    this.setState({ searchByRole: !this.state.searchByRole }, () => {
      this.fetchData(values);
    });
  };

  public isRowLoaded = ({ index }: Index) => {
    // console.log("row loaded", !!this.state.roles[index], index);
    return !!this.state.roles[index];
  };

  public loadMoreRows = (values: RoleSearchFields) => () => {
    const { getRoles = pdClient.getRoles } = this.props;
    const { pageNumber } = this.state;

    return getRoles(this.getSearchParams(values, pageNumber + 1)).then(
      (res) => {
        this.setState(({ roles, pageNumber }: RoleSearchState) => {
          const newTotalRoles = roles.concat(res.roles);
          const newTotalRolesLength = newTotalRoles.length;
          const newTotalRolesLengthPlusOne = newTotalRolesLength + 1;
          const newRowCount =
            newTotalRolesLengthPlusOne >= res.count
              ? newTotalRolesLength
              : newTotalRolesLengthPlusOne;
          return {
            roles: roles.concat(res.roles),
            pageNumber: pageNumber + 1,
            rowCount: newRowCount,
            newFilter: false,
          };
        });
      },
    );
  };

  public rowRenderer = ({ key, index, style }: ListRowProps) => {
    const { roles, selectedRole } = this.state;
    const role = roles[index];

    return (
      <div key={key} style={style}>
        {role ? (
          <RoleSearchItem
            roleName={role.name || "-"}
            employeeName={(role.employee && role.employee.name) || "-"}
            selected={selectedRole && role.id === selectedRole.id}
            onClick={() => this.setState({ selectedRole: role })}
          />
        ) : (
          <CircularProgress size={16} color="secondary" />
        )}
      </div>
    );
  };

  public getSearchParams = (
    values: RoleSearchFields,
    pageNumber: number,
  ): RoleSearchApi => {
    const { searchByRole } = this.state;
    const { structureId } = this.props;
    return {
      pageNumber,
      searchText: values.searchText.toLowerCase(),
      searchByRole,
      structureId,
      location: values.locationFilter.map((x) => x.id),
      lookups: values.businessUnitFilter.map((x) => x.id),
      function: values.functionFilter.map((x) => x.id),
    };
  };

  public fetchData = (values: RoleSearchFields) => {
    const { getRoles = pdClient.getRoles } = this.props;
    return getRoles(this.getSearchParams(values, 1)).then((res) => {
      if (res !== undefined) {
        this.setState({
          roles: res.roles,
          totalCount: res.count,
          rowCount: res.count < 20 ? res.roles.length : res.roles.length + 1,
          pageNumber: 1,
          newFilter: true,
        });
      }
    });
  };

  public render() {
    const { searchByRole, selectedRole } = this.state;
    const { classes, onSelect, structureId, filters } = this.props;

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values, actions) => {
          this.fetchData(values).then(() => {
            actions.setSubmitting(false);
          });
        }}
        render={({ values, isSubmitting, handleReset }) => (
          <div style={{ width: "100%", height: "100%", display: "flex" }}>
            <FormikAutoSave wait={600} submitOnMount={true} />
            <Form className={classes.searchSection} translate="yes">
              <FormLabel disabled={!searchByRole}>Role</FormLabel>
              <Switch
                checked={!searchByRole}
                onChange={this.searchByRoleSwitched(values)}
                value="searchByRole"
                color="secondary"
                classes={{
                  switchBase: classes.switchBase,
                  track: classes.switchTrack,
                }}
              />
              <FormLabel disabled={searchByRole}>Employee</FormLabel>
              <Field
                component={FormTextField}
                label={searchByRole ? "Search by Role" : "Search by Employee"}
                name="searchText"
                fullWidth={true}
                margin="dense"
                disabled={false}
              />
              {searchByRole && (
                <div>
                  <FieldFilters
                    name="locationFilter"
                    filters={filters.locations}
                  />
                  <FieldFilters
                    name="functionFilter"
                    filters={filters.functions}
                  />
                  <FieldFilters
                    name="businessUnitFilter"
                    filters={filters.lookups}
                  />
                </div>
              )}

              <div>
                <FormControl margin="normal">
                  <ButtonClearAll onClick={handleReset} />
                </FormControl>
              </div>

              <FormControl margin="normal">
                {selectedRole ? (
                  <ButtonAssignRole onClick={() => onSelect(selectedRole)} />
                ) : (
                  <ButtonAssignRole disabled={true} />
                )}
              </FormControl>
            </Form>
            <div className={classes.resultsSection}>
              <div style={{ padding: "20px 10px 8px 8px" }}>
                <div style={{ fontSize: "1rem", lineHeight: "1.5em" }}>
                  <DisplayStructurePath structureId={structureId} />
                </div>
                <Typography>{this.state.totalCount}&nbsp;Result(s)</Typography>
              </div>
              <div style={{ flex: 1 }}>
                <AutoSizer>
                  {({ height, width }) => (
                    <div>
                      <InfiniteLoader
                        isRowLoaded={this.isRowLoaded}
                        loadMoreRows={this.loadMoreRows(values)}
                        rowCount={this.state.rowCount}
                      >
                        {({ onRowsRendered, registerChild }) => (
                          <List
                            height={height}
                            onRowsRendered={onRowsRendered}
                            ref={registerChild}
                            rowCount={this.state.rowCount}
                            rowHeight={40}
                            rowRenderer={this.rowRenderer}
                            width={width}
                            style={{ outline: 0 }}
                            scrollToIndex={this.state.newFilter ? 0 : undefined}
                          />
                        )}
                      </InfiniteLoader>
                    </div>
                  )}
                </AutoSizer>
              </div>
              {isSubmitting && <LoadingWindow />}
            </div>
          </div>
        )}
      />
    );
  }
}

const enhance = compose<RoleSearchProps, RoleSearchOuterProps>(
  withStyles(styles),
);

export default enhance(RoleSearch);
