import { ClickAwayListener, Menu, MenuItem } from "@material-ui/core";
import _ from "lodash";
import PopupState, {
  bindMenu,
  bindTrigger,
} from "material-ui-popup-state/index";
import React from "react";
import useDeleteCustomFramework from "../../hooks/useDeleteCustomFramework";
import { Framework } from "../../types/Framework";
import { ButtonMenu } from "../Button";
import DialogToggle from "../DialogToggle";
import FormEditFramework from "../FormEditFramework";
import { confirm } from "../../store/modal/actions";
import { useDispatch } from "react-redux";
import { notifyError } from "../../store/notifications/actions";
import { useTranslation } from "react-i18next";

interface OwnProps {
  framework: Framework;
}

export default function MenuFrameworkEdit({ framework }: OwnProps) {
  const [deleteFramework] = useDeleteCustomFramework();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function handleDelete() {
    dispatch(
      confirm({
        i18nKey: "deleteCustomFramework",
        i18nOptions: {
          frameworkName: framework.name,
        },
        onConfirm: () =>
          deleteFramework(framework.id)
            .then((resp) => {
              if (resp.data && resp.data.DeleteFramework) {
                dispatch(notifyError(t("frameworkDeleteAttachedError")));
              }
            })
            .catch((e) => {
              console.log({ e });
              dispatch(notifyError(t("frameworkDeleteError")));
            }),
      })
    );
  }

  return (
    <div>
      <DialogToggle
        title="Edit Custom Framework"
        width={400}
        render={({ handleOpen }) => (
          <PopupState variant="popover" popupId="framework-menu">
            {(popupState: any) => (
              <ClickAwayListener onClickAway={popupState.close}>
                <React.Fragment>
                  <ButtonMenu {...bindTrigger(popupState)} />
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem onClick={handleOpen}>Edit</MenuItem>
                    {framework.custom && (
                      <MenuItem onClick={handleDelete}>Delete</MenuItem>
                    )}
                  </Menu>
                </React.Fragment>
              </ClickAwayListener>
            )}
          </PopupState>
        )}
        fullWidth={true}
        renderContent={({ handleClose }) => (
          <FormEditFramework
            isEdit={true}
            initialValues={framework}
            onCancel={handleClose}
          />
        )}
      />
    </div>
  );
}
