import { useQuery } from "@apollo/react-hooks";
import { GetFrameworks } from "../../generated/GetFrameworks";
import GET_FRAMEWORKS from "../../graphql/query/getFrameworks";
import normalizeFrameworks, {
  GetFrameworksResult,
} from "./normalizeFrameworks";

function useGetFrameworks(): {
  frameworks: GetFrameworksResult;
  loading: boolean;
} {
  const { data, loading } = useQuery<GetFrameworks>(
    GET_FRAMEWORKS,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  if (data) {
    return { frameworks: normalizeFrameworks(data.FrameworkList), loading };
  }
  return { frameworks: [], loading };
}

export default useGetFrameworks;
