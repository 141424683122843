import { useQuery } from "@apollo/react-hooks";
import idx from "idx";
import { capitalize } from "lodash";
import { useParams, useRouteMatch } from "react-router-dom";
import {
  GetBreadcrumbs,
  GetBreadcrumbsVariables,
} from "../../generated/GetBreadcrumbs";
import GET_BREADCRUMBS from "../../graphql/query/getBreadcrumbs";
import isNotNull from "../../lib/isNotNull";
import useGetCurrentDiagramId from "../useGetCurrentDiagramId";

export default function useGetBreadcrumbs(): {
  data: Array<{
    id: string;
    name: string;
  }>;
  loading: boolean;
} {
  const diagramId = useGetCurrentDiagramId();
  const match = useRouteMatch<{ id: string }>();
  const { slug } = useParams();
  const { data, loading } = useQuery<GetBreadcrumbs, GetBreadcrumbsVariables>(
    GET_BREADCRUMBS,
    {
      fetchPolicy: "no-cache",
      variables: {
        id: diagramId,
      },
    }
  );
  const breadcrumbs = (idx(data, _ => _.ProcessBreadcrumbs) || [])
    .filter(isNotNull)
    .map(({ id, name }) => ({
      id,
      name,
    }));

  if (match.params.id && slug) {
    breadcrumbs.push({
      id: match.params.id,
      name: capitalize(slug),
    });
  }

  return {
    data: breadcrumbs,
    loading,
  };
}
