import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/styles";
import _ from "lodash";
import React, { useState } from "react";
import { isValueInList } from "../../lib/list-helpers/index";
import RACIButton from "../RACIButton";

const useStyles = makeStyles({
  action: {
    padding: "0 16px 16px",
    textAlign: "center",
  },
  paper: {
    display: "inline-block",
  },
  value: {
    margin: 12,
  },
  values: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "center",
    margin: "0 auto",
    padding: 16,
    width: 140,
  },
});

interface RACIOption {
  symbol?: string;
  color?: string;
  id: string;
}

interface Props {
  values: string[];
  options: RACIOption[];
  maxValues?: number;
  onSelect: (value: string) => void;
  onRemove: (value: string) => void;
  disabled?: boolean;
}

const RACIMenu: React.SFC<Props> = (props) => {
  const {
    values,
    options = [],
    children,
    onSelect,
    onRemove,
    maxValues = 2,
    disabled = false,
  } = props;
  const classes = useStyles();
  const [activeValues, setActiveValues] = useState<string[]>(values);

  return (
    <Paper className={classes.paper} elevation={5}>
      <div className={classes.values}>
        {options.map(({ symbol, color, id }, i) => {
          const optionDisabled = _.gte(activeValues.length, maxValues);
          const active = isValueInList(activeValues, id);

          return (
            <RACIButton
              disabled={disabled || (optionDisabled && !active)}
              outline={!active}
              key={i}
              color={color}
              className={classes.value}
              onClick={() => {
                if (active) {
                  onRemove(id);
                  setActiveValues(activeValues.filter((_) => _ !== id));
                } else {
                  onSelect(id);
                  setActiveValues([...activeValues, id]);
                }
              }}
            >
              {symbol}
            </RACIButton>
          );
        })}
      </div>
      {children && <div className={classes.action}>{children}</div>}
    </Paper>
  );
};

export default RACIMenu;
