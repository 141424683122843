import { styled } from "@material-ui/core";

export const ChartBarSpacer = styled("div")<
  null,
  {
    showPlaceholder: boolean;
  }
>(({ showPlaceholder }) => ({
  boxSizing: "border-box",
  position: "relative",
  borderRight: showPlaceholder ? "1px solid #ccc" : 0,
  transition: "width .3s",
  height: 20,
  background: "rgba(0,0,0,.03)",
  "&:after": {
    content: "' '",
    display: showPlaceholder ? "block" : "none",
    position: "absolute",
    top: "50%",
    right: 0,
    width: 8,
    height: 8,
    background: "#fff",
    border: "1px solid #ccc",
    transform: "translate(50%, -50%) rotate(45deg)",
    boxSizing: "border-box",
  },
}));

export const ChartBar = styled("div")<
  null,
  {
    width: number | string;
    height?: number;
    backgroundColor: string;
    highlight?: boolean;
  }
>(({ width, backgroundColor, highlight, height = 20 }) => ({
  height,
  width,
  transition: "width .3s",
  backgroundColor,
  backgroundImage: highlight
    ? `url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAL0lEQVQYV2NkYGBg+P//vy+IRgaMIEFGRsbN6JJgCZBKdEm4BLokigSyJIYETBIABeokeXRNZHcAAAAASUVORK5CYII=)`
    : "none",
}));
