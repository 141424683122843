import { Checkbox, makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { CheckBoxOutlined } from "@material-ui/icons";
import React, { ReactNode } from "react";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    marginLeft: 4,
  },
  selectAllStyle: {
    display: "flex",
    alignItems: "center",
    height: 42,
  },
});

export interface Role {
  id: string;
  name?: string;
  orglabID?: string;
}

interface OwnProps {
  isAllSelected: boolean;
  onToggleAll: (on: boolean) => void;
  children: ReactNode;
}

const InvolvedRoles: React.SFC<OwnProps> = ({
  onToggleAll,
  children,
  isAllSelected,
}) => {
  const classes = useStyles();

  return (
    <div>
      <div>
        <div className={classes.selectAllStyle}>
          <Typography>
            Involved Roles:{" "}
            <Checkbox
              checked={isAllSelected}
              value="checkedB"
              color="default"
              onChange={e => {
                onToggleAll(!isAllSelected);
              }}
              checkedIcon={<CheckBoxOutlined />}
            />{" "}
            Select All
          </Typography>
        </div>
        <div className={classes.root}>
          <Grid container={true} justify="flex-start" spacing={1}>
            {children}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default InvolvedRoles;
