import React, { useState } from "react";
import Countdown from "react-countdown";
import { SESSION_TIMEOUT_DIALOG_MS } from "../../config/constants";
import useReduxDispatch from "../../hooks/useReduxDispatch";
import { logout, refreshSession } from "../../store/login/actions";
import { getLoginExpirationDate } from "../../store/selectors";
import ConfirmationDialog from "../ConfirmationDialog";

export default function AuthSessionTimer() {
  const [forceHideWarning, setForceHideWarning] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [expirationDateInMs, setExpirationDateInMs] = useState(
    getLoginExpirationDate()
  );
  const dispatch = useReduxDispatch();

  const updateExpiration = () =>
    setExpirationDateInMs(getLoginExpirationDate());

  const handleConfirmationConfirm = () => {
    setShowWarning(false);
    setForceHideWarning(true);
    dispatch(refreshSession()).then(() => {
      setForceHideWarning(false);
      updateExpiration();
    });
  };

  const handleConfirmationCancel = () => {
    dispatch(logout());
  };

  const CounterComponent = (
    <Countdown
      date={expirationDateInMs}
      renderer={({ formatted: { minutes, seconds }, completed }) =>
        completed
          ? ""
          : `Your session is going to timeout in ${minutes}:${seconds}`
      }
      onTick={({ total }) => {
        updateExpiration();
        setShowWarning(total <= SESSION_TIMEOUT_DIALOG_MS);
      }}
      onComplete={handleConfirmationCancel}
      onMount={({ completed }) => {
        if (completed) {
          handleConfirmationCancel();
        }
      }}
    />
  );

  return showWarning && !forceHideWarning ? (
    <ConfirmationDialog
      handleConfirm={handleConfirmationConfirm}
      handleCancel={handleConfirmationCancel}
      open={true}
      props={{
        customMessage: CounterComponent,
        i18nKey: "sessionExpire",
      }}
    />
  ) : (
    <span style={{ display: "none" }}>{CounterComponent}</span>
  );
}
