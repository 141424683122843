import { useMutation } from "@apollo/react-hooks";
import { arrayMove } from "react-sortable-hoc";
import { UpdateRoleSortOrderVariables } from "../../generated/UpdateRoleSortOrder";
import { UPDATE_ROLE_SORT_ORDER } from "../../graphql/mutation/updateRoleSortOrder";
import useGetCurrentDiagramId from "../useGetCurrentDiagramId";
import useGetDiagram from "../useGetDiagram";

export default function useUpdateRoleSortOrder() {
  const [sortRoles] = useMutation<any, UpdateRoleSortOrderVariables>(
    UPDATE_ROLE_SORT_ORDER
  );
  const diagramId = useGetCurrentDiagramId();
  const { diagram } = useGetDiagram(diagramId);

  function _sortRoles(newIndex: number, oldIndex: number) {
    if (diagram) {
      const newRoleOrder = arrayMove(diagram.roleOrder, oldIndex, newIndex);
      return sortRoles({
        variables: {
          processID: diagram.id,
          roleOrder: newRoleOrder,
        },
        optimisticResponse: {
          __typename: "Mutation",
          ChangeRoleOrder: {
            id: diagram.id,
            roleOrder: newRoleOrder,
            __typename: "Process",
          },
        },
      });
    }
    return Promise.resolve();
  }

  return [_sortRoles];
}
