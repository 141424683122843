import { AxiosPromise } from "axios";
import _ from "lodash";
import { AnyAction, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { PDClient } from "../../lib/apiClient";
import { LoginResponse } from "../../lib/apiClient/types";
import { clearAuthToken, getAuthToken } from "../../lib/authToken";
import { appVersionSet } from "../appVersion/actions";
import { designSessionsLoad } from "../designSessions/actions";
import { notifyError } from "../notifications/actions";
import { State, ThunkExtraArguments } from "../types";
import * as actionTypes from "./actionTypes";
import { getUserNameFromJWT } from "./utils";

export const login = (
  clientNameValue: string
): ThunkAction<
  AxiosPromise<LoginResponse>,
  State,
  ThunkExtraArguments,
  any
> => (
  dispatch: Dispatch<AnyAction>,
  _getState: () => State,
  { pdClient, _i18n }: ThunkExtraArguments
) => {
  const token = getAuthToken();
  return pdClient
    .login(clientNameValue, token)
    .then(response => {
      const tokenId = response.data.olToken;
      const clientId = response.data.clientId;
      const clientName = response.data.clientName;
      const userEmail = response.data.userEmail;

      if (clientId) {
        dispatch({
          payload: {
            session: tokenId,
            clientName,
            username: getUserNameFromJWT(token),
            clientId,
            userEmail,
          },
          type: actionTypes.LOGIN_SUCCESS,
        });
      } else {
        dispatch({ type: actionTypes.LOGIN_FAILED });
        dispatch(notifyError(_i18n.t("loginNoStructures")));
        return Promise.reject({ invalidStructure: true });
      }
      return Promise.resolve(response);
    })
    .catch((e: any) => {
      dispatch({ type: actionTypes.LOGIN_FAILED });
      if (!e.invalidStructure) {
        dispatch(notifyError(_i18n.t("loginInvalidClient")));
      }
      return Promise.reject(e);
    });
};

export const loginGuest = () => ({ type: actionTypes.LOGIN_GUEST });

export const logout = () => {
  clearAuthToken();
  return { type: actionTypes.LOGOUT };
};

export const loadInitialData = () => async (
  dispatch: Dispatch<AnyAction>,
  getState: () => State,
  { pdClient }: { pdClient: PDClient }
) => {
  const { clientId } = getState().login;

  const versionResponse = await pdClient.getVersion();

  dispatch(appVersionSet(versionResponse));

  if (clientId) {
    return pdClient
      .getInitialAppData(clientId)
      .then(response => {
        dispatch(designSessionsLoad(response.clients.phases));
      })
      .catch(() => {
        dispatch(logout());
      });
  } else {
    return Promise.reject();
  }
};

export const refreshSession = () => (
  _dispatch: Dispatch<AnyAction>,
  _getState: () => State,
  { pdClient }: { pdClient: PDClient }
) => {
  return pdClient.refreshSession();
};
