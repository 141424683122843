import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import useCreateSampleDiagram from "../../hooks/useCreateSampleDiagram";
import useGetDiagrams from "../../hooks/useGetDiagrams";
import { ButtonAddNew } from "../Button";
import ButtonFloatingAction from "../ButtonFloatingAction";
import DiagramListEmpty from "../DiagramListEmpty";
import DialogToggle from "../DialogToggle";
import FormDiagram from "../FormDiagram";
import LoadingInline from "../LoadingInline";
import DiagramList from "./DiagramList";
import DiagramTree from "./DiagramTree";

const DiagramTable: React.SFC = () => {
  const { diagrams, diagramsTree, loading } = useGetDiagrams();
  const {
    createSampleDiagram,
    loading: createLoading,
  } = useCreateSampleDiagram();
  return (
    <Switch>
      <Route
        path={process.env.PUBLIC_URL + "/(list)?"}
        exact={true}
        render={() => (
          <React.Fragment>
            <ButtonFloatingAction>
              <DialogToggle
                render={({ handleOpen }) => (
                  <ButtonAddNew onClick={handleOpen} />
                )}
                renderContent={({ handleClose }) => (
                  <FormDiagram
                    onAfterSubmit={handleClose}
                    onCancel={handleClose}
                  />
                )}
              />
            </ButtonFloatingAction>
            {loading ? (
              <LoadingInline />
            ) : diagrams.length > 0 ? (
              <div style={{ position: "relative", height: "100%" }}>
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                  }}
                >
                  <Switch>
                    <Route
                      path={process.env.PUBLIC_URL +"/"}
                      exact={true}
                      component={() => (
                        <DiagramTree
                          data={diagramsTree}
                          dataLength={diagrams.length}
                        />
                      )}
                    />
                    <Route
                      path={process.env.PUBLIC_URL +"/list"}
                      exact={true}
                      component={() => <DiagramList data={diagrams} />}
                    />
                  </Switch>
                </div>
              </div>
            ) : (
              <DiagramListEmpty
                onCreateSample={() => createSampleDiagram()}
                createLoading={createLoading}
              />
            )}
          </React.Fragment>
        )}
      />
      <Redirect to={process.env.PUBLIC_URL + "/404"} />
    </Switch>
  );
};

export default DiagramTable;
