import { IconButton, Portal } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import { Check, Refresh } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { SortableElement, SortableHandle } from "react-sortable-hoc";
import useDiagramInteractions from "../../hooks/useDiagramInteractions";
import useUpdateRole from "../../hooks/useUpdateRole";
import { pdClient } from "../../lib/apiClient";
import updateRoleWithPaths from "../../lib/updateRoleWithPaths";
import { notifyWarning } from "../../store/notifications/actions";
import { FileRole } from "../../types/Diagram";
import { ErrorResponse } from "../../types/Error";
import { RoleResponse, RoleSearchResponse } from "../../types/Roles";
import RoleInfoWindow from "../RoleInfoWindow";
import RolePanelItemMenu from "../RolePanelItemMenu";
import { ShapeDragHandle } from "../Shapes/index";
import RolePanelItem from "./RolePanelItem";

const SortableRoleItem = SortableElement<any>(({ children, ...props }: any) => (
  <div {...props}>{children}</div>
));

const SortableRoleHandle = SortableHandle<any>(
  ({ children, ...props }: any) => <ShapeDragHandle {...props} />
);

const useStyles = makeStyles((theme: Theme) => ({
  actionsContainer: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: "100%",
    paddingRight: 8,
    display: "flex",
    alignItems: "center",
    visibility: "hidden",
  },
  actionsContainerVisible: {
    visibility: "visible",
  },
  dragHandle: {
    cursor: "move",
    marginLeft: 5,
    color: theme.palette.primary.light,
    "&:hover": {
      color: theme.palette.primary.light,
    },
  },
}));

interface RolePanelItemConnectedProps {
  role: FileRole;
  index: number;
  focusOnRender: boolean;
  canSearchRoles?: boolean;
  onRoleClick: (roleId: string) => void;
  activeRoleId?: string;
  structureId?: string;
  canDeleteRole: boolean;
}

const RolePanelItemConnected: React.SFC<RolePanelItemConnectedProps> = ({
  role,
  structureId,
  onRoleClick,
  index,
  focusOnRender,
  canSearchRoles,
  activeRoleId,
  canDeleteRole,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ErrorResponse | undefined>(undefined);
  const [roleCardInfo, setRoleCardInfo] = useState<RoleResponse | undefined>(
    undefined
  );
  const { detachRole, attachRole, updateRoleName } = useUpdateRole();
  const { viewByRole, diagramId } = useDiagramInteractions();
  const viewRoleCard = viewByRole && viewByRole.id === role.id ? true : false;
  const dispatch = useDispatch();

  useEffect(
    () => {
      handleFetchRole();
    },
    [role.id, role.orglabRoleId]
  );

  function handleFetchRole() {
    const { orglabRoleId, name } = role;
    if (orglabRoleId) {
      fetchRole(orglabRoleId).then(orglabRole => {
        if (orglabRole && name !== orglabRole.name) {
          updateRoleName(role.id, orglabRole.name);
          dispatch(
            notifyWarning(
              `"${name}" has been changed to "${
                orglabRole.name
              }" due to an OrgLab update.`
            )
          );
        }
      });
    }
  }

  function fetchRole(
    orglabRoleId: string
  ): Promise<RoleResponse | undefined | void> {
    setLoading(true);
    if (structureId) {
      return pdClient
        .getRole(orglabRoleId, structureId)
        .then(orglabRoles => {
          setLoading(false);
          setRoleCardInfo(updateRoleWithPaths(orglabRoles[0]));
          return orglabRoles[0];
        })
        .catch(err => {
          const error = _.get(err, "response.data");
          setLoading(false);
          setError(error);
        });
    }
    return Promise.reject();
  }

  function handleRoleClick() {
    onRoleClick(role.id);
  }

  function handleRoleChange(name: string) {
    const { id } = role;
    return updateRoleName(id, name);
  }

  function handleRoleConnect(orglabRole: RoleSearchResponse) {
    attachRole(role.id, orglabRole.name, orglabRole.id);
    fetchRole(orglabRole.id);
  }

  function handleIsRoleSelected(): boolean {
    return _.isEqual(activeRoleId, role.id);
  }

  function renderErrorMessage() {
    const { name } = role;
    if (error) {
      if (error.code === 100) {
        const roleName = _.truncate(name, {
          length: 50,
        });
        return `"${roleName}" has been removed.`;
      }
      return error.message;
    }
    return undefined;
  }

  function renderErrorAction() {
    if (!error) {
      return null;
    }
    return error && error.code === 100 ? (
      <IconButton
        color="secondary"
        onClick={() => {
          detachRole(role.id, role.name);
          setError(undefined);
        }}
      >
        <Check />
      </IconButton>
    ) : (
      <IconButton color="secondary" onClick={handleFetchRole}>
        <Refresh />
      </IconButton>
    );
  }

  return (
    <SortableRoleItem
      index={index}
      key={index}
      dataroleid={role.id}
      collection="role"
    >
      <div style={{ position: "relative" }}>
        {viewRoleCard &&
          roleCardInfo && (
            <Portal>
              <RoleInfoWindow orglabRole={roleCardInfo} anchorId={role.id} />
            </Portal>
          )}
        <RolePanelItem
          loading={loading}
          role={role}
          error={renderErrorMessage()}
          errorAction={renderErrorAction()}
          orglabRole={roleCardInfo}
          focusOnRender={focusOnRender}
          selected={handleIsRoleSelected()}
          onRoleChange={handleRoleChange}
          onRoleClick={handleRoleClick}
          renderMenu={
            <RolePanelItemMenu
              diagramId={diagramId}
              role={role}
              canSearchRoles={canSearchRoles}
              orglabRole={roleCardInfo}
              onRoleSelect={handleRoleConnect}
              canDeleteRole={canDeleteRole}
              structureId={structureId}
            />
          }
        />
        <div
          className={classNames(classes.actionsContainer, {
            [classes.actionsContainerVisible]: handleIsRoleSelected(),
          })}
        >
          <SortableRoleHandle className={classes.dragHandle} />
        </div>
      </div>
    </SortableRoleItem>
  );
};

export default RolePanelItemConnected;
