import _ from "lodash";
import React, { useEffect } from "react";
import useDiagramInteractions from "../../hooks/useDiagramInteractions";
import { DiagramComponentState } from "../../hooks/useGetDiagram/types";
import { useGlobalToggleDiagramPath } from "../../hooks/useToggleDiagramPath";
import AnnotationPanel from "../AnnotationPanel";
import AssignmentConnects from "../AssignmentConnects";
import DiagramAssignments from "../DiagramAssignments";
import DubugPossiblePaths from "../DiagramAssignments/DubugPossiblePaths";
import DiagramFilters from "../DiagramFilters";
import DiagramProcessElements from "../DiagramProcessElements";
import DialogToggle from "../DialogToggle";
import FeedbackLink from "../FeedbackLink";
import FormEditProcessElement from "../FormEditProcessElement";
import FormEditRoleCapacity from "../FormEditRoleCapacity";
import RACISelectionMultiple from "../RACISelectionMultiple";
import { RoleMatrixLegendWindow } from "../RoleMatrixLegend";
import RoleSearchWindow from "../RoleSearchWindow";
import ScrollHorizontal from "../ScrollHorizontal";
import ScrollVertical from "../ScrollVertical";
import {
  DiagramContainer,
  DiagramInner,
  DiagramScrollPane,
} from "./DiagramLayout";

const RoleMatrix: React.SFC<{
  diagram: DiagramComponentState;
}> = ({ diagram }) => {
  const {
    diagramId,
    openRaciMultiselect,
    openAPIndex,
    activeOperationId,
    activeRoleId,
    capacityByRole,
    actions: {
      setClickOperation,
      setActiveRoleId,
      getActiveCellState,
      handleAssignmentClick,
      handleSelectMultiple,
      closeAP,
      resetInteractions,
      hideRoleCapacity,
    },
  } = useDiagramInteractions();

  const { currentPath, addOperations } = useGlobalToggleDiagramPath();

  const {
    operation,
    accountabilityMethodology: { accountabilityMethodologyValues },
  } = diagram;

  const activeOperation = diagram.operation.find(
    (o) => o.id === activeOperationId
  );

  const capacityRoleId = capacityByRole && capacityByRole.orglabRoleId;

  useEffect(
    () => {
      addOperations(operation);
    },
    [operation]
  );

  useEffect(() => () => resetInteractions(), []);

  return (
    <>
      <DiagramFilters options={accountabilityMethodologyValues} />
      <DiagramContainer>
        <DubugPossiblePaths diagramId={diagramId} />
        <DiagramScrollPane>
          <DiagramInner>
            <div>
              <ScrollHorizontal currentPath={currentPath}>
                {({ loading }) => (
                  <DiagramProcessElements
                    diagram={diagram}
                    currentPath={currentPath}
                    activeOperationId={activeOperationId}
                    onClickOperation={(id) => setClickOperation(id, 0)}
                    disableSorting={loading}
                  />
                )}
              </ScrollHorizontal>
              <ScrollVertical>
                <AssignmentConnects
                  currentPath={currentPath}
                  options={accountabilityMethodologyValues}
                >
                  <DiagramAssignments
                    diagram={diagram}
                    currentPath={currentPath}
                    activeOperationId={activeOperationId}
                    activeRoleId={activeRoleId}
                    getActiveState={getActiveCellState}
                    onRoleClick={(id) => setActiveRoleId(id)}
                    onAssignmentClick={handleAssignmentClick}
                  />
                </AssignmentConnects>
              </ScrollVertical>
            </div>
          </DiagramInner>
          <FeedbackLink />
        </DiagramScrollPane>
        {openRaciMultiselect && (
          <RACISelectionMultiple
            options={accountabilityMethodologyValues}
            onDone={handleSelectMultiple}
          />
        )}
        <AnnotationPanel open={Boolean(openAPIndex !== undefined)}>
          {activeOperation && (
            <FormEditProcessElement
              key={activeOperationId}
              onClose={closeAP}
              tabIndex={openAPIndex}
              operation={activeOperation}
            />
          )}
        </AnnotationPanel>
      </DiagramContainer>
      {diagram.structureId && (
        <RoleSearchWindow structureId={diagram.structureId} />
      )}
      <RoleMatrixLegendWindow assignments={accountabilityMethodologyValues} />
      {capacityRoleId &&
        diagram.client.orglabID && (
          <DialogToggle
            open={Boolean(capacityRoleId)}
            title="Add Capacity"
            render={() => null}
            renderContent={() => (
              <FormEditRoleCapacity
                orglabRoleId={capacityRoleId}
                onClose={() => hideRoleCapacity()}
              />
            )}
          />
        )}
    </>
  );
};

export default RoleMatrix;
