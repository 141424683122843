import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import { ProcessElementOuterProps } from "../../types/ProcessElement";
import { IconRepeat, IconSubprocessLink } from "../Icons";
import ProcessElementText from "../ProcessElementText";
import { ShapeChevron } from "../Shapes";
import TextOverflow from "../TextOverflow";
import WithProcessColors from "../WithProcessColors";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      width: "12.1em",
      height: "7em",
      position: "relative",
      fontSize: ".8em",
      "-webkit-tap-highlight-color": "rgba(0,0,0,0)",
    },
    svg: {
      width: "14em",
      position: "absolute",
      left: "-1.7em",
    },
    textOverflow: {
      position: "absolute",
      top: "0.4em",
      left: "0.4em",
      width: "10em",
      height: "5.7em",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    textInner: {
      fontSize: "12px",
      lineHeight: "14px",
    },
    number: {
      position: "absolute",
      bottom: "2px",
      right: "2.5em",
      fontSize: "10px",
      textAlign: "right",
    },
    repeats: {
      borderRadius: "50%",
    },
    icon: {
      width: 16,
      height: 16,
      borderRadius: "50%",
      background: "white",
      color: theme.palette.secondary.main,
    },
    overlays: {
      position: "absolute",
      left: 0,
      right: "1.5em",
      top: "6.4em",
      textAlign: "center",
    },
    overlay: {
      margin: "0 5px",
    },
  });

type ProcessElementTaskInnerProps = WithStyles<typeof styles> & WithTheme;

type ProcessElementTaskProps = ProcessElementOuterProps &
  ProcessElementTaskInnerProps;

const ProcessElementTask: React.SFC<ProcessElementTaskProps> = ({
  active = false,
  size = 1,
  text = "",
  theme,
  classes,
  countNumber,
  subtle = false,
  repeats,
  linkTo,
  highlight,
  ...props
}) => {
  return (
    <WithProcessColors
      subtle={subtle}
      text={text}
      active={active}
      highlight={highlight}
      render={({
        colors: { fill, stroke, textColor, numberColor, strokeWidth },
      }: any) => (
        <div className={classes.container} {...props}>
          <ShapeChevron
            className={classes.svg}
            stroke={stroke}
            strokeWidth={strokeWidth}
            fill={fill}
          />
          <Typography className={classes.number} style={{ color: numberColor }}>
            {countNumber}
          </Typography>
          <div className={classes.textOverflow}>
            <TextOverflow>
              <ProcessElementText textColor={textColor}>
                {text}
              </ProcessElementText>
            </TextOverflow>
          </div>
          <div className={classes.overlays}>
            {repeats && (
              <IconRepeat
                className={classNames(
                  classes.icon,
                  classes.overlay,
                  classes.repeats
                )}
              />
            )}
            {linkTo && (
              <Link className={classes.overlay} to={linkTo}>
                <IconSubprocessLink className={classes.icon} />
              </Link>
            )}
          </div>
        </div>
      )}
    />
  );
};

export default withTheme(withStyles(styles)(ProcessElementTask));
