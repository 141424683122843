import { useMutation } from "@apollo/react-hooks";
import { CreateAccountabilityMethodologyVariables } from "../../generated/CreateAccountabilityMethodology";
import { CreateFramework } from "../../generated/globalTypes";
import { CREATE_FRAMEWORK } from "../../graphql/mutation/createFramework";
import GET_FRAMEWORKS from "../../graphql/query/getFrameworks";

export default function useCreateFramework() {
  const [createFramework] = useMutation<
    any,
    CreateAccountabilityMethodologyVariables
  >(CREATE_FRAMEWORK);

  function _createFramework(
    values: CreateFramework,
  ) {
    const assignables =
      values !== null && values.assignables ? values.assignables : [];

    const assignablesToUpdate = assignables.map((value, idx) => ({
      name: value && value.name,
      description: value && value.description,
      color: value && value.color,
      exclusive: value && value.exclusive,
      symbol: value && value.symbol,
      order: idx,
    }));

    const newValues = values;
    newValues.assignables = assignablesToUpdate;
    return createFramework({
      variables: {
        values,
      },
      refetchQueries: [
        {
          query: GET_FRAMEWORKS,
        },
      ],
    });
  }

  return [_createFramework];
}
