import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { MuiThemeProvider as ThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import theme from "../../config/theme";

export interface DialogToggleRenderProps {
  handleOpen: () => void;
  handleClose: () => void;
}

interface OwnProps {
  render: (props: DialogToggleRenderProps) => React.ReactNode;
  renderContent: (props: DialogToggleRenderProps) => React.ReactNode;
  title?: React.ReactNode;
  width?: number;
  fullWidth?: boolean;
  open?: boolean;
}

export default function DialogToggle(props: OwnProps) {
  const { fullWidth, render, renderContent, title, width } = props;
  const [open, setOpen] = useState(props.open || false);

  useEffect(
    () => {
      props.open !== undefined && setOpen(props.open);
    },
    [props.open]
  );

  function handleOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
  }

  const ContentComponent = fullWidth
    ? (props: any) => React.createElement("div", props)
    : DialogContent;

  return (
    <>
      <React.Fragment>{render({ handleOpen, handleClose })}</React.Fragment>
      <ThemeProvider theme={theme}>
        <Dialog
          open={open}
          onClose={handleClose}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
          aria-labelledby="dialog-title"
        >
          {title && (
            <DialogTitle id="dialog-title" disableTypography={true}>
              <Typography color="secondary" variant="h6" component="div">
                {title}
              </Typography>
            </DialogTitle>
          )}
          <ContentComponent style={{ minWidth: width }}>
            {renderContent({
              handleClose,
              handleOpen,
            })}
          </ContentComponent>
        </Dialog>
      </ThemeProvider>
    </>
  );
}
