import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import Lottie from "react-lottie";
import { ButtonCreateDiagram, ButtonLoadSampleDiagram } from "../Button";
import DialogToggle from "../DialogToggle";
import FormDiagram from "../FormDiagram";
import animationData from "./animation/makeBreakfast.json";

const useStyles = makeStyles((theme) => ({
  animationContainer: {
    overflow: "hidden",
    paddingBottom: "40%",
    position: "relative",
  },
  animationOffset: {
    height: "100%",
    left: 0,
    marginTop: "0",
    position: "absolute",
    top: 0,
    width: "100%",
  },
  button: {
    margin: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px`,
  },
  buttons: {
    marginTop: 30,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
  },
  root: {
    margin: "0 auto",
    maxWidth: 900,
  },
}));

const EmptyCell: React.SFC<{
  onCreateSample?: () => void;
  createLoading?: boolean;
}> = ({ onCreateSample, createLoading }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.animationContainer}>
        <div className={classes.animationOffset}>
          <Lottie
            options={{
              animationData,
              autoplay: true,
              loop: false,
              rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
            }}
          />
        </div>
      </div>
      <Typography align="center" color="primary" variant="h5">
        {`Looks like you don't have any diagrams. Would you like to`}:
      </Typography>
      <div className={classes.buttons}>
        <DialogToggle
          render={({ handleOpen }) => (
            <ButtonCreateDiagram
              onClick={handleOpen}
              className={classes.button}
            />
          )}
          renderContent={({ handleClose }) => (
            <FormDiagram onAfterSubmit={handleClose} onCancel={handleClose} />
          )}
        />

        <ButtonLoadSampleDiagram
          disabled={createLoading}
          onClick={onCreateSample}
          className={classes.button}
        />
      </div>
    </div>
  );
};

export default EmptyCell;
