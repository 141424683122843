import { NextOperationTypes } from "../../generated/globalTypes";
import {
  DiagramComponentOperation,
  DiagramComponentState,
} from "../../hooks/useGetDiagram/types";

export function getOperationAssignmentValues(
  diagram: DiagramComponentState,
  props: {
    roleId?: string;
    operationId?: string;
  }
): string[] {
  const { roleId, operationId } = props;
  if (operationId && roleId) {
    const operation = diagram.operation.find(_ => _ && _.id === operationId);
    if (operation) {
      const assignment = operation.assignments.find(_ => _.roleId === roleId);
      if (assignment && assignment.accountabilityMethodologyValues) {
        return assignment.accountabilityMethodologyValues;
      }
    }
  }
  return [];
}

export function getNextPathType(
  operation: DiagramComponentOperation,
  nextId: string
) {
  return operation.type === "decision"
    ? NextOperationTypes[
        operation.nextOperationYes === nextId
          ? "nextOperationYes"
          : "nextOperationNo"
      ]
    : NextOperationTypes.nextOperation;
}

export function getNextPathTypeByIndex(
  path: DiagramComponentOperation[],
  index: number
) {
  const operation = path[index];
  const nextOperation = path[index + 1];
  return getNextPathType(operation, nextOperation && nextOperation.id);
}

export type OperationEdge = [string, NextOperationTypes, string];
export type OperationEdges = [OperationEdge, OperationEdge, OperationEdge];

export function findNewOperationEdges(
  currentPath: DiagramComponentOperation[],
  oldIndex: number,
  newIndex: number
): OperationEdges {
  if (newIndex < oldIndex) {
    newIndex -= 1;
  }

  const sortingNode = currentPath[oldIndex];
  const prevNodeBefore = currentPath[oldIndex - 1];
  const prevNodeAfter = currentPath[oldIndex + 1];

  const newNodeBefore = currentPath[newIndex];
  const newNodeAfter = currentPath[newIndex + 1];

  const prevPathType = getNextPathType(prevNodeBefore, sortingNode.id);
  const sortPathType = getNextPathType(sortingNode, prevNodeAfter.id);
  const newPathType = getNextPathType(newNodeBefore, newNodeAfter.id);

  return [
    [prevNodeBefore.id, prevPathType, prevNodeAfter.id],
    [sortingNode.id, sortPathType, newNodeAfter.id],
    [newNodeBefore.id, newPathType, sortingNode.id],
  ];
}
