import { BaseStructureWithPath, PhaseResponse } from "../../types/Clients";
import { ReduxActionCreator } from "../types";
import * as actions from "./actions";

export enum DesignSessionsActionTypes {
  DESIGN_SESSIONS_LOAD = "DESIGN_SESSIONS_LOAD",
  DESIGN_SESSIONS_RESET = "DESIGN_SESSIONS_RESET",
}

export interface DesignSessionsLookup {
  [key: string]: BaseStructureWithPath;
}

export interface DesignSessionsState {
  source: PhaseResponse[];
  lookup: DesignSessionsLookup;
}

export type DesignSessionsActionCreatorLoad = ReduxActionCreator<
  DesignSessionsActionTypes.DESIGN_SESSIONS_LOAD,
  PhaseResponse[],
  [PhaseResponse[]]
>;

export type DesignSessionsActionCreatorReset = ReduxActionCreator<
  DesignSessionsActionTypes.DESIGN_SESSIONS_RESET,
  null
>;

export type DesignSessionAction =
  | ReturnType<typeof actions.designSessionsLoad>
  | ReturnType<typeof actions.designSessionsReset>;
