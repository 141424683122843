import { ReduxActionCreator } from "../types";
import * as actions from "./actions";

export enum AppVersionTypes {
  APP_VERSION_SET = "APP_VERSION_SET",
}

export interface UserSettingMultipleAssignments {
  [key: string]: [boolean, number];
}

export interface AppVersionState {
  version: string;
  authUrl: string;
}

export type AppVersionActionCreatorSet = ReduxActionCreator<
  AppVersionTypes.APP_VERSION_SET,
  AppVersionState,
  [AppVersionState]
>;

export type AppVersionAction = ReturnType<typeof actions.appVersionSet>;
