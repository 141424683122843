import idx from "idx";
import { Reducer } from "redux";
import isNotNull from "../../lib/isNotNull";
import { InsightsAction, InsightsActionTypes, InsightsState } from "./type";
import {
  InsightsIncomingDiagrams,
  InsightsIncomingRole,
  InsightsInvolvedRole,
} from "./type";

export const initialState = {
  involvedDiagrams: [],
  involvedRoles: [],
};

const reducer: Reducer<InsightsState, InsightsAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case InsightsActionTypes.INSIGHTS_ADD_DIAGRAMS: {
      const newDiagrams = action.payload.diagrams.filter(
        _ => state.involvedDiagrams.indexOf(_.id) < 0
      );

      return {
        ...state,
        involvedDiagrams: [
          ...state.involvedDiagrams,
          ...newDiagrams.map(({ id }) => id),
        ],
      };
    }

    case InsightsActionTypes.INSIGHTS_ADD_ROLES: {
      return {
        ...state,
        involvedRoles: action.payload.roles,
      };
    }

    case InsightsActionTypes.INSIGHTS_TOGGLE_ALL_ROLES: {
      return {
        ...state,
        involvedRoles: state.involvedRoles.map(role => ({
          ...role,
          selected: action.payload.on,
        })),
      };
    }

    case InsightsActionTypes.INSIGHTS_REMOVE_DIAGRAM: {
      const rolesToRemove = getAllRolesFromDiagrams([action.payload.diagram]);
      return {
        ...state,
        involvedDiagrams: state.involvedDiagrams.filter(
          id => id !== action.payload.diagram.id
        ),
        involvedRoles: removeInvolvedRolesByDiagram(
          rolesToRemove,
          state.involvedRoles
        ),
      };
    }
    case InsightsActionTypes.INSIGHTS_SELECT_ROLE:
      return {
        ...state,
        involvedRoles: state.involvedRoles.map((role, i) => {
          return {
            ...role,
            selected:
              i === action.payload.roleIndex ? !role.selected : role.selected,
          };
        }),
      };

    case InsightsActionTypes.INSIGHTS_LOAD_ROLE_EMPLOYEE:
      return {
        ...state,
        involvedRoles: state.involvedRoles.map((role, i) => {
          if (i === action.payload.roleIndex) {
            return {
              ...role,
              employeeName: action.payload.employeeName,
              layer: action.payload.layer,
              loading: false,
              location: action.payload.location,
              businessUnit: action.payload.businessUnit,
              function: action.payload.function,
            };
          }
          return role;
        }),
      };

    case InsightsActionTypes.INSIGHTS_RESET:
      return initialState;

    default:
      return state;
  }
};

export function removeInvolvedRolesByDiagram(
  rolesToRemove: InsightsIncomingRole[],
  involvedRoles: InsightsInvolvedRole[]
) {
  return involvedRoles
    .map(role => ({
      ...role,
      associatedRoles: role.associatedRoles.filter(
        _ => !Boolean(rolesToRemove.find(x => x.id === _.roleId))
      ),
    }))
    .filter(_ => _.associatedRoles.length > 0);
}

export function getAllRolesFromDiagrams(diagrams: InsightsIncomingDiagrams) {
  return diagrams.reduce<InsightsIncomingRole[]>((acc, value) => {
    const roles = (idx(value, _ => _.roles) || []).filter(isNotNull);
    return [...acc, ...roles];
  }, []);
}

export default reducer;
