import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import React from "react";

const useStyles = makeStyles(theme => ({
  root: {
    borderWidth: 0,
    borderColor: theme.palette.grey[200],
    borderStyle: "solid",
    borderLeftWidth: 6,
    position: "relative",
  },
  roleHasName: {
    borderLeftColor: theme.palette.secondary.main,
    color: theme.palette.text.primary,
  },
  connectedToOrglab: {
    borderLeftColor: "#8DC63F",
  },
  roleHasError: {
    borderLeftColor: theme.palette.error.main,
  },
  involedRoles: {
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
  },
}));

const RolePanelItemWrapper: React.SFC<{
  connectedToOrglab?: boolean;
  hasError?: boolean;
  roleHasName?: boolean;
  involvedRoles?: boolean;
}> = ({
  children,
  connectedToOrglab,
  hasError,
  roleHasName,
  involvedRoles,
}) => {
  const classes = useStyles();
  return (
    <div
      className={classNames(classes.root, {
        [classes.connectedToOrglab]: connectedToOrglab,
        [classes.roleHasName]: roleHasName,
        [classes.roleHasError]: hasError,
        [classes.involedRoles]: involvedRoles,
      })}
    >
      {children}
    </div>
  );
};

export default RolePanelItemWrapper;
