import { useMutation } from "@apollo/react-hooks";
import { UpdateFrameworkAssignables } from "../../generated/globalTypes";
import { UpdateAccoutabilityMethodologyValueVariables } from "../../generated/UpdateAccoutabilityMethodologyValue";
import { UPDATE_FRAMEWORK } from "../../graphql/mutation/updateFramework";
import GET_FRAMEWORKS from "../../graphql/query/getFrameworks";

export default function useUpdateFramework() {
  const [updateFramework] = useMutation<
    any,
    UpdateAccoutabilityMethodologyValueVariables
  >(UPDATE_FRAMEWORK);

  function _updateFramework(
    id: string,
    values: UpdateFrameworkAssignables[],
    clientID: string
  ) {
    const assignablesToUpdate = values.map((value, idx) => ({
      id: value.id,
      color: value.color,
      description: value.description,
      exclusive: value.exclusive,
      order: idx,
    }));
    return updateFramework({
      variables: {
        id,
        assignables: assignablesToUpdate,
      },
      refetchQueries: [
        {
          query: GET_FRAMEWORKS,
          variables: {
            clientOrglabId: clientID,
          },
        },
      ],
    });
  }

  return [_updateFramework];
}
