import { useMutation } from "@apollo/react-hooks";
import { CreateSampleDiagram } from "../../generated/CreateSampleDiagram";
import { CREATE_SAMPLE_DIAGRAM } from "../../graphql/mutation/createSampleDiagram";
import GET_DIAGRAMS_LIST from "../../graphql/query/getDiagramsList";

export default function useCreateSampleDiagram() {
  const [createSampleDiagram, { loading }] = useMutation<CreateSampleDiagram>(
    CREATE_SAMPLE_DIAGRAM
  );

  function _create() {
    return createSampleDiagram({
      fetchPolicy: "no-cache",
      refetchQueries: [
        {
          query: GET_DIAGRAMS_LIST,
        },
      ],
    });
  }
  return { createSampleDiagram: _create, loading };
}
