import { Box, useTheme } from "@material-ui/core";
import React from "react";
import { NextOperationTypes } from "../../../generated/globalTypes";
import { OperationsState } from "../../../hooks/useToggleDiagramPath";
import TextOverflow from "../../TextOverflow";

interface Props {
  operation: OperationsState;
}
export default function ChartOperationVertical({ operation }: Props) {
  const theme = useTheme();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div style={{ padding: 4 }}>
        {operation.type === "decision" ? (
          <svg
            style={{ width: 16, height: 16 }}
            onClick={() =>
              operation.toggleDecisionState && operation.toggleDecisionState()
            }
          >
            <g>
              <path
                d="M8,0.707106781 L0.709191651,7.99791513 L8.09044796,15.2928809 L15.2949905,8.00209724 L8,0.707106781 Z"
                stroke={theme.palette.secondary.main}
                fill={theme.palette.secondary.main}
              />
              {operation.nextPathType ===
              NextOperationTypes.nextOperationYes ? (
                <polygon
                  fill="#FFFFFF"
                  fill-rule="nonzero"
                  points="8.66796875 11.6984375 8.66796875 9.378125 10.7685547 5.3 9.32714844 5.3 7.99560547 8.1828125 6.67285156 5.3 5.22705078 5.3 7.32763672 9.378125 7.32763672 11.6984375"
                />
              ) : (
                <polygon
                  fill="#FFFFFF"
                  fill-rule="nonzero"
                  points="6.71240234 11.6984375 6.71240234 7.48847656 9.27880859 11.6984375 10.597168 11.6984375 10.597168 5.3 9.28320312 5.3 9.28320312 9.51875 6.71240234 5.3 5.39404297 5.3 5.39404297 11.6984375"
                />
              )}
            </g>
          </svg>
        ) : (
          <svg style={{ width: 17, height: 16 }}>
            <g>
              <polygon
                fill="none"
                stroke={theme.palette.secondary.main}
                points="-3.65929509e-13 9.05941988e-14 15.12 9.05941988e-14 16.8 5.4 15.12 10.8 -3.65929509e-13 10.8 1.68 5.4"
              />
              {Boolean(operation.subProcess) && (
                <path
                  d="M4.05555556,5.5 C4.05555556,4.645 4.82777778,3.95 5.77777778,3.95 L8,3.95 L8,3 L5.77777778,3 C4.24444444,3 3,4.12 3,5.5 C3,6.88 4.24444444,8 5.77777778,8 L8,8 L8,7.05 L5.77777778,7.05 C4.82777778,7.05 4.05555556,6.355 4.05555556,5.5 L4.05555556,5.5 Z M6,6 L10,6 L10,5 L6,5 L6,6 L6,6 Z M11.2222222,3 L9,3 L9,3.95 L11.2222222,3.95 C12.1722222,3.95 12.9444444,4.645 12.9444444,5.5 C12.9444444,6.355 12.1722222,7.05 11.2222222,7.05 L9,7.05 L9,8 L11.2222222,8 C12.7555556,8 14,6.88 14,5.5 C14,4.12 12.7555556,3 11.2222222,3 L11.2222222,3 Z"
                  fill={theme.palette.secondary.main}
                />
              )}
            </g>
          </svg>
        )}
      </div>
      <TextOverflow
        maxLines={3}
        style={{
          textAlign: "center",
          flex: 1,
          fontSize: 11,
        }}
      >
        {operation.name || "-"}
      </TextOverflow>
    </div>
  );
}
