/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum NextOperationTypes {
  nextOperation = "nextOperation",
  nextOperationNo = "nextOperationNo",
  nextOperationYes = "nextOperationYes",
}

export enum OperationTypes {
  decision = "decision",
  task = "task",
}

export interface CreateBatchRoleNoteInput {
  roleID: string;
  name?: string | null;
  text?: string | null;
  noteID?: string | null;
}

export interface CreateFramework {
  id?: string | null;
  name?: string | null;
  creationDate?: string | null;
  custom?: boolean | null;
  assignables?: (UpdateFrameworkAssignables | null)[] | null;
}

export interface RoleCapacity {
  id: string;
  capacity?: number | null;
}

export interface UpdateFrameworkAssignables {
  id?: string | null;
  color?: string | null;
  description?: string | null;
  exclusive?: boolean | null;
  order?: number | null;
  name?: string | null;
  symbol?: string | null;
}

export interface UpdateOperationParams {
  name?: string | null;
  cycleTime?: (string | null)[] | null;
  waitTime?: (string | null)[] | null;
  comment?: string | null;
  repeats?: boolean | null;
  dataElements?: (string | null)[] | null;
  outputs?: (string | null)[] | null;
  inputs?: (string | null)[] | null;
  itSystems?: (string | null)[] | null;
  painPoints?: (string | null)[] | null;
  subProcess?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
