import idx from "idx";
import {
  GetDiagram_Process,
  GetDiagram_Process_operation,
  GetDiagram_Process_operation_processes_roles,
  GetDiagram_Process_operation_roleNotes,
} from "../../generated/GetDiagram";
import isNotNull from "../../lib/isNotNull";
import {
  DiagramComponentAssignments,
  DiagramComponentOperation,
  DiagramComponentState,
} from "./types";

// https://monolist.co/blog/2019/10/typescript-3-7/

export function normalizeDiagram(
  diagram: GetDiagram_Process
): DiagramComponentState {
  return {
    id: diagram.id,
    name: diagram.name || "",
    structureId: idx(diagram, _ => _.structure.orglabID) || undefined,
    operation: (idx(diagram, _ => _.operation) || [])
      .filter(isNotNull)
      .map(op => normalizeOperation(op)),
    client: {
      id: idx(diagram, _ => _.client.id) || "",
      name: idx(diagram, _ => _.client.name) || "",
      orglabID: idx(diagram, _ => _.client.orglabID) || "",
    },
    accountabilityMethodology: {
      id: idx(diagram, _ => _.accountabilityMethodology.id) || "",
      name: idx(diagram, _ => _.accountabilityMethodology.name) || "",
      custom: idx(diagram, _ => _.accountabilityMethodology.custom) || false,
      accountabilityMethodologyValues: (
        idx(
          diagram,
          _ => _.accountabilityMethodology.accountabilityMethodologyValues
        ) || []
      ).map(v => ({
        id: v.id,
        symbol: v.symbol,
        color: v.color,
        name: v.name,
        exclusive: v.exclusive,
      })),
    },
    roleOrder: idx(diagram, _ => _.roleOrder) || [],
    roles: (idx(diagram, _ => _.roleOrder) || [])
      .filter(isNotNull)
      .map(roleId => {
        const role = (idx(diagram, _ => _.roles) || []).find(
          r => r.id === roleId
        );
        if (role) {
          return {
            id: role.id,
            name: role.name,
            orglabID: role.orglabID,
            capacity: role.capacity,
            roleNotes: (role.roleNotes || []).filter(isNotNull).map(rn => ({
              id: rn.id,
              text: rn.text,
              operationId: rn.operation && rn.operation.id,
            })),
          };
        }
        return null;
      })
      .filter(isNotNull),
  };
}

const findRoleNote = (
  notes: GetDiagram_Process_operation_roleNotes[],
  role: GetDiagram_Process_operation_processes_roles
): { text?: string; noteID?: string; assignmentDisplay?: string } => {
  const noteFound = notes.find(
    roleNote => roleNote && roleNote.role && role.id === roleNote.role.roleId
  );

  if (noteFound) {
    return {
      text: noteFound.text || "",
      noteID: noteFound.noteId || "",
    };
  }

  return { text: "", noteID: "" };
};

export const normalizeOperation = (
  operation: GetDiagram_Process_operation
): DiagramComponentOperation => {
  const assignments = (idx(operation, _ => _.assignments) || []).map(a => {
    const values = idx(a, _ => _.accountabilityMethodologyValues) || [];
    return {
      id: a.id,
      roleId: idx(a, _ => _.role.id) || "",
      operationId: idx(a, _ => _.operation.id) || "",
      accountabilityMethodologyValues: values.map(v => v.id),
      accountabilityMethodologySymbols: values.map(v => v.symbol),
    };
  });

  return {
    id: operation.id,
    name: operation.name || "",
    type: idx(operation, _ => _.type) || "",
    cycleTime: idx(operation, _ => _.cycleTime) || [],
    waitTime: idx(operation, _ => _.waitTime) || [],
    repeats: idx(operation, _ => _.repeats) || false,
    comment: operation.comment || "",
    inputs: idx(operation, _ => _.inputs) || [],
    subProcess: operation.subProcess || "",
    subProcessName: operation.subProcessName || "",
    dataElements: idx(operation, _ => _.dataElements) || [],
    itSystems: idx(operation, _ => _.itSystems) || [],
    outputs: idx(operation, _ => _.outputs) || [],
    painPoints: idx(operation, _ => _.painPoints) || [],
    nextOperation: idx(operation, _ => _.nextOperation) || undefined,
    nextOperationYes: idx(operation, _ => _.nextOperationYes) || undefined,
    nextOperationNo: idx(operation, _ => _.nextOperationNo) || undefined,
    assignments,
    roleNotes: (idx(operation, _ => _.processes.roleOrder) || [])
      .map(roleId => {
        const role = (idx(operation, _ => _.processes.roles) || []).find(
          r => r.id === roleId
        );
        if (role) {
          return {
            roleID: role.id,
            name: role.name,
            assignmentDisplay: getRoleAssignmentDisplay(role, assignments),
            ...findRoleNote(idx(operation, _ => _.roleNotes) || [], role),
          };
        }
        return null;
      })
      .filter(isNotNull),
  };
};

function getRoleAssignmentDisplay(
  role: GetDiagram_Process_operation_processes_roles,
  assignments: DiagramComponentAssignments
) {
  let assignmentDisplay = "";
  (assignments || []).forEach(assignment => {
    if (assignment.roleId === role.id) {
      const assignments = (
        idx(assignment, _ => _.accountabilityMethodologySymbols) || []
      ).map(av => av.toUpperCase());
      if (assignments.length > 0) {
        assignmentDisplay = `(${assignments.join(",")})`;
      }
    }
  });
  return assignmentDisplay;
}
