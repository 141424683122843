import * as Yup from "yup";
import { FrameworkAssignmentForm } from "../../types/FrameworkForm";

const count = (names: any[]) =>
  names.reduce((a, b) => Object.assign(a, { [b]: (a[b] || 0) + 1 }), {});

const duplicates = (dict: any[]) =>
  Object.keys(dict).filter((a: any) => dict[a] > 1);

const getFirstLetterFromString = (value?: string) => value
? value.substring(0, 1).toUpperCase()
: ""

// use name rather than symbol as "symbol" gets hung up/duplicated during sorting :/
export const getDuplicateSymbols = (
  assignables: FrameworkAssignmentForm[]
): string[] => duplicates(count(assignables.map(({ name }) => getFirstLetterFromString(name))));

export default Yup.lazy((values: any) =>
  Yup.object().shape({
    assignables: Yup.array()
      .of(
        Yup.object().shape({
          color: Yup.string().required("Color is Required"),
          name: Yup.string()
            .required("Assignment Name is Required")
            .test({
              message: "Assignment initial already exists",
              name: "multiple-symbols",
              test: (value?: string) => {
                const newSymbol = getFirstLetterFromString(value);
                return (
                  getDuplicateSymbols(values.assignables).indexOf(newSymbol) < 0
                );
              },
            } as any),
        })
      )
      .required("gotta have assignments"),
    name: Yup.string().required("Framework Name is Required"),
  })
);
