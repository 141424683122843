import Button from "@material-ui/core/Button";
import green from "@material-ui/core/colors/green";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import classNames from "classnames";
import React from "react";
import { accentColors } from "../../config/theme";
import { Level } from "../../types/Level";
import { IconClose } from "../Icons";

const TIMEOUTS: { [key: string]: number } = {
  info: 5000,
  warning: 8000,
};

const styles = (theme: Theme) =>
  createStyles({
    error: {
      backgroundColor: accentColors.error,
      color: "white",
    },
    icon: {
      fontSize: 16,
    },
    iconVariant: {
      marginRight: theme.spacing(1),
      opacity: 0.9,
    },
    info: {
      backgroundColor: accentColors.info,
    },
    message: {
      flex: 1,
      fontFamily: theme.typography.fontFamily,
      paddingTop: theme.spacing(1) * 2,
      paddingBottom: theme.spacing(1) * 2,
      fontSize: 14,
    },
    paper: {
      color: "white",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingLeft: theme.spacing(1) * 3,
      paddingRight: theme.spacing(1),
    },
    success: {
      backgroundColor: green[600],
    },
    warning: {
      backgroundColor: accentColors.warning,
    },
  });

interface Props extends WithStyles<typeof styles> {
  className?: string;
  message: string;
  onClose?: () => void;
  variant: Level;
  actionButton?: () => void;
  actionButtonText?: string;
}

class Notification extends React.Component<Props> {
  public state = {
    render: true,
  };

  private timeout: number | null = null;

  constructor(props: Props) {
    super(props);
    this.startTimer();
  }

  public startTimer = () => {
    const { variant } = this.props;
    if (TIMEOUTS[variant]) {
      this.timeout = window.setTimeout(this.handleExit, TIMEOUTS[variant]);
    }
  };

  public handleExit = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  public handleMouseOver = () => {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  };

  public handleMouseOut = () => {
    this.startTimer();
  };

  public render() {
    const {
      classes,
      className,
      message,
      variant,
      actionButton,
      actionButtonText,
    } = this.props;

    return (
      <Paper
        className={classNames(classes[variant], classes.paper, className)}
        aria-describedby="client-snackbar"
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
      >
        <span id="client-snackbar" className={classes.message}>
          {message}
        </span>
        {actionButtonText && (
          <Button
            style={{ color: "white", marginLeft: 8 }}
            size="small"
            onClick={actionButton}
          >
            {actionButtonText}
          </Button>
        )}
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={this.handleExit}
        >
          <IconClose className={classes.icon} />
        </IconButton>
      </Paper>
    );
  }
}

export default withStyles(styles)(Notification);
