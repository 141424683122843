import gql from "graphql-tag";

export const GET_INSIGHT_DENSITY_MAP = gql`
  query GetInsightDensityMap($roles: [ID]) {
    DensityMap(roles: $roles) {
      roleId
      accountabilityMethodologyValueId
      count
      processName
    }
  }
`;
