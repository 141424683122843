import { isEqual, xorWith } from "lodash";
import { useContext, useEffect } from "react";
import scrollIntoView from "scroll-into-view";
import useRouter from "use-react-router";
import useUpdateDiagram from "../useUpdateDiagram";
import { DiagramInteractionsContext } from "./provider";
import { DiagramInteractionsViewRole } from "./reducer";

export default function useDiagramInteractions() {
  const { state, dispatch } = useContext(DiagramInteractionsContext);
  // const { addAssignment, removeAssignment } = useUpdateDiagram();
  const { match } = useRouter<{
    id: string;
  }>();
  const diagramId = match.params.id;

  useEffect(
    () => {
      const element = document.getElementById(
        `scrollTo${state.scrollToOperationId}`
      );
      if (element) {
        scrollIntoView(element, { time: 200 });
      }
    },
    [state.scrollToOperationId]
  );

  const setNudgeHighlight = (_column: number, _rows: number[]) => {
    _rows.sort((a, b) => a - b);
    dispatch({
      type: "highlightNudges",
      data: {
        column: _column,
        rows: _rows,
      },
    });
  };

  const resetNudgeHighlight = () => {
    dispatch({
      type: "resetNudges",
    });
  };

  const setClickOperation = (id: string, index: number) => {
    if (id === state.activeOperationId) {
      openAP(index);
    }
    setActiveOperationId(id, true);
  };

  function setActiveOperationId(id?: string, scroll?: boolean) {
    dispatch({
      type: "setActiveOperationId",
      data: { id, scroll },
    });
  }

  function setActiveRoleId(id: string) {
    dispatch({
      type: "setActiveRoleId",
      data: { id },
    });
  }

  function getActiveCellState(operationId: string, roleId: string) {
    if (state.openRaciMultiselect) {
      return Boolean(
        state.activeMultiple.find(item => {
          return item.operationId === operationId && item.roleId === roleId;
        })
      );
    }
    return (
      operationId === state.activeOperationId && roleId === state.activeRoleId
    );
  }

  function handleAssignmentClick(operationId: string, roleId: string) {
    if (state.openRaciMultiselect) {
      dispatch({
        type: "setActiveMultiple",
        data: xorWith(state.activeMultiple, [{ operationId, roleId }], isEqual),
      });
    } else {
      setActiveOperationId(operationId);
      setActiveRoleId(roleId);
      dispatch({
        type: "setOpenAssignment",
        data: true,
      });
    }
  }

  function handleSelectMultiple(values: string[]) {
    console.log(">>>values", values, state.activeMultiple);
    dispatch({
      type: "setOpenRaciMultiselect",
      data: false,
    });
  }

  function startMultiAssign() {
    dispatch({
      type: "startMultiAssign",
    });
  }

  function openAP(index?: number) {
    dispatch({
      type: "openAp",
      data: { index },
    });
  }

  function closeAP() {
    setActiveOperationId(undefined);

    openAP(undefined);
  }

  function handleClickAwayFromRACI() {
    if (state.openAssignment) {
      dispatch({
        type: "setOpenAssignment",
        data: false,
      });
    }
  }

  return {
    ...state,
    diagramId,
    actions: {
      setNudgeHighlight,
      resetNudgeHighlight,
      setClickOperation,
      setActiveOperationId,
      setActiveRoleId,
      getActiveCellState,
      handleAssignmentClick,
      handleSelectMultiple,
      startMultiAssign,
      closeAP,
      openAP,
      handleClickAwayFromRACI,
      setShowLegend: () => dispatch({ type: "showLegend" }),
      setHideLegend: () => dispatch({ type: "hideLegend" }),
      showRoleInfo: (data: DiagramInteractionsViewRole) =>
        dispatch({
          type: "showRoleInfo",
          data,
        }),
      editRoleCapacity: (data: DiagramInteractionsViewRole) =>
        dispatch({
          type: "editRoleCapacity",
          data,
        }),
      hideRoleInfo: () => dispatch({ type: "hideRoleInfo" }),
      hideRoleCapacity: () => dispatch({ type: "hideRoleCapacity" }),
      setSortingX: (x?: number) =>
        dispatch({ type: "setSorting", data: { x } }),
      setSortingY: (y?: number) =>
        dispatch({ type: "setSorting", data: { y } }),
      resetInteractions: () => dispatch({ type: "resetInteractions" }),
    },
  };
}
