import { useQuery } from "@apollo/react-hooks";
import {
  Box,
  DialogActions,
  Step,
  StepButton,
  Stepper,
  Typography,
} from "@material-ui/core";
import { Formik } from "formik";
import { compact } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GetDiagramTags } from "../../generated/GetDiagramTags";
import GET_DIAGRAM_TAGS from "../../graphql/query/getDiagramTags";
import { pdClient } from "../../lib/apiClient";
import { IndustriesResponse } from "../../lib/apiClient/types";
import { PhaseResponse } from "../../types/Clients";
import { DiagramFormValues } from "../../types/DiagramForm";
import { ButtonCancel, ButtonSubmit } from "../Button";
import BaseFields from "../FormDiagram/BaseFields";
import LoadingInline from "../LoadingInline";
import DesignSessionField from "./DesignSessionField";
import Step2Fields from "./Step2Fields";
import Step3Fields from "./Step3Fields";

interface FormDiagramProps {
  onCancel: () => void;
  onSubmit: (values: DiagramFormValues) => void;
  onChangeStep: (index: number) => void;
  isEdit?: boolean;
  frameworks: Array<{
    id: string;
    name: string;
  }>;
  initialValues: DiagramFormValues;
  designSessions: PhaseResponse[];
  formStep: number;
  saving?: boolean;
  isSubprocess?: boolean;
}

const FormDiagramOrglab: React.SFC<FormDiagramProps> = ({
  onCancel,
  frameworks,
  isEdit = false,
  designSessions,
  onSubmit,
  initialValues,
  formStep,
  onChangeStep,
  saving,
  isSubprocess,
}) => {
  const { data, loading } = useQuery<GetDiagramTags>(GET_DIAGRAM_TAGS);
  const [industries, setIndustries] = useState<IndustriesResponse[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    pdClient.getIndustries().then((data) => {
      setIndustries(data);
    });
  }, []);

  if (loading || !data) {
    return <LoadingInline />;
  }

  return (
    <div style={{ width: 312 }}>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={(values) => {
          const errors: {
            diagramName?: string;
            decisionFrameworkId?: string;
          } = {};
          if (formStep === 0) {
            if (!values.diagramName) {
              errors.diagramName = "Required field";
            } else if (!values.decisionFrameworkId) {
              errors.decisionFrameworkId = "Required field";
            }
          }
          return errors;
        }}
        render={({ handleSubmit }) => (
          <form
            onSubmit={(e) => {
              e.stopPropagation();
              handleSubmit(e);
            }}
          >
            <Stepper activeStep={formStep}>
              <Step>
                <StepButton onClick={() => onChangeStep(0)} children={null} />
              </Step>
              <Step>
                <StepButton onClick={() => onChangeStep(1)} children={null} />
              </Step>
              <Step>
                <StepButton onClick={() => onChangeStep(2)} children={null} />
              </Step>
            </Stepper>
            {formStep === 0 && (
              <>
                <Typography color="secondary" variant="h6">
                  Setup Diagram: The Basics
                </Typography>
                <BaseFields
                  frameworks={frameworks}
                  disableNameField={isSubprocess}
                  isEdit={isEdit}
                />
                {designSessions.length > 0 && (
                  <DesignSessionField
                    designSessions={designSessions}
                    disabled={
                      isEdit
                        ? Boolean(initialValues.structureId)
                        : initialValues && initialValues.structureId
                          ? true
                          : false
                    }
                  />
                )}
              </>
            )}

            {formStep === 1 && (
              <>
                <Box mb={2}>
                  <Typography color="secondary" variant="h6">
                    Setup Diagram: Tagging
                  </Typography>
                </Box>
                <Typography>{t("diagramSetupProcessType")}</Typography>
                <Step2Fields
                  processArchetypeValues={compact(
                    data.ProcessArchetypeDropdown
                  )}
                  structurePrimaryAxisValues={compact(
                    data.StructurePrimaryAxisDropdown
                  )}
                  primaryOwningFunctionValues={compact(
                    data.PrimaryOwningFunctionDropdown
                  )}
                />
              </>
            )}

            {formStep === 2 && (
              <>
                <Box mb={2}>
                  <Typography color="secondary" variant="h6">
                    Setup Diagram: Tagging
                  </Typography>
                </Box>
                <Typography>{t("diagramSetupProcessType")}</Typography>
                <Step3Fields
                  industryValues={industries}
                  geographyValues={compact(data.GeographyDropdown)}
                />
              </>
            )}

            <DialogActions key="dialogNewActions" style={{ marginTop: 24 }}>
              <ButtonCancel onClick={onCancel} disabled={saving} />
              <ButtonSubmit
                text={formStep === 2 ? (isEdit ? "Save" : "Create") : "Next"}
                type="submit"
                disabled={saving}
                loading={saving}
              />
            </DialogActions>
          </form>
        )}
      />
    </div>
  );
};

export default FormDiagramOrglab;
