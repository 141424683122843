import { MenuItem, TextField } from "@material-ui/core";
import { Field, FieldProps } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import useRouter from "use-react-router";
import useGetDiagram from "../../hooks/useGetDiagram";
import { confirm } from "../../store/modal/actions";
import { ButtonSubprocessLink } from "../Button";
import DialogToggle from "../DialogToggle";
import FormDiagram from "../FormDiagram";
import FormSelectField from "../FormSelectField";
import FormTextField from "../FormTextField";

const FieldSelectSubprocess: React.SFC<
  FieldProps & {
    diagramName: string | null;
    operationId: string;
    subProcessName?: string;
  }
> = ({ form, field, diagramName, operationId, subProcessName }) => {
  const dispatch = useDispatch();
  const { match, history } = useRouter<{
    id: string;
  }>();
  const { diagram } = useGetDiagram(match.params.id);

  return (
    <>
      <Field
        component={FormSelectField}
        name="selectType"
        disableOnSubmitting={true}
        onChange={(e: any) => {
          if (e.target.value === "task") {
            dispatch(
              confirm({
                i18nKey: "unlinkProcessOperation",
                onConfirm: () => {
                  form.setFieldValue("subProcess", "");
                  form.setFieldValue(field.name, "task");
                  form.handleSubmit();
                },
                onCancel: () => {
                  form.setFieldValue(field.name, "subprocess");
                  form.handleSubmit();
                },
              })
            );
          } else {
            form.setFieldValue(field.name, "subprocess");
            form.handleSubmit();
          }
        }}
      >
        <MenuItem value="task">Task</MenuItem>
        <MenuItem value="subprocess">Subprocess</MenuItem>
      </Field>
      {field.value === "subprocess" &&
        (form.values.subProcess ? (
          <>
            <TextField
              label="Subprocess"
              margin="normal"
              value={subProcessName}
              inputProps={{
                readOnly: true,
              }}
              fullWidth={true}
              disabled={true}
            />
            <div style={{ textAlign: "center" }}>
              <ButtonSubprocessLink
                onClick={() =>
                  history.push(process.env.PUBLIC_URL + `/rolematrix/${form.values.subProcess}`)
                }
              />
            </div>
          </>
        ) : (
          <DialogToggle
            render={() => null}
            open={true}
            renderContent={({ handleClose }) => (
              <FormDiagram
                onAfterSubmit={handleClose}
                onAfterCreate={id => {
                  form.setFieldValue("subProcess", id);
                  form.submitForm();
                  handleClose();
                }}
                onCancel={() => {
                  form.setFieldValue("selectType", "task");
                  handleClose();
                }}
                initialValues={{
                  diagramName: diagramName || undefined,
                  structureId: diagram && diagram.structureId,
                  decisionFrameworkId: "",
                }}
                subprocessValues={{
                  parentId: (diagram && diagram.id) || "",
                  processElementId: operationId,
                }}
              />
            )}
          />
        ))}
    </>
  );
};

export default FieldSelectSubprocess;
