import { ChipProps, default as MuiChip } from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Cancel";
import React from "react";

const useStyles = makeStyles({
  chipRoot: {
    fontSize: 12,
    height: 18,
    marginLeft: 2,
    marginRight: 2,
    marginBottom: 1,
    marginTop: 1,
  },
  chipDelete: {
    width: 14,
    marginRight: 2,
  },
  chipLabel: {
    paddingLeft: 6,
    textTransform: "capitalize",
  },
});

export default function Chip(props: ChipProps) {
  const { onDelete } = props;
  const classes = useStyles();
  const classNames = {
    root: classes.chipRoot,
    deleteIcon: classes.chipDelete,
    label: classes.chipLabel,
  };
  return Boolean(onDelete) ? (
    <MuiChip
      classes={classNames}
      deleteIcon={
        <div
          onMouseDown={event => {
            // https://github.com/mui-org/material-ui/issues/18953
            if (!props.disabled && event.button === 0) {
              event.stopPropagation();
              onDelete && onDelete(event);
            }
          }}
        >
          <DeleteIcon
            className="MuiChip-deleteIcon"
            style={{ margin: "0px 5px 0px 0px", width: 14, color: "inherit" }}
          />
        </div>
      }
      {...props}
    />
  ) : (
    <MuiChip classes={classNames} {...props} />
  );
}
