import { ButtonBaseProps } from "@material-ui/core/ButtonBase";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import React from "react";
import RoundButton from "../RoundButton";

const styles = (theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      "&:hover": {
        backgroundColor: theme.palette.secondary.dark,
      },
      "&:active": {
        borderColor: theme.palette.primary.main,
      },
    },
    label: {
      width: "100%",
      display: "inherit",
      alignItems: "inherit",
      justifyContent: "inherit",
    },
  });

type ActionButtonProps = WithStyles<typeof styles> & ButtonBaseProps;

class ActionButton extends React.Component<ActionButtonProps> {
  public render() {
    const { classes, className, ...props } = this.props;
    return (
      <RoundButton className={`${classes.button} ${className}`} {...props} />
    );
  }
}

export default withStyles(styles)(ActionButton);
