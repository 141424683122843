import { useQuery } from "@apollo/react-hooks";
import {
  GetDiagramsList
} from "../../generated/GetDiagramsList";
import GET_DIAGRAMS_LIST from "../../graphql/query/getDiagramsList";
import normalizeDiagrams from "./normalizeDiagrams";

export interface UseGetDiagramsItem {
  id: string;
  name: string;
  decisionFramework: string;
  structureId: string;
  created_by: string;
  created_at: string;
  updated_by: string;
  updated_at: string;
  subprocesses: string[];
  level?: string;
  index: number;
  parentId?: string;
  parentName?: string;
  children: UseGetDiagramsItem[];
}

export type UseGetDiagramsResult = UseGetDiagramsItem[];

function useGetDiagrams(): {
  diagrams: UseGetDiagramsResult;
  diagramsTree: UseGetDiagramsResult;
  loading: boolean;
} {
  const { data, loading } = useQuery<GetDiagramsList>(
    GET_DIAGRAMS_LIST,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  if (data) {
    return { ...normalizeDiagrams(data), loading };
  }
  return { diagrams: [], diagramsTree: [], loading };
}

export default useGetDiagrams;
