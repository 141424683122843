import gql from "graphql-tag";

const UPDATE_OPERATION = gql`
  mutation UpdateOperation(
    $diagramID: ID!
    $id: ID!
    $optional: UpdateOperationParams
  ) {
    EditOperation(diagramID: $diagramID, id: $id, optional: $optional) {
      id
    }
  }
`;

export default UPDATE_OPERATION;
