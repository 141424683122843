import { Level } from "../../types/Level";
import { ActionButton, Notification } from "../../types/Notification";
import { ReduxActionCreator } from "../types";
import * as actions from "./actions";

export enum NotificationsActionTypes {
  NOTIFY_SHOW = "RNS_SHOW_NOTIFICATION",
  NOTIFY_HIDE = "RNS_HIDE_NOTIFICATION",
  NOTIFY_REMOVE_ALL = "RNS_REMOVE_ALL_NOTIFICATIONS"
}

export type NotificationsState = Notification[]

export type NotificationsActionCreatorShow = ReduxActionCreator<
  NotificationsActionTypes.NOTIFY_SHOW,
  {
    level: Level;
    message: string;
    uid: string;
    actionButton?: ActionButton;
    actionButtonText?: string;
  },
  [string, Level, ActionButton?, string?]
>;

export type NotificationsActionCreatorHide = ReduxActionCreator<
  NotificationsActionTypes.NOTIFY_HIDE,
  {
    uid: string;
  },
  [string]
>;

export type NotificationsActionCreatorRemoveAll = ReduxActionCreator<
  NotificationsActionTypes.NOTIFY_REMOVE_ALL,
  null
>;

export type NotificationsAction =
  | ReturnType<typeof actions.notifyError>
  | ReturnType<typeof actions.notifyHide>
  | ReturnType<typeof actions.notifyShow>
  | ReturnType<typeof actions.notifyRemoveAll>;
