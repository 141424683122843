import gql from "graphql-tag";

const GET_BREADCRUMBS = gql`
  query GetBreadcrumbs($id: ID!) {
    ProcessBreadcrumbs(id: $id) {
      id
      name
    }
  }
`;

export default GET_BREADCRUMBS;
