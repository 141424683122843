import { useMutation } from "@apollo/react-hooks";
import DELETE_CUSTOM_FRAMEWORK from "../../graphql/mutation/deleteCustomFramework";
import GET_FRAMEWORKS from "../../graphql/query/getFrameworks";
import {DeleteFramework, DeleteFrameworkVariables} from '../../generated/DeleteFramework';

export default function useDeleteCustomFramework() {
  const [deleteFramework] = useMutation<DeleteFramework, DeleteFrameworkVariables>(
    DELETE_CUSTOM_FRAMEWORK
  );
  function _delete(id: string) {
    return deleteFramework({
      variables: { id },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_FRAMEWORKS,
        },
      ],
    });
  }
  return [_delete];
}
