import { withTheme, WithTheme } from "@material-ui/core/styles";
import React from "react";

const ProcessElementEnd: React.SFC<WithTheme> = ({ theme }) => (
  <div
    style={{
      width: "50px",
      height: "50px",
      borderRadius: "50%",
      border: `1px solid ${theme.palette.text.primary}`,
    }}
  />
);

export default withTheme(ProcessElementEnd);
