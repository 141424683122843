import { isEqual, xor, xorWith } from "lodash";
import { Action } from "../types";
import { RACISelectionState } from "./";
import * as actionTypes from "./actionTypes";

export const initialState: RACISelectionState = {
  multiple: false,
  multipleSelections: [],
  multipleValues: [],
  open: false,
};

const reducer = (state = initialState, { type, payload }: Action<any>) => {
  switch (type) {
    case actionTypes.RACI_SELECTION_OPEN:
      return {
        ...state,
        open: true,
      };

    case actionTypes.RACI_SELECTION_CLOSE:
      return {
        ...state,
        open: false,
      };

    case actionTypes.RACI_SELECTION_START_MULTIPLE:
      return { ...state, multiple: true };

    case actionTypes.RACI_SELECTION_END_MULTIPLE:
      return {
        multiple: false,
        multipleSelections: [],
        multipleValues: [],
        open: false,
      };

    case actionTypes.RACI_SELECTION_MULTIPLE_ADD:
      return {
        ...state,
        multipleSelections: xorWith(
          state.multipleSelections,
          [payload],
          isEqual
        ),
      };

    case actionTypes.RACI_SELECTION_MULTIPLE_VALUES:
      return {
        ...state,
        multipleValues: xor(state.multipleValues, [payload]),
      };

    default:
      return state;
  }
};

export default reducer;
