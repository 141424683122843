import { useQuery } from "@apollo/react-hooks";
import idx from "idx";
import {
  GetDiagramFormValues,
  GetDiagramFormValuesVariables,
} from "../../generated/GetDiagramFormValues";
import { GET_DIAGRAM_FORM_VALUES } from "../../graphql/query/getDiagramFormValues";
import { normalizeDiagramFormDate } from "./util";

export default function useGetDiagramFormData(
  diagramId: string
) {
  const { data, loading } = useQuery<GetDiagramFormValues, GetDiagramFormValuesVariables>(
    GET_DIAGRAM_FORM_VALUES,
    {
      fetchPolicy: "no-cache",
      variables: {
        diagramId,
      },
    }
  );

  const diagram = idx(data, _ => _.Process[0]);

  return {
    diagram,
    initialFormValues: normalizeDiagramFormDate(diagram),
    loading,
  };
}
