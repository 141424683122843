import idx from "idx";
import { cloneDeep, keyBy } from "lodash";
import { UseGetDiagramsResult } from ".";
import { GetDiagramsList } from "../../generated/GetDiagramsList";

const createDataTree = (
  dataset: UseGetDiagramsResult
): UseGetDiagramsResult => {
  const hashTable = Object.create(null);

  dataset.forEach(
    (aData) => (hashTable[aData.id] = { ...aData, children: [] })
  );

  const dataTree: UseGetDiagramsResult = [];

  dataset.forEach((aData) => {
    if (aData.parentId) {
      hashTable[aData.parentId].children.push(hashTable[aData.id]);
    } else {
      dataTree.push(hashTable[aData.id]);
    }
  });

  return dataTree;
};

function addParentReferences(diagrams: UseGetDiagramsResult) {
  const lookup = keyBy(diagrams, "id");

  for (const key in lookup) {
    const diagram = lookup[key];
    diagram.subprocesses.forEach((subprocessId) => {
      if (lookup[subprocessId]) {
        lookup[subprocessId].parentId = key;
        lookup[subprocessId].parentName = lookup[key].name;
      }
    });
  }

  return cloneDeep(Object.values(lookup));
}

function addLevels(diagrams: UseGetDiagramsResult) {
  const result: UseGetDiagramsResult = [];

  function iterate(curr: UseGetDiagramsResult, level: number = 1) {
    curr.forEach((d, i) => {
      d.level = `L${level}`;
      result.push(d);
      iterate(d.children, level + 1);
    });
  }
  iterate(diagrams);

  return result;
}

export default function normalizeDiagrams(data: GetDiagramsList) {
  const processes = idx(data, (_) => _.DiagramList) || [];

  const diagrams: UseGetDiagramsResult = processes.map(
    (p, index) =>
      p && {
        id: p.id,
        name: p.name || "",
        decisionFramework:
          p.accountabilityMethodology && p.accountabilityMethodology.name,
        structureId: p.structure && p.structure.orglabID,
        created_by: p.createdBy && p.createdBy.login,
        created_at: p.creationDate,
        updated_by: p.updatedBy && p.updatedBy.login,
        updated_at: p.updatedDate,
        subprocesses: (p.subprocesses || []).map((_) => _.processId),
        children: [],
        index,
      }
  );

  const diagramsTree = createDataTree(addParentReferences(diagrams));

  return {
    diagramsTree,
    diagrams: addLevels(diagramsTree),
  };
}
