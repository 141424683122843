import ButtonBase, { ButtonBaseProps } from "@material-ui/core/ButtonBase";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import classNames from "classnames";
import React from "react";

const styles = (theme: Theme) =>
  createStyles({
    button: {
      ...theme.typography.button,
      borderRadius: "50%",
      width: "30px",
      height: "30px",
      textAlign: "center",
      cursor: "inherit",
    },
    hasClick: {
      cursor: "pointer",
    },
    label: {
      width: "100%",
      display: "inherit",
      alignItems: "inherit",
      justifyContent: "inherit",
    },
  });

type RoundButtonOuterProps = ButtonBaseProps & {
  disabled?: boolean;
};

type RoundButtonProps = WithStyles<typeof styles> & RoundButtonOuterProps;

class RoundButton extends React.Component<RoundButtonProps> {
  public render() {
    const { classes, className, children, disabled, ...props } = this.props;
    return (
      <ButtonBase
        className={classNames(classes.button, className, {
          [classes.hasClick]: props.onClick ? true : false,
        })}
        disableRipple={true}
        disableTouchRipple={true}
        {...props}
        disabled={disabled || !props.onClick ? true : false}
      >
        <div className={classes.label}>{children}</div>
      </ButtonBase>
    );
  }
}

export default withStyles(styles)(RoundButton);
