import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import React from "react";
import Textarea from "react-textarea-autosize";

const styles = (theme: Theme) =>
  createStyles({
    textarea: {
      resize: "none",
      border: 0,
      background: "transparent",
      fontSize: "12px",
      outline: 0,
      fontFamily: "inherit",
      width: "100%",
      padding: 0,
      margin: "0 5px 0 0",
      color: theme.palette.text.primary,
      "&::placeholder": {
        color: lighten(theme.palette.text.primary, 0.58),
      },
    },
  });

type AutoresizeTextareaProps = WithStyles<typeof styles> & {
  focusOnRender?: boolean;
  inputProps?: {
    id?: string;
    maxLength?: number;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onBlur?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    placeholder?: string;
    value?: string;
    disabled?: boolean;
  };
};

class AutoresizeTextarea extends React.Component<AutoresizeTextareaProps> {
  public inputRef?: HTMLTextAreaElement = undefined;

  public componentDidMount() {
    if (this.props.focusOnRender) {
      if (this.inputRef) {
        this.inputRef.focus();
      }
    }
  }

  public handleInputRef = (el: HTMLTextAreaElement): void => {
    this.inputRef = el;
  };

  public render() {
    const { classes, inputProps } = this.props;

    return (
      <Textarea
        inputRef={this.handleInputRef}
        className={classes.textarea}
        maxRows={3}
        {...inputProps}
      />
    );
  }
}

export default withStyles(styles)(AutoresizeTextarea);
