import { CircularProgress, makeStyles, Typography } from "@material-ui/core";
import { Check } from "@material-ui/icons";
import React from "react";
import { InsightsInvolvedRole } from "../../../store/insights/type";
import { RoleResponse } from "../../../types/Roles";
import RolePanelItemWrapper from "../../RolePanelItem/RolePanelItemWrapper";
import TextOverflow from "../../TextOverflow";

const useStyles = makeStyles({
  cellContent: {
    display: "flex",
    marginLeft: 8,
    padding: 4,
    cursor: "pointer",
    textAlign: "left",
    height: 40,
    alignItems: "center",
  },
  checkStyle: {
    position: "absolute",
    right: 4,
  },
});

interface OwnProps {
  role: InsightsInvolvedRole;
  isChecked: boolean;
  onRoleClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  getRole?: (orglabID: string) => Promise<RoleResponse>;
}

const InvolvedRole: React.SFC<OwnProps> = ({
  role,
  isChecked,
  onRoleClick,
}) => {
  const classes = useStyles();

  return (
    <RolePanelItemWrapper
      connectedToOrglab={Boolean(role.orglabID)}
      roleHasName={true}
      hasError={false}
      involvedRoles={true}
    >
      <div style={{ background: "#fff" }}>
        <div className={classes.cellContent} onClick={onRoleClick}>
          <Typography component="div" style={{ width: "90%", height: "120%" }}>
            <TextOverflow
              maxLines={2}
              style={{
                textAlign: "left",
                lineHeight: "1.4em",
                width: "100%",
                height: "100%",
              }}
            >
              {role.name || "-"}
              {(role.orglabID &&
                role.employeeName &&
                ` (${role.employeeName})`) ||
                (role.orglabID && !role.loading && " (-)")}
            </TextOverflow>
          </Typography>
          {}
          {role.loading ? (
            <CircularProgress size={20} className={classes.checkStyle} />
          ) : (
            isChecked && <Check className={classes.checkStyle} />
          )}
        </div>
      </div>
    </RolePanelItemWrapper>
  );
};

export default InvolvedRole;
