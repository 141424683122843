import { Switch } from "@material-ui/core";
import { SwitchProps } from "@material-ui/core/Switch";
import { FieldProps } from "formik";
import React from "react";

interface Props
  extends FieldProps,
    Omit<
      SwitchProps,
      "form" | "name" | "onChange" | "value" | "defaultChecked"
    > {}

const FormSwitchField: React.SFC<Props> = ({
  field,
  children,
  form: { isSubmitting },
  disabled = false,
  ...props
}) => {
  return (
    <Switch
      {...{
        disabled: isSubmitting || disabled,
        ...props,
        ...field,
        value: field.name,
        checked: field.value,
      }}
      color="secondary"
    />
  );
};

export default FormSwitchField;
