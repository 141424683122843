import { makeStyles, Theme, Typography } from "@material-ui/core";
import * as _ from "lodash";
import React from "react";
import { DiagramComponentFrameworkValues } from "../../../hooks/useGetDiagram/types";
import RACIButton from "../../RACIButton";

interface Props {
  accountabilityValues: DiagramComponentFrameworkValues;
  selectedValue: number[];
  setSelectedValue: (value: number) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
  },
  axisOuter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  axisInner: {
    margin: "0px 4px",
  },
}));

export default function AnalysisAxis({
  accountabilityValues,
  selectedValue,
  setSelectedValue,
}: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.axisOuter}>
        <Typography>Analysis Axis: </Typography>
        {accountabilityValues.map((value, idx) => (
          <div key={idx} className={classes.axisInner}>
            <RACIButton
              color={value.color}
              outline={selectedValue.indexOf(idx) <= -1}
              onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
                setSelectedValue(idx)
              }
            >
              {value.symbol}
            </RACIButton>
          </div>
        ))}
      </div>
    </div>
  );
}
