import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { find } from "lodash";
import React, { ReactElement, ReactNode } from "react";
import { InsightDiagrams } from "../../../hooks/useGetInsightDiagrams";
import RolePanelItemWrapper from "../../RolePanelItem/RolePanelItemWrapper";
import TextOverflow from "../../TextOverflow";

const CustomTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    width: 300,
    fontSize: theme.typography.pxToRem(12),
    boxShadow: "-2px 0 5px rgba(0,0,0,.4)",
    borderRadius: 0,
    padding: "6px 12px",
  },
  arrow: {
    color: "white",
  },
}))(Tooltip);

const useStyles = makeStyles({
  cellContent: {
    display: "flex",
    marginLeft: 8,
    cursor: "pointer",
    textAlign: "left",
    height: 40,
    alignItems: "center",
  },
  textOuter: {
    width: "90%",
  },
  textOverflow: {
    textAlign: "left",
    lineHeight: "1.4em",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 8,
  },
});

interface Props {
  children: ReactElement;
  data: {
    [key: string]: number;
  };
  selectedProcesses: InsightDiagrams;
  label: string;
  orglab: boolean;
  employeeName?: string;
}

interface InnerTooltip {
  name: any;
  count: any;
}

export default function AnalysisTooltip({
  children,
  data,
  selectedProcesses,
  label,
  orglab,
  employeeName,
}: Props) {
  const classes = useStyles();
  const processes = Object.keys(data).reduce<InnerTooltip[]>(
    (acc, diagramId) => {
      const process = find(selectedProcesses, { id: diagramId });
      if (process) {
        return [...acc, { name: process.name, count: data[diagramId] }];
      }
      return acc;
    },
    []
  );
  return (
    <CustomTooltip
      arrow={true}
      placement="left"
      title={
        <>
          <RolePanelItemWrapper
            connectedToOrglab={orglab}
            roleHasName={true}
            hasError={false}
            involvedRoles={true}
          >
            <div className={classes.cellContent}>
              <Typography component="div" className={classes.textOuter}>
                <TextOverflow maxLines={2} className={classes.textOverflow}>
                  {label || "-"}
                  {(orglab && employeeName && ` (${employeeName})`) ||
                    (orglab && " (-)")}
                </TextOverflow>
              </Typography>
            </div>
          </RolePanelItemWrapper>

          <div className={classes.header}>
            <Typography variant={"subtitle2"}>Involved Processes</Typography>
            <Typography variant={"subtitle2"}>Assignments</Typography>
          </div>
          <List dense={true}>
            {processes.map((process, idx) => (
              <ListItem disableGutters={true} key={idx}>
                <ListItemText primary={process.name} />
                <ListItemSecondaryAction>
                  <Typography>{process.count}</Typography>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </>
      }
    >
      {children}
    </CustomTooltip>
  );
}
