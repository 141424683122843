import React from "react";
import { createContext, useReducer } from "react";
import reducer, { DiagramInteractionsAction, initialState } from "./reducer";

export const DiagramInteractionsContext = createContext({
  state: initialState,
  dispatch: (() => 0) as React.Dispatch<DiagramInteractionsAction>,
});

export function DiagramInteractionsProvider(props: any) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <DiagramInteractionsContext.Provider
      value={{ state, dispatch }}
      {...props}
    />
  );
}
