import InputAdornment from "@material-ui/core/InputAdornment";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import { FieldProps, getIn } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

const styles = (theme: Theme) =>
  createStyles({
    adorn: {
      color: `${theme.palette.grey[500]}`,
    },
  });

interface FormTextFieldOuterProps extends FieldProps {
  disableOnSubmitting?: boolean;
  charMax?: number;
}

interface FormTextFieldInnerProps extends WithStyles<typeof styles> {}

export type FormTextFieldProps = FormTextFieldOuterProps &
  FormTextFieldInnerProps &
  TextFieldProps;

const FormTextField: React.SFC<FormTextFieldProps> = ({
  field,
  form: { touched, errors, isSubmitting },
  disabled = false,
  label,
  disableOnSubmitting = true,
  charMax,
  classes,
  inputProps,
  InputProps,
  ...props
}) => {
  const { name } = field;
  const touch = getIn(touched, name);
  const error = getIn(errors, name);
  const { t } = useTranslation("formFields");
  const fieldLength =
    field.value && field.value.length ? field.value.length : 0;

  return (
    <TextField
      {...props}
      {...field}
      label={label || t(name)}
      error={touch && error ? true : false}
      helperText={(touch && error) || props.helperText}
      disabled={disableOnSubmitting ? disabled : isSubmitting || disabled}
      inputProps={{
        maxLength: charMax,
        ...inputProps,
      }}
      InputProps={{
        endAdornment: charMax ? (
          <InputAdornment position="end" className={classes.adorn}>
            {fieldLength}/{charMax}
          </InputAdornment>
        ) : (
          undefined
        ),
        ...InputProps,
      }}
    />
  );
};

export default withStyles(styles)(FormTextField);
