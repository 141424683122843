import _ from "lodash";
import React from "react";
import useDiagramInteractions from "../../hooks/useDiagramInteractions";
import { RoleResponse } from "../../types/Roles";
import WindowAnchored from "../WindowAnchored";
import RoleInfoCard from "./RoleInfoCard";

interface RoleSearchWindowProps {
  orglabRole: RoleResponse;
  anchorId: string;
}

const RoleSearchWindow: React.SFC<RoleSearchWindowProps> = props => {
  const { orglabRole, anchorId } = props;
  const {
    actions: { hideRoleInfo },
  } = useDiagramInteractions();

  return (
    <WindowAnchored
      anchorId={anchorId}
      onClickAway={() => {
        hideRoleInfo();
      }}
      windowSizeStyle={{
        width: 256,
        height: "50vh",
        maxHeight: 300,
      }}
    >
      <div
        style={{
          padding: 8,
          overflow: "auto",
          height: "100%",
          position: "relative",
        }}
      >
        <RoleInfoCard orglabRole={orglabRole} />
      </div>
    </WindowAnchored>
  );
};

export default RoleSearchWindow;
