import React from "react";

export default function XAxis({ ticks }: { ticks: number[] }) {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      {ticks.map((value, i) => (
        <div
          key={i}
          style={{
            height: 0,
            width: 1,
          }}
        >
          <span
            style={{
              position: "absolute",
              bottom: 0,
              fontSize: 9,
              transform: "translate(-50%, 15px)",
            }}
          >
            {value}
          </span>
        </div>
      ))}
    </div>
  );
}
