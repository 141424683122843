import { applyMiddleware, compose, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import i18n from "../config/i18n";
import { pdClient } from "../lib/apiClient";
import rootReducer from "./reducer";
import { State, StoreActions } from "./types";

interface DevtoolWindow extends Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
}

const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const persistedReducer = persistReducer<State, StoreActions>(
  {
    key: "rolematrix",
    storage,
    whitelist: ["rolematrix", "login", "frameworks", "userSettings"],
  },
  rootReducer
);

export const store = createStore(
  persistedReducer,
  composeEnhancers(
    applyMiddleware(thunk.withExtraArgument({ pdClient, _i18n: i18n }))
  )
);

if (process.env.NODE_ENV !== "production" && module.hot) {
  module.hot.accept("./reducer", () => {
    store.replaceReducer(persistedReducer);
  });
}

pdClient.addProvider(store);

export const persistor = persistStore(store);
