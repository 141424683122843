import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect, MapStateToProps } from "react-redux";
import { compose, withState } from "recompose";
import i18n from "../../config/i18n";
import { hideModal } from "../../store/modal/actions";
import { State } from "../../store/types";
import { ModalProps } from "../../types/ModalProps";
import ConfirmationDialog from "../ConfirmationDialog";

const styles = (theme: Theme) =>
  createStyles({
    actions: {
      paddingTop: 10,
    },
    title: {
      alignItems: "center",
      display: "inline-flex",
    },
    titleIcon: {
      marginRight: theme.spacing(1),
    },
  });

interface ConfirmationDialogContainerStateProps {
  open: boolean;
  modalProps: ModalProps<{
    noShowAgain: boolean;
  }>;
  username: string;
}
interface ConfirmationDialogContainerInnerProps
  extends WithStyles<typeof styles>,
    ConfirmationDialogContainerStateProps {
  t: i18n.TFunction;
  handleHideModal: () => void;
}

interface ConfirmationDialogContainerOuterProps {
  handleCancel?: () => void;
  handleConfirm?: () => void;
  loading?: boolean;
}

type ConfirmationDialogContainerProps = ConfirmationDialogContainerInnerProps &
  ConfirmationDialogContainerOuterProps;

class ConfirmationDialogContainer extends Component<
  ConfirmationDialogContainerProps
> {
  public static defaultProps = {
    open: false,
    props: {},
  };
  public state = {
    checked: true,
    loading: false,
  };

  public render() {
    const { open, modalProps, handleHideModal, username } = this.props;
    return open ? (
      <ConfirmationDialog
        open={open}
        props={modalProps}
        loading={this.state.loading}
        handleCancel={() => {
          if (modalProps.onCancel) {
            modalProps.onCancel();
          }
          handleHideModal();
        }}
        handleConfirm={({ noShowAgain }) => {
          if (modalProps.onConfirm) {
            this.setState({ loading: true });
            return Promise.resolve(modalProps.onConfirm({ noShowAgain })).then(
              () => {
                handleHideModal();
                this.setState({ loading: false });
              }
            );
          }
          handleHideModal();
        }}
      />
    ) : null;
  }
}

const mapStateToProps: MapStateToProps<
  {},
  ConfirmationDialogContainerProps,
  State
> = ({ login: { username }, modal: { modalProps, open } }) => ({
  open,
  modalProps,
  username,
});

const enhance = compose<
  ConfirmationDialogContainerProps,
  ConfirmationDialogContainerOuterProps
>(
  withTranslation(),
  connect(
    mapStateToProps,
    { handleHideModal: hideModal }
  ),
  withState("loading", "setLoading", false),
  withStyles(styles)
);

export default enhance(ConfirmationDialogContainer);
