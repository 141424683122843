import { isEmpty, sortBy, trim } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { pdClient } from "../../lib/apiClient";
import isNotNull from "../../lib/isNotNull";
import {
  insightsAddDiagrams,
  insightsAddRoles,
  insightsLoadRoleEmployee,
  insightsRemoveDiagram,
  insightsSelectRole,
  insightsToggleAllRoles,
} from "../../store/insights/actions";
import {
  InsightsIncomingDiagram,
  InsightsIncomingDiagrams,
  InsightsInvolvedRole,
} from "../../store/insights/type";
import { State } from "../../store/types";

export default function useInsightActions() {
  const dispatch = useDispatch();
  const { involvedRoles, involvedDiagrams } = useSelector((state: State) => ({
    involvedRoles: state.insights.involvedRoles,
    involvedDiagrams: state.insights.involvedDiagrams,
  }));

  return {
    removeDiagram: (diagram: InsightsIncomingDiagram) => {
      dispatch(insightsRemoveDiagram(diagram));
    },
    selectRole: (roleIndex: number) => {
      dispatch(insightsSelectRole(roleIndex));
    },
    toggleAllRoles: (on: boolean) => {
      dispatch(insightsToggleAllRoles(on));
    },
    addDiagrams: (allSelectedDiagrams: InsightsIncomingDiagrams) => {
      const newSelectedDiagrams = allSelectedDiagrams.filter(
        _ => involvedDiagrams.indexOf(_.id) < 0
      );
      const updatedRoles = sortBy(
        getInvolvedRolesFromDiagrams(involvedRoles, newSelectedDiagrams),
        ({ name }) => (name ? name.toUpperCase() : "z") // "z" ensures empty names are added to the end :/
      );
      updatedRoles.forEach(({ orglabID }, i) => {
        if (orglabID) {
          pdClient.getInvolvedRole(orglabID).then(role => {
            dispatch(
              insightsLoadRoleEmployee(
                i,
                role.employeeName,
                role.layer,
                role.location.name || undefined,
                role.businessUnit.name || undefined,
                role.function.name || undefined
              )
            );
          });
        }
      });
      dispatch(insightsAddDiagrams(allSelectedDiagrams));
      dispatch(insightsAddRoles(updatedRoles));
    },
  };
}

export function matchRoleNames(nameA: string, nameB: string): boolean {
  return trim(nameA.toLowerCase()) === trim(nameB.toLowerCase());
}

export function getInvolvedRolesFromDiagrams(
  existingInvolvedRoles: InsightsInvolvedRole[],
  newDiagrams: InsightsIncomingDiagrams
): InsightsInvolvedRole[] {
  const newInvolvedRoles = [...existingInvolvedRoles];
  newDiagrams.forEach(newDiagram => {
    (newDiagram.roles || []).filter(isNotNull).forEach(role => {
      // role merging
      const foundRoleIndex = role.orglabID
        ? newInvolvedRoles.findIndex(_ => _.orglabID === role.orglabID)
        : !isEmpty(role.name)
          ? newInvolvedRoles.findIndex(_ =>
              matchRoleNames(_.name || "", role.name || "")
            )
          : -1;

      if (foundRoleIndex > -1) {
        newInvolvedRoles[foundRoleIndex].associatedRoles.push({
          roleId: role.id,
          processId: newDiagram.id,
          processName: newDiagram.name,
          capacity: role.capacity,
        });
      } else {
        newInvolvedRoles.push({
          name: role.name || "",
          associatedRoles: [
            {
              roleId: role.id,
              processId: newDiagram.id,
              processName: newDiagram.name,
              capacity: role.capacity,
            },
          ],
          selected: true,
          orglabID: role.orglabID || undefined,
          loading: role.orglabID ? true : false,
        });
      }
    });
  });
  return newInvolvedRoles;
}
