import { useMutation } from "@apollo/react-hooks";
import {
  UpdateRoleCapacity,
  UpdateRoleCapacityVariables,
} from "../../generated/UpdateRoleCapacity";
import { UPDATE_ROLE_CAPACITY } from "../../graphql/mutation/updateRoleCapacity";

export default function useUpdateRoleCapacity() {
  const [_updateRoleCapacity, { loading }] = useMutation<
    UpdateRoleCapacity,
    UpdateRoleCapacityVariables
  >(UPDATE_ROLE_CAPACITY);

  function updateRoleCapacity(
    roleCapacities: Array<{ id: string; capacity: number }>
  ) {
    return _updateRoleCapacity({
      variables: {
        roleCapacities,
      },
    });
  }

  return {
    updateRoleCapacity,
    loading,
  };
}
