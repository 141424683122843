import idx from "idx";
import { GetFrameworks } from "../../generated/GetFrameworks";
import isNotNull from "../../lib/isNotNull";

interface GetGetFrameworksValuesResult {
  id: string;
  symbol?: string;
  color?: string;
  name?: string;
  description?: string;
  exclusive: boolean;
  order: number;
}
export type GetFrameworksResult = Array<{
  id: string;
  name: string;
  diagramCount: number;
  createdBy: string;
  creationDate: string;
  updatedBy: string;
  updatedDate: string;
  children: GetGetFrameworksValuesResult[];
  custom: boolean;
  index: number;
}>;

export default function normalizeFrameworks(
  data: GetFrameworks["FrameworkList"]
): GetFrameworksResult {
  const frameworksList = data || [];

  return frameworksList.filter(isNotNull).map((f, index) => ({
    id: f.id,
    name: f.name || "",
    diagramCount: idx(f, _ => _.diagramCount) || 0,
    createdBy: f.custom === true ? idx(f, _ => _.createdBy.login) || "" : "admin",
    creationDate: f.creationDate || '',
    updatedBy: f.updatedBy && f.updatedBy.login || '',
    updatedDate: f.updatedDate || '',
    children: idx(f, _ => _.children) || [],
    custom: f.custom || false,
    index,
  }));  
}
