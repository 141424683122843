import { findIndex, groupBy, reverse, sortBy } from "lodash";
import {
  DiagramComponentFrameworkValues,
  DiagramComponentState,
} from "../../../hooks/useGetDiagram/types";
import {
  OperationsState,
  useLocalToggleDiagramPath,
} from "../../../hooks/useToggleDiagramPath";
import { InsightsInvolvedRole } from "../../../store/insights/type";

export default function useGetLinkages(
  diagram: DiagramComponentState,
  involvedRoles: InsightsInvolvedRole[],
  groupByFn: (
    involvedRole: InsightsInvolvedRole
  ) => number | string | undefined,
  filterAccountabilityValues?: DiagramComponentFrameworkValues
) {
  const { currentPath } = useLocalToggleDiagramPath(diagram.operation);
  const group = groupBy(involvedRoles, groupByFn);
  const frameworkValues =
    diagram.accountabilityMethodology.accountabilityMethodologyValues;
  const headers = sortBy(Object.keys(group));
  const filteredFrameworkValues = frameworkValues.filter(
    ({ symbol }) =>
      filterAccountabilityValues
        ? findIndex(filterAccountabilityValues, {
            symbol,
          }) > -1
        : true
  );

  return {
    frameworkValues,
    headers,
    filteredFrameworkValues,
    operationAxis: currentPath
      .slice(1, currentPath.length - 1)
      .map((operation, i) => ({
        operation,
        tooltipData: filteredFrameworkValues
          .map(framework => ({
            ...framework,
            headers: headers.map(value => ({
              title: value,
              group: group[value].filter(({ associatedRoles }) =>
                associatedRoles.find(role =>
                  operation.assignments.find(
                    ({ roleId, accountabilityMethodologyValues }) =>
                      role.roleId === roleId &&
                      accountabilityMethodologyValues.indexOf(framework.id) > -1
                  )
                )
              ),
            })),
          }))
          .filter(({ headers }) => headers.length > 0),
        values: headers.map(value =>
          filteredFrameworkValues.map(({ id, symbol, color, name }) => ({
            roles: getRolesFromLinkageGroup({
              involvedRoles: group[value],
              operation,
              frameworkId: id,
            }),
            value,
            id,
            symbol,
            color,
            name,
          }))
        ),
      })),
  };
}

function getRolesFromLinkageGroup({
  involvedRoles,
  operation,
  frameworkId,
}: {
  involvedRoles: InsightsInvolvedRole[];
  operation: OperationsState;
  frameworkId: string;
}) {
  const foundRoles: InsightsInvolvedRole[] = [];
  operation.assignments.forEach(
    ({ accountabilityMethodologyValues, roleId }) => {
      if (accountabilityMethodologyValues.indexOf(frameworkId) > -1) {
        involvedRoles.forEach(involvedRole => {
          involvedRole.associatedRoles.forEach(a => {
            if (a.roleId === roleId) {
              foundRoles.push(involvedRole);
            }
          });
        });
      }
    }
  );
  return foundRoles;
}
