import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import React, { SFC } from "react";
import { IconUser } from "../Icons/index";
import Truncate from "../Truncate";

const useStyles = makeStyles({
  activeUserAvatar: {
    marginRight: 8,
    marginTop: 2,
  },
  activeUserName: {
    fontSize: "1rem",
  },
  root: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "row",
    fontSize: 14,
  },
  clientName: {
    fontSize: ".8rem",
    lineHeight: ".8rem",
  },
});

const ActiveUser: SFC<{
  userName?: string;
  clientName?: string;
}> = ({ userName, clientName }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <IconUser className={classes.activeUserAvatar} />
      <div>
        <Typography
          component="div"
          classes={{ root: classes.activeUserName }}
          color="inherit"
        >
          <Truncate maxWidth={180}>{userName}</Truncate>
        </Typography>
        <Typography
          component="div"
          classes={{ root: classes.clientName }}
          color="inherit"
        >
          {clientName}
        </Typography>
      </div>
    </div>
  );
};

export default ActiveUser;
