import CssBaseline from "@material-ui/core/CssBaseline";
import { createStyles, Theme, withStyles } from "@material-ui/core/styles";
import React from "react";

const styles = (theme: Theme) =>
  createStyles({
    "@global": {
      a: {
        "&:hover": {
          textDecoration: "underline",
        },
        color: theme.palette.secondary.dark,
        textDecoration: "none",
      },
      body: {
        overflowX: "hidden",
        lineHeight: "1.2",
      },
      "html, body": {
        width: "100%",
      },
      "input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button": {
        "-moz-appearance": "none",
        "-webkit-appearance": "none",
        appearance: "none",
        margin: "0",
      },
      "ul, ol": {
        paddingLeft: "1.2em",
      },
      ".jtk-connector": {
        zIndex: 1,
      },
    },
  });

const RootStyles: React.SFC = ({ children }) => {
  return (
    <React.Fragment>
      <CssBaseline />
      {children}
    </React.Fragment>
  );
};

export default withStyles(styles)(RootStyles);
