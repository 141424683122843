import { AppBar, Grid, IconButton, Paper, Tab, Tabs } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import RoleClarityIcon from "@material-ui/icons/PermIdentityOutlined";
import SpeedIcon from "@material-ui/icons/QueryBuilderOutlined";
import LinkIcon from "@material-ui/icons/Link";
import React from "react";
import { Link } from "react-router-dom";
import { IconClose } from "../../Icons";

interface Props {
  closeUrl?: string;
  onChange: (value: any) => void;
  activeTab: number;
}

export default function PageInsightsTabs({
  closeUrl,
  onChange,
  activeTab,
}: Props) {
  return (
    <AppBar
      position="static"
      style={{ background: grey[50], boxShadow: "none" }}
    >
      <Grid container={true} wrap="nowrap" spacing={2} alignItems="center">
        <Grid item={true} xs={true}>
          <Tabs
            value={activeTab}
            indicatorColor="secondary"
            textColor="primary"
            onChange={(_event, value) => {
              onChange(value);
            }}
            style={{ borderBottom: "1px solid #fff" }}
          >
            <Tab value={0} label="Role Clarity" icon={<RoleClarityIcon />} />
            <Tab value={1} label="Decision Speed" icon={<SpeedIcon />} />
            <Tab value={2} label="Linkages & Handoffs" icon={<LinkIcon />} />
          </Tabs>
        </Grid>
      </Grid>
    </AppBar>
  );
}
