import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import {
  createStyles,
  MuiThemeProvider,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { CheckBoxOutlined } from "@material-ui/icons";
import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import { compose } from "recompose";
import i18n from "../../config/i18n";
import { warningTheme } from "../../config/theme";
import { ModalProps } from "../../types/ModalProps";
import { ButtonCancel, ButtonOk } from "../Button";
import { IconInfo } from "../Icons";

const styles = (theme: Theme) =>
  createStyles({
    actions: {
      paddingTop: 20,
    },
    title: {
      alignItems: "center",
      display: "inline-flex",
    },
    titleIcon: {
      marginRight: theme.spacing(1),
    },
  });

interface ConfirmationDialogInnerProps extends WithStyles<typeof styles> {
  t: i18n.TFunction;
}

interface ConfirmationDialogConfirmProps {
  noShowAgain: boolean;
}

interface ConfirmationDialogOuterProps {
  open: boolean;
  props: ModalProps<{ noShowAgain: boolean }>;
  handleCancel?: () => void;
  handleConfirm?: (props: ConfirmationDialogConfirmProps) => void;
  loading?: boolean;
  autoDismiss?: boolean;
}

type ConfirmationDialogProps = ConfirmationDialogInnerProps &
  ConfirmationDialogOuterProps;

class ConfirmationDialog extends Component<ConfirmationDialogProps> {
  public static defaultProps = {
    open: false,
    props: {},
  };
  public state = {
    acnowledgeChecked: true,
    noShowAgain: false,
  };

  public componentDidMount() {
    const { autoDismiss, handleConfirm } = this.props;
    const { noShowAgain } = this.state;
    const acknowledge = this.getTranslateContent("acknowledge");
    this.setState({ acnowledgeChecked: acknowledge ? false : true });
    if (autoDismiss && handleConfirm) {
      handleConfirm({ noShowAgain });
    }
  }

  public getTranslateContent = (context?: string) => {
    const {
      t,
      props: { i18nKey, i18nOptions },
    } = this.props;
    return t(`confirmations:${i18nKey}.${context}`, {
      ...i18nOptions,
      defaultValue: false,
    });
  };

  public render() {
    const {
      open,
      handleCancel,
      handleConfirm,
      classes,
      t,
      loading,
      props: { i18nKey, i18nOptions, customMessage, noShowAgainOption },
    } = this.props;
    const { acnowledgeChecked, noShowAgain } = this.state;
    const title = this.getTranslateContent("title");
    const message = this.getTranslateContent("message");
    const acknowledge = this.getTranslateContent("acknowledge");
    const cancelText = t(`confirmations:${i18nKey}.cancelText`, {
      defaultValue: "Cancel",
    });
    const confirmText = t(`confirmations:${i18nKey}.confirmText`, {
      defaultValue: "Ok",
    });

    return (
      <MuiThemeProvider theme={warningTheme}>
        <Dialog
          open={open}
          onClose={handleCancel}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
        >
          {title && (
            <DialogTitle disableTypography={true}>
              <Typography variant="h6" color="secondary">
                <div className={classes.title}>
                  <IconInfo className={classes.titleIcon} />
                  <Trans
                    i18nKey={`${i18nKey}.title`}
                    components={[<span style={{ fontWeight: "bold" }}>0</span>]}
                    {...i18nOptions}
                  >
                    {title}
                  </Trans>
                </div>
              </Typography>
            </DialogTitle>
          )}
          <DialogContent>
            {message && (
              <Typography variant="body1">
                <Trans
                  i18nKey={`${i18nKey}.message`}
                  components={[<span style={{ fontWeight: "bold" }}>0</span>]}
                  {...i18nOptions}
                >
                  {message}
                </Trans>
              </Typography>
            )}
            <Typography variant="body1">{customMessage}</Typography>
            {acknowledge && (
              <FormGroup row={true}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={acnowledgeChecked}
                      onChange={() => {
                        this.setState({
                          acnowledgeChecked: !this.state.acnowledgeChecked,
                        });
                      }}
                      value="checkedB"
                      color="default"
                      checkedIcon={<CheckBoxOutlined />}
                    />
                  }
                  label={acknowledge}
                />
              </FormGroup>
            )}
            {noShowAgainOption && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={noShowAgain}
                    onChange={() => {
                      this.setState({
                        noShowAgain: !this.state.noShowAgain,
                      });
                    }}
                    color="default"
                    checkedIcon={<CheckBoxOutlined />}
                  />
                }
                label="Do not show again"
              />
            )}

            <DialogActions className={classes.actions}>
              {handleCancel && (
                <ButtonCancel
                  onClick={handleCancel}
                  color="secondary"
                  text={cancelText}
                  disabled={loading}
                />
              )}
              {handleConfirm && (
                <ButtonOk
                  onClick={() => {
                    handleConfirm({ noShowAgain });
                  }}
                  color="secondary"
                  text={confirmText}
                  disabled={!acnowledgeChecked || loading}
                  loading={loading}
                />
              )}
            </DialogActions>
          </DialogContent>
        </Dialog>
      </MuiThemeProvider>
    );
  }
}

const enhance = compose<ConfirmationDialogProps, ConfirmationDialogOuterProps>(
  withTranslation(),
  withStyles(styles)
);
export default enhance(ConfirmationDialog);
