import Paper from "@material-ui/core/Paper";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import React from "react";
import { OVERLAY_VALUES } from "../../config/constants";
import { DiagramComponentFrameworkValues } from "../../hooks/useGetDiagram/types";
import { FrameworkAssignment } from "../../types/Framework";
import RACIButton from "../RACIButton";

const styles = (theme: Theme) =>
  createStyles({
    button: {
      alignItems: "center",
      background: "white",
      borderRadius: theme.spacing(1),
      cursor: "pointer",
      display: "flex",
      marginRight: theme.spacing(1),
      padding: theme.spacing(1),
    },
    container: {
      alignItems: "center",
      display: "flex",
    },
    header: {
      borderRight: "1px solid rgba(255, 255, 255, .5)",
      marginLeft: theme.spacing(1) * 3,
      marginRight: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    icon: {
      fontSize: "18px",
      marginRight: "5px",
    },
    iconCaption: {
      color: "#00bcd4",
    },
    iconCaptionDisabled: {
      color: theme.palette.grey[400],
    },
    iconDisabled: {
      color: `${theme.palette.grey[400]} !important`,
    },
    overlayButtons: {
      display: "flex",
    },
    raciButton: {
      marginRight: theme.spacing(1),
    },
    raciButtons: {
      display: "flex",
    },
    root: {
      backgroundColor: theme.palette.grey[50],
      padding: theme.spacing(1),
      position: "relative",
      zIndex: 1,
    },
  });

interface ComponentProps extends WithStyles<typeof styles> {
  selectedOverlays: any;
  selectedRACIValue: any;
  toggleValues: DiagramComponentFrameworkValues;
  onToggleOverlay: any;
  onToggleRACI: any;
}

class DiagramFilters extends React.Component<ComponentProps> {
  public render() {
    const {
      selectedOverlays,
      selectedRACIValue,
      toggleValues,
      classes,
      onToggleOverlay,
      onToggleRACI,
    } = this.props;
    return (
      <Paper className={classes.root}>
        <div className={classes.container}>
          <div className={classes.header}>
            <Typography variant="body2">Analytics</Typography>
          </div>
          <div className={classes.overlayButtons}>
            {OVERLAY_VALUES.map(({ name, id, Icon, color }, i) => {
              const isActive = selectedOverlays[id];
              return (
                <div
                  key={i}
                  onClick={onToggleOverlay(id)}
                  className={classes.button}
                >
                  <Icon
                    style={{ color }}
                    className={classNames(classes.icon, {
                      [classes.iconDisabled]: !isActive,
                    })}
                  />
                  <Typography
                    className={classNames(classes.iconCaption, {
                      [classes.iconCaptionDisabled]: !isActive,
                    })}
                    variant="caption"
                  >
                    {name}
                  </Typography>
                </div>
              );
            })}
          </div>
          <div className={classes.raciButtons}>
            {toggleValues.map(({ symbol, id, color }, i) => (
              <RACIButton
                color={color}
                key={i}
                inactive={selectedRACIValue !== id}
                className={classNames(classes.raciButton)}
                onClick={onToggleRACI(id, symbol, selectedRACIValue === id)}
              >
                {symbol}
              </RACIButton>
            ))}
          </div>
        </div>
      </Paper>
    );
  }
}

export default withStyles(styles)(DiagramFilters);
