import { styled } from "@material-ui/styles";
import React, { Fragment } from "react";
import { DiagramComponentState } from "../../hooks/useGetDiagram/types";
import getPathOperations from "../../lib/getPathOperations";

const Node = styled("div")({
  display: "inline-block",
});

const Container = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: 1000000,
  padding: 10,
  fontSize: ".6em",
  color: "#999",
});

const DisplayPossiblePaths: React.SFC<{
  paths: string[][];
  diagram: DiagramComponentState;
}> = ({ paths, diagram }) => {
  return (
    <Container>
      {paths.map((nodes, j) => (
        <div key={j}>
          {getPathOperations(nodes, diagram.operation).map((node, i) => {
            const nextNodeId = nodes[i + 1];
            switch (node.type) {
              case "start":
                return <Fragment key={i}>○—</Fragment>;
              case "end":
                return <Fragment key={i}>○</Fragment>;
              case "task":
                return (
                  <Node key={i} title={node.id}>
                    {"▱—"}
                  </Node>
                );
              case "decision":
                let color = "inherit";
                if (node.nextOperationYes === nextNodeId) {
                  color = "green";
                }
                if (node.nextOperationNo === nextNodeId) {
                  color = "red";
                }

                return (
                  <Node key={i} title={node.id}>
                    <span style={{ color }}>{"◇"}</span>
                    {"—"}
                  </Node>
                );
            }
          })}
        </div>
      ))}
    </Container>
  );
};

export default DisplayPossiblePaths;
