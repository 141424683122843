import { FieldProps } from "formik";
import { upperFirst } from "lodash";
import React from "react";

interface OwnProps extends FieldProps {
  index: number;
}

class FormTextFieldUpdateSymbol extends React.Component<OwnProps> {
  public UNSAFE_componentWillReceiveProps(nextProps: OwnProps) {
    const { field, index, form } = this.props;
    const nextValue = upperFirst(nextProps.field.value).charAt(0);
    const thisValue = upperFirst(field.value).charAt(0);
    if (nextValue !== thisValue) {
      form.setFieldValue(`assignables.${index}.symbol`, nextValue || "?");
    }
  }
  public render() {
    return null;
  }
}

export default FormTextFieldUpdateSymbol;
