import { Grid, Typography } from "@material-ui/core";
import { get, head } from "lodash";
import React, { useState } from "react";
import { InsightsInvolvedRole } from "../../../store/insights/type";
import ExpansionPanel from "../../ExpansionPanel";
import CapacityDistribution from "../CapacityDistribution";
import InvolvedRoles from "../InvolvedRoles";
import InvolvedRole from "../InvolvedRoles/InvolvedRole";

interface Props {
  involvedRoles: InsightsInvolvedRole[];
  toggleAllRoles: (on: boolean) => void;
  allInvolvedRolesSelected: boolean;
  selectRole: (index: number) => void;
}

export default function TabCapacity({
  involvedRoles,
  toggleAllRoles,
  allInvolvedRolesSelected,
  selectRole,
}: Props) {
  return (
    <ExpansionPanel
      header="Capacity Distribution"
      info={
        <>
          Measure the amount of time that the (selected) roles/decision-makers
          spend across the (selected) processes.
          <br />
          <br />
          Couple the insights from this chart, with those from the Decision
          Density Map, to uncover which of the decision-makers are over-loaded
          or under-loaded with decision-making responsibility.
        </>
      }
      content={
        <>
          <div style={{ width: "100%" }}>
            <div>
              <Typography variant="caption" style={{ color: "red" }}>
                Chart only displays OrgLab-Connected roles.
              </Typography>
            </div>
            <div>
              <InvolvedRoles
                onToggleAll={on => toggleAllRoles(on)}
                isAllSelected={allInvolvedRolesSelected}
              >
                {involvedRoles.map((role, index) => {
                  return role.orglabID ? (
                    <Grid item={true} xs={3} key={index}>
                      <InvolvedRole
                        role={role}
                        isChecked={role.selected}
                        onRoleClick={() => selectRole(index)}
                      />
                    </Grid>
                  ) : null;
                })}
              </InvolvedRoles>
            </div>
            <div
              style={{
                marginTop: 8,
                border: "1px solid #eceff1",
                padding: 4,
              }}
            >
              <CapacityDistribution involvedRoles={involvedRoles} />
            </div>
          </div>
        </>
      }
    />
  );
}
