import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import React, { ReactNode } from "react";

const useStyles = makeStyles({
  root: {
    fontWeight: 600,
    fontSize: 10,
    width: 16,
    height: 16,
    borderRadius: "50%",
    border: "1px solid",
    textAlign: "center",
    color: "white",
    paddingRight: 1,
    boxSizing: "border-box",
    userSelect: "none",
  },
  yes: {
    borderColor: "#0091A6",
    background: "#00BBD3",
  },
  no: {
    borderColor: "#D1E0E3",
    background: "#ECF0F1",
    color: "#00BBD3",
  },
});

interface ProcessElementProps {
  className?: string;
  style?: React.CSSProperties;
  decisionType: "yes" | "no";
}

function ProcessElement({
  className,
  decisionType,
  style,
}: ProcessElementProps) {
  const classes = useStyles();
  return (
    <Typography
      component="div"
      className={classNames(classes.root, className, {
        [classes.yes]: decisionType == "yes",
        [classes.no]: decisionType == "no",
      })}
      style={style}
    >
      {decisionType === "yes" ? "Y" : "N"}
    </Typography>
  );
}

export default ProcessElement;
