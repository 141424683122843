import gql from "graphql-tag";

export const UPDATE_FRAMEWORK = gql`
  mutation UpdateAccoutabilityMethodologyValue(
    $id: ID!
    $assignables: [UpdateFrameworkAssignables]
  ) {
    EditAccountabilityMethodology(
      id: $id
      assignables: $assignables
    ) {
      id
      accountabilityMethodologyValues {
        id
        name
        description
        order
        color
        exclusive
      }
    }
  }
`;
