import { string } from "prop-types";
import { Reducer } from "redux";
import { AppVersionAction, AppVersionState, AppVersionTypes } from "./types";

export const initialState: AppVersionState = {
  authUrl: "",
  version: "",
};

const reducer: Reducer<AppVersionState, AppVersionAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AppVersionTypes.APP_VERSION_SET:
      return {
        authUrl: action.payload.authUrl,
        version: action.payload.version,
      };

    default:
      return state;
  }
};

export default reducer;
