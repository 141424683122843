import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import _ from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import displayTime from "../../lib/displayTime";

const useStyles = makeStyles(theme => ({
  font: {
    fontFamily: theme.typography.fontFamily,
    fontSize: 9,
    color: theme.palette.primary.main,
    lineHeight: "12px",
  },
  cycleWait: {
    borderWidth: "0 0 2px",
    borderStyle: "solid",
    borderColor: theme.palette.primary.main,
    marginRight: 10,
    marginLeft: 6,
    textAlign: "center",
    height: 18,
    marginBottom: 8,
    visibility: "hidden",
  },
  showTimes: {
    visibility: "visible",
  },
}));

interface DiagramProcessTimeProps {
  cycleTime: string[];
  waitTime: string[];
  isSorting?: boolean;
}

const DiagramProcessTime: React.SFC<DiagramProcessTimeProps> = ({
  cycleTime,
  waitTime,
  isSorting,
}) => {
  const classes = useStyles();
  const { t } = useTranslation("timeConversion");
  // !operation.isSorting && formattedTimes ? true : false;
  const [cycleTimeValue, cycleTimeUnit] = cycleTime;
  const [waitTimeValue, waitTimeUnit] = waitTime;
  const shouldRenderTime = cycleTimeValue || waitTimeValue;

  return (
    <div
      className={classNames(classes.cycleWait, classes.font, {
        [classes.showTimes]: !isSorting && shouldRenderTime,
      })}
    >
      {cycleTimeValue && waitTimeValue
        ? `${cycleTimeValue} ${t(cycleTimeUnit || "")} / ${waitTimeValue} ${t(
            waitTimeUnit || ""
          )}`
        : cycleTimeValue && !waitTimeValue
          ? displayTime(cycleTime)
          : !cycleTimeValue && waitTimeValue && displayTime(waitTime)}
    </div>
  );
};

export default DiagramProcessTime;
