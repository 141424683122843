import {
  Template,
  TemplateConnector,
  TemplatePlaceholder,
} from "@devexpress/dx-react-core";
import { CustomTreeData, DataTypeProvider } from "@devexpress/dx-react-grid";
import {
  Grid,
  TableFixedColumns,
  TableHeaderRow,
  TableTreeColumn,
  Toolbar,
  VirtualTable,
} from "@devexpress/dx-react-grid-material-ui";
import { range } from "lodash";
import React from "react";
import { UseGetDiagramsResult } from "../../hooks/useGetDiagrams";
import { ButtonCollapse, ButtonExpand } from "../Button";
import ColumnStateContainer from "./ColumnStateContainer";
import DiagramViewMenu from "./DiagramViewMenu";
import {
  FormatActions,
  FormatAlert,
  FormatDate,
  FormatLinks,
  FormatStructure,
  FormatToolbar,
} from "./TableFormatters";
import TreeStateContainer from "./TreeStateContainer";

const getChildRows = (row: any, rootRows: any) => {
  return row
    ? row.children && row.children.length > 0
      ? row.children
      : null
    : rootRows;
};

const DiagramTree: React.SFC<{
  data: UseGetDiagramsResult;
  dataLength: number;
}> = ({ data, dataLength }) => (
  <Grid
    rows={data}
    columns={[
      { name: "name", title: "Diagram Name" },
      { name: "decisionFramework", title: "Framework" },
      { name: "structureId", title: "Associated Structure" },
      { name: "created_by", title: "Creator" },
      { name: "created_at", title: "Creation Date" },
      { name: "updated_by", title: "Last edited by" },
      { name: "updated_at", title: "Last edited date" },
      { name: "actions", title: " " },
    ]}
    rootComponent={(props) => (
      <Grid.Root {...props} style={{ height: "100%" }} />
    )}
  >
    <DataTypeProvider formatterComponent={FormatActions} for={["actions"]} />
    <DataTypeProvider formatterComponent={FormatLinks} for={["name"]} />
    <DataTypeProvider
      formatterComponent={FormatDate}
      for={["created_at", "updated_at"]}
    />
    <DataTypeProvider
      formatterComponent={FormatStructure}
      for={["structureId"]}
    />
    <DataTypeProvider formatterComponent={FormatAlert} for={["alert"]} />
    <TreeStateContainer localStorageKey="diagramTreeExpandedIds" />
    <CustomTreeData getChildRows={getChildRows} />
    <VirtualTable
      height="auto"
      columnExtensions={[{ columnName: "actions", align: "center" }]}
    />
    <ColumnStateContainer
      localStorageKey="diagramTreeColumnWidths"
      initialColumnWidths={[
        { columnName: "name", width: 400 },
        { columnName: "decisionFramework", width: 100 },
        { columnName: "structureId", width: 150 },
        { columnName: "created_by", width: 100 },
        { columnName: "created_at", width: 150 },
        { columnName: "updated_by", width: 100 },
        { columnName: "updated_at", width: 150 },
        { columnName: "actions", width: 60 },
      ]}
    />
    <TableHeaderRow />
    <TableTreeColumn for="name" />
    <TableFixedColumns rightColumns={["actions"]} leftColumns={["name"]} />
    <Toolbar rootComponent={FormatToolbar} />
    <Template name="toolbarContent">
      <DiagramViewMenu />
      <TemplateConnector>
        {(_getters, actions) => (
          <React.Fragment>
            <ButtonCollapse onClick={() => actions.changeRowIds([])} />
            <ButtonExpand
              onClick={() => actions.changeRowIds(range(dataLength))}
            />
          </React.Fragment>
        )}
      </TemplateConnector>
      <TemplatePlaceholder />
    </Template>
  </Grid>
);

export default DiagramTree;
