import { ButtonBase } from "@material-ui/core";
import { ButtonBaseProps } from "@material-ui/core/ButtonBase";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import { withStyles } from "@material-ui/styles";
import React from "react";

export const MenuNavbarButton = withStyles({
  root: {
    height: "100%",
  },
})((props: ButtonBaseProps) => <ButtonBase aria-haspopup="true" {...props} />);

const MenuNavbar = withStyles({
  paper: {
    borderRadius: 0,
    boxShadow: "inset 0 3px 4px rgba(0,0,0,.2)",
    backgroundColor: "#184F67",
    color: "white",
    width: 236,
  },
})((props: MenuProps) => (
  <Menu
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    elevation={1}
    getContentAnchorEl={null}
    marginThreshold={0}
    {...props}
  />
));

export default MenuNavbar;
