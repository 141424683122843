import { Box, Grid, Typography } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import React from "react";
import useDiagramInteractions from "../../hooks/useDiagramInteractions";
import { DiagramComponentFrameworkValues } from "../../hooks/useGetDiagram/types";
import { ButtonMenu } from "../Button";
import RACIButton from "../RACIButton";
import RolePanelItemWrapper from "../RolePanelItem/RolePanelItemWrapper";
import WindowAnchored from "../WindowAnchored";

const RoleBorder = styled("div")(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[100]}`,
  marginBottom: theme.spacing(1),
}));

const RoleText = styled("div")(({ theme }) => ({
  padding: theme.spacing(0.5),
  fontSize: 12,
}));

export type LegendAssignments = Array<{
  color: string;
  symbol: string;
  name: string;
}>;

export const RoleMatrixLegendContent: React.SFC<{
  assignments: DiagramComponentFrameworkValues;
}> = ({ assignments }) => {
  return (
    <Box p={2}>
      <Typography variant="h6" color="secondary">
        Legend
      </Typography>
      <Box pb={1}>
        <Typography variant="subtitle2">Role</Typography>
      </Box>
      <RoleBorder>
        <RolePanelItemWrapper connectedToOrglab={true}>
          <RoleText>Role attached from Orglab</RoleText>
        </RolePanelItemWrapper>
      </RoleBorder>
      <RoleBorder>
        <RolePanelItemWrapper roleHasName={true}>
          <RoleText>Role added in DecisionLab</RoleText>
        </RolePanelItemWrapper>
      </RoleBorder>
      <RoleBorder>
        <RolePanelItemWrapper>
          <RoleText>Role not assigned</RoleText>
        </RolePanelItemWrapper>
      </RoleBorder>
      <RoleBorder>
        <RolePanelItemWrapper hasError={true}>
          <RoleText>OrgLab role in error</RoleText>
        </RolePanelItemWrapper>
      </RoleBorder>
      <Box py={1}>
        <Typography variant="subtitle2">Framework</Typography>
      </Box>
      {assignments.map(({ color, symbol, name }, i) => (
        <Grid
          container={true}
          direction="row"
          justify="flex-start"
          alignItems="center"
          spacing={2}
          key={i}
        >
          <Grid item={true}>
            <RACIButton color={color}>{symbol}</RACIButton>
          </Grid>
          <Grid item={true}>{name}</Grid>
        </Grid>
      ))}
    </Box>
  );
};

export const RoleMatrixLegendWindow: React.SFC<{
  assignments: DiagramComponentFrameworkValues;
}> = ({ assignments }) => {
  const {
    showLegend,
    actions: { setHideLegend },
  } = useDiagramInteractions();
  return (
    <>
      {showLegend && (
        <WindowAnchored
          hideOverlay={true}
          anchorId={"roleMatrixLegend"}
          onClickAway={() => {
            setHideLegend();
          }}
          windowSizeStyle={{
            width: 250,
            height: "auto",
          }}
        >
          <RoleMatrixLegendContent assignments={assignments} />
        </WindowAnchored>
      )}
    </>
  );
};

export const RoleMatrixLegendMenu: React.SFC = ({ children }) => {
  const {
    actions: { setShowLegend },
  } = useDiagramInteractions();
  return (
    <ButtonMenu
      id="roleMatrixLegend"
      onClick={() => {
        setShowLegend();
      }}
    />
  );
};
