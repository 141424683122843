import axios from "axios";
import fileDownload from "js-file-download";
import { airbrake } from "../../lib/airbrakeLogging";
import { pdClient } from "../../lib/apiClient";
import { PPTFile } from "../../types/PPTExport";

const http = axios.create({
  baseURL: process.env.REACT_APP_PPTX_URL,
  responseType: "json",
  timeout: 5000,
});

// POST pptxRequest.json -> /createRaciMatrixPayload -> intermediate.json
// POST intermediate.json -> /generate -> {"jobId":"5a95d40e8ee9c51cc0efb9cb"}
// GET // /getJobStatus/5a95d40e8ee9c51cc0efb9cb -> until {"status": ["completed"],"additionalInfo": ""}
// GET /download/5a95d40e8ee9c51cc0efb9cb -> Download the file

const URL_BUILD_INTERMEDIATE_JSON = "/createRaciMatrixPayload";
const URL_GENERATE = "/generate";
const URL_STATUS = "/getJobStatus/"; // /getJobStatus/(jobId)
const URL_DOWNLOAD = "/download/"; // /Download/(jobId)

const getHeaders = () => ({ Authorization: `Bearer ${localStorage['dlToken']}` });

const generatePptxDiagram = (requestBody: any) => {
  return http.post(URL_GENERATE, requestBody, { headers: getHeaders() });
};

export function getRoles(orglabRoleIds: string, structureId: string) {
  return pdClient.getRole(orglabRoleIds, structureId);
}

// We have to poll for a `status` field in the response body to tell whether
// the powerpoint file is ready.
const checkPptxGenerationStatus = (jobId: string) => {
  let tries = 0;
  const maxTries = 100;
  const waitDuration = 2500;
  const poll = (myResolve: () => void, myReject: () => void) => {
    tries++;
    return http.get(`${URL_STATUS}${jobId}`, {
      headers: getHeaders()
    }).then(response => {
      if (response.data.status[0] === "completed") {
        return myResolve();
      }
      if (response.data.status[0] === "error" || tries > maxTries) {
        return myReject();
      } else {
        setTimeout(poll, waitDuration, myResolve, myReject);
      }
    });
  };
  return new Promise((res, rej) => {
    poll(res, rej);
  });
};

const initiatePptxDownload = (url: string, name: string) => {
  return http.get(url, {
    headers: getHeaders(),
    responseType: "blob"
  }).then(response => {
    fileDownload(response.data, name);
  });
};

export const diagramToPptx = (
  filename: string,
  payload: PPTFile
): Promise<any> => {
  let jobId = "";

  return http
    .post(URL_BUILD_INTERMEDIATE_JSON, payload, { headers: getHeaders() })
    .then(response => generatePptxDiagram(response.data))
    .then(response => {
      jobId = response.data.jobId;
      return checkPptxGenerationStatus(jobId).then(() => {
        const downloadUrl = `${URL_DOWNLOAD}${jobId}`;
        return initiatePptxDownload(downloadUrl, `${filename}.pptx`);
      });
    })
    .catch(err => {
      airbrake.notify({
        error: "Export to powerpoint error",
        params: { error: err },
      });
      return Promise.reject();
    });
};
