import { createStyles, Theme, withStyles, WithStyles } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import { Field, FieldArray, Formik } from "formik";
import React, { useState } from "react";
import { connect } from "react-redux";
import { SortableContainer } from "react-sortable-hoc";
import { compose } from "recompose";
import useCreateFramework from "../../hooks/useCreateFramework";
import useUpdateFramework from "../../hooks/useUpdateFramework";
import generateUUID from "../../lib/generateUUID";
import { State } from "../../store/types";
import { Framework, FrameworkAssignment } from "../../types/Framework";
import {
  ButtonAddRow,
  ButtonCancel,
  ButtonCreate,
  ButtonUpdate,
} from "../Button";
import FormEditFrameworkAssignment from "../FormEditFrameworkAssignment";
import FormTextField from "../FormTextField";
import validationSchema from "./validationSchema";

const SortableList = SortableContainer<{}>((props: any) => {
  return <div>{props.children}</div>;
});

const styles = (theme: Theme) =>
  createStyles({
    activeAssignment: {
      boxShadow: theme.shadows[1],
      zIndex: 10000,
    },
  });

interface OwnProps {
  onSubmit: () => void;
  onCancel: () => void;
  initialValues: Framework;
  isEdit?: boolean;
  clientId: string;
  login: string;
}

type Props = State & OwnProps & WithStyles<typeof styles>;

const getDefaultAssignment = (): FrameworkAssignment => {
  return {
    color: "",
    description: "",
    exclusive: false,
    name: "",
    symbol: "",
    id: "",
  };
};

const defaultAssignment = getDefaultAssignment();

export const FormEditFramework: React.SFC<Props> = (props) => {
  const defaultProps = {
    initialValues: {
      assignables: [defaultAssignment],
      custom: true,
      name: "",
      id: "",
    },
  };

  const {
    onSubmit,
    initialValues,
    onCancel,
    isEdit = false,
    classes,
    clientId,
    login,
  } = props;

  const [active, setActive] = useState(0);

  const [updateFramework] = useUpdateFramework();
  const [createFramework] = useCreateFramework();

  const handleToggleChange = (newActive: number) => () => {
    if (active === newActive) {
      setActive(0);
    } else {
      setActive(newActive);
    }
  };

  const handleSubmit = (values: Framework, actions: any) => {
    if (!isEdit) {
      values.assignables.forEach((assignable) => {
        assignable.id = generateUUID();
      });
    }

    actions.setSubmitting(true);
    isEdit
      ? updateFramework(values.id, values.assignables, clientId).then(() => {
          onCancel();
          actions.setSubmitting(false);
        })
      : createFramework(values).then(() => {
          onSubmit();
          actions.setSubmitting(false);
        });
  };

  return (
    <Formik
      initialValues={initialValues ? initialValues : defaultProps.initialValues}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
      validationSchema={validationSchema}
      validateOnBlur={false}
    >
      {({ values, errors, isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <div style={{ padding: `0 24px 8px` }}>
            {isEdit ? null : (
              <div style={{ textAlign: "right" }}>
                <Typography variant="caption">
                  {6 - values.assignables.length}/6 assignments available
                </Typography>
              </div>
            )}
            <Field
              component={FormTextField}
              name="name"
              label={"Framework Name"}
              charMax={isEdit ? null : 15}
              fullWidth={true}
              disabled={isEdit}
            />
          </div>
          <FieldArray name="assignables">
            {(arrayHelpers) => (
              <div>
                <SortableList
                  axis="y"
                  lockAxis="y"
                  onSortEnd={({ oldIndex, newIndex }) => {
                    arrayHelpers.move(oldIndex, newIndex);
                  }}
                  useDragHandle={true}
                  helperClass={classes.activeAssignment}
                >
                  {values.assignables.map((assignment, index) => (
                    <FormEditFrameworkAssignment
                      key={index}
                      index={index}
                      errors={errors}
                      open={active === index}
                      onToggleClick={handleToggleChange(index)}
                      remove={arrayHelpers.remove}
                      isEdit={isEdit}
                    />
                  ))}
                </SortableList>
                {isEdit
                  ? null
                  : values.assignables.length < 6 && (
                      <div style={{ textAlign: "center" }}>
                        <ButtonAddRow
                          onClick={() => {
                            const assignment = getDefaultAssignment();
                            arrayHelpers.push(assignment);
                            handleToggleChange(values.assignables.length)();
                          }}
                        />
                      </div>
                    )}
                <DialogActions style={{ margin: `16px 24px` }}>
                  <ButtonCancel
                    disabled={isSubmitting}
                    type="button"
                    onClick={onCancel}
                    color="secondary"
                  />
                  {isEdit ? (
                    <ButtonUpdate disabled={isSubmitting} type="submit" />
                  ) : (
                    <ButtonCreate disabled={isSubmitting} type="submit" />
                  )}
                </DialogActions>
              </div>
            )}
          </FieldArray>
        </form>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: State) => ({
  clientId: state.login.clientId,
  login: state.login.username,
});

const enhance = compose<any, any>(
  connect(mapStateToProps),
  withStyles(styles)
);

export default enhance(FormEditFramework);
