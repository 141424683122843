import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch } from "react-router";
import useRouter from "use-react-router";
import useDiagramSubscription from "../../hooks/useDiagramSubscription";
import useGetDiagram from "../../hooks/useGetDiagram";
import LoadingInline from "../LoadingInline";
import PageInsights from "../PageInsights";
import RoleMatrix from "../RoleMatrix";

const PageDiagram: React.SFC = (props) => {
  const { match } = useRouter<{
    id: string;
  }>();
  const dispatch = useDispatch();
  const { diagram, loading } = useGetDiagram(match.params.id);
  useDiagramSubscription(match.params.id);

  if (loading) {
    return <LoadingInline />;
  }

  if (!diagram) {
    return null;
  }

  return (
    <>
      <Route
        path={process.env.PUBLIC_URL + "/rolematrix/:id"}
        exact={true}
        render={() => <RoleMatrix diagram={diagram} />}
      />
      <Route
        path={process.env.PUBLIC_URL + "/rolematrix/:id/insights"}
        render={() => <PageInsights diagram={diagram} />}
      />
    </>
  );
};

export default PageDiagram;
