import { ApolloProvider } from '@apollo/react-hooks';
import { MuiThemeProvider as ThemeProvider } from '@material-ui/core/styles';
import React from 'react';
import ErrorBoundary from 'react-error-boundary';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import i18n from '../../config/i18n';
import theme from '../../config/theme';
import graphqlClient from '../../graphql/client';
import { airbrake } from '../../lib/airbrakeLogging';
import { checkUrlforJwtToken } from '../../lib/authToken';
import { persistor, store } from '../../store';
import AppLayout from '../AppLayout';
import ConfirmationDialogContainer from '../ConfirmationDialogContainer';
import Notifications from '../Notifications';
import PageError from '../PageError';
import Routes from '../Routes';
import WelcomeDialog from '../WelcomeDialog';
import { CSP } from './CSP';
import RootStyles from './RootStyles';

const errorHandler = (error: Error, componentStack: string) => {
  airbrake.notify({
    error: 'Front End Error',
    params: { error, componentStack },
  });
};

checkUrlforJwtToken();

const App = () => (
  <ApolloProvider client={graphqlClient}>
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <RootStyles>
          <ErrorBoundary
            onError={errorHandler}
            FallbackComponent={() => <PageError />}
          >
            <Provider store={store}>
              <PersistGate loading="loading..." persistor={persistor}>
                <React.Fragment>
                  <CSP />
                  <ConfirmationDialogContainer />
                  <Notifications />
                  <Router>
                    <AppLayout>
                      <WelcomeDialog />
                      <>
                        <Routes />
                      </>
                    </AppLayout>
                  </Router>
                </React.Fragment>
              </PersistGate>
            </Provider>
          </ErrorBoundary>
        </RootStyles>
      </ThemeProvider>
    </I18nextProvider>
  </ApolloProvider>
);

export default App;
