import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import {
  createStyles,
  MuiThemeProvider,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { warningTheme } from "../../config/theme";

const styles = (theme: Theme) =>
  createStyles({
    popperOuterStyle: {
      "&::before": {
        borderColor: "transparent transparent white",
        borderStyle: "solid",
        borderWidth: "8px",
        bottom: "100%",
        content: "' '",
        left: "50%",
        marginLeft: "-5px",
        position: "absolute",
      },
      marginTop: 8,
      zIndex: 1500,
    },
    root: {
      padding: theme.spacing(1),
    },
    sizeSmall: {
      minHeight: 28,
      minWidth: 62,
      padding: "5px 6px",
    },
  });

export interface ConfirmationTooltipProps extends WithStyles<typeof styles> {
  title?: string;
  description?: string;
  noButtonText?: string;
  yesButtonText?: string;
  children: any;
  onYes: () => void;
  onNo?: () => void;
  onClickAway?: boolean;
  customTheme?: any;
  icon?: any;
}

class ConfirmationTooltip extends React.Component<ConfirmationTooltipProps> {
  public static defaultProps = {
    customTheme: warningTheme,
    description: "Are you sure you wish to continue?",
    icon: <div>i</div>,
    noButtonText: "No",
    onClickAway: true,
    title: "Confirmation",
    yesButtonText: "Yes",
  };
  public state = {
    anchorEl: null,
  };

  public handleClickOn = (event: any) => {
    const { currentTarget } = event;
    this.setState({
      anchorEl: currentTarget,
    });
  };

  public handleClickOff = () => {
    this.setState({ anchorEl: null });
  };

  public handleClickYes = () => {
    this.props.onYes();

    this.setState({ anchorEl: null });
  };

  public render() {
    const { classes, customTheme } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    const id = open ? "no-transition-popper" : "";

    return (
      <MuiThemeProvider theme={customTheme}>
        <div>
          <div onClick={this.handleClickOn} aria-describedby={id}>
            {this.props.children}
          </div>
          <Popper
            className={classes.popperOuterStyle}
            open={open}
            id={id}
            anchorEl={anchorEl}
          >
            <ClickAwayListener onClickAway={this.handleClickOff}>
              <Paper className={classes.root}>
                <Typography
                  variant="h6"
                  color="secondary"
                  style={{ fontSize: "1.1rem" }}
                >
                  <div style={{ marginBottom: 4, display: "flex" }}>
                    {this.props.icon}
                    <div style={{ marginTop: 2 }}>{this.props.title}</div>
                  </div>
                </Typography>
                <Typography variant="body1">
                  {this.props.description}
                </Typography>
                <div style={{ margin: "12px 0 0 auto", textAlign: "right" }}>
                  <Button
                    onClick={
                      this.props.onNo ? this.props.onNo : this.handleClickOff
                    }
                    style={{ marginRight: 8 }}
                    color="secondary"
                    size="small"
                    className={classes.sizeSmall}
                  >
                    {this.props.noButtonText}
                  </Button>
                  <Button
                    onClick={this.props.onYes && this.handleClickYes}
                    variant="contained"
                    color="secondary"
                    size="small"
                    className={classes.sizeSmall}
                  >
                    {this.props.yesButtonText}
                  </Button>
                </div>
              </Paper>
            </ClickAwayListener>
          </Popper>
        </div>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(ConfirmationTooltip);
