import { get } from "lodash";
import { useSelector } from "react-redux";
import useRouter from "use-react-router";
import checkForMultipleAssignments from "../../lib/processNudges/rules/checkForMultipleAssignments";
import getUserSettings from "../../store/selectors/getUserSettings";
import { State } from "../../store/types";
import { UserSetting } from "../../store/userSettings/types";
import useGetDiagram from "../useGetDiagram";
import { useGlobalToggleDiagramPath } from "../useToggleDiagramPath";

export default function useGetNudges() {
  const { match } = useRouter<{
    id: string;
  }>();
  const diagramId = match.params.id;
  const { diagram } = useGetDiagram(diagramId);
  const { currentPath } = useGlobalToggleDiagramPath();
  const { userSettings } = useSelector<
    State,
    {
      userSettings?: UserSetting;
    }
  >(state => {
    return {
      userSettings: getUserSettings(state),
    };
  });

  const nudgeSettings =
    userSettings &&
    userSettings.nudgeMultipleAssignments &&
    userSettings.nudgeMultipleAssignments[diagramId];

  if (diagram) {
    const accountabilityMethodology =
      diagram && diagram.accountabilityMethodology;
    const assignables =
      accountabilityMethodology.accountabilityMethodologyValues || [];
    const nudges = assignables
      .filter(({ exclusive }) => exclusive)
      .map(({ id, symbol, name }) =>
        checkForMultipleAssignments(
          diagram.roles,
          currentPath,
          id,
          symbol,
          name || "",
          nudgeSettings && nudgeSettings[id] ? nudgeSettings[id][1] : 1,
          !accountabilityMethodology.custom && id === "raci-a" ? false : true
          // true
        )
      );

    const validNudges = nudges.filter(({ id }) =>
      get(userSettings, `nudgeMultipleAssignments.${diagramId}.${id}.0`, true)
    );
    const totalNudges = validNudges.reduce((a, b) => a + b.positions.length, 0);
    const nudgesOff = validNudges.length <= 0;
    return {
      nudges,
      validNudges,
      totalNudges,
      nudgesOff,
    };
  }

  return {
    nudges: [],
    validNudges: [],
    totalNudges: 0,
    nudgesOff: true,
  };
}
