import React from "react";

const fillColor = "#00b8d4";

const ShapeDragHandle = ({ ...props }) => (
  <svg width="12px" height="45px" viewBox="0 0 12 45" {...props}>
    <g
      id="color-story"
      transform="translate(-52.000000, -411.000000)"
      fill="currentColor"
    >
      <g id="swim-lanes" transform="translate(20.000000, 375.000000)">
        <g id="Row-HOVER" transform="translate(0.000000, 36.000000)">
          <g
            id="drag-handle"
            transform="translate(38.000000, 22.500000) rotate(90.000000) translate(-38.000000, -22.500000) translate(16.000000, 17.000000)"
          >
            <g id="pattern">
              <rect
                id="Rectangle-5"
                x="13"
                y="7"
                width="4"
                height="4"
                rx="50"
                ry="50"
                style={{ fill: fillColor }}
              />
              <rect
                id="Rectangle-5"
                x="20"
                y="7"
                width="4"
                height="4"
                rx="50"
                ry="50"
                style={{ fill: fillColor }}
              />
              <rect
                id="Rectangle-5"
                x="27"
                y="7"
                width="4"
                height="4"
                rx="50"
                ry="50"
                style={{ fill: fillColor }}
              />
              <rect
                id="Rectangle-5"
                x="13"
                y="0"
                width="4"
                height="4"
                rx="50"
                ry="50"
                style={{ fill: fillColor }}
              />
              <rect
                id="Rectangle-5"
                x="20"
                y="0"
                width="4"
                height="4"
                rx="50"
                ry="50"
                style={{ fill: fillColor }}
              />
              <rect
                id="Rectangle-5"
                x="27"
                y="0"
                width="4"
                height="4"
                rx="50"
                ry="50"
                style={{ fill: fillColor }}
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

ShapeDragHandle.defaultProps = {};

export default ShapeDragHandle;
