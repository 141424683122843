import { useSelector } from "react-redux";
import { isMobileDevice } from "../../lib/componentProps";
import { getUserIsMcK } from "../../store/selectors";
import { State } from "../../store/types";

export interface UserStatusState {
  isMobile: boolean;
  isMcMuffin: boolean;
}

export default function useUserStatus(): UserStatusState {
  const userState = useSelector<State, UserStatusState>(state => ({
    isMobile: isMobileDevice(),
    isMcMuffin: getUserIsMcK(state),
  }));

  return userState;
}
