import { Box, CircularProgress, Grid } from "@material-ui/core";
import React from "react";

export default function LoadingInline() {
  return (
    <Grid container={true} direction="row" justify="center" alignItems="center">
      <Box p={1}>
        <CircularProgress color="secondary" />
      </Box>
    </Grid>
  );
}
