import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";
import React from "react";

export interface CreateIconButtonOuterProps {
  text?: string;
  icon?: JSX.Element;
  buttonProps?: IconButtonProps;
  displayName: string;
}

const createIconButton = ({
  icon,
  buttonProps,
  displayName,
}: CreateIconButtonOuterProps) => {
  const CustomButton: React.SFC<IconButtonProps & { text?: string }> = ({
    classes,
    children,
    ...props
  }) => {
    return (
      <IconButton color="secondary" {...buttonProps} {...props}>
        {icon}
      </IconButton>
    );
  };
  CustomButton.displayName = displayName;
  return CustomButton;
};

export default createIconButton;
