const sortText = (a: string, b: string) => {
  a = a.toLowerCase();
  b = b.toLowerCase();
  return a < b ? -1 : 1;
};

const sortDate = (a: string, b: string) => {
  const aDate = new Date(a);
  const bDate = new Date(b);
  return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
};

export { sortText, sortDate };
