import { Sorting, SortingState } from "@devexpress/dx-react-grid";
import React from "react";

interface SortingStateContainerProps {
  localStorageKey: string;
}

interface SortingStateContainerState {
  sorting: Sorting[];
}

class SortingStateContainer extends React.Component<
  SortingStateContainerProps,
  SortingStateContainerState
> {
  constructor(props: SortingStateContainerProps) {
    super(props);

    const sorting = localStorage.getItem(props.localStorageKey);

    this.state = {
      sorting: sorting
        ? JSON.parse(sorting)
        : [{ columnName: "name", direction: "asc" }],
    };
  }

  public changeSorting = (sorting: SortingStateContainerState["sorting"]) => {
    const { localStorageKey } = this.props;
    this.setState({ sorting });
    localStorage.setItem(localStorageKey, JSON.stringify(sorting));
  };

  public render() {
    const { sorting } = this.state;

    return (
      <SortingState onSortingChange={this.changeSorting} sorting={sorting} />
    );
  }
}

export default SortingStateContainer;
