import React, { SFC } from "react";
import { connect } from "react-redux";
import { State } from "../../store/types";
import ActiveUser from "./ActiveUser";

export interface ActiveUserConnectedStateProps {
  userName?: string;
  clientName?: string;
}

const ActiveConnectedUser: SFC<ActiveUserConnectedStateProps> = props => (
  <ActiveUser {...props} />
);

const mapStateToProps = (state: State): ActiveUserConnectedStateProps => ({
  userName: state.login.username,
  clientName: state.login.client,
});

export default connect(mapStateToProps)(ActiveConnectedUser);
