import { DiagramComponentOperation } from "../../hooks/useGetDiagram/types";

export default function getPathOperations(
  path: string[],
  operations: DiagramComponentOperation[]
) {
  const pathWithOperations: DiagramComponentOperation[] = [];

  path.forEach(operationId => {
    const operation = operations.find(_ => _.id === operationId);
    if (operation) {
      pathWithOperations.push(operation);
    }
  });

  return pathWithOperations;
}
