import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import React from "react";

interface Article {
  link: string;
  title: string;
  desc: string;
  role: string;
}

const KnowledgeList: React.SFC<{
  articles: Article[];
}> = ({ articles }) => (
  <List>
    {articles.map((article, index) => (
      <ListItem
        key={index}
        divider={index !== articles.length - 1}
        button={true}
        target="_blank"
        component="a"
        href={article.link}
        role={article.role}
      >
        <ListItemText
          primary={
            <Box pb={1}>
              <Typography style={{ fontWeight: 700 }}>
                {article.title}
              </Typography>
            </Box>
          }
          secondary={
            <Box pb={1}>
              <Typography>{article.desc.substring(0, 140) + "..."}</Typography>
            </Box>
          }
        />
      </ListItem>
    ))}
  </List>
);

export default KnowledgeList;
