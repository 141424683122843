import createSvgIcon from "@material-ui/icons/utils/createSvgIcon";
import React from "react";

export default createSvgIcon(
  <g fill="currentColor" fillRule="evenodd" transform="translate(1,3)">
    <path
      d="M7.45454545,0 C6.65454545,1.77833276e-15 6,0.901001112 6,2 L6,6 L7.96818034,6 L7.96818034,2 L16.1818182,2 L20.1666667,2 L20.1666667,16 L7.96818034,16 L7.96818034,12 L6,12 L6,16 C6,17.1190211 6.65454545,18 7.45454545,18 L20.5454545,18 C21.3454545,18 22,17.1190211 22,16 L22,2 C22,0.890989989 21.3454545,0 20.5454545,0 L7.45454545,0 Z M10,13 L14,9 L10,5 L10,8 L0,8 L0,10 L10,10 L10,13 L10,13 Z"
      id="input-path-1"
    />
  </g>,
  "Input"
);
