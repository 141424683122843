import { useMutation } from "@apollo/react-hooks";
import { ExecutionResult } from "graphql";
import { CopyDiagram, CopyDiagramVariables } from "../../generated/CopyDiagram";
import { COPY_DIAGRAM } from "../../graphql/mutation/copyDiagram";
import GET_DIAGRAMS_LIST from "../../graphql/query/getDiagramsList";

export default function useCopyDiagram(): {
  copyDiagram: (id: string) => Promise<ExecutionResult<CopyDiagram>>;
  submitting: boolean;
} {
  const [copyDiagram, { loading }] = useMutation<
    CopyDiagram,
    CopyDiagramVariables
  >(COPY_DIAGRAM);

  return {
    copyDiagram: id =>
      copyDiagram({
        variables: {
          id,
        },
        fetchPolicy: "no-cache",
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: GET_DIAGRAMS_LIST,
          },
        ],
      }),
    submitting: loading,
  };
}
