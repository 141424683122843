import { Box, lighten, styled, Typography } from "@material-ui/core";
import React, { ReactNode, useState } from "react";
import {
  DiagramComponentFrameworkValues,
  DiagramComponentState,
} from "../../../hooks/useGetDiagram/types";
import { InsightsInvolvedRole } from "../../../store/insights/type";
import LightTooltip from "../../LightTooltip";
import ChartOperationVertical from "../ChartOperationVertical";
import useGetLinkages from "./useGetLinkages";

interface Props {
  diagram: DiagramComponentState;
  involvedRoles: InsightsInvolvedRole[];
  groupBy: (involvedRole: InsightsInvolvedRole) => number | string | undefined;
  formatHeader?: (value: string, index: number) => string;
  filterAccountabilityValues?: DiagramComponentFrameworkValues;
}

function TooltipData({
  children,
  color,
}: {
  children: ReactNode;
  color?: string;
}) {
  return (
    <div style={{ display: "flex" }}>
      <div style={{ padding: 2 }}>
        <BaseBubble
          style={{
            backgroundColor: color,
          }}
        />
      </div>
      <div>
        <strong>{name}</strong>
        <Box pb={1}>{children}</Box>
      </div>
    </div>
  );
}

export default function Linkages({
  diagram,
  involvedRoles,
  groupBy,
  formatHeader,
  filterAccountabilityValues,
}: Props) {
  const { headers, operationAxis } = useGetLinkages(
    diagram,
    involvedRoles,
    groupBy,
    filterAccountabilityValues
  );
  const [hoverAccValue, setHoverAccValue] = useState<string | undefined>(
    undefined
  );

  return (
    <Container>
      <ColumnSticky>
        {headers.map((label, i) => (
          <Cell key={i} style={{ justifyContent: "left" }}>
            {formatHeader ? formatHeader(label, i) : label}
            {"\u00A0"}
          </Cell>
        ))}
      </ColumnSticky>
      {operationAxis.map(
        ({ operation, values, tooltipData }, operationAxisIndex) => {
          return (
            <LightTooltip
              title={
                <div style={{ width: 260 }}>
                  <h4>{operation.name}</h4>
                  {tooltipData.map(({ name, headers, id, color }) => {
                    return hoverAccValue === id || !Boolean(hoverAccValue) ? (
                      <TooltipData color={color}>
                        <strong>{name}</strong>
                        <Box pb={1}>
                          {headers.map(({ title, group }, i) => (
                            <div>
                              {group.map(role => {
                                return (
                                  <div>
                                    {" "}
                                    {formatHeader
                                      ? formatHeader(title, i)
                                      : title}{" "}
                                    {role.name || "-"}
                                    {role.orglabID &&
                                      role.employeeName &&
                                      ` (${role.employeeName})`}
                                  </div>
                                );
                              })}
                            </div>
                          ))}
                        </Box>
                      </TooltipData>
                    ) : null;
                  })}
                </div>
              }
              placement="right-start"
            >
              <Column>
                {values.map(frameworkValues => {
                  return (
                    <CellGrid className="foo">
                      <BubbleWrapper>
                        {frameworkValues.map(({ roles, color, id }, i) => {
                          const count = roles.length;
                          const size = 12 + 6 * count;
                          return count ? (
                            <BubbleContainer className="bc">
                              <Bubble
                                style={{
                                  backgroundColor: color,
                                  width: size > 40 ? 40 : size,
                                  height: size > 40 ? 40 : size,
                                }}
                                onMouseOver={() => setHoverAccValue(id)}
                                onMouseOut={() => setHoverAccValue(undefined)}
                              />
                            </BubbleContainer>
                          ) : null;
                        })}
                      </BubbleWrapper>
                    </CellGrid>
                  );
                })}
                <CellOperation>
                  <CellOperationLine />
                  <Typography variant="caption">
                    {operationAxisIndex + 1}
                  </Typography>
                  <ChartOperationVertical operation={operation} />
                </CellOperation>
              </Column>
            </LightTooltip>
          );
        }
      )}
      <Column style={{ flex: 1 }}>
        {headers.map((_, i) => <CellGrid key={i} style={{ width: "100%" }} />)}
        <CellOperation style={{ width: "100%" }} />
      </Column>
    </Container>
  );
}

const Column = styled("div")(({ theme }) => ({
  "&:hover .foo": {
    backgroundColor: lighten(theme.palette.grey[50], 0.5),
  },
  "&:hover .bc": {
    width: 16,
  },
}));

const ColumnSticky = styled("div")({
  position: "sticky",
  left: 0,
  background: "white",
  zIndex: 1,
});

const Cell = styled("div")({
  width: 120,
  height: 50,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: 11,
});

const CellGrid = styled(Cell)(({ theme }) => ({
  backgroundImage: `linear-gradient(0deg, transparent 49%, ${
    theme.palette.grey[100]
  } 50%, transparent 51%)`,
}));

const CellOperation = styled("div")(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.grey[100]}`,
  width: 120,
  height: 100,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

const CellOperationLine = styled("div")(({ theme }) => ({
  width: 1,
  height: 10,
  background: theme.palette.grey[100],
}));

const Container = styled("div")({
  display: "flex",
  overflow: "auto",
  paddingBottom: 20,
  width: "100%",
});

const BaseBubble = styled("div")({
  borderRadius: "50%",
  border: "1px solid white",
  width: 10,
  height: 10,
});

const Bubble = styled(BaseBubble)({
  position: "absolute",
  transform: "translate(-50%, -50%)",
  top: "50%",
  left: "50%",
});

const BubbleContainer = styled("div")({
  width: 10,
  height: 4,
  position: "relative",
  transition: "width .3s",
});

const BubbleWrapper = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
