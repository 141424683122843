import generateUUID from "../../lib/generateUUID";
import {
  NotificationsActionCreatorHide,
  NotificationsActionCreatorRemoveAll,
  NotificationsActionCreatorShow,
  NotificationsActionTypes,
} from "./types";

export const notifyShow: NotificationsActionCreatorShow = (
  message,
  level = "info",
  actionButton = undefined,
  actionButtonText = ""
) => ({
  payload: {
    level,
    message,
    uid: generateUUID(),
    actionButton,
    actionButtonText,
  },
  type: NotificationsActionTypes.NOTIFY_SHOW,
});

export const notifyError = (
  message: string,
  actionButton?: () => void,
  actionButtonText?: string
) => notifyShow(message, "error", actionButton, actionButtonText);

export const notifyWarning = (
  message: string,
  actionButton?: () => void,
  actionButtonText?: string
) => notifyShow(message, "warning", actionButton, actionButtonText);

export const notifyInfo = (message: string) => notifyShow(message, "info");

export const notifyHide: NotificationsActionCreatorHide = uid => ({
  payload: { uid },
  type: NotificationsActionTypes.NOTIFY_HIDE,
});

export const notifyRemoveAll: NotificationsActionCreatorRemoveAll = () => ({
  payload: null,
  type: NotificationsActionTypes.NOTIFY_REMOVE_ALL,
});
