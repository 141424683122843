import {
  IconButton,
  InputAdornment,
  InputLabel,
  makeStyles,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import classNames from "classnames";
import { Field, FieldArray, useField } from "formik";
import React, { useState } from "react";
import arrayHasEmptyValues from "../../lib/arrayHasEmptyValues";
import ActionButton from "../ActionButton";
import FormTextField from "../FormTextField";
import { IconAdd, IconDelete } from "../Icons";

const Container = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const AddButton = styled(IconButton)({
  marginRight: "auto",
  marginLeft: "auto",
  display: "flex",
});

const useStyles = makeStyles({
  lastField: {
    marginBottom: 5,
  },
  hideDelete: {
    visibility: "hidden",
    paddingLeft: 10,
  },
  showDelete: {
    visibility: "visible",
  },
});

const FieldAnalytic: React.SFC<{
  name: string;
  label: string;
  values: Array<string | null>;
  color: string;
  Icon: React.ComponentType<SvgIconProps>;
}> = ({ name, values, label, Icon, color }) => {
  const [field, meta, helpers] = useField({
    name: "activeAnalyticField",
  });
  const classes = useStyles();

  return (
    <FieldArray
      name={name}
      render={arrayHelpers => (
        <div>
          <InputLabel>{label}</InputLabel>
          {values.map((value, index) => {
            return (
              <Container key={index}>
                <Field
                  name={`${name}.${index}`}
                  onFocus={() => helpers.setValue(`${name}.${index}`)}
                  component={FormTextField}
                  label=" "
                  fullWidth={true}
                  multiline={true}
                  rowsMax="8"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon style={{ color, fontSize: "1em" }} />
                      </InputAdornment>
                    ),
                  }}
                  className={classNames({
                    [classes.lastField]: value && value.length === index + 1,
                  })}
                />

                <div
                  className={classNames(classes.hideDelete, {
                    [classes.showDelete]:
                      field.value === `${name}.${index}` && values.length > 1,
                  })}
                >
                  <ActionButton
                    type="submit"
                    onClick={() => arrayHelpers.remove(index)}
                  >
                    <IconDelete />
                  </ActionButton>
                </div>
              </Container>
            );
          })}
          <div
            style={{
              visibility: arrayHasEmptyValues(values) ? "hidden" : "visible",
            }}
          >
            <AddButton onClick={() => arrayHelpers.push("")}>
              <IconAdd />
            </AddButton>
          </div>
        </div>
      )}
    />
  );
};

export default FieldAnalytic;
