import {
  Badge,
  Button,
  ButtonBase,
  createStyles,
  Grid,
  Theme,
  Typography,
} from "@material-ui/core";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React, { useState } from "react";
import { compose } from "recompose";
import { Nudge, NudgeTypes } from "../../types/Nudges";

const styles = (theme: Theme) =>
  createStyles({
    nudge: {
      border: `1px solid ${theme.palette.grey[100]}`,
      padding: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      margin: theme.spacing(2),
      position: "relative",
    },
    nudgeMultiple: {
      "&:after": {
        content: "' '",
        display: "block",
        position: "absolute",
        top: "100%",
        left: 4,
        right: 4,
        height: 4,
        borderColor: theme.palette.grey[100],
        borderStyle: "solid",
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderBottomWidth: 1,
        borderTopWidth: 0,
        borderBottomLeftRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
      },
    },
    nudgePosition: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      marginTop: theme.spacing(0.5),
      display: "block",
    },
    nudgePositionMultiple: {
      border: `1px solid ${theme.palette.grey[100]}`,
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      borderRadius: theme.shape.borderRadius,
    },
    nudgePositionColumn: {
      color: theme.palette.grey[300],
    },
    nudgeMessage: {
      fontWeight: "bold",
      fontSize: 12,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    viewBadge: {
      right: theme.spacing(1.8),
      top: -1,
    },
    viewButton: {
      paddingLeft: theme.spacing(4),
      marginTop: theme.spacing(0.5),
    },
  });

interface DiagramNudgesOuterProps {
  nudge: Nudge;
  onNudgeHighlight: (props: { column?: number; rows?: number[] }) => void;
}

type DiagramNudgesInnerProps = WithStyles<typeof styles>;

type DiagramNudgesProps = DiagramNudgesInnerProps & DiagramNudgesOuterProps;

const DiagramNudges: React.SFC<DiagramNudgesProps> = props => {
  const { nudge, classes, onNudgeHighlight } = props;
  const { positions, message } = nudge;
  const [showAll, setShowAll] = useState<boolean>(false);
  const multipleNudges = positions.length > 1;

  return (
    <div
      className={classNames(classes.nudge, {
        [classes.nudgeMultiple]: multipleNudges && !showAll,
      })}
    >
      <div className={classes.nudgeMessage}>{message}</div>
      {positions.map((position, i) => {
        switch (position.type) {
          case NudgeTypes.MULTIPLE_ASSIGNMENTS:
            if (showAll || (!showAll && i === 0)) {
              return (
                <ButtonBase
                  key={i}
                  component="div"
                  onClick={() => {
                    onNudgeHighlight({
                      column: position.column,
                      rows: position.rows,
                    });
                  }}
                  className={classNames(classes.nudgePosition, {
                    [classes.nudgePositionMultiple]: multipleNudges,
                  })}
                >
                  <Typography key={i} component="div" variant="body2">
                    <div style={{ wordBreak: "break-all" }}>
                      {position.processName}{" "}
                    </div>
                    <span className={classes.nudgePositionColumn}>
                      Column {position.column}
                    </span>
                  </Typography>
                </ButtonBase>
              );
            }
          default:
            return null;
        }
      })}
      <Grid container={true} justify="center">
        {multipleNudges && (
          <Button
            size="small"
            color="secondary"
            onClick={() => setShowAll(!showAll)}
            className={classes.viewButton}
          >
            <Badge
              classes={{
                badge: classes.viewBadge,
              }}
              color="secondary"
              badgeContent={positions.length}
            >
              {" "}
            </Badge>
            {showAll ? "View Less" : "View All"}
          </Button>
        )}
      </Grid>
    </div>
  );
};

const enhance = compose<DiagramNudgesProps, DiagramNudgesOuterProps>(
  withStyles(styles)
);

export default enhance(DiagramNudges);
