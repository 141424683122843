import i18n from "../../config/i18n";

export default function displayTime([value, unit]: string[]) {
  const displayUnit = i18n.t(unit || "", {
    ns: "timeConversion",
  });
  if (value && displayUnit) {
    return [value, displayUnit].join(" ");
  }
  return undefined;
}
