import gql from "graphql-tag";

export const UPDATE_ROLE_ATTACH = gql`
  mutation RoleAttach($id: ID!, $name: String, $orglabID: String) {
    RoleAttach(id: $id, name: $name, orglabID: $orglabID) {
      id
      name
      orglabID
    }
  }
`;

export const UPDATE_ROLE_DETACH = gql`
  mutation RoleDetach($id: ID!, $name: String) {
    RoleDetach(id: $id, name: $name) {
      id
      name
      orglabID
      capacity
    }
  }
`;
export const UPDATE_ROLE_NAME = gql`
  mutation UpdateRoleName($id: ID!, $name: String) {
    EditRoleName(id: $id, name: $name) {
      id
      name
    }
  }
`;
