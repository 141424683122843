import {
  Box,
  DialogActions,
  FormHelperText,
  InputAdornment,
  LinearProgress,
  Typography,
  TextField,
  withStyles,
  Theme,
} from "@material-ui/core";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { Fragment } from "react";
import handleNumberInput, {
  handlePasteNumberInput,
} from "../../lib/handleNumberInput";
import { ButtonCancel, ButtonSubmit } from "../Button";
import FormTextField from "../FormTextField";
import { RolesForCapacity } from "./useGetRolesForCapacity";

const TotalTextField = withStyles((theme: Theme) => {
  return {
    root: {
      "& .Mui-disabled": {
        color: theme.palette.secondary.light,
      },
      "& label.Mui-disabled": {
        color: theme.palette.secondary.light,
      },
      "& fieldset": {
        borderColor: theme.palette.secondary.light + " !important",
      },
    },
  };
})(TextField);

interface Props {
  onClose: () => void;
  onSubmit: (values: { roles: RolesForCapacity[] }) => void;
  roles: RolesForCapacity[];
}

var total = 0;

interface Calc {
  roles: RolesForCapacity[];
}

const calculateTotal = ({ roles }: Calc) => {
  total = 0;
  roles.forEach((role) => {
    total += role.capacity ? parseInt(role.capacity.toString()) : 0;
  });
};

export default function FormEditRoleCapacity({
  onClose,
  roles,
  onSubmit,
}: Props) {
  return (
    <Formik
      initialValues={{
        roles,
      }}
      onSubmit={(values) => onSubmit(values)}
    >
      {({ values, isSubmitting }) => {
        calculateTotal(values);
        return (
          <Form translate="yes" style={{ width: 400 }}>
            <Box pb={1} style={{ top: -12, position: "relative" }}>
              <Typography variant="body2">
                What percentage of this role's time will be spent on this
                decision-making process?
              </Typography>
            </Box>
            {values.roles.map(({ name, capacity }, i) => (
              <Fragment key={i}>
                <Box
                  mb={2}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{ flex: 1, maxWidth: 200 }}
                  >
                    {name}
                  </Typography>
                  <div style={{ width: 100, paddingLeft: 20 }}>
                    <Field
                      label="Capacity"
                      variant="outlined"
                      margin="dense"
                      component={FormTextField}
                      name={`roles.${i}.capacity`}
                      onKeyPress={handleNumberInput}
                      onPaste={handlePasteNumberInput}
                      inputProps={{ maxLength: 3 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </Box>
              </Fragment>
            ))}
            <Box
              mb={2}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <div style={{ width: 100, paddingLeft: 20 }}>
                <TotalTextField
                  label="Total"
                  variant="outlined"
                  margin="dense"
                  disabled
                  value={total}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }}
                />
              </div>
            </Box>
            <FormHelperText error={true}>
              <ErrorMessage name="roles" />
            </FormHelperText>
            <DialogActions style={{ padding: `0 0 8px` }}>
              <ButtonCancel
                disabled={isSubmitting}
                type="button"
                onClick={onClose}
              />
              <ButtonSubmit
                loading={isSubmitting}
                disabled={isSubmitting}
                type="submit"
              />
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
}
