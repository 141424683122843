import { makeStyles, Slider } from "@material-ui/core";
import { SliderProps } from "@material-ui/core/Slider";
import { FieldProps } from "formik";
import React from "react";

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.secondary.main,
  },
}));

interface Props
  extends FieldProps,
    Omit<
      SliderProps,
      "form" | "name" | "onChange" | "value" | "defaultChecked"
    > {}

const FormSliderField: React.SFC<Props> = ({
  field,
  children,
  form: { isSubmitting, setFieldValue },
  disabled = false,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Slider
      {...props}
      disabled={isSubmitting || disabled}
      name={field.name}
      value={field.value}
      classes={{
        root: classes.root,
      }}
      onChange={(event, value) => {
        setFieldValue(field.name, value);
      }}
    />
  );
};

export default FormSliderField;
