import { SvgIconProps } from "@material-ui/core/SvgIcon";
import React from "react";

const ShapeChevron: React.SFC<SvgIconProps> = ({
  stroke,
  fill,
  strokeWidth,
  ...props
}) => (
  <svg viewBox="0 0 800 400" {...props}>
    <path
      d="m4.49623,4.4912l97.7594,194.02639l-98.25564,196.48242l694.73686,0l97.26316,-196.48242l-97.26316,-194.51759l-694.24062,0.49121z"
      strokeWidth={strokeWidth}
      stroke={stroke}
      fill={fill}
      className="shapeFill"
    />
  </svg>
);

ShapeChevron.defaultProps = {
  stroke: "black",
  fill: "transparent",
  strokeWidth: 30,
};

export default ShapeChevron;
