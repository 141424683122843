import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import links from "./links";

export default new ApolloClient({
  link: links,
  cache: new InMemoryCache({
    dataIdFromObject: o => o.id,
  }),
});
