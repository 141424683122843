import gql from "graphql-tag";

export const CREATE_FRAMEWORK = gql`
  mutation CreateAccountabilityMethodology(
    $values: CreateFramework
  ) {
    CreateNewFramework(values: $values) {
      id
      name
      createdBy {
        login
      }
      creationDate
      updatedBy {
        login
      }
      updatedDate
      custom
      accountabilityMethodologyValues {
        id
        name
        description
        order
        color
        exclusive
      }
    }
  }
`;
