import { Backdrop } from "@material-ui/core";
import { ClickAwayListenerProps } from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import { PopperPlacementType } from "@material-ui/core/Popper";
import {
  createStyles,
  styled,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import classNames from "classnames";
import _ from "lodash";
import PopperJs from "popper.js";
import React from "react";

const Container = styled("div")({
  position: "absolute",
  zIndex: 100,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

const styles = (theme: Theme) =>
  createStyles({
    arrow: {
      borderColor: "transparent white",
      borderStyle: "solid",
      borderWidth: "8px 8px 8px 0",
      position: "absolute",
    },
    popper: {
      '&[x-placement*="bottom"] $arrow': {
        top: -4,
        transform: "rotate(90deg)",
      },
      '&[x-placement*="top"] $arrow': {
        bottom: -4,
        transform: "rotate(-90deg)",
      },
      '&[x-placement*="left"] $arrow': {
        right: 0,
        transform: "rotate(180deg)",
      },
      '&[x-placement*="right"] $arrow': {
        left: 0,
      },
      left: 0,
      position: "absolute",
      top: 0,
      transform: "translate3d(-1000px, -1000px, 0px)",
      zIndex: 2,
    },
    popperHide: {
      display: "none",
    },
    window: {
      margin: 8,
      width: 300,
      height: 300,
    },
  });

interface RoleSearchWindowOuterProps {
  hideOverlay?: boolean;
  anchorId?: string;
  onClickAway?: ClickAwayListenerProps["onClickAway"];
  placement?: PopperPlacementType;
  windowSizeStyle?: React.CSSProperties;
}

interface RoleSearchWindowInnerProps
  extends WithStyles<typeof styles>,
    RoleSearchWindowOuterProps {}

type RoleSearchWindowProps = RoleSearchWindowInnerProps;

class RoleSearchWindow extends React.Component<RoleSearchWindowProps> {
  private popper: PopperJs | null;
  private targetElement: HTMLDivElement | null;

  constructor(props: RoleSearchWindowProps) {
    super(props);
    this.popper = null;
    this.targetElement = null;
  }

  public componentDidMount() {
    const { anchorId } = this.props;
    anchorId && this.handlePopper(anchorId);
  }

  public componentDidUpdate(prevProps: RoleSearchWindowProps) {
    const { anchorId } = this.props;
    // Typical usage (don't forget to compare props):

    if (anchorId && anchorId !== prevProps.anchorId) {
      this.handlePopper(anchorId);
    }
  }

  public handlePopper = (anchorId: string) => {
    const { placement } = this.props;
    const referenceElement = document.getElementById(anchorId);
    if (this.popper) {
      this.popper.destroy();
    }
    if (referenceElement && this.targetElement) {
      this.popper = new PopperJs(referenceElement, this.targetElement, {
        placement: placement || "left",
        modifiers: {
          preventOverflow: {
            boundariesElement: "window",
          },
        },
      });
    }
  };

  public handlePaperRef = (el: HTMLDivElement) => {
    this.targetElement = el;
  };

  public handleClickAway = (event: React.MouseEvent<any>) => {
    const { onClickAway } = this.props;
    onClickAway && onClickAway(event);
  };

  public render() {
    const {
      classes,
      children,
      windowSizeStyle,
      anchorId,
      hideOverlay,
    } = this.props;

    return (
      <Container
        style={{
          transform: anchorId ? undefined : "translate(-1000em, -1000em)",
        }}
      >
        <Backdrop
          open={anchorId ? true : false}
          invisible={hideOverlay}
          onClick={this.handleClickAway}
          style={{ width: "100%", height: "120vh" }}
        />
        <div
          ref={this.handlePaperRef}
          className={classNames("popper", classes.popper, {
            [classes.popperHide]: anchorId ? false : true,
          })}
        >
          <Paper className={classes.window} style={windowSizeStyle}>
            {children}
          </Paper>
          <div
            className={classNames("popper__arrow", classes.arrow)}
            x-arrow=""
          />
        </div>
      </Container>
    );
  }
}

export default withStyles(styles)(RoleSearchWindow);
