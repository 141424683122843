import { Box, styled, Typography } from "@material-ui/core";
import React from "react";
import { ChartBar } from "../ChartBar";

interface Props {
  totalDays: number;
  totalCycle: number;
  totalCyclePercent: number;
  totalWait: number;
  totalWaitPercent: number;
}

export default function TimeHeader({
  totalDays,
  totalCycle,
  totalCyclePercent,
  totalWait,
  totalWaitPercent,
}: Props) {
  return (
    <>
      <GanttTitle>Total Process Time: {totalDays} days</GanttTitle>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="caption">
          Time spent on activities:{" "}
          <strong>
            {totalCycle} ({totalCyclePercent}%)
          </strong>
        </Typography>
        <Typography variant="caption" style={{ textAlign: "right" }}>
          Time spent on waiting:{" "}
          <strong>
            {totalWait} ({totalWaitPercent}%)
          </strong>
        </Typography>
      </div>
      <Box mb={1}>
        <div style={{ display: "flex" }}>
          <ChartBar
            height={8}
            width={`${totalCyclePercent}%`}
            backgroundColor={"#FA9F82"}
          />
          <ChartBar
            height={8}
            width={`${totalWaitPercent}%`}
            backgroundColor={"#8C59CB"}
          />
        </div>
      </Box>
    </>
  );
}

const GanttTitle = styled("div")(({ theme }) => ({
  background: theme.palette.grey[50],
  padding: 4,
  textAlign: "center",
  fontSize: 12,
  fontWeight: "bold",
}));
