import React from "react";
import { DiagramComponentFrameworkValues } from "../../../hooks/useGetDiagram/types";
import { InvolvedDiagrams } from "../../../hooks/useGetInsightDiagrams";
import { InsightsState } from "../../../store/insights/type";
import ExpansionPanel from "../../ExpansionPanel";
import Linkages from "../Linkages";
import LinkagesSelection from "../LinkagesSelection";

interface Props {
  involvedRoles: InsightsState["involvedRoles"];
  involvedDiagrams: InvolvedDiagrams;
  accountabilityValues: DiagramComponentFrameworkValues;
}

export default function TabLinkages({
  involvedDiagrams,
  involvedRoles,
  accountabilityValues,
}: Props) {
  const orglabInvolvedRoles = involvedRoles.filter(({ orglabID }) =>
    Boolean(orglabID)
  );
  return (
    <>
      <ExpansionPanel
        defaultExpanded={true}
        header="Decision-Making Layers Chart"
        info="Blends organizational layer information from OrgLab with process information for the selected process, to highlight how decision-rights are clustered by organizational levels. Size of the bubble indicates concentration of decision-rights; bigger the bubble, more the concentration."
        content={
          <LinkagesSelection
            filters={["layer"]}
            involvedDiagrams={involvedDiagrams}
            accountabilityValues={accountabilityValues}
          >
            {({ diagram, filteredAccountabilityValues }) => (
              <Linkages
                groupBy={role => role.layer}
                involvedRoles={orglabInvolvedRoles}
                diagram={diagram}
                filterAccountabilityValues={filteredAccountabilityValues}
                formatHeader={v =>
                  v === "undefined"
                    ? "Non-Selected"
                    : v === "0"
                      ? "N"
                      : `N-${v}`
                }
              />
            )}
          </LinkagesSelection>
        }
      />
      <ExpansionPanel
        defaultExpanded={false}
        header="Proximity and Symmetry of Decision-Makers"
        info="Blends Orglab structure data with process information to highlight the efficiency of handoffs across the process activities. Levers of analysis include Function, Location and Business Unit. Size of the bubble indicates concentration of decision-rights; bigger the bubble, more the concentration."
        content={
          <LinkagesSelection
            filters={["location", "businessUnit", "function"]}
            involvedDiagrams={involvedDiagrams}
            accountabilityValues={accountabilityValues}
          >
            {({ diagram, filteredAccountabilityValues, filterProp }) => (
              <Linkages
                groupBy={role => role[filterProp || "location"]}
                involvedRoles={orglabInvolvedRoles}
                diagram={diagram}
                filterAccountabilityValues={filteredAccountabilityValues}
                formatHeader={v => (v === "undefined" ? "Non-Selected" : v)}
              />
            )}
          </LinkagesSelection>
        }
      />
    </>
  );
}
