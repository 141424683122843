import createSvgIcon from "@material-ui/icons/utils/createSvgIcon";
import React from "react";

export default createSvgIcon(
  <g fill="currentColor" fillRule="evenodd" transform="translate(1,3)">
    <path
      d="M18,0 L22,0 L22,18 L18,18 L18,0 Z M12,4 L16,4 L16,18 L12,18 L12,4 Z M6,7 L10,7 L10,18 L6,18 L6,7 Z M0,11 L4,11 L4,18 L0,18 L0,11 Z"
      id="data-path-1"
    />
  </g>,
  "Data"
);
