import gql from "graphql-tag";

export const ADD_DIAGRAM_ASSIGNMENT = gql`
  mutation AddDiagramAssignment(
    $roleID: ID!
    $operationID: ID!
    $assignmentID: ID!
  ) {
    AddDiagramAssignment(
      roleID: $roleID
      operationID: $operationID
      assignmentID: $assignmentID
    ) {
      id
      accountabilityMethodologyValues {
        id
      }
    }
  }
`;

export const REMOVE_DIAGRAM_ASSIGNMENT = gql`
  mutation RemoveDiagramAssignment(
    $roleID: ID!
    $operationID: ID!
    $assignmentID: ID!
  ) {
    RemoveDiagramAssignment(
      roleID: $roleID
      operationID: $operationID
      assignmentID: $assignmentID
    ) {
      id
    }
  }
`;
