import { useLazyQuery } from "@apollo/react-hooks";
import idx from "idx";
import { max } from "lodash";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  GetInsightDensityMap,
  GetInsightDensityMapVariables,
} from "../../generated/GetInsightDensityMap";
import { GET_INSIGHT_DENSITY_MAP } from "../../graphql/query/getInsightDensityMap";
import isNotNull from "../../lib/isNotNull";
import { InsightsInvolvedRole } from "../../store/insights/type";
import { State } from "../../store/types";
import { DiagramComponentFrameworkValues } from "../useGetDiagram/types";
import normalizeDensityMapData from "./util";

export type GetInsightDensityMapResult = InsightsInvolvedRole & {
  accountabilityCount: number[];
  splitByProcess: Array<{
    [key: string]: number;
  }>;
};

const DEFAULT_MAX_COUNT = 10;

export default function useGetInsightDensityMapData(
  accountabilityValues: DiagramComponentFrameworkValues
) {
  const [result, setResult] = useState<GetInsightDensityMapResult[]>([]);
  const { involvedRoles } = useSelector((state: State) => ({
    involvedRoles: state.insights.involvedRoles,
  }));

  const [_fetchData, { loading }] = useLazyQuery<
    GetInsightDensityMap,
    GetInsightDensityMapVariables
  >(GET_INSIGHT_DENSITY_MAP, {
    fetchPolicy: "no-cache",
    onCompleted: data => {
      const densityMapData = (idx(data, _ => _.DensityMap) || []).filter(
        isNotNull
      );
      const normalizedResult = normalizeDensityMapData({
        densityMapData,
        accountabilityValues,
        involvedRoles,
      });
      setResult(normalizedResult);
    },
  });

  const selectedRoleIds = involvedRoles.reduce<string[]>((acc, value) => {
    if (value.selected) {
      const roleIds = value.associatedRoles.map(_ => _.roleId);
      return [...acc, ...roleIds];
    }
    return acc;
  }, []);

  const maxCount =
    max(
      result.reduce<number[]>(
        (acc, curr) => [...acc, ...curr.accountabilityCount],
        []
      )
    ) || DEFAULT_MAX_COUNT;

  return {
    fetchData: () => {
      _fetchData({
        variables: { roles: selectedRoleIds },
      });
    },
    maxCount: maxCount >= DEFAULT_MAX_COUNT ? maxCount : DEFAULT_MAX_COUNT,
    data: result,
    loading,
  };
}
