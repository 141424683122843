import React from "react";
import { useSelector } from "react-redux";
import { State } from "../../store/types";

export default function AppVersion() {
  const version = useSelector<State, string>(
    ({ appVersion }) => appVersion.version
  );
  return (
    <div>
      Version: {process.env.REACT_APP_VERSION} / API Version: {version}
    </div>
  );
}
