import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DiagramComponentFrameworkValues } from "../../hooks/useGetDiagram/types";
import {
  diagramFilterReset,
  diagramFilterToggleOverlay,
  diagramFilterToggleRACI,
} from "../../store/diagramFilters/actions";
import { State } from "../../store/types";
import DiagramFilters from "./DiagramFilters";

interface Props {
  options: DiagramComponentFrameworkValues;
}

interface StateProps {
  selectedOverlays: any;
  selectedRACIValue: any;
}

const DiagramFiltersConnected: React.SFC<Props> = ({ options }) => {
  const dispatch = useDispatch();
  const { selectedOverlays, selectedRACIValue } = useSelector<
    State,
    StateProps
  >(state => ({
    selectedOverlays: state.diagramFilters.selectedOverlays,
    selectedRACIValue: state.diagramFilters.selectedRACIValue,
  }));

  // unmount
  useEffect(
    () => () => {
      dispatch(diagramFilterReset());
    },
    []
  );

  const handleToggleOverlay = (value: string) => () => {
    dispatch(diagramFilterToggleOverlay(value));
  };

  const handleToggleRACI = (value: string) => () => {
    dispatch(diagramFilterToggleRACI(value));
  };

  return (
    <DiagramFilters
      selectedOverlays={selectedOverlays}
      selectedRACIValue={selectedRACIValue}
      toggleValues={options.filter(_ => _.exclusive)}
      onToggleOverlay={handleToggleOverlay}
      onToggleRACI={handleToggleRACI}
    />
  );
};

export default DiagramFiltersConnected;
