import { default as MuiToolbar } from "@material-ui/core/Toolbar";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import DisplayStructurePath from "../DisplayStructurePath";
import MenuDiagramEdit from "../MenuDiagramEdit";
import MenuFrameworkEdit from "../MenuFrameworkEdit";

export const FormatActions = (props: any) => {
  return <MenuDiagramEdit diagram={props.row} />;
};

export const FormatFrameworkActions = (props: any) => {
  const { row } = props;
  const framework = {
    name: row.name,
    assignables: row.children,
    id: row.id,
    custom: row.custom,
  };
  return row.custom ? <MenuFrameworkEdit framework={framework} /> : null;
};

export const FormatLinks = (props: any) => (
  <Link
    to={process.env.PUBLIC_URL + `/rolematrix/${props.row.id}`}
    style={{ color: "inherit", textDecoration: "underline" }}
  >
    {props.value}
  </Link>
);

export const FormatDate = (props: any) => {
  const { t } = useTranslation("translations");
  return <>{t("formatDate", { date: props.value })}</>;
};

export const FormatAlert = (props: any) => null;

export const FormatToolbar = (props: any) => (
  <MuiToolbar {...props} disableGutters={true} />
);

export const FormatStructure = (props: any) => (
  <DisplayStructurePath structureId={props.row.structureId} />
);

export const FormatFrameworkUser = (props: any) => (
  <div>{props.row.updatedBy ? "test" : null}</div>
);
