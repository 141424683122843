export function setAuthToken(token: string, client?: string): void {
  if (token) {
    localStorage.setItem("dlToken", token);
  }
  if (client) {
    localStorage.setItem("client", client);
  }
}

export function getAuthToken(): string | undefined {
  return localStorage.getItem("dlToken") || "";
}

export function clearAuthToken(): void {
  localStorage.removeItem("dlToken");
  localStorage.removeItem("client");
}

export function parseJwtFromUrl() {
  const jwtMatches = window.location.hash.match(/jwt=([a-zA-Z0-9_.-]+)/);
  return Array.isArray(jwtMatches) && jwtMatches.length > 1
    ? jwtMatches[1]
    : undefined;
}

export function checkUrlforJwtToken() {
  const jwt = parseJwtFromUrl();

  if (jwt !== undefined) {
    window.location.hash = "";
    setAuthToken(jwt);
  }
}
