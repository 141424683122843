import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import React from "react";
import { insightDensityMapColors } from "../../../config/theme";
import { DiagramComponentFrameworkValues } from "../../../hooks/useGetDiagram/types";
import { GetInsightDensityMapResult } from "../../../hooks/useGetInsightDensityMapData";
import Truncate from "../../Truncate";

interface Props {
  accountabilityValues: DiagramComponentFrameworkValues;
  data: GetInsightDensityMapResult[];
  colorCountThreshold?: number[];
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
  },
  tableRoot: {
    width: "100%",
    tableLayout: "fixed",
    border: "none",
    borderCollapse: "collapse",
  },
  th: {
    padding: 8,
    border: "1px solid " + theme.palette.grey[100],
    "&:first-child": {
      textAlign: "left",
    },
  },
  td: {
    textAlign: "center",
    padding: 8,
    borderLeft: "1px solid " + theme.palette.grey[100],
    borderRight: "1px solid " + theme.palette.grey[100],
    borderBottom: "1px solid " + theme.palette.grey[100],
    "&:first-child": {
      textAlign: "left",
    },
  },
}));

export default function DecisionDensityMapTable({
  accountabilityValues,
  data,
  colorCountThreshold = [6, 3],
}: Props) {
  const classes = useStyles();
  const theme = useTheme<Theme>();

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table
          style={{ tableLayout: "fixed" }}
          stickyHeader={true}
          aria-label="sticky table"
        >
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 300 }}>Roles</TableCell>
              {accountabilityValues.map((col, i) => (
                <TableCell align="center" key={i}>
                  {col.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(({ name, employeeName, accountabilityCount }, i) => (
              <TableRow key={i}>
                <TableCell>
                  <Truncate maxWidth="100%">
                    {name || "-"} {employeeName && `(${employeeName})`}
                  </Truncate>
                </TableCell>
                {accountabilityCount.map((count, ai) => (
                  <TableCell
                    key={ai}
                    align="center"
                    style={{
                      background:
                        count > colorCountThreshold[1]
                          ? insightDensityMapColors.dark
                          : count > colorCountThreshold[0]
                            ? insightDensityMapColors.medium
                            : count > 0
                              ? insightDensityMapColors.light
                              : theme.palette.grey[200],
                      color: "white",
                      boxShadow:
                        "white 1px 1px 0 inset, white -1px -1px 0 inset",
                    }}
                  >
                    {count}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
