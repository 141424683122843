import Button, { ButtonProps } from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Fab from "@material-ui/core/Fab";
import { get } from "lodash";
import React from "react";

export interface CreateButtonOuterProps {
  text?: string;
  icon?: JSX.Element;
  buttonProps?: ButtonProps;
  displayName: string;
  fabProps?: any;
}

const createButton = ({
  text,
  icon: baseIcon,
  buttonProps,
  fabProps,
  displayName,
}: CreateButtonOuterProps) => {
  const CustomButton: React.SFC<
    ButtonProps & { text?: string; loading?: boolean; icon?: JSX.Element }
  > = ({ classes, children, loading, icon, ...props }) => {
    const showText = props.text || text;
    const renderIcon = icon || baseIcon || null;
    return (
      <div>
        {fabProps !== undefined ? (
          <Fab {...fabProps} {...props}>
            {renderIcon}
            {loading && (
              <CircularProgress
                size={14}
                style={{ marginRight: 8 }}
                color="inherit"
              />
            )}
            {showText && (
              <span style={{ marginLeft: renderIcon ? 8 : 0 }}>{showText}</span>
            )}
            {children}
          </Fab>
        ) : (
          <Button {...buttonProps} {...props}>
            {renderIcon}
            {loading && (
              <CircularProgress
                size={14}
                style={{ marginRight: 8 }}
                color="inherit"
              />
            )}
            {showText && (
              <span style={{ marginLeft: renderIcon ? 8 : 0 }}>{showText}</span>
            )}
            {children}
          </Button>
        )}
      </div>
    );
  };
  CustomButton.displayName = displayName;
  return CustomButton;
};

export default createButton;
