import { makeStyles, Typography } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { SortableElement } from "react-sortable-hoc";
import { NextOperationTypes } from "../../generated/globalTypes";
import useCreateOperation from "../../hooks/useCreateOperation";
import useDeleteOperation from "../../hooks/useDeleteOperation";
import useDiagramInteractions from "../../hooks/useDiagramInteractions";
import {
  DiagramComponentOperation,
  DiagramComponentState,
} from "../../hooks/useGetDiagram/types";
import { OperationsState } from "../../hooks/useToggleDiagramPath";
import { isMobileDevice } from "../../lib/componentProps";
import DiagramProcessTime from "../DiagramProcessTimes/DiagramProcessTime";
import DiagramRoleHeader from "../DiagramRoleHeader";
import ProcessElement from "../ProcessElement";
import ProcessElementEnd from "../ProcessElementEnd";
import ProcessElementStart from "../ProcessElementStart";
import { RoleMatrixLegendMenu } from "../RoleMatrixLegend";

interface Props {
  diagram: DiagramComponentState;
  currentPath: OperationsState[];
  activeOperationId?: string;
  onClickOperation: (id: string) => void;
  disableSorting?: boolean;
}

const SortableItem = SortableElement<any>(({ children, ...props }: any) => (
  <div {...props}>{children}</div>
));

const useStyles = makeStyles(theme => ({
  processElement: {
    padding: "10px 0 20px",
  },
  processEnd: {
    padding: "79px 50px 0 1px",
    verticalAlign: "top",
  },
  processStart: {
    padding: "79px 0 0 0",
    width: 260,
    verticalAlign: "top",
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "space-between",
  },
  processRow: {
    transform: "translateX(10px)",
  },
  processElementCell: {
    verticalAlign: "top",
    position: "relative",
    height: "200px",
  },
  roleHeader: {
    marginLeft: 40,
    marginTop: 106,
  },
  roleHeaderText: {
    fontSize: 12,
    fontWeight: 600,
    width: 256,
    display: "flex",
    alignItems: "center",
  },
  roleHeaderTextIsNotMobile: {
    borderLeft: "40px solid white",
    paddingLeft: 8,
    position: "fixed",
    zIndex: 10000,
    top: 234,
    left: 0,
    height: 32,
    display: "flex",
    alignItems: "center",
  },
  roleHeaderTextIsMobile: {
    position: "static",
  },
}));

const DiagramProcessElements: React.SFC<Props> = ({
  diagram,
  activeOperationId,
  onClickOperation,
  currentPath,
  disableSorting,
}) => {
  const classes = useStyles();
  const diagramId = (diagram && diagram.id) || "";
  const { addTask, addDecision } = useCreateOperation(diagramId);
  const [deleteOperation] = useDeleteOperation();
  const {
    sorting,
    actions: { setClickOperation, setActiveOperationId },
  } = useDiagramInteractions();

  return (
    <>
      <table cellSpacing="0" cellPadding="0">
        <tbody>
          <tr>
            {currentPath.map((operation, i) => {
              const nextOperation = currentPath[i + 1];

              if (operation && nextOperation) {
                return operation.type === "start" ? (
                  <td key={i} className={classNames(classes.processStart)}>
                    <ProcessElementStart />
                    <DiagramRoleHeader className={classes.roleHeader}>
                      <Typography
                        component="div"
                        className={classNames(classes.roleHeaderText, {
                          [classes.roleHeaderTextIsNotMobile]: !isMobileDevice(),
                          [classes.roleHeaderTextIsMobile]: isMobileDevice(),
                        })}
                      >
                        <div style={{ flex: 1 }}>
                          Role/Organization/Employee
                        </div>
                        <RoleMatrixLegendMenu />
                      </Typography>
                    </DiagramRoleHeader>
                  </td>
                ) : (
                  <td
                    key={i}
                    // className={classNames(classes.processElementCell)}
                    data-process-id={operation.id}
                    // style={{
                    //   zIndex: item.isSorting
                    //     ? 1001
                    //     : isActive
                    //       ? 1000
                    //       : processElements.length - i + 2
                    // }}
                  >
                    <SortableItem
                      index={i}
                      id={`scrollTo${operation.id}`}
                      data-process-sort-id={operation.id}
                      className={classes.processElement}
                      disabled={disableSorting}
                    >
                      <ProcessElement
                        decisionHasYes={Boolean(operation.nextOperationYes)}
                        decisionHasNo={Boolean(operation.nextOperationNo)}
                        decisionIsYes={
                          operation.nextPathType ===
                          NextOperationTypes.nextOperationYes
                        }
                        decisionIsNo={
                          operation.nextPathType ===
                          NextOperationTypes.nextOperationNo
                        }
                        operation={operation}
                        active={operation.id === activeOperationId}
                        isSorting={i === sorting.x}
                        countNumber={`${i}`}
                        processIndex={i}
                        onClick={() => {
                          onClickOperation(operation.id);
                        }}
                        onChangeDecision={() => operation.toggleDecisionState()}
                        onRemove={() => {
                          deleteOperation(
                            diagramId,
                            operation.id,
                            nextOperation.id
                          );
                        }}
                        onAddTask={() => {
                          addTask(
                            operation.id,
                            nextOperation.id,
                            operation.nextPathType,
                            id => setActiveOperationId(id)
                          );
                        }}
                        onAddDecision={() => {
                          addDecision(
                            operation.id,
                            nextOperation.id,
                            operation.nextPathType,
                            id => setActiveOperationId(id)
                          );
                        }}
                        onSetAnalyticsTab={() =>
                          setClickOperation(operation.id, 1)
                        }
                        operationCount={diagram.operation.length}
                      />
                    </SortableItem>
                    <DiagramProcessTime
                      isSorting={i === sorting.x}
                      cycleTime={operation.cycleTime}
                      waitTime={operation.waitTime}
                    />
                    <DiagramRoleHeader />
                  </td>
                );
              } else {
                return (
                  <td key={i} className={classNames(classes.processEnd)}>
                    <ProcessElementEnd />
                  </td>
                );
              }
            })}
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default DiagramProcessElements;
