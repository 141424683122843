import gql from "graphql-tag";

const DIAGRAM_SUBSCRIPTION = gql`
  subscription DiagramSubscription($id: ID!) {
    DiagramUpdated(id: $id) {
      id
      name
      structure {
        id
        orglabID
        name
      }
      operation {
        id
        name
        type
        cycleTime
        waitTime
        repeats
        dataElements
        inputs
        itSystems
        outputs
        painPoints
        subProcess
        nextOperation
        nextOperationYes
        nextOperationNo
        subProcessName
        comment
        roleNotes {
          noteId: id
          role {
            roleId: id
            name
          }
          operation {
            assignments {
              role {
                name
                id
              }
              accountabilityMethodologyValues(orderBy: order_asc) {
                id
                symbol
                order
              }
            }
          }
          text
        }
        processes {
          roles {
            id
            name
          }
          roleOrder
        }
        assignments {
          id
          role {
            id
          }
          operation {
            id
          }
          accountabilityMethodologyValues {
            id
            symbol
          }
        }
      }
      client {
        id
        name
        orglabID
      }
      accountabilityMethodology {
        id
        name
        custom
        accountabilityMethodologyValues(orderBy: order_asc) {
          id
          symbol
          color
          name
          exclusive
          order
        }
      }
      roleOrder
      roles {
        id
        name
        orglabID
        roleNotes {
          id
          text
          operation {
            id
            name
          }
        }
      }
    }
  }
`;

export default DIAGRAM_SUBSCRIPTION;
