import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { CheckBoxOutlined } from "@material-ui/icons";
import { FieldProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

interface OwnProps extends FieldProps {
  checkboxProps: CheckboxProps;
  label?: string;
}

const FormCheckboxField: React.SFC<OwnProps> = ({
  field,
  form: { isSubmitting },
  checkboxProps: { disabled = false },
  label,
  ...props
}) => {
  const { name } = field;
  const { t } = useTranslation("formFields");
  return (
    <FormControlLabel
      style={{ marginLeft: "-6px" }}
      control={
        <Checkbox
          disabled={isSubmitting || disabled}
          {...props}
          {...field}
          checked={field.value ? true : false}
          value={field.value ? true : false}
          style={{ width: 30, height: 30 }}
          checkedIcon={<CheckBoxOutlined />}
          color="default"
        />
      }
      label={label || t(name)}
    />
  );
};

export default FormCheckboxField;
