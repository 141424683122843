import gql from "graphql-tag";

const UPDATE_OPERATION_EDGES = gql`
  mutation UpdateOperationEdges(
    $fromID_A: ID!
    $toID_A: ID!
    $pathType_A: NextOperationTypes!
    $fromID_B: ID!
    $toID_B: ID!
    $pathType_B: NextOperationTypes!
    $fromID_C: ID!
    $toID_C: ID!
    $pathType_C: NextOperationTypes!
  ) {
    UpdateOperationEdgeA: UpdateOperationEdge(
      fromID: $fromID_A
      toID: $toID_A
      pathType: $pathType_A
    ) {
      id
      nextOperation
      nextOperationYes
      nextOperationNo
    }
    UpdateOperationEdgeB: UpdateOperationEdge(
      fromID: $fromID_B
      toID: $toID_B
      pathType: $pathType_B
    ) {
      id
      nextOperation
      nextOperationYes
      nextOperationNo
    }
    UpdateOperationEdgeC: UpdateOperationEdge(
      fromID: $fromID_C
      toID: $toID_C
      pathType: $pathType_C
    ) {
      id
      nextOperation
      nextOperationYes
      nextOperationNo
    }
  }
`;

export default UPDATE_OPERATION_EDGES;
