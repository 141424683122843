import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import React from "react";
import { SortableContainer } from "react-sortable-hoc";
import useDiagramInteractions from "../../hooks/useDiagramInteractions";
import useUpdateRoleSortOrder from "../../hooks/useUpdateRoleSortOrder";
import { sortableProps } from "../../lib/componentProps";

const SortableRoleList = SortableContainer<{
  className?: string;
  id?: string;
}>((props: any) => (
  <div className={props.className} id={props.id}>
    {props.children}
  </div>
));

const useStyles = makeStyles({
  root: {
    position: "relative",
    zIndex: 1,
    marginTop: 1,
  },
  scrollHelper: {
    zIndex: 10,
  },
});

const ScrollVertical: React.SFC = ({ children }) => {
  const classes = useStyles();
  const [updateSortOrder] = useUpdateRoleSortOrder();
  const {
    actions: { setSortingY },
  } = useDiagramInteractions();

  return (
    <SortableRoleList
      className={classNames(classes.root)}
      lockAxis="y"
      helperClass={classes.scrollHelper}
      useDragHandle={true}
      onSortStart={({ index }) => setSortingY(index)}
      onSortEnd={({ newIndex, oldIndex }) => {
        setSortingY(undefined);
        if (oldIndex !== newIndex) {
          updateSortOrder(newIndex, oldIndex);
        }
      }}
      {...sortableProps}
    >
      {children}
    </SortableRoleList>
  );
};

export default ScrollVertical;
