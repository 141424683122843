import createSvgIcon from "@material-ui/icons/utils/createSvgIcon";
import React from "react";

export default createSvgIcon(
  <g fill="currentColor" fillRule="evenodd" transform="translate(1,3)">
    <path
      d="M9.31053853,0.993626872 C9.48672297,0.69053489 9.7394826,0.439078974 10.0434816,0.264464255 C11.0012913,-0.285694307 12.223741,0.0447721979 12.7738996,1.00258185 L21.9675119,17.0083869 C22.1416901,17.3116259 22.2333141,17.6552346 22.2332441,18.0049373 C22.2330231,19.1095067 21.3374134,20.004758 20.2328439,20.004537 L1.73736357,20.000836 C1.38442505,20.0007653 1.03779043,19.9072995 0.732658606,19.7299293 C-0.222293245,19.174825 -0.546433761,17.9506827 0.00867050108,16.9957308 L9.31053853,0.993626872 Z M1.73776378,18.000836 L20.2332442,18.004537 L11.0396318,1.99873205 L1.73776378,18.000836 Z M11.9138756,13.1722303 L10.1897377,13.1722303 L10,7 L12.1118627,7 L11.9138756,13.1722303 Z M10,15.013012 C10,14.7222699 10.096288,14.4812636 10.2888669,14.2899859 C10.4814458,14.0987083 10.7442861,14.0030708 11.0773955,14.0030708 C11.410505,14.0030708 11.6733452,14.0987083 11.8659241,14.2899859 C12.058503,14.4812636 12.154791,14.7222699 12.154791,15.013012 C12.154791,15.2935526 12.0611054,15.5281831 11.8737313,15.7169104 C11.6863573,15.9056378 11.4209146,16 11.0773955,16 C10.7338764,16 10.4684338,15.9056378 10.2810597,15.7169104 C10.0936856,15.5281831 10,15.2935526 10,15.013012 Z"
      id="pain-point-path-1"
    />
  </g>,
  "PainPoint"
);
