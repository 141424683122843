export const initialState: DiagramInteractionsState = {
  highlightColumn: undefined,
  highlightRows: [],
  showLegend: false,
  openAssignment: false,
  openRaciMultiselect: false,
  openAPIndex: undefined,
  activeOperationId: undefined,
  activeRoleId: undefined,
  activeMultiple: [],
  sorting: {},
};

export type ActiveMultiple = Array<{
  operationId: string;
  roleId: string;
}>;

export interface DiagramInteractionsViewRole {
  id: string;
  name: string;
  orglabRoleId?: string;
}

export interface DiagramInteractionsSorting {
  x?: number;
  y?: number;
}

export interface DiagramInteractionsState {
  highlightColumn?: number;
  highlightRows: number[];
  showLegend: boolean;
  openAssignment: boolean;
  openRaciMultiselect: boolean;
  openAPIndex?: number;
  activeOperationId?: string;
  activeRoleId?: string;
  activeMultiple: ActiveMultiple;
  scrollToOperationId?: string;
  viewByRole?: DiagramInteractionsViewRole;
  capacityByRole?: DiagramInteractionsViewRole;
  sorting: DiagramInteractionsSorting;
}

export type DiagramInteractionsAction =
  | { type: "highlightNudges"; data: { column: number; rows: number[] } }
  | { type: "resetNudges" }
  | { type: "showLegend" }
  | { type: "hideLegend" }
  | { type: "openAp"; data: { index?: number } }
  | { type: "setActiveOperationId"; data: { id?: string; scroll?: boolean } }
  | { type: "setActiveRoleId"; data: { id?: string } }
  | { type: "setActiveMultiple"; data: ActiveMultiple }
  | { type: "setOpenAssignment"; data: boolean }
  | { type: "setOpenRaciMultiselect"; data: boolean }
  | { type: "startMultiAssign" }
  | { type: "showRoleInfo"; data: DiagramInteractionsViewRole }
  | { type: "editRoleCapacity"; data: DiagramInteractionsViewRole }
  | { type: "hideRoleInfo" }
  | { type: "hideRoleCapacity" }
  | { type: "setSorting"; data: DiagramInteractionsSorting }
  | { type: "resetInteractions" };

export default function reducer(
  state: DiagramInteractionsState,
  action: DiagramInteractionsAction
): DiagramInteractionsState {
  switch (action.type) {
    case "highlightNudges":
      return {
        ...state,
        highlightColumn: action.data.column,
        highlightRows: action.data.rows,
      };

    case "resetNudges":
      return {
        ...state,
        highlightColumn: undefined,
        highlightRows: [],
      };

    case "showLegend":
      return {
        ...state,
        showLegend: true,
      };
    case "hideLegend":
      return {
        ...state,
        showLegend: false,
      };
    case "openAp":
      return {
        ...state,
        openAPIndex: action.data.index,
      };
    case "setActiveOperationId":
      return {
        ...state,
        activeOperationId: action.data.id,
        scrollToOperationId: action.data.scroll ? action.data.id : undefined,
      };
    case "setActiveRoleId":
      return {
        ...state,
        activeRoleId: action.data.id,
      };
    case "setActiveMultiple":
      return {
        ...state,
        activeMultiple: action.data,
      };
    case "setOpenRaciMultiselect":
      return {
        ...state,
        openRaciMultiselect: action.data,
      };
    case "startMultiAssign":
      return {
        ...state,
        openRaciMultiselect: true,
        openAssignment: false,
        activeOperationId: undefined,
        activeRoleId: undefined,
      };
    case "setOpenAssignment":
      return {
        ...state,
        openAssignment: action.data,
      };
    case "showRoleInfo":
      return {
        ...state,
        viewByRole: action.data,
      };
    case "editRoleCapacity":
      return {
        ...state,
        capacityByRole: action.data,
      };
    case "hideRoleInfo":
      return {
        ...state,
        viewByRole: undefined,
      };
    case "hideRoleCapacity":
      return {
        ...state,
        capacityByRole: undefined,
      };
    case "setSorting":
      return {
        ...state,
        sorting: action.data,
      };
    case "resetInteractions":
      return initialState;
    default:
      return state;
    // throw new Error();
  }
}
