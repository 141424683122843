import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import useDiagramInteractions from "../../hooks/useDiagramInteractions";
import { getSelectedRACIFilter } from "../../store/selectors";
import { State } from "../../store/types";
import NudgeScroll from "./NudgeScroll";
import { DiagramAssignmentRACI } from "./DiagramAssignmentRACI";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "relative",
  },
  containerHighlight: {
    zIndex: 9998,
    "& .DiagramCell": {
      outlineWidth: 3,
      outlineColor: theme.palette.secondary.main,
      outlineOffset: -2,
    },
  },
  values: {
    margin: "0 5px",
    display: "inline-block",
  },
  valuesDisable: {
    opacity: 0.3,
  },
  hintBorder: {
    border: "1px dashed #ccc",
    borderRadius: "50%",
    padding: ".5em",
  },
  displayValues: {
    opacity: 1,
    transition: "opacity .3s",
  },
  displayValuesHide: {
    opacity: 0,
  },
}));

interface DiagramAssignmentRACIProps {
  onOpen: () => void;
  active: boolean;
  focused: boolean;
  options?: Array<{
    color?: string;
    symbol?: string;
    id: string;
  }>;
  values?: string[];
  hideValues: boolean;
  showAdd: boolean;
  width: string | number;
  roleId: string;
  processId: string;
  roleIndex: number;
  processIndex: number;
}

interface StateProps {
  selectedRaciValue: string | null;
}

const DiagramAssignmentRACIContainer: React.SFC<DiagramAssignmentRACIProps> = (
  props
) => {
  const {
    active,
    values = [],
    focused,
    showAdd,
    hideValues,
    width,
    options = [],
    onOpen,
    roleId,
    processId,
    roleIndex,
    processIndex,
  } = props;
  const id = `${roleId}${processId}`;
  const classes = useStyles();
  const { highlightColumn, highlightRows } = useDiagramInteractions();
  const { selectedRaciValue } = useSelector<State, StateProps>((state) => ({
    selectedRaciValue: getSelectedRACIFilter(state),
  }));

  return (
    <div
      className={classNames(classes.container, {
        [classes.containerHighlight]:
          processIndex === highlightColumn &&
          highlightRows.indexOf(roleIndex) > -1,
      })}
      style={{ width }}
      data-role-id={roleId}
      data-process-id={processId}
    >
      <NudgeScroll processIndex={processIndex} roleIndex={roleIndex} />
      <DiagramAssignmentRACI
        id={id}
        active={active}
        focused={focused}
        values={values}
        hideValues={hideValues}
        options={options}
        selectedRaciValue={selectedRaciValue}
        showAdd={showAdd}
        roleId={roleId}
        processId={processId}
      />
    </div>
  );
};

export default DiagramAssignmentRACIContainer;
