const AirbrakeClient = require("airbrake-js");
const Package = require("../../../package.json");

export const airbrake = new AirbrakeClient({
  host: process.env.PD_ERRBIT_URL || "https://oops.orglabsolutions.com",
  projectId: 1,
  projectKey: process.env.PD_ERRBIT_KEY || "f2489c3e25691732e31324c8dd5274b4",
  keysBlacklist: [
    "password", // exact match
    /secret/, // regexp match
    /client/,
    /login/,
  ],
});

airbrake.addFilter((notice: any) => {
  if (process.env.NODE_ENV !== "development") {
    // if production then log to errbit
    notice.context.environment = process.env.NODE_ENV;
    notice.context.version = Package.version;
    return notice;
  } else {
    return null;
  }
});
