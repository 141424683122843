import { cloneDeep } from "lodash";
import {
  RoleResponse,
  RoleResponseFunction,
  RoleResponseLocation,
  RoleResponseLookup,
} from "../../types/Roles";

const PATH_CONCAT = " > ";

type PathResource =
  | RoleResponseLocation
  | RoleResponseFunction
  | RoleResponseLookup;

const getPath = ({ part_of, name }: PathResource): string => {
  if (part_of) {
    return [getPath(part_of), name].join(PATH_CONCAT);
  }
  return name;
};

const updateRoleWithPaths = (role: RoleResponse): RoleResponse => {
  const newRole: RoleResponse = cloneDeep(role);
  if (newRole.location) {
    newRole.location.path = getPath(newRole.location);
  }
  if (newRole.function) {
    newRole.function.path = getPath(newRole.function);
  }
  if (newRole.lookups) {
    newRole.lookups = role.lookups.map(lookup => ({
      ...lookup,
      path: getPath(lookup),
    }));
  }
  return newRole;
};

export default updateRoleWithPaths;
