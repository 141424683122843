import idx from "idx";
import { GetDiagramFormValues_Process } from "../../generated/GetDiagramFormValues";
import { DiagramFormValues } from "../../types/DiagramForm";

export function normalizeDiagramFormDate(
  diagram: GetDiagramFormValues_Process | null | undefined
): DiagramFormValues {
  const structurePrimaryAxisOther = idx(
    diagram,
    _ => _.structurePrimaryAxisOther
  );
  const primaryOwningFunctionOther = idx(
    diagram,
    _ => _.primaryOwningFunctionOther
  );
  const processArchetypeOther = idx(diagram, _ => _.processArchetypeOther);
  const industryOther = idx(diagram, _ => _.industryOther);
  const industryOrglabID = idx(diagram, _ => _.industryOrglabID);
  const orgSize = idx(diagram, _ => _.organizationSize);

  return {
    diagramName: idx(diagram, _ => _.name) || "",
    decisionFrameworkId:
      idx(diagram, _ => _.accountabilityMethodology.id) || "",
    structureId: idx(diagram, _ => _.structure.orglabID) || "",
    structurePrimaryAxis:
      idx(diagram, _ => _.structurePrimaryAxis.id) ||
      (structurePrimaryAxisOther ? "__other" : ""),
    structurePrimaryAxisOther: structurePrimaryAxisOther || "",
    processArchetype:
      idx(diagram, _ => _.processArchetype.id) ||
      (processArchetypeOther ? "__other" : ""),
    processArchetypeOther: processArchetypeOther || "",
    industry:
      idx(diagram, _ => _.industry.id) || (industryOther ? "__other" : ""),
    industryOther: industryOther || "",
    industryOrglabID: industryOrglabID || "",
    geography: idx(diagram, _ => _.geography.id) || "",
    description: idx(diagram, _ => _.description) || "",
    // organizationSize: diagram.org
    primaryOwningFunction:
      idx(diagram, _ => _.primaryOwningFunction.id) ||
      (primaryOwningFunctionOther ? "__other" : ""),
    primaryOwningFunctionOther: primaryOwningFunctionOther || "",
    organizationSize: orgSize ? orgSize.toString() : "",
  };
}
