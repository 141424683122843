import gql from "graphql-tag";

const DELETE_ROLE = gql`
  mutation DeleteRole($processID: ID!, $roleID: ID!) {
    RemoveRole(processID: $processID, roleID: $roleID) {
      id
    }
  }
`;

export default DELETE_ROLE;
