import { Box, MenuItem, Select } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { get, head, xor } from "lodash";
import React, { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import useGetDiagram from "../../../hooks/useGetDiagram";
import {
  DiagramComponentFrameworkValues,
  DiagramComponentState,
} from "../../../hooks/useGetDiagram/types";
import { InvolvedDiagrams } from "../../../hooks/useGetInsightDiagrams";
import LoadingInline from "../../LoadingInline";
import AnalysisAxis from "../AnalysisAxis";
import DiagramSelection from "../DiagramSelection";

type FilterValues = "layer" | "location" | "businessUnit" | "function";

interface RenderProps {
  filterProp?: FilterValues;
  diagram: DiagramComponentState;
  filteredAccountabilityValues: DiagramComponentFrameworkValues;
}

interface Props {
  involvedDiagrams: InvolvedDiagrams;
  accountabilityValues: DiagramComponentFrameworkValues;
  filters?: FilterValues[];
  children: (props: RenderProps) => ReactNode;
}

export default function LinkagesSelection({
  involvedDiagrams,
  accountabilityValues,
  filters,
  children,
}: Props) {
  const [diagramId, setDiagramId] = useState(get(head(involvedDiagrams), "id"));
  const { diagram, loading } = useGetDiagram(diagramId || "");
  const { t } = useTranslation("translations");
  const [filterProp, setFilterProp] = useState<FilterValues | undefined>(
    head(filters)
  );
  const [selectedValues, setSelectedValue] = useState(
    accountabilityValues.map((_, i) => i)
  );
  const filteredAccountabilityValues = selectedValues.map(
    (index) => accountabilityValues[index]
  );

  return (
    <div style={{ width: "100%" }}>
      <Box mb={2} style={{ display: "flex" }}>
        <Box mr={4}>
          <DiagramSelection
            value={diagramId}
            onChange={setDiagramId}
            diagrams={involvedDiagrams}
          />
        </Box>
        {filters &&
          filters.length > 1 && (
            <Select
              value={filterProp}
              onChange={(event) => setFilterProp(event.target.value as "layer")}
              IconComponent={() => <ExpandMoreIcon />}
            >
              {filters.map((filter) => (
                <MenuItem value={filter}>{t(filter)}</MenuItem>
              ))}
            </Select>
          )}

        <AnalysisAxis
          accountabilityValues={accountabilityValues}
          selectedValue={selectedValues}
          setSelectedValue={(v) => {
            const newValues = xor(selectedValues, [v]);
            if (newValues.length > 0) {
              setSelectedValue(newValues);
            }
          }}
        />
      </Box>
      {loading ? (
        <LoadingInline />
      ) : (
        diagram && (
          <Box py={4}>
            {diagram &&
              children({ filterProp, diagram, filteredAccountabilityValues })}
          </Box>
        )
      )}
    </div>
  );
}
