import gql from "graphql-tag";

export const GET_DIAGRAM_FORM_VALUES = gql`
  query GetDiagramFormValues($diagramId: ID!) {
    Process(id: $diagramId) {
      id
      name
      description
      processArchetypeOther
      structurePrimaryAxisOther
      industryOther
      industryOrglabID
      geographyOther
      primaryOwningFunctionOther
      organizationSize
      structure {
        id
        orglabID
      }
      accountabilityMethodology {
        id
      }
      processArchetype {
        id
      }
      structurePrimaryAxis {
        id
      }
      industry {
        id
      }
      geography {
        id
      }
      primaryOwningFunction {
        id
      } 
    }
  }
`;
