import { useMutation } from "@apollo/react-hooks";
import { DeleteDiagramVariables } from "../../generated/DeleteDiagram";
import DELETE_DIAGRAM from "../../graphql/mutation/deleteDiagram";
import GET_DIAGRAMS_LIST from "../../graphql/query/getDiagramsList";

export default function useDeleteDiagram() {
  const [deleteDiagram] = useMutation<any, DeleteDiagramVariables>(
    DELETE_DIAGRAM
  );
  function _delete(diagramId: string) {
    return deleteDiagram({
      variables: {
        id: diagramId,
      },
      fetchPolicy: "no-cache",
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_DIAGRAMS_LIST,
        },
      ],
    });
  }
  return [_delete];
}
