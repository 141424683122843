import { useMutation } from "@apollo/react-hooks";
import {
  DelinkDiagramFromParent,
  DelinkDiagramFromParentVariables,
} from "../../generated/DelinkDiagramFromParent";
import DELINK_DIAGRAM_FROM_PARENT from "../../graphql/mutation/updateDelinkDiagram";
import GET_DIAGRAMS_LIST from "../../graphql/query/getDiagramsList";

export default function useDelinkDiagram() {
  const [delinkDiagram] = useMutation<
    DelinkDiagramFromParent,
    DelinkDiagramFromParentVariables
  >(DELINK_DIAGRAM_FROM_PARENT);

  function _delink(id: string, parentID: string) {
    delinkDiagram({
      variables: {
        id,
        parentID,
      },
      refetchQueries: [
        {
          query: GET_DIAGRAMS_LIST,
        },
      ],
    });
  }

  return [_delink];
}
