import { ClipboardEvent, KeyboardEvent } from "react";

export const handleNumberInput = (event: KeyboardEvent<HTMLDivElement>) => {
  const keyCode = event.keyCode || event.which;
  const keyValue = String.fromCharCode(keyCode);
  if (!/^\d+$/.test(keyValue)) { event.preventDefault(); }
};

export const handlePasteNumberInput = (
  event: ClipboardEvent<HTMLDivElement>
) => {
  const clipboardData = event.clipboardData.getData("text/plain");
  const regex = /^[0-9]+$/;
  if (!clipboardData.match(regex)) { event.preventDefault(); }
};

export default handleNumberInput;
