import { DataProxy } from "apollo-cache";
import { FetchResult } from "apollo-link";
import gql from "graphql-tag";
import idx from "idx";
import { GetDiagram } from "../../../generated/GetDiagram";
import { NextOperationTypes } from "../../../generated/globalTypes";
import GET_DIAGRAM from "../../query/getDiagram";

export const CREATE_OPERATION = gql`
  mutation CreateOperation(
    $id: ID!
    $diagramID: ID!
    $nextID: ID!
    $type: OperationTypes!
    $pathType: NextOperationTypes!
    $newID: ID!
  ) {
    AddDiagramOperation(
      id: $id
      diagramID: $diagramID
      nextID: $nextID
      type: $type
      pathType: $pathType
      newID: $newID
    ) {
      id
      name
      type
      cycleTime
      waitTime
      repeats
      dataElements
      inputs
      itSystems
      outputs
      painPoints
      subProcess
      nextOperation
      nextOperationYes
      nextOperationNo
      subProcessName
      comment
      roleNotes {
        noteId: id
        role {
          roleId: id
          name
        }
        operation {
          assignments {
            role {
              name
              id
            }
            accountabilityMethodologyValues(orderBy: order_asc) {
              id
              symbol
              order
            }
          }
        }
        text
      }
      processes {
        roles {
          id
          name
        }
        roleOrder
      }
      assignments {
        id
        role {
          id
        }
        operation {
          id
        }
        accountabilityMethodologyValues {
          id
          symbol
        }
      }
    }
  }
`;

export const handleCreateOperationUpdate = (
  diagramId: string,
  currentOperationId: string,
  pathType: NextOperationTypes
) => (store: DataProxy, result: FetchResult) => {
  const data = store.readQuery<GetDiagram>({
    query: GET_DIAGRAM,
    variables: {
      id: diagramId,
    },
  });

  let operations = idx(data, _ => _.Process[0].operation);

  if (operations && result.data) {
    const newOperation = result.data.AddDiagramOperation;
    operations.push(newOperation);
    operations = operations.map(operation => {
      if (operation && operation.id === currentOperationId) {
        operation[pathType] = idx(result, _ => _.data.AddDiagramOperation.id);
      }
      return operation;
    });
  }
  store.writeQuery({
    query: GET_DIAGRAM,
    data: { ...data, operations },
    variables: {
      id: diagramId,
    },
  });
};
