import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import { Field } from "formik";
import React from "react";
import { Link } from "react-router-dom";
import FormSelectField from "../FormSelectField";
import FormTextField from "../FormTextField";

interface BaseFieldsProps {
  isEdit?: boolean;
  disableNameField?: boolean;
  frameworks: Array<{
    id: string;
    name: string;
  }>;
}

class BaseFields extends React.Component<BaseFieldsProps> {
  public render() {
    const { frameworks, disableNameField, isEdit = false } = this.props;
    return (
      <>
        <Field
          component={FormTextField}
          label={"Diagram Name"}
          name="diagramName"
          fullWidth={true}
          charMax={60}
          margin="dense"
          disabled={disableNameField}
        />
        <Field
          component={FormSelectField}
          name="decisionFrameworkId"
          margin="dense"
          fullWidth={true}
          disabled={isEdit}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {frameworks.map(framework => (
            <MenuItem value={framework.id} key={framework.id}>
              {framework.name}
            </MenuItem>
          ))}
        </Field>
        <Grid
          container={true}
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <FormHelperText>
            <Link
              to={{
                pathname: process.env.PUBLIC_URL +"/frameworks",
                state: { createNewFramework: true },
              }}
            >
              Create New Framework
            </Link>
          </FormHelperText>
        </Grid>
      </>
    );
  }
}

export default BaseFields;
