import { useMutation } from "@apollo/react-hooks";
import { useDispatch } from "react-redux";
import {
  DeleteOperation,
  DeleteOperationVariables,
} from "../../generated/DeleteOperation";
import DELETE_OPEATION from "../../graphql/mutation/deleteOperation";
import GET_DIAGRAM from "../../graphql/query/getDiagram";
import { confirm } from "../../store/modal/actions";

export default function useDeleteOperation() {
  const dispatch = useDispatch();
  const [deleteOperation] = useMutation<
    DeleteOperation,
    DeleteOperationVariables
  >(DELETE_OPEATION);
  function _delete(diagramId: string, operationId: string, nextID: string) {
    dispatch(
      confirm({
        i18nKey: "deleteProcess",
        onConfirm: () =>
          deleteOperation({
            variables: {
              id: operationId,
              nextID,
            },
            awaitRefetchQueries: true,
            refetchQueries: [
              {
                query: GET_DIAGRAM,
                variables: {
                  id: diagramId,
                },
              },
            ],
          }),
      })
    );
  }
  return [_delete];
}
