import { useMutation } from "@apollo/react-hooks";
import { CreateRole, CreateRoleVariables } from "../../generated/CreateRole";
import {
  CREATE_ROLE,
  handleCreateRoleUpdate,
} from "../../graphql/mutation/createRole";
import GET_DIAGRAM from "../../graphql/query/getDiagram";
import generateUUID from "../../lib/generateUUID";

export default function useCreateRole() {
  const [createRole, { loading }] = useMutation<
    CreateRole,
    CreateRoleVariables
  >(CREATE_ROLE);

  function addRole(diagramID: string) {
    const newID = generateUUID();
    return createRole({
      variables: {
        processID: diagramID,
      },
      optimisticResponse: {
        CreateNewRole: {
          __typename: "Role",
          id: newID,
          name: null,
          orglabID: null,
          roleNotes: null,
        },
      },
      refetchQueries: [
        {
          query: GET_DIAGRAM,
          variables: {
            id: diagramID,
          },
        },
      ],
      update: handleCreateRoleUpdate(diagramID),
    });
  }

  return { addRole, loading };
}
