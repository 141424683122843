import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ButtonList, ButtonTree } from "../Button";

const DiagramViewMenu = ({ history, location }: RouteComponentProps<any>) => {
  const linkTo = (path: string) => () => history.push(path);
  return (
    <div>
      {location.pathname === (process.env.PUBLIC_URL + "/") ? (
        <ButtonList onClick={linkTo(process.env.PUBLIC_URL +"/list")} />
      ) : (
        <ButtonTree onClick={linkTo(process.env.PUBLIC_URL +"/")} />
      )}
    </div>
  );
};

export default withRouter(DiagramViewMenu);
