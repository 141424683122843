const isValueInList = (values: string[], val: string): boolean => {
  return values.indexOf(val) >= 0;
};

const addOrRemoveListValue = (values: string[], val: string): string[] => {
  if (isValueInList(values, val)) {
    return values.filter(v => val !== v);
  }
  return [...values, val];
};

export { addOrRemoveListValue, isValueInList };
