import { Reducer } from "redux";
import convertPhaseResponseToLookup from "../../lib/convertPhaseResponseToLookup";
import { DesignSessionAction, DesignSessionsState } from "./types";
import { DesignSessionsActionTypes } from "./types";

export const initialState: DesignSessionsState = {
  source: [],
  lookup: {},
};

const reducer: Reducer<DesignSessionsState, DesignSessionAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case DesignSessionsActionTypes.DESIGN_SESSIONS_LOAD: {
      const source = action.payload || state.source;
      return {
        source,
        lookup: convertPhaseResponseToLookup(source),
      };
    }

    case DesignSessionsActionTypes.DESIGN_SESSIONS_RESET:
      return {
        source: [],
        lookup: {},
      };

    default:
      return state;
  }
};

export default reducer;
