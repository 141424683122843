import {
  DiagramFilterActionCreatorReset,
  DiagramFilterActionCreatorToggleOverlay,
  DiagramFilterActionCreatorToggleRACI,
  DiagramFiltersActionTypes,
} from "./types";

export const diagramFilterToggleOverlay: DiagramFilterActionCreatorToggleOverlay = overlayValue => ({
  payload: overlayValue,
  type: DiagramFiltersActionTypes.DIAGRAM_FILTER_TOGGLE_OVERLAY,
});

export const diagramFilterToggleRACI: DiagramFilterActionCreatorToggleRACI = RACIValue => ({
  payload: RACIValue,
  type: DiagramFiltersActionTypes.DIAGRAM_FILTER_TOGGLE_RACI,
});

export const diagramFilterReset: DiagramFilterActionCreatorReset = () => ({
  payload: null,
  type: DiagramFiltersActionTypes.DIAGRAM_FILTER_RESET,
});
