import {
  createStyles,
  makeStyles,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
import React from "react";

const useStyles = makeStyles({
  root: {
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-box-pack": "center",
  },
});

interface TextOverflowProps {
  maxLines?: number;
  className?: string;
  style?: React.CSSProperties;
  classes?: ClassNameMap;
}

const TextOverflow: React.SFC<TextOverflowProps> = ({
  className,
  maxLines = 5,
  style,
  ...props
}) => {
  const classes = useStyles();
  return (
    <div
      className={classNames(classes.root, className)}
      style={{
        ...style,
        WebkitLineClamp: maxLines,
      }}
      {...props}
    />
  );
};

export default TextOverflow;
