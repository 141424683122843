import { AppVersionActionCreatorSet, AppVersionTypes } from "./types";

export const appVersionSet: AppVersionActionCreatorSet = ({
  version,
  authUrl,
}) => ({
  payload: {
    authUrl,
    version,
  },
  type: AppVersionTypes.APP_VERSION_SET,
});
