import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import classNames from "classnames";
import React from "react";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      height: "30px",
      alignItems: "center",
      justifyContent: "flex-start",
      paddingLeft: "10px",
      display: "flex",
      outline: `1px solid ${theme.palette.grey[100]}`,
      background: theme.palette.grey[100],
    },
  });

type DiagramRoleHeaderProps = WithStyles<typeof styles> & {
  active?: boolean;
  className?: string;
};

const DiagramRoleHeader: React.SFC<DiagramRoleHeaderProps> = ({
  children,
  classes,
  active,
  className,
  ...props
}) => (
  <div className={classNames(classes.container, className)} {...props}>
    {children}
  </div>
);

export default withStyles(styles)(DiagramRoleHeader);
