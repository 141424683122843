import React, { Component } from "react";

interface TruncateProps {
  maxWidth: number | string;
}

class Truncate extends Component<TruncateProps> {
  public render() {
    const { maxWidth, children } = this.props;

    return (
      <div
        style={{
          maxWidth,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {children}
      </div>
    );
  }
}

export default Truncate;
