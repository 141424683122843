import { Theme, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/styles";
import { median } from "d3";
import React from "react";
import { GetInsightDensityMapResult } from "../../../hooks/useGetInsightDensityMapData";

interface Props {
  selectedValue: number;
  densityMapData: GetInsightDensityMapResult[];
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    borderRadius: 0,
    width: 300,
    marginBottom: 8,
  },
  statLabel: {
    fontWeight: "bold",
    margin: 0,
  },
  statValue: {
    fontWeight: 500,
  },
  contentBox: {
    display: "flex",
    flexDirection: "column",
  },
}));

export default function InsightAnalysisResults({
  selectedValue,
  densityMapData,
}: Props) {
  const styles = useStyles();
  const arrayOfValues = densityMapData.map(
    data => data.accountabilityCount[selectedValue]
  );
  const averageOfValues =
    arrayOfValues.reduce((a, b) => a + b, 0) / arrayOfValues.length;

  return (
    <Card className={styles.card} variant="outlined">
      <Box className={styles.contentBox}>
        <Box p={2} flex={"auto"}>
          <Typography className={styles.statLabel}>
            Median number of assignments:{" "}
            <span className={styles.statValue}>{median(arrayOfValues)}</span>
          </Typography>
        </Box>
        <Divider />
        <Box p={2} flex={"auto"}>
          <Typography className={styles.statLabel}>
            Average number of assignments:{" "}
            <span className={styles.statValue}>
              {averageOfValues ? parseFloat(averageOfValues.toFixed(2)) : ""}
            </span>
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}
