import { gql } from "apollo-boost";

export const UPDATE_ROLE_CAPACITY = gql`
  mutation UpdateRoleCapacity($roleCapacities: [RoleCapacity]!) {
    EditRoleCapacity(roleCapacities: $roleCapacities) {
      id
      capacity
    }
  }
`;
