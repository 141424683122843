import { Template, TemplatePlaceholder } from "@devexpress/dx-react-core";
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  SortingState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  TableFilterRow,
  TableFixedColumns,
  TableHeaderRow,
  Toolbar,
  VirtualTable,
} from "@devexpress/dx-react-grid-material-ui";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { UseGetDiagramsResult } from "../../hooks/useGetDiagrams";
import { sortDate, sortText } from "../../lib/sorting";
import ColumnStateContainer from "./ColumnStateContainer";
import DiagramViewMenu from "./DiagramViewMenu";
import SortingStateContainer from "./SortingStateContainer";
import {
  FormatActions,
  FormatAlert,
  FormatDate,
  FormatLinks,
  FormatStructure,
  FormatToolbar,
} from "./TableFormatters";

const useStyles = makeStyles({
  filterCellHidden: {
    "& > div > div": {
      display: "none",
    },
  },
  filterCellHiddenSticky: {
    position: "sticky",
    borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
    zIndex: 300,
    backgroundColor: "white",
    "& > div > div": {
      display: "none",
    },
  },
});

const DiagramList: React.SFC<{
  data: UseGetDiagramsResult;
}> = ({ data }) => {
  const classes = useStyles();

  const FilterCell = (props: any) => {
    if (props.column.name == "actions") {
      return (
        <TableFilterRow.Cell
          {...props}
          className={classes.filterCellHiddenSticky}
        />
      );
    } else if (!props.filteringEnabled) {
      return (
        <TableFilterRow.Cell {...props} className={classes.filterCellHidden} />
      );
    } else {
      return <TableFilterRow.Cell {...props} />;
    }
  };

  return (
    <Grid
      rows={data}
      columns={[
        { name: "name", title: "Diagram Name" },
        { name: "level", title: "Level" },
        { name: "decisionFramework", title: "Framework" },
        { name: "structureId", title: "Associated Structure" },
        { name: "created_by", title: "Creator" },
        { name: "created_at", title: "Creation Date" },
        { name: "updated_by", title: "Last edited by" },
        { name: "updated_at", title: "Last edited date" },
        { name: "actions", title: " " },
      ]}
      rootComponent={(props) => (
        <Grid.Root {...props} style={{ height: "100%" }} />
      )}
    >
      <DataTypeProvider formatterComponent={FormatActions} for={["actions"]} />
      <DataTypeProvider formatterComponent={FormatLinks} for={["name"]} />
      <DataTypeProvider
        formatterComponent={FormatDate}
        for={["created_at", "updated_at"]}
      />
      <DataTypeProvider
        formatterComponent={FormatStructure}
        for={["structureId"]}
      />
      <DataTypeProvider formatterComponent={FormatAlert} for={["alert"]} />
      <SortingStateContainer localStorageKey="diagramListSorting" />
      <IntegratedSorting
        columnExtensions={[
          {
            columnName: "name",
            compare: sortText,
          },
          {
            columnName: "created_at",
            compare: sortDate,
          },
          {
            columnName: "updated_at",
            compare: sortDate,
          },
        ]}
      />
      <SortingState
        columnExtensions={[
          {
            columnName: "actions",
            sortingEnabled: false,
          },
        ]}
      />
      <FilteringState
        columnExtensions={[
          {
            columnName: "actions",
            filteringEnabled: false,
          },
          {
            columnName: "alert",
            filteringEnabled: false,
          },
          {
            columnName: "created_at",
            filteringEnabled: false,
          },
          {
            columnName: "updated_at",
            filteringEnabled: false,
          },
          {
            columnName: "structureId",
            filteringEnabled: false,
          },
        ]}
      />
      <IntegratedFiltering />
      <VirtualTable height="auto" />
      <ColumnStateContainer
        localStorageKey="diagramListColumnWidths"
        initialColumnWidths={[
          { columnName: "name", width: 400 },
          { columnName: "level", width: 80 },
          { columnName: "decisionFramework", width: 100 },
          { columnName: "structureId", width: 150 },
          { columnName: "created_by", width: 100 },
          { columnName: "created_at", width: 150 },
          { columnName: "updated_by", width: 150 },
          { columnName: "updated_at", width: 150 },
          { columnName: "actions", width: 60 },
        ]}
      />
      <TableHeaderRow showSortingControls={true} />
      <TableFilterRow showFilterSelector={false} cellComponent={FilterCell} />
      <TableFixedColumns rightColumns={["actions"]} leftColumns={["name"]} />
      <Toolbar rootComponent={FormatToolbar} />
      <Template name="toolbarContent">
        <DiagramViewMenu />
        <TemplatePlaceholder />
      </Template>
    </Grid>
  );
};

export default DiagramList;
