import { combineReducers, Reducer } from "redux";
import appVersion from "./appVersion/reducer";
import designSessions from "./designSessions/reducer";
import diagramFilters from "./diagramFilters/reducer";
import insights from "./insights/reducer";
import login from "./login/reducer";
import modal from "./modal/reducer";
import notifications from "./notifications/reducer";
import raciSelection from "./raciSelection/reducer";
import roleSearch from "./roleSearch/reducer";
import { State, StoreActions } from "./types";
import userSettings from "./userSettings/reducer";

export const appReducers: Reducer<State, StoreActions> = combineReducers({
  designSessions,
  diagramFilters,
  login,
  modal,
  notifications,
  raciSelection,
  roleSearch,
  userSettings,
  insights,
  appVersion,
});

const rootReducer: Reducer<State, StoreActions> = (state, action) => {
  if (state && action.type === "session cleared") {
    return appReducers(
      {
        userSettings: state.userSettings,
        appVersion: state.appVersion,
      } as State,
      action
    );
  }
  return appReducers(state, action);
};

export default rootReducer;
