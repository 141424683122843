import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import classNames from "classnames";
import React from "react";
import RoundButton from "../RoundButton";

const styles = (theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: "currentColor",
    },
    disabled: {
      "&:hover": {
        backgroundColor: theme.palette.action.disabled,
      },
      backgroundColor: theme.palette.action.disabled,
    },
    disabledOutline: {
      color: theme.palette.action.disabled,
    },
    inactive: {
      "&:hover": {
        backgroundColor: theme.palette.grey[100],
      },
      backgroundColor: theme.palette.grey[200],
    },
    inactiveOutline: {
      color: theme.palette.grey[200],
    },
    label: {
      alignItems: "inherit",
      display: "inherit",
      justifyContent: "inherit",
      width: "100%",
    },
    outline: {
      "&:hover": {
        backgroundColor: "white",
      },
      backgroundColor: "white",
      borderColor: "currentColor",
      borderStyle: "solid",
      borderWidth: 1,
    },
    text: {
      color: theme.palette.common.white,
      fontWeight: 600,
    },
    textOutline: {
      color: "currentColor",
      fontWeight: 500,
    },
  });

type RACIButtonInnerProps = WithStyles<typeof styles>;

interface RACIButtonOuterProps {
  disabled?: boolean;
  outline?: boolean;
  color?: string;
  inactive?: boolean;
  className?: string;
  onClick?: any;
  id?: string;
}

type RACIButtonProps = RACIButtonInnerProps & RACIButtonOuterProps;

class RACIButton extends React.Component<RACIButtonProps> {
  public render() {
    const {
      classes,
      className,
      children,
      outline = false,
      inactive,
      color = "#ccc",
      onClick,
      ...props
    } = this.props;
    return (
      <RoundButton
        className={classNames(classes.button, className, {
          [classes.disabled]: props.disabled,
          [classes.disabledOutline]: props.disabled && outline,
          [classes.outline]: outline,
          [classes.inactive]: inactive,
          [classes.inactiveOutline]: inactive && outline,
        })}
        onClick={onClick}
        style={{
          color: !props.disabled && !inactive && color ? color : undefined,
        }}
        {...props}
      >
        <span
          className={classNames(classes.text, {
            [classes.textOutline]: outline,
          })}
        >
          {children}
        </span>
      </RoundButton>
    );
  }
}

export default withStyles(styles)(RACIButton);
