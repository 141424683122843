import { useMutation } from "@apollo/react-hooks";
import {
  CreateDiagram,
  CreateDiagramVariables,
} from "../../generated/CreateDiagram";
import { CREATE_DIAGRAM } from "../../graphql/mutation/createDiagram";
import GET_DIAGRAMS_LIST from "../../graphql/query/getDiagramsList";
import { DiagramFormValues } from "../../types/DiagramForm";

export default function useCreateDiagram() {
  const [createDiagram, { loading }] = useMutation<
    CreateDiagram,
    CreateDiagramVariables
  >(CREATE_DIAGRAM);

  function _create(values: DiagramFormValues, parentOperationID?: string) {
    return createDiagram({
      variables: {
        accountabilityMethodologyID: values.decisionFrameworkId || "",
        structureId: values.structureId || "",
        name: values.diagramName || "",
        description: values.description,
        organizationSize: values.organizationSize,
        processArchetypeID: values.processArchetype,
        processArchetypeOther: values.processArchetypeOther,
        structurePrimaryAxisID: values.structurePrimaryAxis,
        structurePrimaryAxisOther: values.structurePrimaryAxisOther,
        industryID: values.industry,
        industryOther: values.industryOther,
        industryOrglabID: values.industryOrglabID,
        primaryOwningFunctionID: values.primaryOwningFunction,
        primaryOwningFunctionOther: values.primaryOwningFunctionOther,
        geographyID: values.geography,
        parentOperationID,
      },
      fetchPolicy: "no-cache",
      refetchQueries: [
        {
          query: GET_DIAGRAMS_LIST,
        },
      ],
    });
  }
  return { createDiagram: _create, createSaving: loading };
}
