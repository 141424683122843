import { RoleSearchResponse } from "../../types/Roles";
import { ReduxActionCreator, ReduxThunkActionCreator } from "../types";
import * as actions from "./actions";

export enum RoleSearchActionTypes {
  ROLE_SEARCH_START = "ROLE_SEARCH_START",
  ROLE_SEARCH_END = "ROLE_SEARCH_END",
}

export interface RoleSearchState {
  roleId?: string;
  onSelectRole?: (orglabRole: RoleSearchResponse) => void;
}

export type RoleSearchActionCreatorStart = ReduxActionCreator<
  RoleSearchActionTypes.ROLE_SEARCH_START,
  RoleSearchState,
  [RoleSearchState]
>;

export type RoleSearchActionCreatorEnd = ReduxActionCreator<
  RoleSearchActionTypes.ROLE_SEARCH_END,
  null
>;

export type RoleSearchActionCreatorOpen = ReduxThunkActionCreator<
  RoleSearchActionTypes.ROLE_SEARCH_START,
  RoleSearchState,
  Promise<RoleSearchResponse>,
  [string]
>;

export type RoleSearchAction =
  | ReturnType<typeof actions.roleSearchStart>
  | ReturnType<typeof actions.roleSearchEnd>;
