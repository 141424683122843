import ButtonBase from "@material-ui/core/ButtonBase";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import classNames from "classnames";
import React, { MouseEvent, MouseEventHandler } from "react";
import { RACI_CELL_HEIGHT } from "../../config/constants";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      outline: `1px solid ${theme.palette.grey[100]}`,
      height: RACI_CELL_HEIGHT,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.common.white,
      "&:hover": {
        background: "#ECF0F1",
      },
    },
    button: {
      fontFamily: theme.typography.fontFamily,
      width: "100%",
      height: "100%",
      padding: "8px 10px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    active: {
      backgroundColor: "#ECF0F1",
    },
    focused: {
      backgroundColor: lighten("#ECF0F1", 0.5),
    },
  });

interface DiagramCellOuterProps {
  active?: boolean;
  focused?: boolean;
  hover?: boolean;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  className?: string;
  id?: string;
  buttonClassName?: string;
  disabled?: boolean;
}

interface DiagramCellInnerProps extends WithStyles<typeof styles> {}

type DiagramCellProps = DiagramCellOuterProps & DiagramCellInnerProps;

const DiagramCell: React.SFC<DiagramCellProps> = ({
  children,
  classes,
  active,
  focused,
  onClick,
  className,
  buttonClassName,
  disabled,
  ...props
}) => {
  return (
    <div
      className={classNames(
        classes.container,
        className,
        "DiagramCell",
        !onClick && buttonClassName,
        {
          [classes.active]: active,
          [classes.focused]: focused,
        }
      )}
      {...props}
    >
      {onClick ? (
        <ButtonBase
          color="primary"
          onClick={(event: MouseEvent<HTMLElement>) => onClick(event)}
          component="span"
          disabled={disabled}
          className={classNames(classes.button, buttonClassName)}
        >
          {children || ""}
        </ButtonBase>
      ) : (
        children
      )}
    </div>
  );
};

DiagramCell.defaultProps = {
  active: false,
};

export default withStyles(styles)(DiagramCell);
