import { useMutation } from "@apollo/react-hooks";
import { UpdateOperationEdgesVariables } from "../../generated/UpdateOperationEdges";
import UPDATE_OPERATION_EDGES from "../../graphql/mutation/updateOperationsEdges";
import { findNewOperationEdges } from "../../lib/diagramUtils";
import { DiagramComponentOperation } from "../useGetDiagram/types";

export default function useUpdateOperationEdges() {
  const [updateOperation, { loading }] = useMutation<
    any,
    UpdateOperationEdgesVariables
  >(UPDATE_OPERATION_EDGES);

  function _updateOperationEdges(
    currentPath: DiagramComponentOperation[],
    oldIndex: number,
    newIndex: number
  ) {
    const newEdges = findNewOperationEdges(currentPath, oldIndex, newIndex);

    return updateOperation({
      variables: {
        fromID_A: newEdges[0][0],
        pathType_A: newEdges[0][1],
        toID_A: newEdges[0][2],

        fromID_B: newEdges[1][0],
        pathType_B: newEdges[1][1],
        toID_B: newEdges[1][2],

        fromID_C: newEdges[2][0],
        pathType_C: newEdges[2][1],
        toID_C: newEdges[2][2],
      },
      optimisticResponse: {
        __typename: "Mutation",
        UpdateOperationEdgeA: {
          id: newEdges[0][0],
          nextOperation: null,
          nextOperationNo: null,
          nextOperationYes: null,
          [newEdges[0][1]]: newEdges[0][2],
          __typename: "Operation",
        },
        UpdateOperationEdgeB: {
          id: newEdges[1][0],
          nextOperation: null,
          nextOperationNo: null,
          nextOperationYes: null,
          [newEdges[1][1]]: newEdges[1][2],
          __typename: "Operation",
        },
        UpdateOperationEdgeC: {
          id: newEdges[2][0],
          nextOperation: null,
          nextOperationNo: null,
          nextOperationYes: null,
          [newEdges[2][1]]: newEdges[2][2],
          __typename: "Operation",
        },
      },
    });
  }

  return { updateEdges: _updateOperationEdges, loading };
}
