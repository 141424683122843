import {
  Box,
  Card,
  CardContent,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { orderBy } from "lodash";
import React, { useState } from "react";
import { orgGraphColors } from "../../../config/theme";
import { DiagramComponentFrameworkValues } from "../../../hooks/useGetDiagram/types";
import { GetInsightDensityMapResult } from "../../../hooks/useGetInsightDensityMapData";
import { InsightDiagrams } from "../../../hooks/useGetInsightDiagrams";
import AnalysisAxis from "../AnalysisAxis";
import AsterPlot from "../AsterPlot/AsterPlot";
import InsightAnalysisResults from "./InsightAnalysisResults";

interface Props {
  accountabilityValues: DiagramComponentFrameworkValues;
  densityMapData: GetInsightDensityMapResult[];
  selectedProcesses: InsightDiagrams;
  colors?: string[];
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
  },
  legend: {
    width: 300,
    maxHeight: 700,
    overflow: "auto",
  },
  legendRow: {
    display: "flex",
    alignItems: "center",
    padding: 4,
  },
  legendColor: {
    width: 12,
    height: 12,
    position: "relative",
  },
  legendLabel: {
    marginLeft: 26,
    maxWidth: 200,
  },
}));

const DecisionRightsLoad: React.SFC<Props> = ({
  accountabilityValues,
  densityMapData,
  selectedProcesses,
  colors = orgGraphColors,
}) => {
  const classes = useStyles();

  const [selectedValues, setSelectedValues] = useState([0]);
  const selectedValue = selectedValues[0] || 0;

  const maxCount = densityMapData.map(data => {
    return data.accountabilityCount[selectedValue];
  });

  const displayData = orderBy(
    densityMapData
      .filter(data => {
        if (data.accountabilityCount[selectedValue] === 0) {
          return false;
        }
        return true;
      })
      .map((data, i) => ({
        id: "1",
        order: 1,
        color: colors[i % colors.length] || "#999",
        score:
          (data.accountabilityCount[selectedValue] / Math.max(...maxCount)) *
          100,
        assignmentCount: data.accountabilityCount[selectedValue],
        splitByProcess: data.splitByProcess[selectedValue],
        label: data.name || "",
        orglab: Boolean(data.orglabID),
        employeeName: data.employeeName || "",
      })),
    "score",
    "desc"
  );

  const emptyData = densityMapData.filter(
    data => data.accountabilityCount[selectedValue] === 0
  );

  return densityMapData.length > 0 ? (
    <div className={classes.root}>
      <AnalysisAxis
        accountabilityValues={accountabilityValues}
        selectedValue={selectedValues}
        setSelectedValue={v => setSelectedValues([v])}
      />
      <div style={{ display: "flex" }}>
        <div style={{ position: "relative", top: "-30px" }}>
          <InsightAnalysisResults
            selectedValue={selectedValue}
            densityMapData={densityMapData}
          />
          <Card className={classes.legend} variant="outlined">
            <CardContent>
              {displayData.map((data, i) => (
                <Box className={classes.legendRow} key={i}>
                  <div
                    className={classes.legendColor}
                    style={{
                      backgroundColor: data.color,
                    }}
                  />
                  <div className={classes.legendLabel}>
                    {data.label}{" "}
                    {data.orglab &&
                      data.employeeName &&
                      ` (${data.employeeName})`}
                  </div>
                </Box>
              ))}
              {emptyData.map((data, i) => (
                <Box className={classes.legendRow} key={i}>
                  <div
                    className={classes.legendColor}
                    style={{
                      backgroundColor: "white",
                      border: "1px solid lightGrey",
                    }}
                  />
                  <div className={classes.legendLabel}>
                    {data.name || "-"}{" "}
                    {data.orglabID &&
                      data.employeeName &&
                      ` (${data.employeeName})`}
                  </div>
                </Box>
              ))}
            </CardContent>
          </Card>
        </div>
        <div style={{ width: "100%", padding: 60 }}>
          <AsterPlot
            width={600}
            height={600}
            data={displayData}
            maxAssignments={Math.max(...maxCount)}
            selectedProcesses={selectedProcesses}
          />
        </div>
      </div>
    </div>
  ) : (
    <Typography>
      No data available. Please select one or more involved roles and click
      “Refresh Insights”.
    </Typography>
  );
};

export default DecisionRightsLoad;
