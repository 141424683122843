import { Typography } from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "recompose";
import { pdClient } from "../../lib/apiClient";
import { roleSearchEnd } from "../../store/roleSearch/actions";
import { getDiagramFromRouteProps } from "../../store/selectors";
import { State } from "../../store/types";
import { DiagramFile } from "../../types/Diagram";
import { OrglabFilters } from "../../types/OrglabFilters";
import { RoleSearchResponse } from "../../types/Roles";
import LoadingWindow from "../LoadingWindow";
import RoleSearch from "../RoleSearch";
import WindowAnchored from "../WindowAnchored";

interface RoleSearchWindowStateProps {
  selectedRoleId?: string;
  onSelectRole?: (orglabRole: RoleSearchResponse) => void;
}

const RoleSearchWindow: React.SFC<{
  structureId?: string;
}> = ({ structureId }) => {
  const dispatch = useDispatch();
  const { selectedRoleId, onSelectRole } = useSelector<
    State,
    RoleSearchWindowStateProps
  >(state => ({
    selectedRoleId: state.roleSearch.roleId,
    onSelectRole: state.roleSearch.onSelectRole,
  }));

  const [loadingFilters, setLoadingFilters] = useState(false);
  const [filters, setFilters] = useState<OrglabFilters>({
    locations: [],
    functions: [],
    lookups: [],
  });
  const [filterError, setFilterError] = useState(false);

  useEffect(() => {
    if (structureId) {
      setLoadingFilters(true);
      pdClient
        .getFilters(structureId)
        .then(filters => {
          setLoadingFilters(false);
          setFilters(filters);
        })
        .catch(err => {
          setFilterError(true);
        });
    }
  }, []);

  const handleClickAway = () => {
    dispatch(roleSearchEnd());
  };

  const handleUpdateRole = (orglabRole: RoleSearchResponse) => {
    if (selectedRoleId && onSelectRole) {
      onSelectRole(orglabRole);
      handleClickAway();
    }
  };

  // if (!selectedRoleId) return null;

  return (
    <WindowAnchored
      anchorId={selectedRoleId}
      onClickAway={handleClickAway}
      windowSizeStyle={{
        width: "80vw",
        height: "95vh",
        maxWidth: 900,
        maxHeight: 680,
      }}
    >
      {filterError ? (
        <Typography
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          Error retrieving filters.
        </Typography>
      ) : loadingFilters ? (
        <LoadingWindow />
      ) : (
        structureId && (
          <RoleSearch
            filters={filters}
            structureId={structureId}
            onSelect={orglabRole => {
              handleUpdateRole(orglabRole);
            }}
          />
        )
      )}
    </WindowAnchored>
  );
};

export default RoleSearchWindow;
