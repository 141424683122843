import { createStyles, WithStyles, withStyles } from "@material-ui/core";
import classNames from "classnames";
import _ from "lodash";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import useDiagramInteractions from "../../hooks/useDiagramInteractions";
import {
  DiagramComponentOperation,
  DiagramComponentState,
} from "../../hooks/useGetDiagram/types";
import { isMobileDevice } from "../../lib/componentProps";
import { getOperationAssignmentValues } from "../../lib/diagramUtils";
import DiagramAssignmentRACIContainer from "../DiagramAssignmentRACI";
import RoleAddButton from "../RoleAddButton";
import RolePanelItem from "../RolePanelItem";

const styles = createStyles({
  roles: {
    paddingLeft: 40,
    width: 260,
    position: "sticky",
    zIndex: 9999,
    left: 0,
    background: "white",
  },
  rolesIsMobile: {
    position: "static",
  },
  root: {
    display: "flex",
  },
});

interface DiagramAssignmentsOuterProps {
  diagram: DiagramComponentState;
  currentPath: DiagramComponentOperation[];
  activeRoleId?: string;
  activeOperationId?: string;
  onRoleClick: (roleId: string) => void;
  onAssignmentClick: (operationId: string, roleId: string) => void;
  getActiveState: (operationId: string, roleId: string) => boolean;
}

type DiagramAssignmentsInnerProps = WithStyles<typeof styles>;

type DiagramAssignmentsProps = DiagramAssignmentsOuterProps &
  DiagramAssignmentsInnerProps;

const DiagramAssignments: React.SFC<DiagramAssignmentsProps> = (props) => {
  const {
    activeRoleId,
    activeOperationId,
    classes,
    diagram,
    currentPath,
    onRoleClick,
    onAssignmentClick,
    getActiveState,
  } = props;

  const roles = diagram.roles;
  const raciOptions =
    diagram.accountabilityMethodology.accountabilityMethodologyValues;
  const { sorting } = useDiagramInteractions();

  return (
    <div className={classes.root}>
      <div
        className={classNames(classes.roles, {
          [classes.rolesIsMobile]: isMobileDevice(),
        })}
      >
        {roles.map((role, roleIndex) => (
          <RolePanelItem
            onRoleClick={onRoleClick}
            key={roleIndex}
            role={{
              id: role.id,
              name: role.name || "",
              orglabRoleId: role.orglabID || undefined,
              capacity: role.capacity,
            }}
            index={roleIndex}
            focusOnRender={false}
            activeRoleId={activeRoleId}
            canSearchRoles={diagram.structureId ? true : false}
            structureId={diagram.structureId}
            canDeleteRole={diagram.roles.length > 1 ? true : false}
          />
        ))}
        <RoleAddButton diagramId={diagram.id} />
      </div>
      <div>
        {roles.map((role, roleIndex) => (
          <div className={classes.root} key={roleIndex}>
            {currentPath.map((operation, operationIndex) => {
              if (operation.type === "task" || operation.type === "decision") {
                return (
                  <div key={operationIndex}>
                    <DiagramAssignmentRACIContainer
                      key={operationIndex}
                      options={raciOptions}
                      processIndex={operationIndex}
                      roleIndex={roleIndex}
                      roleId={role.id}
                      processId={operation.id}
                      hideValues={Boolean(
                        operationIndex === sorting.x || sorting.y === roleIndex
                      )}
                      active={getActiveState(operation.id, role.id)}
                      focused={
                        _.isEqual(activeRoleId, role.id) ||
                        _.isEqual(activeOperationId, operation.id)
                      }
                      values={getOperationAssignmentValues(diagram, {
                        operationId: operation.id,
                        roleId: role.id,
                      })}
                      showAdd={false} // processElements.length <= 1 && roles.length <= 1}
                      // onOpen={this.handleEntityClick({ processElement, role })}
                      onOpen={() => onAssignmentClick(operation.id, role.id)}
                      width={operation.type === "decision" ? 171 : 155}
                    />
                  </div>
                );
              }
              return null;
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

const enhance = compose<DiagramAssignmentsProps, DiagramAssignmentsOuterProps>(
  withTranslation("translations"),
  withRouter,
  withStyles(styles)
);

export default enhance(DiagramAssignments);
