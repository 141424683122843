import _ from "lodash";
import { Reducer } from "redux";
import { ModalAction, ModalActionTypes, ModalState } from "./types";

export const initialState = {
  open: false,
  modalProps: {},
};

const reducer: Reducer<ModalState, ModalAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ModalActionTypes.CREATE_MODAL:
      return { modalProps: action.payload.modalProps, open: true };

    case ModalActionTypes.DESTROY_MODAL:
      return {
        ...state,
        open: false,
      };

    default:
      return state;
  }
};

export default reducer;
