import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import _ from "lodash";
import React from "react";
import { withRouter } from "react-router";
import { compose } from "recompose";
import getRoleFieldData from "../../lib/getRoleFieldData";
import { RoleResponse } from "../../types/Roles";

interface RoleInfoCardOuterProps {
  orglabRole: RoleResponse;
}

type RoleInfoCardProps = RoleInfoCardOuterProps;

class RoleInfoCard extends React.Component<RoleInfoCardProps> {
  public render() {
    const { orglabRole } = this.props;
    return getRoleFieldData(orglabRole).map(field => (
      <FormControl key={field.id} margin="dense" disabled={true} fullWidth={true}>
        <InputLabel htmlFor={field.id} disabled={true} shrink={true}>
          {field.label}
        </InputLabel>
        <Input
          id={field.id}
          value={field.value}
          disabled={true}
          multiline={true}
          inputComponent={input => (
            <div className={input.className}>
              {input.value}
              {"\u00A0"}
            </div>
          )}
        />
      </FormControl>
    ));
  }
}

const enhance = compose<RoleInfoCardProps, RoleInfoCardOuterProps>(withRouter);

export default enhance(RoleInfoCard);
