import React from "react";
import LoadingInline from "../LoadingInline";
import FormEditRoleCapacity from "./FormEditRoleCapacity";
import useGetRolesForCapacity from "./useGetRolesForCapacity";
import useUpdateRoleCapacity from "./useUpdateRoleCapacity";

interface Props {
  orglabRoleId: string;
  onClose: () => void;
}

export default function _FormEditRoleCapacity({
  onClose,
  orglabRoleId,
}: Props) {
  const { data, loading } = useGetRolesForCapacity(orglabRoleId);
  const { updateRoleCapacity } = useUpdateRoleCapacity();

  return loading ? (
    <LoadingInline />
  ) : (
    <FormEditRoleCapacity
      roles={data}
      onClose={onClose}
      onSubmit={({ roles }) => {
        updateRoleCapacity(
          roles.map(({ roleId, capacity }) => ({
            id: roleId,
            capacity: parseInt(capacity.toString()),
          }))
        ).then(() => {
          onClose();
        });
      }}
    />
  );
}
