import {
  Box,
  Divider,
  InputLabel,
  Switch,
  Typography,
} from "@material-ui/core";
import { Field, FieldArray, Form, Formik, FormikProps } from "formik";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "recompose";
import { getDiagramIdFromRouteProps } from "../../store/selectors";
import getUserSettings from "../../store/selectors/getUserSettings";
import { State } from "../../store/types";
import { userSet } from "../../store/userSettings/actions";
import {
  UserSettingMultipleAssignments,
  UserSettingsSetHandler,
} from "../../store/userSettings/types";
import { Nudge } from "../../types/Nudges";
import FormikAutoSave from "../FormikAutoSave";
import FormSliderField from "../FormSliderField";
import FormSwitchField from "../FormSwitchField";

const NudgeLabel: React.SFC<{ label: string }> = ({ label, children }) => (
  <div style={{ display: "flex", alignItems: "flex-start" }}>
    <Box my={1} style={{ flex: 1 }}>
      {label}
    </Box>
    {children}
  </div>
);
interface DiagramNudgesManagerOuterProps {
  nudges: Nudge[];
  onSubmit?: () => void;
}

interface DiagramNudgesManagerDispatchProps {
  handleUserSet: UserSettingsSetHandler;
}

type DiagramNudgesManagerInnerProps = DiagramNudgesManagerDispatchProps &
  DiagramNudgesManagerStateProps;

type DiagramNudgesManagerProps = DiagramNudgesManagerOuterProps &
  DiagramNudgesManagerInnerProps;

interface DiagramNudgesManagerValues {
  nudges: Array<{
    active: boolean;
    threshold: number;
  }>;
}

interface DiagramNudgesManagerStateProps {
  diagramId: string;
  nudgeSettings?: UserSettingMultipleAssignments;
}

const DiagramNudgesManager: React.SFC<DiagramNudgesManagerProps> = ({
  nudges,
  handleUserSet,
  diagramId,
  nudgeSettings,
  onSubmit,
}) => {
  const defaultNudgeSetting = {
    active: true,
    threshold: 1,
  };

  const initialNudges = nudges.map(
    ({ id }) =>
      nudgeSettings && nudgeSettings[id]
        ? {
            active: nudgeSettings[id][0],
            threshold: nudgeSettings[id][1],
          }
        : defaultNudgeSetting
  );

  function handleSubmit(values: DiagramNudgesManagerValues) {
    handleUserSet("nudgeMultipleAssignments", {
      [diagramId]: values.nudges.reduce(
        (acc, current, i) => ({
          ...acc,
          [nudges[i].id]: [current.active, current.threshold],
        }),
        {}
      ),
    });
    // handleNudgesUpdateDiagram(diagramId);
    onSubmit && onSubmit();
  }

  return (
    <Formik
      initialValues={{
        nudges: initialNudges,
      }}
      onSubmit={(values, actions) => {
        handleSubmit(values);
        actions.setSubmitting(false);
      }}
      render={({
        values,
        setFieldValue,
      }: FormikProps<DiagramNudgesManagerValues>) => (
        <Form translate="yes">
          <FormikAutoSave />
          <Box mx={2} mt={1}>
            <NudgeLabel label="All Nudges">
              <Switch
                checked={values.nudges.every(x => x.active)}
                onChange={e => {
                  values.nudges.forEach((_, i) => {
                    setFieldValue(`nudges.${i}.active`, e.target.checked);
                  });
                }}
              />
            </NudgeLabel>
          </Box>
          <Box mx={2} mb={1}>
            <Typography variant="subtitle2">Role Clarity</Typography>
          </Box>
          <FieldArray
            name="nudges"
            render={() =>
              values.nudges.map((value, i) => (
                <React.Fragment key={i}>
                  <Box m={2}>
                    <NudgeLabel label={nudges[i].adminMessage(value.threshold)}>
                      <Field
                        name={`nudges.${i}.active`}
                        component={FormSwitchField}
                      />
                    </NudgeLabel>
                    <InputLabel shrink={true}>Nudge Threshold</InputLabel>
                    <Box mx={1}>
                      <Field
                        name={`nudges.${i}.threshold`}
                        component={FormSliderField}
                        disabled={!value.active || !nudges[i].canManage}
                        step={1}
                        marks={[1, 2, 3, 4, 5].map(value => ({
                          value,
                          label: value,
                        }))}
                        max={5}
                        min={1}
                      />
                    </Box>
                  </Box>
                  <Divider />
                </React.Fragment>
              ))
            }
          />
        </Form>
      )}
    />
  );
};

const enhance = compose<
  DiagramNudgesManagerProps,
  DiagramNudgesManagerOuterProps
>(
  withRouter,
  connect<
    DiagramNudgesManagerStateProps,
    DiagramNudgesManagerDispatchProps,
    DiagramNudgesManagerOuterProps,
    State
  >(
    (state, props) => {
      const diagramId = getDiagramIdFromRouteProps(props);
      const userSettings = getUserSettings(state);
      return {
        diagramId,
        nudgeSettings:
          userSettings &&
          userSettings.nudgeMultipleAssignments &&
          userSettings.nudgeMultipleAssignments[diagramId],
      };
    },
    {
      handleUserSet: userSet,
    }
  )
);

export default enhance(DiagramNudgesManager);
