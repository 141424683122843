import { useMutation } from "@apollo/react-hooks";
import { DeleteRole, DeleteRoleVariables } from "../../generated/DeleteRole";
import DELETE_ROLE from "../../graphql/mutation/deleteRole";
import GET_DIAGRAM from "../../graphql/query/getDiagram";

export default function useDeleteRole() {
  const [deleteRole] = useMutation<DeleteRole, DeleteRoleVariables>(
    DELETE_ROLE
  );
  function _delete(roleID: string, processID: string) {
    deleteRole({
      variables: {
        roleID,
        processID,
      },
      refetchQueries: [
        {
          query: GET_DIAGRAM,
          variables: {
            id: processID,
          },
        },
      ],
    });
  }
  return [_delete];
}
