import React from "react";
import { cloneDeep, uniq } from "lodash";
import Helmet from "react-helmet";

interface ContentSecurityPolicy {
  [key: string]: string[];
}

export const contentSecurityPolicy: ContentSecurityPolicy = {
  "default-src": ["'self'", "*.orglabsolutions.com"],
  "connect-src": [
    "'self'",
    "https://*.orglabsolutions.com",
    "https://*.orglabsolutions.com:*",
    "https://solutions.mckinsey.com",
  ],
  "style-src": ["'self'", "blob:", "'unsafe-inline'"],
  "img-src": ["'self'", "data:"],
  "font-src": ["'self'"],
  "object-src": ["'none'"],
  "script-src": ["'self'", "'unsafe-inline'"],
  "frame-src": ["https://orglab.atlassian.net", "https://player.vimeo.com"],
};

export const buildContentSecurityPolicy = (
  defaultCSP: ContentSecurityPolicy,
  environment: typeof process.env.NODE_ENV
) => {
  const CSP = cloneDeep(defaultCSP);

  if (environment === "development") {
    CSP["connect-src"].push("http://localhost:*");
  }
  if (environment === "test") {
    // CSP for TEST env
  }
  if (environment === "production") {
    // CSP for PRODUCTION env
  }
  return buildPolicyString(CSP);
};

const buildPolicyString = (policy: ContentSecurityPolicy) => {
  return Object.keys(policy)
    .map((key) => {
      const value = policy[key];
      const valueStr = Array.isArray(value) ? uniq(value).join(" ") : value;
      return `${key} ${valueStr}`;
    })
    .join("; ");
};

export const CSP = () => (
  <Helmet>
    {
      <meta
        http-equiv="Content-Security-Policy"
        content={buildContentSecurityPolicy(
          contentSecurityPolicy,
          process.env.NODE_ENV
        )}
      />
    }
  </Helmet>
);
