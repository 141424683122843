import { makeStyles } from "@material-ui/styles";
import { xor } from "lodash";
import React, { useState } from "react";
import { DiagramComponentFrameworkValues } from "../../hooks/useGetDiagram/types";
import { ButtonAssign } from "../Button";
import RACIMenu from "../RACIMenu";

const useStyles = makeStyles({
  fixed: {
    position: "fixed",
  },
  inner: {
    bottom: 0,
    position: "absolute",
    right: 0,
  },
  root: {
    bottom: 20,
    position: "absolute",
    right: 20,
    zIndex: 100,
  },
});

const RACISelectionMultiple: React.SFC<{
  onDone: (values: string[]) => void;
  options: DiagramComponentFrameworkValues;
}> = ({ onDone, options }) => {
  const classes = useStyles();
  const [values, setValues] = useState<string[]>([]);

  function select(value: string) {
    setValues(xor(values, [value]));
  }

  return (
    <div className={classes.root}>
      <div className={classes.fixed}>
        <div className={classes.inner}>
          <RACIMenu
            options={options}
            onSelect={(value: string) => select(value)}
            onRemove={(value: string) => select(value)}
            values={values}
          >
            <ButtonAssign onClick={() => onDone(values)} />
          </RACIMenu>
        </div>
      </div>
    </div>
  );
};

export default RACISelectionMultiple;
