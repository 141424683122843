import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import { Theme } from "@material-ui/core/styles";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { CheckBoxOutlined } from "@material-ui/icons";
import React from "react";
import { ButtonDismiss } from "../Button";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      borderTop: `1px solid ${theme.palette.grey[200]}`,
      padding: 24,
    },
  });

export type WelcomeContentInnerProps = WithStyles<typeof styles>;

export interface WelcomeContentOuterProps {
  onDismiss: () => void;
  onDontShowAgain: () => void;
  showAgain: boolean;
}

export type WelcomeContentProps = WelcomeContentInnerProps &
  WelcomeContentOuterProps;

export const WelcomeContent: React.SFC<WelcomeContentProps> = ({
  classes,
  onDismiss,
  onDontShowAgain,
  showAgain,
}) => (
  <Typography component="div" className={classes.root}>
    <Grid
      container={true}
      spacing={1}
      alignItems="center"
      alignContent="flex-end"
    >
      <Grid item={true} xs={12}>
        <span style={{ fontWeight: "bold" }}>Want to learn more?</span>
      </Grid>
      <Grid item={true} xs={8}>
        <Grid container={true} spacing={3} alignItems="center">
          <Grid item={true}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              role="videoTutorials"
              href="https://vimeo.com/273834926/8fff8e206e"
            >
              Watch video tutorial(s)
            </a>
          </Grid>
          <Grid item={true}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              role="emGuide"
              href="http://home.intranet.mckinsey.com/ks/research/summary/document?id=862845"
            >
              Access the EM Guide
            </a>
          </Grid>
          <Grid item={true}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              role="viewPrimer"
              href="https://mckinsey.ent.box.com/s/gup6gwfcgshx7ah95k6s48khyx86tsm4"
            >
              View a primer on DL
            </a>
          </Grid>
        </Grid>
      </Grid>
      <Grid item={true} xs={4}>
        <Grid
          container={true}
          spacing={3}
          alignItems="center"
          wrap="nowrap"
          justify="flex-end"
        >
          <Grid item={true}>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={onDontShowAgain}
                  checked={showAgain}
                  color="default"
                  checkedIcon={<CheckBoxOutlined />}
                />
              }
              label="Do not show again"
            />
          </Grid>
          <Grid item={true}>
            <ButtonDismiss type="submit" onClick={onDismiss} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Typography>
);

export default withStyles(styles)(WelcomeContent);
