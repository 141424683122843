import * as actions from "./actions";

export enum UserSettingsTypes {
  USER_SETTINGS_SET = "SETTINGS_SET",
}

export interface UserSettingMultipleAssignments {
  [key: string]: [boolean, number];
}

export interface UserSetting {
  dismissSignInConfirmation?: boolean;
  dismissWelcome?: boolean;
  hasConfirmedDecision?: boolean;
  nudgeMultipleAssignments?: {
    [diagramId: string]: UserSettingMultipleAssignments;
  };
}

export interface UserSettingsState {
  [userId: string]: UserSetting;
}

export type UserSettingsSetHandler = <
  K extends keyof UserSetting,
  V extends UserSetting[K]
>(
  key: K,
  value: V
) => void;

export type UserSettingsAction = ReturnType<typeof actions.userSettingsSet>;
