import {
  ModalActionCreatorCreate,
  ModalActionCreatorDestroy,
  ModalActionTypes,
} from "./types";

export const hideModal: ModalActionCreatorDestroy = () => ({
  payload: null,
  type: ModalActionTypes.DESTROY_MODAL,
});

export const confirm: ModalActionCreatorCreate = (modalProps = {}) => ({
  payload: { modalProps },
  type: ModalActionTypes.CREATE_MODAL,
});
