import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose, withHandlers } from "recompose";
import { notifyError } from "../../store/notifications/actions";
import DiagramListEmpty from "./DiagramListEmpty";

const enhance = compose<any, any>(
  withRouter,
  withTranslation("translations"),
  connect(
    null,
    {
      notifyError,
    }
  )
);

export default enhance(DiagramListEmpty);
