import MenuItem from "@material-ui/core/MenuItem";
import { Field, FieldProps } from "formik";
import React from "react";
import {
  GetDiagramTags_PrimaryOwningFunctionDropdown,
  GetDiagramTags_ProcessArchetypeDropdown,
  GetDiagramTags_StructurePrimaryAxisDropdown,
} from "../../generated/GetDiagramTags";
import { DiagramFormValues } from "../../types/DiagramForm";
import FormSelectField from "../FormSelectField";
import FormTextField from "../FormTextField";

interface Props {
  processArchetypeValues: GetDiagramTags_ProcessArchetypeDropdown[];
  structurePrimaryAxisValues: GetDiagramTags_StructurePrimaryAxisDropdown[];
  primaryOwningFunctionValues: GetDiagramTags_PrimaryOwningFunctionDropdown[];
}

const Step2Fields: React.SFC<Props> = ({
  processArchetypeValues,
  structurePrimaryAxisValues,
  primaryOwningFunctionValues,
}) => {
  return (
    <>
      <Field
        component={FormSelectField}
        name="processArchetype"
        margin="dense"
        fullWidth={true}
        disabled={false}
        disableOnSubmitting={true}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {processArchetypeValues.map(({ id, label }) => (
          <MenuItem value={id} key={id}>
            {label}
          </MenuItem>
        ))}
        <MenuItem value="__other">Other</MenuItem>
      </Field>
      <Field
        render={({ form }: FieldProps<DiagramFormValues>) =>
          form.values.processArchetype === "__other" ? (
            <Field
              component={FormTextField}
              name="processArchetypeOther"
              margin="dense"
              fullWidth={true}
              charMax={100}
              disabled={false}
            />
          ) : null
        }
      />

      <Field
        component={FormSelectField}
        name="primaryOwningFunction"
        margin="dense"
        fullWidth={true}
        disabled={false}
        disableOnSubmitting={true}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {primaryOwningFunctionValues.map(({ id, label }) => (
          <MenuItem value={id} key={id}>
            {label}
          </MenuItem>
        ))}
        <MenuItem value="__other">Other</MenuItem>
      </Field>
      <Field
        render={({ form }: FieldProps<DiagramFormValues>) =>
          form.values.primaryOwningFunction === "__other" ? (
            <Field
              component={FormTextField}
              name="primaryOwningFunctionOther"
              margin="dense"
              fullWidth={true}
              charMax={100}
              disabled={false}
            />
          ) : null
        }
      />
      <Field
        component={FormSelectField}
        name="structurePrimaryAxis"
        margin="dense"
        fullWidth={true}
        disabled={false}
        disableOnSubmitting={true}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {structurePrimaryAxisValues.map(({ id, label }) => (
          <MenuItem value={id} key={id}>
            {label}
          </MenuItem>
        ))}
        <MenuItem value="__other">Other</MenuItem>
      </Field>
      <Field
        render={({ form }: FieldProps<DiagramFormValues>) =>
          form.values.structurePrimaryAxis === "__other" ? (
            <Field
              component={FormTextField}
              name="structurePrimaryAxisOther"
              margin="dense"
              fullWidth={true}
              charMax={100}
              disabled={false}
            />
          ) : null
        }
      />
      <Field
        component={FormTextField}
        name="description"
        margin="dense"
        fullWidth={true}
        multiline={true}
        charMax={300}
        disabled={false}
      />
    </>
  );
};

export default Step2Fields;
