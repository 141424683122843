import React, { useRef, useState, useEffect } from "react";
import {
  CircularProgress,
  ClickAwayListener,
  makeStyles,
  Popper,
  Theme,
} from "@material-ui/core";
import classNames from "classnames";
import DiagramCell from "../DiagramCell";
import { IconAdd } from "../Icons";
import RACIButton from "../RACIButton";
import RACIMenu from "../RACIMenu";
import useUpdateDiagram from "../../hooks/useUpdateDiagram";
import { useDispatch } from "react-redux";
import { notifyError } from "../../store/notifications/actions";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  values: {
    margin: "0 5px",
    display: "inline-block",
  },
  valuesDisable: {
    opacity: 0.3,
  },
  hintBorder: {
    border: "1px dashed #ccc",
    borderRadius: "50%",
    padding: ".5em",
  },
  displayValues: {
    opacity: 1,
    transition: "opacity .3s",
  },
  displayValuesHide: {
    opacity: 0,
  },
}));

type Props = {
  active?: boolean;
  id: string;
  focused?: boolean;
  values: string[];
  hideValues?: boolean;
  options: Array<{
    color?: string;
    symbol?: string;
    id: string;
  }>;
  selectedRaciValue: string | null;
  showAdd?: boolean;
  roleId: string;
  processId: string;
};

export function DiagramAssignmentRACI({
  id,
  active,
  focused,
  values,
  hideValues,
  options,
  selectedRaciValue,
  showAdd,
  roleId,
  processId,
}: Props) {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [originalValues, setOriginalValues] = useState<string[]>(values);
  const { addAssignment, removeAssignment, updateSaving } = useUpdateDiagram();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  //whenever values change (drag and drop) set originalValues to values
  useEffect(
    () => {
      setOriginalValues(values);
    },
    [values]
  );

  function handleOpen() {
    setOpen((prevOpen) => !prevOpen);
  }

  function handleClose(target: EventTarget) {
    setOpen(false);
  }

  async function handleSelect(_id: string) {
    try {
      await addAssignment(processId, roleId, _id);
      setOriginalValues([...originalValues, _id]);
    } catch (e) {
      dispatch(notifyError(t("assignmentSaveError")));
    }
  }

  async function handleRemove(_id: string) {
    try {
      await removeAssignment(processId, roleId, _id);
      setOriginalValues(originalValues.filter((_) => _ !== _id));
    } catch (e) {
      dispatch(notifyError(t("assignmentSaveError")));
    }
  }

  return (
    <>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorRef.current}
        transition
        style={{ zIndex: 10000 }}
      >
        <ClickAwayListener onClickAway={(e) => handleClose(e.target)}>
          <div>
            <RACIMenu
              key={id}
              disabled={false}
              options={options}
              values={originalValues}
              onSelect={(assignmentID) => {
                handleSelect(assignmentID);
              }}
              onRemove={(assignmentID) => {
                handleRemove(assignmentID);
              }}
            />
          </div>
        </ClickAwayListener>
      </Popper>
      <DiagramCell
        id={id}
        active={active}
        focused={focused}
        onClick={handleOpen}
      >
        <div ref={anchorRef}>
          {updateSaving ? (
            <CircularProgress
              style={{ animationDuration: "450ms" }}
              size={40}
              color="secondary"
            />
          ) : originalValues.length > 0 ? (
            <div
              className={classNames(classes.displayValues, {
                [classes.displayValuesHide]: hideValues,
              })}
            >
              {options
                .filter(
                  (option) =>
                    option &&
                    option.id &&
                    originalValues.indexOf(option.id) > -1
                )
                .map((option, i) => {
                  if (option) {
                    const { symbol, color, id: assignmentId } = option;
                    return (
                      <RACIButton
                        key={i}
                        id={`${id}_${assignmentId}`}
                        color={color || "#999999"}
                        className={classNames(classes.values, {
                          [classes.valuesDisable]:
                            selectedRaciValue &&
                            selectedRaciValue !== assignmentId,
                        })}
                      >
                        {symbol}
                      </RACIButton>
                    );
                  }
                  return null;
                })}
            </div>
          ) : (
            showAdd && (
              <div className={classes.hintBorder}>
                <IconAdd style={{ display: "block" }} />
              </div>
            )
          )}
        </div>
      </DiagramCell>
    </>
  );
}
