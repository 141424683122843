import { gql } from "apollo-boost";

export const GET_CAPACITY_ROLES = gql`
  query GetCapacityRoles($orglabRoleID: String!) {
    Role(orglabRoleID: $orglabRoleID) {
      id
      capacity
      processes {
        name
      }
    }
  }
`;
