import { Box, Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import Lottie from "react-lottie";
import animationData from "./animation/coffeeSpill.json";

const PageError: React.SFC<{}> = () => (
  <Grid
    container={true}
    spacing={0}
    direction="column"
    alignItems="center"
    justify="center"
    style={{ minHeight: "100vh", textAlign: "center" }}
  >
    <div style={{ maxWidth: 400 }}>
      <div style={{ marginLeft: 80 }}>
        <Lottie
          options={{
            animationData,
            autoplay: true,
            loop: false,
            rendererSettings: { preserveAspectRatio: "xMidYMid slice" },
          }}
        />
      </div>
      <Typography variant="h6">Oops, something went wrong, our bad!</Typography>
      <Box mb={4} mt={1}>
        <Typography>Please try again. Still need help?</Typography>
      </Box>
      <Box mb={8}>
        <Button
          color="secondary"
          variant="contained"
          href="mailto:decisionlab-support@mckinsey.com?Subject=DecisionLab%20Support"
        >
          Email Decision Lab Support
        </Button>
      </Box>
    </div>
  </Grid>
);

export default PageError;
