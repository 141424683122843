import createSvgIcon from "@material-ui/icons/utils/createSvgIcon";
import React from "react";

export default createSvgIcon(
  <React.Fragment>
    <g transform="scale(0.5)">
      <path d="m 14,14 v 4 h 28 v -4 z m 0,20 H 42 V 30 H 14 Z m 8.064516,-8 H 42 V 22 H 22.064516 Z M 6,18 h 4 V 14 H 6 Z m 0,16 h 4 V 30 H 6 Z m 8,-8 h 4 v -4 h -4 z" />
      <path fill="none" d="M0 0h24v24H0z" />
    </g>
  </React.Fragment>,
  "Tree"
);
