import blueGrey from "@material-ui/core/colors/blueGrey";
import { createMuiTheme } from "@material-ui/core/styles";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const breakpoints = createBreakpoints({});

const contrastTextDark = "#263238";

export const accentColors = {
  error: "#930900",
  info: "#0091A6",
  warning: "#EB8E33",
};

export const secondaryColors = {
  dark: "#008094",
  light: "#33C6DC",
  main: "#00B8D4",
};

export const primaryColors = {
  dark: "#052A39",
  light: "#35708E",
  main: "#083C52",
};

export const insightDensityMapColors = {
  light: "#39BDF3",
  medium: "#027AB1",
  dark: "#034B6F",
};

const theme = createMuiTheme({
  spacing: 8,
  overrides: {
    MuiButton: {
      root: {
        fontWeight: 600,
        textTransform: "uppercase",
      },
    },
    MuiCheckbox: {
      root: {
        color: contrastTextDark,
        "&$checked": {
          color: contrastTextDark,
        },
      },
    },
    MuiChip: {
      root: {
        backgroundColor: blueGrey[50],
      },
    },
    MuiFormControl: {
      root: {
        fontSize: 14,
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: secondaryColors.main,
        },
        color: contrastTextDark,
        fontSize: 14,
      },
    },
    MuiInput: {
      root: {
        color: contrastTextDark,
        fontSize: 14,
      },
      underline: {
        "&:after": {
          borderBottom: "2px solid " + secondaryColors.main,
        },
        "&:hover:not($disabled):not($focused):not($error):before": {
          borderBottom: "2px solid " + secondaryColors.main,
        },
      },
    },
    MuiMenuItem: {
      root: {
        lineHeight: "1.5em",
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: 20,
      },
    },
    MuiTab: {
      root: {
        fontSize: 14,
        selected: {
          color: blueGrey[900],
        },
        fontWeight: 600,
        textTransform: "none",
      },
      textColorPrimary: {
        color: blueGrey[400],
      },
    },
    MuiTableRow: {
      root: {
        height: 48,
      },
    },
    MuiTableCell: {
      root: {
        padding: "0",
        fontSize: ".8125rem",
      },
    },
    MuiTypography: {
      body1: {
        fontSize: 14,
        color: contrastTextDark,
      },
      h4: {
        fontWeight: 100,
      },
      h6: {
        fontWeight: 400,
      },
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: blueGrey[50],
        borderBottomColor: secondaryColors.main,
        boxShadow: "none",
        color: contrastTextDark,
      },
    },
    MuiBadge: {
      badge: {
        top: -2,
        right: -2,
      },
    },
    MuiStepIcon: {
      root: {
        color: blueGrey[300],
        "&$active": {
          color: secondaryColors.main,
        },
        "&$completed": {
          color: secondaryColors.main,
        },
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(0, 0, 0, 0.2)",
      },
    },
  },
  palette: {
    background: {
      default: "#fff",
    },
    contrastThreshold: 3,
    error: {
      main: accentColors.error,
    },
    grey: blueGrey,
    primary: {
      contrastText: "#fff",
      dark: primaryColors.dark,
      light: primaryColors.light,
      main: primaryColors.main,
    },
    secondary: {
      contrastText: "#fff",
      dark: secondaryColors.dark,
      light: secondaryColors.light,
      main: secondaryColors.main,
    },
    text: {
      disabled: lighten(contrastTextDark, 0.38),
      primary: contrastTextDark,
      secondary: lighten(contrastTextDark, 0.54),
    },
    tonalOffset: 0.2,
    action: {
      selected: `${primaryColors.dark}11`,
      hover: `${primaryColors.dark}22`,
    },
  },
  shape: {
    borderRadius: 2,
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
    fontSize: 14,
    fontWeightMedium: 700,
  },
});

export const warningTheme = createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        fontWeight: 600,
        textTransform: "uppercase",
      },
    },
    MuiCheckbox: {
      root: {
        color: contrastTextDark,
        "&$checked": {
          color: contrastTextDark,
        },
      },
    },
  },
  palette: {
    primary: {
      main: primaryColors.main,
    },
    secondary: {
      main: accentColors.error,
    },
    text: {
      primary: primaryColors.main,
    },
  },
  shape: {
    borderRadius: 2,
  },
  typography: {
    body1: {
      color: blueGrey[900],
      fontSize: 14,
    },
    fontFamily: "Roboto, sans-serif",
  },
});

export const orgGraphColors = [
  "#9D6BC9",
  "#346DB6",
  "#84417A",
  "#EB8E33",
  "#EE95AA",
  "#3BB36C",
  "#EDBA20",
  "#AF154A",
  "#8BBA6A",
  "#006EB0",
  "#005428",
  "#D2D852",
  "#54A1D1",
  "#B84977",
  "#77B7BC",
  "#D64753",
  "#2B4D99",
  "#669B29",
  "#A93252",
  "#FFABD5",
  "#367266",
  "#474F4F",
  "#0A327A",
  "#F473AD",
  "#EA5A21",
  "#72401A",
  "#F7AD33",
  "#009688",
  "#E76D1C",
  "#7B7C7F",
  "#00753B",
  "#696CC1",
  "#BD6C3E",
  "#0091A6",
  "#9CCB3F",
  "#86424E",
  "#DC807F",
  "#66307C",
  "#BD93AA",
  "#770000",
  "#7D9AAA",
];

export default theme;
