import IconMore from "@material-ui/icons/MoreVert";
import React from "react";
import {
  IconAdd,
  IconArrowDropDown,
  IconArrowDropUp,
  IconAssign,
  IconFileUpload,
  IconInsights,
  IconList,
  IconNewDiagram,
  IconSubprocessLink,
  IconTree,
  IconViewSample,
} from "../Icons";
import createButton from "./createButton";
import createIconButton from "./createIconButton";

export const ButtonInsights = createButton({
  buttonProps: {
    color: "inherit",
  },
  icon: <IconInsights />,
  displayName: "Insights",
  text: "Insights",
});

export const ButtonCreateDiagram = createButton({
  buttonProps: {
    color: "secondary",
    variant: "contained",
  },
  displayName: "Create Diagram",
  icon: <IconNewDiagram />,
  text: "Create Your First Diagram",
});

export const ButtonToggleNudges = createButton({
  buttonProps: {
    color: "inherit",
  },
  displayName: "Nudges",
  text: "Nudges",
});

export const ButtonLoadSampleDiagram = createButton({
  buttonProps: {
    color: "secondary",
    variant: "contained",
  },
  displayName: "View Sample Diagram",
  icon: <IconViewSample />,
  text: "View a Sample Diagram",
});

export const ButtonImportDiagram = createButton({
  buttonProps: {
    color: "secondary",
    variant: "contained",
  },
  displayName: "Import Diagram",
  icon: <IconFileUpload />,
  text: "Import Diagram",
});

export const ButtonAddNew = createButton({
  fabProps: {
    "aria-label": "Add",
    color: "secondary",
  },
  displayName: "Add New",
  icon: <IconAdd />,
});

export const ButtonAddRow = createIconButton({
  buttonProps: {
    "aria-label": "Add",
  },
  displayName: "Add Row",
  icon: <IconAdd />,
});

export const ButtonCancel = createButton({
  buttonProps: {
    color: "secondary",
    role: "cancel",
  },
  displayName: "Cancel",
  text: "Cancel",
});

export const ButtonCreate = createButton({
  buttonProps: {
    color: "secondary",
    variant: "contained",
  },
  displayName: "Create",
  text: "Create",
});

export const ButtonUpdate = createButton({
  buttonProps: {
    color: "secondary",
    variant: "contained",
  },
  displayName: "Update",
  text: "Update",
});

export const ButtonSubmit = createButton({
  buttonProps: {
    color: "secondary",
    variant: "contained",
  },
  displayName: "Submit",
  text: "Submit",
});

export const ButtonOk = createButton({
  buttonProps: {
    color: "primary",
    variant: "contained",
    role: "submit",
  },
  displayName: "Ok",
  text: "Ok",
});

export const ButtonSubprocessLink = createButton({
  fabProps: {
    color: "secondary",
    size: "small",
  },
  displayName: "Subprocess Link",
  icon: <IconSubprocessLink />,
});

export const ButtonSignIn = createButton({
  buttonProps: {
    color: "secondary",
    variant: "contained",
  },
  displayName: "Sign In",
  text: "Sign In",
});

export const ButtonContinueGuest = createButton({
  buttonProps: {
    variant: "contained",
    role: "guestLogin",
  },
  displayName: "Continue as Guest",
  text: "Continue as Guest*",
});

export const ButtonMultiAssign = createButton({
  buttonProps: {
    color: "secondary",
    size: "small",
    variant: "contained",
  },
  displayName: "Multi-Assign",
  text: "Multi-Assign",
});

export const ButtonAssign = createButton({
  buttonProps: {
    color: "secondary",
    size: "small",
    variant: "contained",
  },
  displayName: "Assign",
  icon: <IconAssign />,
  text: "Assign",
});

export const ButtonAssignRole = createButton({
  buttonProps: {
    color: "secondary",
    size: "small",
    variant: "contained",
  },
  displayName: "AssignRole",
  text: "Assign Role",
});

export const ButtonDismiss = createButton({
  buttonProps: {
    color: "secondary",
    variant: "contained",
  },
  displayName: "Dismiss",
  text: "Dismiss",
});

export const ButtonClearAll = createButton({
  buttonProps: {
    color: "secondary",
    size: "small",
  },
  displayName: "ClearAll",
  text: "Clear All",
});

export const ButtonList = createIconButton({
  displayName: "List",
  icon: <IconList />,
});

export const ButtonTree = createIconButton({
  displayName: "Tree",
  icon: <IconTree />,
});

export const ButtonExpand = createIconButton({
  displayName: "Expand",
  icon: <IconArrowDropDown />,
});

export const ButtonCollapse = createIconButton({
  displayName: "Collapse",
  icon: <IconArrowDropUp />,
});

export const ButtonMenu = createIconButton({
  displayName: "Menu",
  icon: <IconMore />,
});
