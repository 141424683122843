import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import React from "react";

const styles = (theme: Theme) =>
  createStyles({
    buttons: {
      position: "absolute",
      right: theme.spacing(1),
      top: 0,
      transform: "translateY(-50%)",
    },
    root: {
      position: "relative",
      zIndex: 1100,
    },
  });

export type ButtonFloatingActionsInnerProps = WithStyles<typeof styles>;

export type ButtonFloatingActionsProps = ButtonFloatingActionsInnerProps;

const ButtonFloatingActions: React.SFC<ButtonFloatingActionsProps> = ({
  classes,
  children,
}) => (
  <div className={classes.root}>
    <div className={classes.buttons}>{children}</div>
  </div>
);

export default withStyles(styles)(ButtonFloatingActions);
