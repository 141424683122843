import React, { useEffect, useState } from "react";
import useGetDiagram from "../../hooks/useGetDiagram";
import useKeyPress from "../../hooks/useKeyPress";
import findAllDiagramPaths from "../../lib/findAllDiagramPaths";
import DisplayPossiblePaths from "./DisplayPossiblePaths";

const DubugPossiblePaths: React.SFC<{
  diagramId: string;
}> = ({ diagramId }) => {
  const keysPressed = useKeyPress();
  const [showPaths, setShowPaths] = useState(false);

  useEffect(
    () => {
      if (keysPressed.P && keysPressed.Control && keysPressed.Shift) {
        setShowPaths(!showPaths);
      }
    },
    [keysPressed]
  );
  const { diagram } = useGetDiagram(diagramId);
  if (diagram) {
    const allPaths = findAllDiagramPaths(diagram);

    if (!showPaths) { return null; }

    return <DisplayPossiblePaths diagram={diagram} paths={allPaths} />;
  }
  return null;
};

export default DubugPossiblePaths;
