import { Theme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import React, { ReactSVG, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useRouter from "use-react-router";
import useGetBreadcrumbs from "../../hooks/useGetBreadcrumbs";
import { isMobileDevice } from "../../lib/componentProps";
import { IconBreadcrumbSeparator, IconHome } from "../Icons";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "inline-flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "flex-start",
    overflow: "hidden",
    color: "#D1E0E3",
  },
  crumb: {
    fontFamily: theme.typography.fontFamily,
    transition: "all .3s",
    maxWidth: "6vw",
    cursor: "pointer",
    fontWeight: 700,
    fontSize: 12,
  },
  crumbActive: {
    maxWidth: "100%",
  },
  crumbContent: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    width: "100%",
  },
  separator: {
    margin: "0 4px",
    fontSize: 16,
    color: "#829AA8",
  },
  home: {
    fontSize: 18,
    marginRight: 6,
    color: "#829AA8",
  },
}));

const BreadcrumbsContainer: React.SFC = () => {
  const { history } = useRouter();
  const { data: path } = useGetBreadcrumbs();
  const [activeIndex, setActiveIndex] = useState<number | undefined>();

  return (
    <Breadcrumbs
      path={path}
      activeIndex={activeIndex}
      onMouseOut={i => {
        if (!isMobileDevice()) {
          setActiveIndex(path.length - 1);
        }
      }}
      onMouseOver={i => {
        if (!isMobileDevice()) {
          setActiveIndex(i);
        }
      }}
      onClick={i => {
        const url = process.env.PUBLIC_URL + `/rolematrix/${path[i].id}`;
        if (isMobileDevice()) {
          setActiveIndex(i);
          if (activeIndex === i) {
            history.push(url);
          }
        } else {
          history.push(url);
        }
      }}
    />
  );
};

interface Props {
  path: Array<{
    name: string;
    id: string;
  }>;
  onMouseOver: (i: number) => void;
  onMouseOut: (i: number) => void;
  onClick: (i: number) => void;
  activeIndex?: number;
}
export const Breadcrumbs: React.SFC<Props> = ({
  path,
  onMouseOut,
  onMouseOver,
  onClick,
  activeIndex,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Link to={process.env.PUBLIC_URL + "/"}>
        <IconHome className={classes.home} />
      </Link>
      {path.map(({ name }, i) => [
        i > 0 && (
          <IconBreadcrumbSeparator
            key="separator"
            className={classes.separator}
          />
        ),
        <div
          key={i}
          onMouseOver={() => onMouseOver(i)}
          onMouseOut={() => onMouseOut(i)}
          onClick={() => onClick(i)}
          className={classNames(classes.crumb, {
            [classes.crumbActive]:
              activeIndex === i ||
              (activeIndex === undefined && i === path.length - 1),
          })}
        >
          <div className={classes.crumbContent}>{name}</div>
        </div>,
      ])}
    </div>
  );
};

export default BreadcrumbsContainer;
