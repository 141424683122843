import { Tooltip, withStyles } from "@material-ui/core";

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    fontSize: 11,
    fontWeight: theme.typography.fontWeightRegular,
  },
}))(Tooltip);

export default LightTooltip;
