import axios, {
  AxiosError,
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import jsonapi from "jsonapi-serializer";
import _ from "lodash";
import { Store } from "redux";
import { logout } from "../../store/login/actions";
import { notifyError } from "../../store/notifications/actions";
import { State, StoreActions } from "../../store/types";
import { ClientsResponse } from "../../types/Clients";
import { OrglabFilters } from "../../types/OrglabFilters";
import {
  RoleResponse,
  RoleSearchApi,
  RolesSearchResponse,
} from "../../types/Roles";
import { airbrake } from "../airbrakeLogging";
import { getAuthToken, setAuthToken } from "../authToken";
import { IndustriesResponse, InvolvedRoleResponse, LoginResponse } from "./types";

const URL_BASE = process.env.REACT_APP_API_URL;

const PDApiContentType = "application/vnd.api+json";
// const PDApiContentType = "application/json";

const URL_LOGIN = "sessions";

interface InitialAppDataResponse {
  clients: ClientsResponse;
}

export const pdAxios = axios.create({
  baseURL: URL_BASE,
  headers: {
    common: {
      Accept: PDApiContentType,
      "Content-Type": PDApiContentType,
    },
  },
});

type Serializer = (type: string, attributes: string[], data: any) => string;

const pdclientSerializer: Serializer = (
  type: string,
  attributes: string[],
  data: any,
): string => {
  const s = new jsonapi.Serializer(type, {
    attributes,
    keyForAttribute: "camelCase",
  });
  const result = s.serialize(data);
  return result;
};

export class PDClient {
  public http: AxiosInstance;

  private serialize: Serializer;

  private store?: Store<State, StoreActions>;

  constructor(http: AxiosInstance, serialize: Serializer) {
    this.http = http;
    this.serialize = serialize;
    this.store = undefined;
    http.defaults.headers["content-type"] = PDApiContentType;
    http.defaults.headers.Accept = PDApiContentType;
    http.interceptors.response.use(
      (response: any) => {
        return response;
      },
      (error: AxiosError) => {
        airbrake.notify({
          error: "Error fetching from api",
          params: { error },
        });
        return Promise.reject(error);
      },
    );
  }

  public handleOrglabRequestError = (error: AxiosError) => {
    if (this.store) {
      if (error.response && error.response.status === 401) {
        this.store.dispatch(logout());
      } else {
        airbrake.notify({
          error: "Error fetching from api",
          params: { error },
        });
        this.store.dispatch(notifyError("Failed to load data from Orglab."));
      }
    }
    return Promise.reject(error);
  };

  public addProvider = (store: Store<State, StoreActions>) => {
    this.store = store;
    this.http.interceptors.request.use((request: AxiosRequestConfig) => {
      request.headers = {
        ...request.headers,
        authorization: `Bearer ${getAuthToken()}`,
      };
      return request;
    });
    this.http.interceptors.response.use((response: AxiosResponse) => {
      setAuthToken(
        _.get(response, "headers.auth-token"),
        _.get(response, "headers.client"),
      );
      return response;
    });
  };

  public login = (
    clientId: string,
    authToken: string = "",
  ): AxiosPromise<LoginResponse> => {
    return this.http.post(
      URL_LOGIN,
      this.serialize("user_credentials", ["clientId", "jwtToken"], {
        clientId,
        jwtToken: authToken,
      }),
    );
  };

  public refreshSession = (): AxiosPromise => {
    return this.http.get(`/session/refresh`);
  };

  public getVersion = (): Promise<{ version: string; authUrl: string }> =>
    this.http.get("/").then((response) => {
      return response.data;
    });

  public getFilters = (strucureId: string): Promise<OrglabFilters> => {
    return this.http
      .get(`/structures/${strucureId}/filters`)
      .then((response) => response.data.data)
      .catch(this.handleOrglabRequestError);
  };

  public getPhases = (client: string): Promise<ClientsResponse> => {
    return this.http
      .get(`/structures?client=${client}`)
      .then((response) => response.data[0])
      .catch(this.handleOrglabRequestError);
  };

  public getInitialAppData = (
    clientId: string,
  ): Promise<InitialAppDataResponse> => {
    return new Promise((resolve, reject) => {
      this.getPhases(clientId)
        .then((clients) => {
          resolve({ clients });
        })
        .catch(reject);
    });
  };

  public getRoles = (
    searchApi: RoleSearchApi,
  ): Promise<RolesSearchResponse> => {
    return this.http
      .post(`/olab/roles`, searchApi)
      .then((response) => response.data)
      .catch(this.handleOrglabRequestError);
  };

  public getRole = (
    orglabRoleIds: string,
    structureId: string,
  ): Promise<RoleResponse[]> => {
    return this.http
      .get<{ role?: RoleResponse[] }>(
        `/olab/${structureId}/role/${orglabRoleIds}`,
      )
      .then((response) => response.data.role || [])
      .catch(this.handleOrglabRequestError);
  };

  public getInvolvedRole = (roleId: string): Promise<InvolvedRoleResponse> => {
    return this.http
      .get(`/olab/involvedroles/${roleId}`)
      .then((response) => response.data)
      .catch(this.handleOrglabRequestError);
  };

  public getIndustries = (): Promise<IndustriesResponse[]> => {
    return this.http
      .get(`/olab/industries`)
      .then((response) => response.data)
      .catch(this.handleOrglabRequestError);
  };
}

export const pdClient = new PDClient(pdAxios, pdclientSerializer);
