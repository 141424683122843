import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useRouter from "use-react-router";
import { DesignSessionsState } from "../../store/designSessions/types";
import { confirm } from "../../store/modal/actions";
import { notifyError } from "../../store/notifications/actions";
import { State } from "../../store/types";
import { BaseStructureWithPath } from "../../types/Clients";
import { RoleResponse } from "../../types/Roles";
import useGetDiagram from "../useGetDiagram";
import { useGlobalToggleDiagramPath } from "../useToggleDiagramPath";
import { diagramToPptx, getRoles } from "./client";
import { toPowerpointRequestStructure } from "./converter";

export default function useExportPPTX() {
  const { match } = useRouter<{
    id: string;
  }>();
  const { diagram } = useGetDiagram(match.params.id);
  const dispatch = useDispatch();
  const { currentPath } = useGlobalToggleDiagramPath();
  const { t } = useTranslation();
  const { designSessions, structure } = useSelector<
    State,
    {
      designSessions: DesignSessionsState;
      structure?: BaseStructureWithPath;
    }
  >(state => ({
    designSessions: state.designSessions,
    structure:
      diagram && diagram.structureId
        ? state.designSessions.lookup[diagram.structureId]
        : undefined,
  }));

  function _export() {
    if (diagram) {
      const requestPPTXService = (orglabRoles: RoleResponse[]) => {
        return new Promise(resolve => {
          const pptxPayload = toPowerpointRequestStructure(
            diagram,
            designSessions.lookup,
            orglabRoles,
            currentPath // path
          );
          diagramToPptx(diagram.name || "PPTXExport", pptxPayload)
            .then(resolve)
            .catch(() => {
              dispatch(notifyError(t("powerpointExportError")));
              resolve();
            });
        });
      };

      const startExport = () => {
        return new Promise((resolve, reject) => {
          if (structure && structure.id && !structure.archived) {
            const orglabRoleIds = diagram.roles
              .filter(role => (role.orglabID ? true : false))
              .map(role => role.orglabID)
              .join(",");
            if (orglabRoleIds) {
              getRoles(orglabRoleIds, structure.id).then(orglabRoles => {
                requestPPTXService(orglabRoles).then(resolve);
              });
            } else {
              requestPPTXService([]).then(resolve);
            }
          } else {
            requestPPTXService([]).then(resolve);
          }
        });
      };

      dispatch(
        confirm({
          i18nKey: "exportPPTX",
          onConfirm: startExport,
        })
      );
    }
  }

  return [_export];
}
