import { useMutation } from "@apollo/react-hooks";
import { CreateOperationVariables } from "../../generated/CreateOperation";
import {
  NextOperationTypes,
  OperationTypes,
} from "../../generated/globalTypes";
import {
  CREATE_OPERATION,
  handleCreateOperationUpdate,
} from "../../graphql/mutation/createOperation";
import GET_DIAGRAM from "../../graphql/query/getDiagram";
import generateUUID from "../../lib/generateUUID";

export default function useCreateOperation(diagramID: string) {
  const [createOperation] = useMutation<any, CreateOperationVariables>(
    CREATE_OPERATION
  );

  function addTask(
    id: string,
    nextID: string,
    pathType: NextOperationTypes,
    cb: (id: string) => void
  ) {
    return addOperation(id, nextID, OperationTypes.task, pathType, cb);
  }

  function addDecision(
    id: string,
    nextID: string,
    pathType: NextOperationTypes,
    cb: (id: string) => void
  ) {
    return addOperation(id, nextID, OperationTypes.decision, pathType, cb);
  }

  function addOperation(
    id: string,
    nextID: string,
    type: OperationTypes,
    pathType: NextOperationTypes,
    cb: (id: string) => void
  ) {
    const newID = generateUUID();
    cb(newID);
    return createOperation({
      variables: {
        nextID,
        id,
        diagramID,
        type,
        pathType,
        newID,
      },
      optimisticResponse: {
        AddDiagramOperation: {
          __typename: "Operation",
          id: newID,
          name: null,
          type,
          nextOperation: (type === "task" && nextID) || null,
          nextOperationYes: (type === "decision" && nextID) || null,
          nextOperationNo: null,
          cycleTime: [],
          waitTime: [],
          repeats: false,
          dataElements: [],
          inputs: [],
          itSystems: [],
          outputs: [],
          painPoints: [],
          assignments: [],
          subProcess: null,
          subProcessName: "",
          comment: null,
          roleNotes: null,
          processes: null,
        },
      },
      refetchQueries: [
        {
          query: GET_DIAGRAM,
          variables: {
            id: diagramID,
          },
        },
      ],
      update: handleCreateOperationUpdate(diagramID, id, pathType),
    }).then(result => {
      return result.data.AddDiagramOperation.id;
    });
  }

  return { addTask, addDecision };
}
