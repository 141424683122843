export enum NudgeTypes {
  MULTIPLE_ASSIGNMENTS = "MULTIPLE_ASSIGNMENTS",
  MANY_PAIN_POINTS = "MANY_PAIN_POINTS",
  DUPLICATE_PROCESS_NAMES = "DUPLICATE_PROCESS_NAMES",
}

export interface NudgeMultipleAssignments {
  type: NudgeTypes.MULTIPLE_ASSIGNMENTS;
  processId: string;
  processName: string;
  column: number;
  rows: number[];
}

export interface NudgeManyPaintPoints {
  type: NudgeTypes.MANY_PAIN_POINTS;
}

export interface NudgeDuplicateProcessNames {
  type: NudgeTypes.DUPLICATE_PROCESS_NAMES;
  roleId: string;
}

export type NudgePosition =
  | NudgeMultipleAssignments
  | NudgeManyPaintPoints
  | NudgeDuplicateProcessNames;

export interface Nudge {
  message: string;
  canManage: boolean;
  adminMessage: (count: number) => string;
  type: NudgeTypes;
  id: string;
  positions: NudgePosition[];
}

export type NudgeResponse = Nudge[];
