import { WithTheme, withTheme } from "@material-ui/core";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import React, { Component } from "react";

interface ProcessColorsRenderProps {
  colors: {
    fill: string;
    stroke: string;
    textColor: string;
    numberColor: string;
    strokeWidth: number;
  };
}

type ProcessColorsInnerProps = WithTheme;

interface ProcessColorsOuterProps {
  active?: boolean;
  text: string;
  subtle?: boolean;
  highlight?: boolean;
  render: (props: ProcessColorsRenderProps) => React.ReactNode;
}

type ProcessColorsProps = ProcessColorsInnerProps & ProcessColorsOuterProps;

class ProcessColors extends Component<ProcessColorsProps> {
  public render() {
    const { active, text, theme, subtle, render, highlight } = this.props;
    const strokeWidth = highlight ? 12 : 6;

    const fill = highlight
      ? theme.palette.common.white
      : active
        ? theme.palette.primary.light
        : "#ECF0F1";

    const stroke = highlight
      ? theme.palette.secondary.main
      : subtle
        ? theme.palette.grey[100]
        : active
          ? theme.palette.primary.main
          : theme.palette.secondary.main;

    const textColor = highlight
      ? theme.palette.text.primary
      : active
        ? text
          ? theme.palette.common.white
          : theme.palette.common.white
        : text
          ? theme.palette.text.primary
          : lighten(theme.palette.text.primary, 0.58);

    const numberColor = highlight
      ? theme.palette.text.primary
      : active
        ? theme.palette.common.white
        : theme.palette.text.primary;

    return render({
      colors: { fill, stroke, textColor, numberColor, strokeWidth },
    });
  }
}

export default withTheme(ProcessColors);
