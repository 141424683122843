import { useSubscription } from "@apollo/react-hooks";
import {
  DiagramSubscription,
  DiagramSubscriptionVariables,
} from "../generated/DiagramSubscription";
import DIAGRAM_SUBSCRIPTION from "../graphql/subscriptions/DiagramSubscription";

function useDiagramSubscription(id: string) {
  const { loading } = useSubscription<
    DiagramSubscription,
    DiagramSubscriptionVariables
  >(DIAGRAM_SUBSCRIPTION, {
    variables: {
      id,
    },
  });

  return {
    loading,
  };
}

export default useDiagramSubscription;
