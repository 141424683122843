import {
  Box,
  createStyles,
  Divider,
  ListSubheader,
  makeStyles,
  MenuItem,
  Theme,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useExportPPTX from "../../hooks/useExportPPTX";
import useUserStatus from "../../hooks/useUserStatus";
import DialogKnowledgeCenter from "../DialogKnowledgeCenter/DialogKnowledgeCenter";
import MenuNavbar, { MenuNavbarButton } from "../MenuNavbar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuItem: {
      paddingLeft: "24px",
      whiteSpace: "normal",
      maxWidth: "251px",
    },
    subheader: {
      color: theme.palette.grey[400],
      cursor: "default",
      fontWeight: 700,
    },
  })
);

const DiagramMenu: React.SFC = () => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const { isMobile, isMcMuffin } = useUserStatus();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const [exportPPTX] = useExportPPTX();

  function toggleShowDialog() {
    setOpenDialog(!openDialog);
    handleClose();
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handlePPTExport() {
    exportPPTX();
    handleClose();
  }

  if (isMobile && !isMcMuffin) {
    return null;
  }

  return (
    <>
      <MenuNavbarButton
        aria-controls="diagram-menu"
        role="moreKebab"
        onClick={event => {
          setAnchorEl(event.currentTarget);
        }}
      >
        <Box p={2}>
          <MoreVertIcon />
        </Box>
      </MenuNavbarButton>
      <MenuNavbar
        id="diagram-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {[
          !isMobile && (
            <ListSubheader key={0} className={classes.subheader}>
              Export
            </ListSubheader>
          ),
          !isMobile && (
            <MenuItem
              key={1}
              className={classes.menuItem}
              onClick={handlePPTExport}
            >
              {t("Export Current View to PowerPoint")}
            </MenuItem>
          ),
          isMcMuffin && <Divider key={2} />,
          isMcMuffin && (
            <ListSubheader key={3} className={classes.subheader}>
              Resources
            </ListSubheader>
          ),
          isMcMuffin && (
            <MenuItem
              key={4}
              className={classes.menuItem}
              onClick={toggleShowDialog}
            >
              {t("Best Practices Knowledge Center")}
            </MenuItem>
          ),
        ]}
      </MenuNavbar>
      <DialogKnowledgeCenter
        open={openDialog}
        onShowDialog={toggleShowDialog}
      />
    </>
  );
};

export default DiagramMenu;
