import { connect, FormikContextType } from "formik";
import { debounce, isEqual } from "lodash";
import * as React from "react";

export type FormikFormProps = Pick<
  React.FormHTMLAttributes<HTMLFormElement>,
  Exclude<
    keyof React.FormHTMLAttributes<HTMLFormElement>,
    "onReset" | "onSubmit"
  >
>;

export interface FormikAutoSaveOuterProps {
  submitOnMount?: boolean;
  wait?: number;
}

export type FormikAutoSaveProps = FormikAutoSaveOuterProps & {
  formik: FormikContextType<any>;
};

class FormikAutoSave extends React.Component<FormikAutoSaveProps> {

  public commit = debounce(() => {
    this.props.formik.handleSubmit();
  }, this.props.wait);
  public componentDidMount() {
    this.props.submitOnMount && this.props.formik.handleSubmit();
  }

  public UNSAFE_componentWillReceiveProps(nextProps: FormikAutoSaveProps) {
    if (!isEqual(nextProps.formik.values, this.props.formik.values)) {
      this.commit();
    }
  }

  public render() {
    return null;
  }
}

export default connect<FormikAutoSaveOuterProps>(FormikAutoSave);
