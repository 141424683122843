import createSvgIcon from "@material-ui/icons/utils/createSvgIcon";
import React from "react";

export default createSvgIcon(
  <g
    id="Page-1"
    stroke="none"
    strokeWidth="2"
    fill="none"
    fillRule="evenodd"
    transform="scale(1) translate(0, 0)"
  >
    <g id="activity_active" stroke="currentColor">
      <path
        d="M12,4.06066017 L4.06378748,11.9968727 L12.1001856,19.9393213 L19.9424857,12.0031459 L12,4.06066017 Z"
        id="Triangle-2"
      />
    </g>
  </g>,
  "Diamond"
);
