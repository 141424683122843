import { useLazyQuery } from "@apollo/react-hooks";
import idx from "idx";
import { difference } from "lodash";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetInsightDiagrams,
  GetInsightDiagramsVariables,
} from "../../generated/GetInsightDiagrams";
import { GET_INSIGHT_DIAGRAMS } from "../../graphql/query/getInsightDiagrams";
import { State } from "../../store/types";
import useGetLogin from "../useGetLogin";
import useInsightActions from "./useInsightActions";

export interface InsightRole {
  id: string;
  name?: string;
  orglabID?: string;
}

export interface InsightDiagram {
  id: string;
  name: string;
  roles: InsightRole[];
}
export type InsightDiagrams = InsightDiagram[];
export type InsightRoles = InsightRole[];

export interface InvolvedDiagram {
  id: string;
  name: string;
}
export type InvolvedDiagrams = InvolvedDiagram[];

export default function useGetInsightDiagrams(
  diagramId: string,
  frameworkId: string
) {
  const { clientId } = useGetLogin();
  const [activeInsightTab, setActiveInsightTab] = useState(0);
  const { involvedDiagrams, involvedRoles } = useSelector((state: State) => ({
    involvedDiagrams: state.insights.involvedDiagrams,
    involvedRoles: state.insights.involvedRoles,
  }));
  const [getInsightDiagrams, { data, loading }] = useLazyQuery<
    GetInsightDiagrams,
    GetInsightDiagramsVariables
  >(GET_INSIGHT_DIAGRAMS, {
    fetchPolicy: "no-cache",
    onCompleted: () => {
      const currentDiagram = getDiagramById(diagramId);
      if (currentDiagram) {
        selectDiagrams([currentDiagram.id]);
      }
    },
  });
  const allDiagrams = (idx(data, _ => _.InsightsSelectedProcess) || []).map(
    diagram => ({
      id: diagram.id,
      name: diagram.name,
      roles: diagram.roles || [],
    })
  );

  const {
    removeDiagram,
    selectRole,
    toggleAllRoles,
    addDiagrams,
  } = useInsightActions();

  useEffect(
    () => {
      frameworkId &&
        getInsightDiagrams({
          variables: {
            clientId: clientId || "",
            frameworkId: frameworkId || "",
          },
        });
    },
    [frameworkId]
  );

  function getDiagramById(id: string | null) {
    return allDiagrams.find(_ => _.id === id);
  }

  function selectDiagrams(diagramIds: string[]) {
    const updatedDiagrams = allDiagrams.filter(({ id }) =>
      diagramIds.find(_ => _ === id)
    );
    const removeDiagrams = difference(
      involvedDiagrams,
      updatedDiagrams.map(_ => _.id)
    );
    if (removeDiagrams.length > 0) {
      removeInvolvedDiagram(removeDiagrams[0]);
    } else {
      addDiagrams(updatedDiagrams);
    }
  }

  function getInvolvedDiagrams() {
    const involved: Array<{ id: string; name: string }> = [];
    involvedDiagrams.forEach(diagramId => {
      const diagram = allDiagrams.find(({ id }) => id === diagramId);
      if (diagram) {
        involved.push({
          id: diagram.id,
          name: diagram.name,
        });
      }
    });
    return involved;
  }

  function removeInvolvedDiagram(diagramId: string) {
    const diagram = getDiagramById(diagramId);
    if (diagram) {
      removeDiagram(diagram);
    }
  }

  return {
    selectDiagrams,
    removeDiagram: removeInvolvedDiagram,
    selectRole,
    toggleAllRoles,
    allDiagrams,
    involvedDiagrams: getInvolvedDiagrams(),
    involvedRoles,
    loading,
    activeInsightTab,
    setActiveInsightTab,
  };
}
