import {
  GetInsightDiagrams_InsightsSelectedProcess,
  GetInsightDiagrams_InsightsSelectedProcess_roles,
} from "../../generated/GetInsightDiagrams";
import * as actions from "./actions";

export enum InsightsActionTypes {
  INSIGHTS_ADD_DIAGRAM = "INSIGHTS_ADD_DIAGRAM",
  INSIGHTS_ADD_DIAGRAMS = "INSIGHTS_ADD_DIAGRAMS",
  INSIGHTS_ADD_ROLES = "INSIGHTS_ADD_ROLES",
  INSIGHTS_REMOVE_DIAGRAM = "INSIGHTS_REMOVE_DIAGRAM",
  INSIGHTS_SELECT_ROLE = "INSIGHTS_SELECT_ROLE",
  INSIGHTS_TOGGLE_ALL_ROLES = "INSIGHTS_TOGGLE_ALL_ROLES",
  INSIGHTS_LOAD_ROLE_EMPLOYEE = "INSIGHTS_LOAD_ROLE_EMPLOYEE",
  INSIGHTS_RESET = "INSIGHTS_RESET",
}
export interface InsightsInvolvedRole {
  associatedRoles: Array<{
    roleId: string;
    processId: string;
    processName?: string | null;
    capacity?: number | null;
  }>;
  name?: string;
  orglabID?: string;
  employeeName?: string;
  layer?: number;
  location?: string;
  businessUnit?: string;
  function?: string;
  selected: boolean;
  loading: boolean;
}

export interface InsightsState {
  involvedDiagrams: string[];
  involvedRoles: InsightsInvolvedRole[];
}

export interface InsightsIncomingDiagram {
  id: string;
  name: string | null;
  roles: InsightsIncomingRole[];
}

export type InsightsIncomingDiagrams = InsightsIncomingDiagram[];

export interface InsightsIncomingRole {
  id: string;
  name: string | null;
  orglabID: string | null;
  capacity: number | null;
}

export type InsightsAction =
  | ReturnType<typeof actions.insightsAddDiagrams>
  | ReturnType<typeof actions.insightsAddRoles>
  | ReturnType<typeof actions.insightsRemoveDiagram>
  | ReturnType<typeof actions.insightsSelectRole>
  | ReturnType<typeof actions.insightsToggleAllRoles>
  | ReturnType<typeof actions.insightsLoadRoleEmployee>
  | ReturnType<typeof actions.insightsReset>;
