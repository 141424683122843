import _ from "lodash";
import { RoleResponse } from "../../types/Roles";

const BUSINESS_UNIT_CATEGORIES = ["sub business unit", "sub-business unit"];

const getRoleFieldData = (role: RoleResponse) => [
  {
    id: "positionId",
    label: "Position ID",
    value: role.cs_id,
  },
  {
    id: "roleTitle",
    label: "Role/Position Title",
    value: role.name,
  },
  {
    id: "roleDescription",
    label: "Description",
    value: role.description,
  },
  {
    id: "employeeName",
    label: "Employee Name",
    value: role.employee && role.employee.name,
  },
  {
    id: "businessUnit",
    label: "Business Unit",
    value: role.lookups
      .filter(({ category }) =>
        BUSINESS_UNIT_CATEGORIES.includes(category.toLowerCase())
      )
      .map(({ path }) => path)
      .join(", "),
  },
  {
    id: "function",
    label: "Function",
    value: role.function && role.function.path,
  },
  {
    id: "location",
    label: "Location",
    value: role.location && role.location.path,
  },
  {
    id: "archetype",
    label: "Archetype",
    value: role.archetype && role.archetype.name,
  },
];

export default getRoleFieldData;
