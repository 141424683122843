import {
  CustomTreeData,
  DataTypeProvider,
  IntegratedSelection,
  SelectionState,
  TreeDataState,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  TableHeaderRow,
  TableTreeColumn,
  VirtualTable,
} from "@devexpress/dx-react-grid-material-ui";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router";
import useGetFramework from "../../hooks/useGetFrameworks";
import { State } from "../../store/types";
import { ButtonAddNew } from "../Button";
import ButtonFloatingAction from "../ButtonFloatingAction";
import DialogToggle from "../DialogToggle";
import FormEditFramework from "../FormEditFramework";
import LoadingInline from "../LoadingInline";
import {
  FormatDate,
  FormatFrameworkActions,
} from "../PageDiagrams/TableFormatters";
import RACIButton from "../RACIButton";

const columns = [
  { name: "name", title: "Framework Name" },
  { name: "color", title: "Color" },
  { name: "exclusive", title: "Unique" },
  { name: "diagramCount", title: "Diagrams" },
  { name: "createdBy", title: "Creator" },
  { name: "creationDate", title: "Creation Date" },
  { name: "updatedBy", title: "Last Edited By" },
  { name: "updatedDate", title: "Last Edited Date" },
  { name: "actions", title: " " },
];

const getChildRows = (row: any, rootRows: any) => {
  if (row) {
    return _.isEmpty(row.children) ? null : row.children;
  }
  return rootRows;
};

const FormatColor = (props: DataTypeProvider.ValueFormatterProps) => {
  return props.row.color ? (
    <RACIButton color={props.row.color}>{props.row.symbol}</RACIButton>
  ) : null;
};

const FormatUnique = (props: DataTypeProvider.ValueFormatterProps) => {
  return props.row.exclusive ? <div>Yes</div> : null;
};

const EmptyIndent = () => null;

interface OwnProps {
  clientId: string | undefined;
}

const PageFrameworks: React.SFC<OwnProps> = () => {
  const { frameworks, loading } = useGetFramework();

  return (
    <React.Fragment>
      <ButtonFloatingAction>
        <Route
          render={({ location }) => (
            <DialogToggle
              title="Create Custom Framework"
              width={400}
              open={_.get(location, "state.createNewFramework")}
              render={({ handleOpen }) => <ButtonAddNew onClick={handleOpen} />}
              fullWidth={true}
              renderContent={({ handleClose }) => (
                <FormEditFramework
                  onSubmit={handleClose}
                  onCancel={handleClose}
                />
              )}
            />
          )}
        />
      </ButtonFloatingAction>

      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          height: "100%",
          position: "relative",
        }}
      >
        {loading ? (
          <LoadingInline />
        ) : (
          <Grid
            rows={loading ? [] : frameworks}
            columns={columns}
            rootComponent={(props) => (
              <Grid.Root {...props} style={{ height: "100%" }} />
            )}
          >
            <SelectionState />
            <TreeDataState />
            <CustomTreeData getChildRows={getChildRows} />
            <DataTypeProvider
              formatterComponent={FormatColor}
              for={["color"]}
            />
            <DataTypeProvider
              formatterComponent={FormatUnique}
              for={["exclusive"]}
            />
            <DataTypeProvider
              formatterComponent={FormatDate}
              for={["creationDate", "updatedDate"]}
            />
            <DataTypeProvider
              formatterComponent={FormatFrameworkActions}
              for={["actions"]}
            />
            <IntegratedSelection />
            <VirtualTable
              height="auto"
              columnExtensions={[
                { columnName: "name", width: 300 },
                { columnName: "color", width: 100 },
                { columnName: "exclusive", width: 100 },
                { columnName: "created_by", width: 150 },
                { columnName: "created_at", width: 150 },
                { columnName: "diagramCount", width: 80 },
                { columnName: "updated_by", width: 150 },
                { columnName: "updated_at", width: 150 },
                { columnName: "actions", width: 60 },
              ]}
            />
            <TableHeaderRow />
            <TableTreeColumn indentComponent={EmptyIndent} for="name" />
          </Grid>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state: State) => ({
  clientId: state.login.clientId,
});

export default connect(mapStateToProps)(PageFrameworks);
