import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route as BaseRoute, RouteProps } from "react-router-dom";
import { useLogin } from "../../hooks/useLogin";
import { getAuthToken } from "../../lib/authToken";
import { getClientNameFromJWT } from "../../store/login/utils";
import { State } from "../../store/types";
import LoadingScreen from "../LoadingScreen";

export default function RouteAuth({ children, ...rest }: RouteProps) {
  const { submitLogin } = useLogin();
  const [loadingLogin, setLoadingLogin] = useState(true);
  const { clientId } = useSelector<State, { clientId?: string }>((state) => ({
    clientId: state.login.clientId,
  }));
  const token = getAuthToken();
  const tokenClientName = getClientNameFromJWT(token);

  useEffect(() => {
    if (tokenClientName) {
      submitLogin(tokenClientName)
        .then(() => {
          setLoadingLogin(false);
        })
        .catch(() => {
          setLoadingLogin(false);
        });
    } else {
      setLoadingLogin(false);
    }
  }, []);

  if (loadingLogin) {
    return <LoadingScreen />;
  }

  return (
    <BaseRoute
      {...rest}
      render={() =>
        clientId && getAuthToken() ? children : <Redirect to={process.env.PUBLIC_URL + "/login"} />
      }
    />
  );
}
