import { useQuery } from "@apollo/react-hooks";
import idx from "idx";
import {
  GetCapacityRoles,
  GetCapacityRolesVariables,
} from "../../generated/GetCapacityRoles";
import { GET_CAPACITY_ROLES } from "../../graphql/query/getCapacityRoles";

export interface RolesForCapacity {
  name?: string;
  capacity: number;
  roleId: string;
}

export default function useGetRolesForCapacity(
  orglabRoleID: string
) {
  const { data, loading } = useQuery<
    GetCapacityRoles,
    GetCapacityRolesVariables
  >(GET_CAPACITY_ROLES, {
    fetchPolicy: "no-cache",
    variables: {
      orglabRoleID,
    },
  });

  const normalized = (idx(data, _ => _.Role) || []).reduce<RolesForCapacity[]>(
    (acc, curr) => {
      if (curr) {
        return [
          ...acc,
          {
            capacity: curr.capacity || 0,
            name: idx(curr, _ => _.processes[0].name) || "",
            roleId: curr.id,
          },
        ];
        // curr.processes
      }
      return acc;
    },
    []
  );

  return { data: normalized, loading };
}
