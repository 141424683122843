import blueGrey from "@material-ui/core/colors/blueGrey";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import React from "react";

const ShapeGatewayNo: React.SFC<SvgIconProps> = ({
  stroke,
  fill,
  color,
  ...props
}) => (
  <svg width="40px" height="37px" viewBox="0 0 40 37" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-122.000000, -27.000000)">
        <g transform="translate(123.000000, 28.000000)">
          <path
            d="M18.0952381,34.5454545 C24.7577208,34.5454545 31.3926414,28.7878788 38,17.2727273 C31.3926414,5.75757576 24.7577208,0 18.0952381,0 C8.10151405,0 0,7.73326341 0,17.2727273 C0,26.8121911 8.10151405,34.5454545 18.0952381,34.5454545 Z"
            stroke={blueGrey[100]}
            fill={blueGrey[50]}
          />
          <polygon
            id="N"
            fill="#00BBD3"
            points="23.2075424 23.3181818 20.6096547 23.3181818 15.5524332 15.9310065 15.5524332 23.3181818 12.9545455 23.3181818 12.9545455 12.0909091 15.5524332 12.0909091 20.6183143 19.4935065 20.6183143 12.0909091 23.2075424 12.0909091"
          />
        </g>
      </g>
    </g>
  </svg>
);

ShapeGatewayNo.defaultProps = {};

export default ShapeGatewayNo;
