import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { loadInitialData } from "../../store/login/actions";
import LoadingScreen from "../LoadingScreen";

interface AppLayoutDispatchProps {
  loadInitialData: () => Promise<any>;
}

export type AppLayoutInnerProps = RouteComponentProps<any> &
  AppLayoutDispatchProps;

export type AppLayoutProps = AppLayoutInnerProps;

class AppLayout extends Component<AppLayoutProps> {
  public state = {
    isLoading: false,
    render: false,
  };

  public componentDidMount() {
    this.handleInitialLoad();
  }
  public UNSAFE_componentWillUpdate(nextProps: AppLayoutProps) {
    const nextWelcome = _.get(nextProps.location.state, "welcome");
    const thisWelcome = _.get(this.props.location.state, "welcome");
    if (!_.isEqual(nextWelcome, thisWelcome) && nextWelcome === true) {
      this.handleInitialLoad();
    }
  }

  public handleInitialLoad = () => {
    this.setState({ isLoading: true });
    this.props
      .loadInitialData()
      .then(this.handleDoneLoading)
      .catch(this.handleDoneLoading);
    this.setState({ render: true });
  };

  public handleDoneLoading = () => {
    this.setState({ isLoading: false });
  };

  public render() {
    const { children } = this.props;

    if (this.state.isLoading) {
      return <LoadingScreen />;
    }

    if (!this.state.render) {
      return null;
    }

    return children;
  }
}

const enhance = compose<AppLayoutInnerProps, {}>(
  withRouter,
  connect(
    null,
    { loadInitialData }
  )
);

export default enhance(AppLayout);
