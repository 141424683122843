import { defaultTo } from "lodash";
import {
  DiagramComponentOperation,
  DiagramComponentRole,
} from "../../../hooks/useGetDiagram/types";
import { Nudge, NudgeTypes } from "../../../types/Nudges";

const checkForMultipleAssignments = (
  roles: DiagramComponentRole[],
  currentPath: DiagramComponentOperation[],
  assignmentId: string,
  assignmentSymbol: string,
  assignmentName: string,
  threshold: number,
  canManage: boolean
): Nudge => {
  const validateAssignments = currentPath
    .map((node, i) => {
      const rows: number[] = [];
      node.assignments.forEach(
        ({ roleId, accountabilityMethodologyValues }) => {
          if (accountabilityMethodologyValues.indexOf(assignmentId) > -1) {
            rows.push(roles.findIndex(r => r.id === roleId));
          }
        }
      );
      rows.sort();
      return {
        ...node,
        index: i,
        rows,
      };
    })
    .filter(({ rows }) => rows.length > threshold);

  return {
    message: `Multiple '${assignmentSymbol.toUpperCase()}' assignments associated with the following step(s):`,
    adminMessage: count => {
      return `Highlight when more than ${count} role is designated to "${assignmentName}" action/decision in a process step`;
    },
    type: NudgeTypes.MULTIPLE_ASSIGNMENTS,
    id: assignmentId,
    canManage,
    positions:
      validateAssignments.length >= 1
        ? validateAssignments.map(({ id, name, index, rows }) => ({
            processId: id,
            processName: defaultTo(name, "-"),
            column: index,
            rows,
            type: NudgeTypes.MULTIPLE_ASSIGNMENTS,
          }))
        : [],
  };
};

export default checkForMultipleAssignments;
