import React from "react";
import useUserStatus, { UserStatusState } from "../../hooks/useUserStatus";

interface UserStatusOuterProps {
  render(props: UserStatusState): React.ReactNode;
}

type UserStatusProps = UserStatusOuterProps;

const UserStatus: React.SFC<UserStatusProps> = ({ render }) => {
  const { isMobile, isMcMuffin } = useUserStatus();
  return (
    <>
      {render({
        isMobile,
        isMcMuffin,
      })}
    </>
  );
};

export default UserStatus;
