import { Box, Container, Grid, IconButton } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { find } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { DiagramComponentState } from "../../hooks/useGetDiagram/types";
import useGetInsightDensityMapData from "../../hooks/useGetInsightDensityMapData";
import useGetInsightDiagrams from "../../hooks/useGetInsightDiagrams";
import { insightsReset } from "../../store/insights/actions";
import { ButtonSubmit } from "../Button";
import ExpansionPanel from "../ExpansionPanel";
import { IconClose } from "../Icons";
import LoadingInline from "../LoadingInline";
import DecisionDensityMap from "./DecisionDensityMap";
import DecisionRightsLoad from "./DecisionRightsLoadDistribution";
import InvolvedRoles from "./InvolvedRoles";
import InvolvedRole from "./InvolvedRoles/InvolvedRole";
import PageInsightsSelectDiagrams from "./PageInsightsSelectDiagrams";
import PageInsightsTabs from "./PageInsightsTabs";
import TabCapacity from "./TabCapacity";
import TabLinkages from "./TabLinkages";
import TabTimeDistribution from "./TabTimeDistribution";

interface Props {
  getInsightDiagrams?: ReturnType<typeof useGetInsightDiagrams>;
  getDensityMapData?: ReturnType<typeof useGetInsightDensityMapData>;
  diagram: DiagramComponentState;
}

function PageInsights({
  diagram,
  getInsightDiagrams = useGetInsightDiagrams(
    diagram.id,
    diagram.accountabilityMethodology.id
  ),
  getDensityMapData = useGetInsightDensityMapData(
    diagram.accountabilityMethodology.accountabilityMethodologyValues
  ),
}: Props) {
  const {
    loading,
    allDiagrams,
    involvedRoles,
    involvedDiagrams,
    selectDiagrams,
    removeDiagram,
    selectRole,
    toggleAllRoles,
    activeInsightTab,
    setActiveInsightTab,
  } = getInsightDiagrams;

  const {
    data: densityMapData,
    loading: densityMapLoading,
    fetchData: fetchDensityMapData,
    maxCount,
  } = getDensityMapData;

  const allInvolvedRolesSelected =
    involvedRoles.filter((_) => _.selected).length === involvedRoles.length;

  const [canSubmitUpdates, setCanSubmitUpdates] = useState<boolean>(true);
  const accountabilityValues =
    diagram.accountabilityMethodology.accountabilityMethodologyValues;
  const dispatch = useDispatch();

  useEffect(() => setCanSubmitUpdates(true), [involvedRoles]);
  useEffect(
    () => () => {
      dispatch(insightsReset());
    },
    []
  );

  if (loading) {
    return <LoadingInline />;
  }

  return (
    <div style={{ height: "100%", overflow: "auto", background: grey[50] }}>
      <Grid item={true} style={{ position: "absolute", right: "0" }}>
        <IconButton
          component={Link}
          to={process.env.PUBLIC_URL + `/rolematrix/${diagram.id}`}
          key="close"
          aria-label="Close"
          color="inherit"
          style={{ marginRight: 8 }}
        >
          <IconClose />
        </IconButton>
      </Grid>
      <Container>
        <Box p={2}>
          <Box mb={1}>
            <PageInsightsSelectDiagrams
              currentDiagramId={diagram.id}
              allDiagrams={allDiagrams}
              selectedDiagrams={involvedDiagrams.map(({ id }) => id)}
              onChange={(e) => {
                selectDiagrams(e.target.value as string[]);
              }}
              onDelete={(diagramId) => {
                removeDiagram(diagramId);
              }}
            />
          </Box>
          <PageInsightsTabs
            onChange={(value) => setActiveInsightTab(value)}
            closeUrl={`/`}
            activeTab={activeInsightTab}
          />
        </Box>
      </Container>
      <Container>
        <Box p={2}>
          {activeInsightTab === 0 && (
            <>
              <InvolvedRoles
                onToggleAll={(on) => toggleAllRoles(on)}
                isAllSelected={allInvolvedRolesSelected}
              >
                {involvedRoles.map((role, index) => (
                  <Grid item={true} xs={3} key={index}>
                    <InvolvedRole
                      role={role}
                      isChecked={role.selected}
                      onRoleClick={() => selectRole(index)}
                    />
                  </Grid>
                ))}
              </InvolvedRoles>
              <div style={{ textAlign: "right", marginTop: 4 }}>
                <ButtonSubmit
                  disabled={!canSubmitUpdates}
                  text="Refresh Insights"
                  onClick={() => {
                    fetchDensityMapData();
                    setCanSubmitUpdates(false);
                  }}
                />
              </div>
              <ExpansionPanel
                header="Decision Rights Load Distribution"
                defaultExpanded={true}
                info="An aster plot that helps identify the decision-makers with the most and least decision-rights along the selected axis, as well as the median and average values for these decision-rights."
                content={
                  <DecisionRightsLoad
                    accountabilityValues={
                      diagram.accountabilityMethodology
                        .accountabilityMethodologyValues
                    }
                    densityMapData={densityMapData}
                    selectedProcesses={allDiagrams.filter(({ id }) =>
                      Boolean(find(involvedDiagrams, { id }))
                    )}
                  />
                }
              />
              <ExpansionPanel
                header="Decision Density Map"
                info={
                  <div>
                    A heat-map of the decision-rights of the (selected)
                    roles/decision-makers. The deeper the color, the stronger
                    the aggregation of decision rights.
                    <br />
                    <br />
                    Couple the insights from this chart, with those from the
                    Capacity Distribution chart, to uncover which of the
                    decision-makers are over-loaded or under-loaded with
                    decision-making responsibility.
                  </div>
                }
                content={
                  <DecisionDensityMap
                    maxCount={maxCount}
                    loading={densityMapLoading}
                    data={densityMapData}
                    accountabilityValues={accountabilityValues}
                  />
                }
              />
            </>
          )}
          {activeInsightTab === 1 && (
            <>
              <TabTimeDistribution involvedDiagrams={involvedDiagrams} />
              <TabCapacity
                involvedRoles={involvedRoles}
                toggleAllRoles={toggleAllRoles}
                allInvolvedRolesSelected={allInvolvedRolesSelected}
                selectRole={selectRole}
              />
            </>
          )}
          {activeInsightTab === 2 && (
            <TabLinkages
              involvedDiagrams={involvedDiagrams}
              involvedRoles={involvedRoles}
              accountabilityValues={accountabilityValues}
            />
          )}
        </Box>
      </Container>
    </div>
  );
}

export default PageInsights;
