import { makeStyles, Typography } from "@material-ui/core";
import HideIcon from "@material-ui/icons/VisibilityOff";
import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import getStructurePathFromLookup from "../../lib/getStructurePathFromLookup";
import { State } from "../../store/types";
import { BaseStructureWithPath } from "../../types/Clients";

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: theme.typography.fontFamily,
    display: "inline-flex",
    alignItems: "center",
  },
  archived: {
    fontStyle: "italic",
    textDecoration: "line-through",
    color: theme.palette.grey[400],
  },
  notFound: { color: "red", fontStyle: "italic" },
}));

interface DisplayStructurePathProps {
  structureId?: string;
}

interface StateProps {
  structure?: BaseStructureWithPath;
  structurePath?: string;
}

const DisplayStructurePath: React.SFC<DisplayStructurePathProps> = ({
  structureId,
}) => {
  const classes = useStyles();
  const { structure, structurePath } = useSelector<State, StateProps>(state => {
    return {
      structurePath: getStructurePathFromLookup(
        state.designSessions.lookup,
        structureId
      ),
      structure: structureId
        ? state.designSessions.lookup[structureId]
        : undefined,
    };
  });
  const archived = structure && structure.archived;

  return (
    <span
      className={classNames(classes.root, {
        [classes.archived]: archived,
        [classes.notFound]: !structure,
      })}
    >
      {archived && <HideIcon style={{ fontSize: 16, marginRight: 4 }} />}
      {structurePath}
    </span>
  );
};

export default DisplayStructurePath;
