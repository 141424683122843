import { Box, MenuItem, Select } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";

interface Props {
  diagrams: Array<{ name: string; id: string }>;
  value?: string;
  onChange: (value: string) => void;
}

export default function DiagramSelection({ diagrams, value, onChange }: Props) {
  return (
    <Select
      value={value}
      onChange={(event) => onChange(event.target.value as string)}
      IconComponent={() => <ExpandMoreIcon />}
    >
      {diagrams.map(({ id, name }) => <MenuItem value={id}>{name}</MenuItem>)}
    </Select>
  );
}
