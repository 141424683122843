import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import _ from "lodash";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import useCopyDiagram from "../../hooks/useCopyDiagram";
import useDeleteDiagram from "../../hooks/useDeleteDiagram";
import useDelinkDiagram from "../../hooks/useDelinkDiagram";
import { UseGetDiagramsItem } from "../../hooks/useGetDiagrams";
import { confirm } from "../../store/modal/actions";
import { ButtonMenu } from "../Button";
import DialogToggle from "../DialogToggle";
import FormDiagram from "../FormDiagram";
import FormDuplicateDiagram from "../FormDuplicateDiagram";

const MenuDiagramEdit: React.SFC<{
  diagram: UseGetDiagramsItem;
}> = props => {
  const { diagram } = props;
  const dispatch = useDispatch();
  const [deleteDiagram] = useDeleteDiagram();
  const [delinkFromParent] = useDelinkDiagram();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { copyDiagram, submitting } = useCopyDiagram();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDelinkFromParent = () => {
    if (diagram.parentId) {
      const parentDiagramName = diagram.parentName;
      dispatch(
        confirm({
          i18nKey: "delinkProcessFromParent",
          i18nOptions: {
            diagramName: diagram.name,
            parentDiagramName,
          },
          onConfirm: () => {
            if (diagram.parentId) {
              delinkFromParent(diagram.id, diagram.parentId);
            }
          },
        })
      );
    }
  };

  return (
    <div>
      <React.Fragment>
        <ButtonMenu onClick={handleClick} />
        <Menu
          anchorEl={anchorEl}
          keepMounted={true}
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
        >
          <DialogToggle
            render={({ handleOpen }) => (
              <MenuItem
                onClick={() => {
                  handleOpen();
                  handleCloseMenu();
                }}
              >
                Edit Info
              </MenuItem>
            )}
            renderContent={({ handleClose }) => (
              <FormDiagram
                diagramId={diagram.id}
                onAfterSubmit={() => {
                  handleClose();
                  handleCloseMenu();
                }}
                onCancel={() => {
                  handleClose();
                  handleCloseMenu();
                }}
                isEdit={true}
              />
            )}
          />

          <DialogToggle
            title="Duplicate Diagram"
            render={({ handleOpen }) => (
              <MenuItem onClick={handleOpen}>Duplicate</MenuItem>
            )}
            renderContent={({ handleClose }) => (
              <FormDuplicateDiagram
                submitting={Boolean(submitting)}
                onSubmit={() => {
                  copyDiagram(diagram.id);
                }}
                onCancel={() => {
                  handleCloseMenu();
                  handleClose();
                }}
                hasSubprocesses={diagram.children.length > 0}
              />
            )}
          />

          {diagram.parentId && (
            <MenuItem onClick={handleDelinkFromParent}>Delink</MenuItem>
          )}
          <Divider />

          <MenuItem
            onClick={() => {
              dispatch(
                confirm({
                  i18nKey: "deleteDiagram",
                  onConfirm: () => deleteDiagram(diagram.id),
                })
              );
              handleCloseMenu();
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </React.Fragment>
    </div>
  );
};

export default MenuDiagramEdit;
