import { Action, Plugin } from "@devexpress/dx-react-core";
import { TreeDataState } from "@devexpress/dx-react-grid";
import React from "react";

interface TreeStateContainerProps {
  localStorageKey: string;
}

interface TreeStateContainerState {
  expandedRowIds: Array<string | number>;
}

class TreeStateContainer extends React.Component<
  TreeStateContainerProps,
  TreeStateContainerState
> {
  constructor(props: TreeStateContainerProps) {
    super(props);

    const rowIds = localStorage.getItem(props.localStorageKey);

    this.state = {
      expandedRowIds: rowIds ? JSON.parse(rowIds) : [],
    };
  }

  public changeRowIds = (expandedRowIds: Array<string | number>) => {
    const { localStorageKey } = this.props;
    this.setState({ expandedRowIds });
    localStorage.setItem(localStorageKey, JSON.stringify(expandedRowIds));
  };

  public render() {
    const { expandedRowIds } = this.state;

    return (
      <Plugin name="TreeStateContainer">
        <TreeDataState
          expandedRowIds={expandedRowIds}
          onExpandedRowIdsChange={this.changeRowIds}
        />
        <Action name="changeRowIds" action={this.changeRowIds} />
      </Plugin>
    );
  }
}

export default TreeStateContainer;
