import React, { useRef } from "react";
import useDiagramInteractions from "../../hooks/useDiagramInteractions";

const NudgeScroll: React.SFC<{
  processIndex: number;
  roleIndex: number;
}> = ({ processIndex, roleIndex }) => {
  const { highlightColumn, highlightRows } = useDiagramInteractions();
  const element = useRef<HTMLInputElement>(null);

  if (
    processIndex === highlightColumn &&
    highlightRows.indexOf(roleIndex) === 0
  ) {
    if (element.current) {
      element.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }

  return (
    <>
      <div ref={element} />
    </>
  );
};

export default NudgeScroll;
