import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { notifyHide } from "../../store/notifications/actions";
import { getNotifications } from "../../store/selectors";
import * as handlers from "./handlers";
import Notifications from "./Notifications";

const mapStateToProps = (state: any) => ({
  messages: getNotifications(state),
});

const enhance = compose<any, any>(
  connect(
    mapStateToProps,
    { notifyHide }
  ),
  withHandlers(handlers)
);

export default enhance(Notifications);
