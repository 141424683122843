import gql from "graphql-tag";

const GET_DIAGRAM_TAGS = gql`
  query GetDiagramTags {
    ProcessArchetypeDropdown(orderBy: label_asc) {
      id
      label
    }
    StructurePrimaryAxisDropdown(orderBy: label_asc) {
      id
      label
    }
    IndustryDropdown(orderBy: label_asc) {
      id
      label
      children(orderBy: label_asc) {
        id
        label
      }
    }
    GeographyDropdown(orderBy: label_asc) {
      id
      label
    }
    PrimaryOwningFunctionDropdown(orderBy: label_asc) {
      id
      label
    }
  }
`;

export default GET_DIAGRAM_TAGS;
