import { AppBar, Box, Tab, Tabs } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import BallotIcon from "@material-ui/icons/Ballot";
import BuildIcon from "@material-ui/icons/Build";
import StarIcon from "@material-ui/icons/StarOutlined";
import React from "react";
import { useTranslation } from "react-i18next";

export const WelcomeContent: React.SFC<{
  isGuest?: boolean;
}> = ({ isGuest }) => {
  const [value, setValue] = React.useState<number>(0);
  const { t } = useTranslation("welcomeContent");
  const tConfig = { returnObjects: true };

  return (
    <div>
      <div style={{ padding: 24 }}>
        <Typography variant="h4" color="secondary">
          Welcome
        </Typography>
        <Typography>
          DecisionLab is a powerful, one-of-a-kind, digital product that enables
          digital problem-solving and collaboration, on decision-making and
          process design so McKinsey teams and clients can:
        </Typography>
        <Typography component="div">
          <ul>
            <li>
              Rapidly align on and visualize their key decision-making processes
              along with the roles involved in these processes
            </li>
            <li>
              Iterate on these processes to improve decision-rights and role
              clarity
            </li>
          </ul>
        </Typography>
      </div>
      <AppBar position="static" color="default">
        <Tabs
          centered={true}
          value={value}
          onChange={(_e, value) => setValue(value)}
        >
          <Tab value={0} label="What's New" icon={<StarIcon />} />
          <Tab value={1} label="Under-the-hood" icon={<BuildIcon />} />
          <Tab value={2} label="Additional Resources" icon={<BallotIcon />} />
        </Tabs>
      </AppBar>
      <Box p={3}>
        {value === 0 && (
          <Typography component="div" role="whatsNewText">
            <ul>
              {t<string[]>("whatsNew", tConfig).map((text, i) => (
                <li key={i}>{text}</li>
              ))}
            </ul>
          </Typography>
        )}
        {value === 1 && (
          <Typography component="div" role="underTheHoodText">
            <ul>
              {t<string[]>("underTheHood", tConfig).map((text, i) => (
                <li key={i}>{text}</li>
              ))}
            </ul>
          </Typography>
        )}
        {value === 2 && (
          <Typography component="div" role="additionalResourcesText">
            <ul>
              {t<string[]>("additionalResources", tConfig).map((text, i) => (
                <li key={i}>{text}</li>
              ))}
            </ul>
          </Typography>
        )}
      </Box>
    </div>
  );
};

export default WelcomeContent;
