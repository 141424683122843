import { Action } from "../types";
import { LoginState } from "./";
import * as actionTypes from "./actionTypes";

export const initialState = {
  client: "",
  dismissWelcome: false,
  isGuest: false,
  clientId: "",
  username: "",
  userEmail: "",
};

export default (
  state: LoginState = initialState,
  { type, payload }: Action<any>
) => {
  switch (type) {
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isGuest: false,
        clientId: payload.clientId,
        username: payload.username,
        client: payload.clientName,
        userEmail: payload.userEmail,
      };
    case actionTypes.LOGIN_GUEST:
      return {
        ...state,
        isGuest: true,
        clientId: "",
        username: "Guest",
        userEmail: "",
      };
    case actionTypes.LOGIN_FAILED:
      return {
        ...state,
        clientId: "",
        username: "",
        client: "",
        userEmail: "",
      };
    case actionTypes.LOGOUT:
      return {
        ...state,
        client: "",
        isGuest: false,
        clientId: "",
        username: "",
        userEmail: "",
      };
    default:
      return state;
  }
};
