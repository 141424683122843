import { keyBy } from "lodash";
import { DecisionState, OperationsState } from ".";
import { DiagramComponentOperation } from "../useGetDiagram/types";

export function getInitialDecisionState(
  operations: DiagramComponentOperation[],
  decisionState: DecisionState
) {
  const _initialDecisionState: DecisionState = {};

  operations.forEach(o => {
    if (o && o.nextOperationYes) {
      _initialDecisionState[o.id] = decisionState[o.id] || "yes";
    } else if (o && o.nextOperationNo) {
      _initialDecisionState[o.id] = decisionState[o.id] || "no";
    }
  });

  return _initialDecisionState;
}

export function getNewPath(
  operations: DiagramComponentOperation[],
  decisionState: DecisionState
) {
  const currentPath: DiagramComponentOperation[] = [];
  const operationLookup = keyBy(operations, "id");
  const startOperation = operations.find(o => o && o.type === "start");
  const endOperation = operations.find(o => o && o.type === "end");
  const foundOperations: { [key: string]: boolean } = {};

  if (startOperation) {
    findPath(startOperation);
  }

  function findPath(operation: DiagramComponentOperation) {
    if (foundOperations[operation.id]) {
      return;
    }
    currentPath.push(operation);
    foundOperations[operation.id] = true;
    if (
      (operation.type === "task" || operation.type === "start") &&
      operation.nextOperation
    ) {
      const next = operationLookup[operation.nextOperation];
      if (next) {
        findPath(next);
      }
    }
    if (operation.type === "decision") {
      if (decisionState[operation.id] === "yes") {
        if (operation.nextOperationYes) {
          const next = operationLookup[operation.nextOperationYes];
          if (next) {
            findPath(next);
          }
        } else if (endOperation) {
          findPath(endOperation);
        }
      }
      if (decisionState[operation.id] === "no") {
        if (operation.nextOperationNo) {
          const next = operationLookup[operation.nextOperationNo];
          if (next) {
            findPath(next);
          }
        } else if (endOperation) {
          findPath(endOperation);
        }
      }
    }
  }
  return currentPath;
}
