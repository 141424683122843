import { Box, makeStyles } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { createStyles, Theme } from "@material-ui/core/styles";
import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import useReactRouter from "use-react-router";
import { logout } from "../../store/login/actions";
import ActiveUser from "../ActiveUser";
import { ReactComponent as Logo } from "../App/logos/logo_full_light.svg";
import { IconArrowDropDown } from "../Icons";
import MenuNavbar, { MenuNavbarButton } from "../MenuNavbar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      width: 176,
      height: 40,
      display: "block",
    },
    activeUser: {
      borderBottom: "2px solid " + theme.palette.primary.dark,
    },
    arrowIcon: {
      marginRight: 8,
      alignSelf: "center",
    },
    buttonActive: {
      backgroundColor: "#184F67",
    },
  })
);

function MainMenu() {
  const { t } = useTranslation("translations");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { history } = useReactRouter();
  const classes = useStyles();
  const dispatch = useDispatch();

  function handleLogoutClick() {
    dispatch(logout());
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <MenuNavbarButton
        aria-controls="main-menu"
        role="mainMenu"
        aria-haspopup="true"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        className={classNames({
          [classes.buttonActive]: Boolean(anchorEl),
        })}
      >
        <Box px={2}>
          <Logo className={classes.logo} />
        </Box>
        <IconArrowDropDown className={classes.arrowIcon} />
      </MenuNavbarButton>
      <MenuNavbar
        id="main-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem disabled={true} classes={{ root: classes.activeUser }}>
          <ActiveUser />
        </MenuItem>
        <MenuItem
          onClick={() => {
            history.push(process.env.PUBLIC_URL + "/");
            handleClose();
          }}
        >
          {t("Home")}
        </MenuItem>
        <MenuItem onClick={handleLogoutClick}>{t("Logout")}</MenuItem>
      </MenuNavbar>
    </>
  );
}

export default MainMenu;
