import MenuItem from "@material-ui/core/MenuItem";
import { Field, FieldProps } from "formik";
import React from "react";
import {
  GetDiagramTags_GeographyDropdown,
  GetDiagramTags_IndustryDropdown,
} from "../../generated/GetDiagramTags";
import { IndustriesResponse } from "../../lib/apiClient/types";
import handleNumberInput, {
  handlePasteNumberInput,
} from "../../lib/handleNumberInput";
import { DiagramFormValues } from "../../types/DiagramForm";
import FormSelectField from "../FormSelectField";
import FormTextField from "../FormTextField";

interface Props {
  industryValues: IndustriesResponse[];
  geographyValues: GetDiagramTags_GeographyDropdown[];
}

const Step3Fields: React.SFC<Props> = ({ industryValues, geographyValues }) => (
  <>
    <Field
      component={FormSelectField}
      name="industryOrglabID"
      margin="dense"
      fullWidth={true}
      disabled={false}
      disableOnSubmitting={true}
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      {industryValues.map(({ id, name }) => [
        <MenuItem value={id} key={id}>
          {name}
        </MenuItem>,
      ])}
      <MenuItem value="__other">Other</MenuItem>
    </Field>
    <Field
      render={({ form }: FieldProps<DiagramFormValues>) =>
        form.values.industry === "__other" ? (
          <Field
            component={FormTextField}
            name="industryOther"
            margin="dense"
            fullWidth={true}
            charMax={100}
            disabled={false}
          />
        ) : null
      }
    />
    <Field
      component={FormSelectField}
      name="geography"
      margin="dense"
      fullWidth={true}
      disabled={false}
      disableOnSubmitting={true}
    >
      <MenuItem value="">
        <em>None</em>
      </MenuItem>
      {geographyValues.map(({ id, label }) => (
        <MenuItem value={id} key={id}>
          {label}
        </MenuItem>
      ))}
    </Field>
    <Field
      component={FormTextField}
      name="organizationSize"
      margin="dense"
      fullWidth={true}
      charMax={10}
      onKeyPress={handleNumberInput}
      onPaste={handlePasteNumberInput}
      disabled={false}
    />
  </>
);

export default Step3Fields;
