import { get, head } from "lodash";
import React, { useState } from "react";
import useGetDiagram from "../../../hooks/useGetDiagram";
import { InvolvedDiagrams } from "../../../hooks/useGetInsightDiagrams";
import ExpansionPanel from "../../ExpansionPanel";
import LoadingInline from "../../LoadingInline";
import DecisionTimeDistribution from "../DecisionTimeDistribution";
import DiagramSelection from "../DiagramSelection";

interface Props {
  involvedDiagrams: InvolvedDiagrams;
}

export default function TabTimeDistribution({ involvedDiagrams }: Props) {
  const [diagramId, setDiagramId] = useState(get(head(involvedDiagrams), "id"));
  const { diagram, loading } = useGetDiagram(diagramId || "");
  return (
    <ExpansionPanel
      defaultExpanded={true}
      header="Decision Time Distribution"
      info={
        <div>
          Represents the cascaded aggregation of the time taken to complete the
          (selected) decision-making process. Total Time is a summation of the
          Cycle Time (duration) and Wait Time of each step in the process.
          <br />
          <br />
          To view the “Yes” or “No” path from a decision point, simply click the
          “Y/N” circle associated with that decision point on the Y-axis.
        </div>
      }
      content={
        <div style={{ width: "100%" }}>
          <DiagramSelection
            value={diagramId}
            onChange={setDiagramId}
            diagrams={involvedDiagrams}
          />
          {loading ? (
            <LoadingInline />
          ) : (
            diagram && <DecisionTimeDistribution diagram={diagram} />
          )}
        </div>
      }
    />
  );
}
