import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

const styles = createStyles({
  root: {
    fontSize: "12px",
    lineHeight: "14px",
  },
});

type ProcessElementTextProps = WithStyles<typeof styles> & {
  textColor: string;
};

const ProcessElementText: React.SFC<ProcessElementTextProps> = ({
  classes,
  children,
  textColor,
}) => (
  <Typography
    className={classes.root}
    style={{
      color: textColor,
    }}
    variant="caption"
    component="div"
  >
    {children || "Label"}
  </Typography>
);

export default withStyles(styles)(ProcessElementText);
