import _ from "lodash";
import getRoleFieldData from "../../lib/getRoleFieldData";
import updateRoleWithPaths from "../../lib/updateRoleWithPaths";
import { DiagramFile } from "../../types/Diagram";
import {
  PPTFile,
  PPTHeadingLabel,
  PPTRow,
  PPTTaskNote,
} from "../../types/PPTExport";
import { RoleResponse } from "../../types/Roles";

import i18n from "../../config/i18n";
import { NextOperationTypes } from "../../generated/globalTypes";
import { getNextPathType } from "../../lib/diagramUtils";
import getStructurePathFromLookup from "../../lib/getStructurePathFromLookup";
import { DesignSessionsLookup } from "../../store/designSessions/types";
import {
  DiagramComponentFrameworkValues,
  DiagramComponentOperation,
  DiagramComponentRole,
  DiagramComponentState,
} from "../useGetDiagram/types";

type KeyMapFunctionString = (attr: string) => string;
type KeyMapFunctionArray = (attr: string[]) => string;

interface KeyMap {
  type: [number, KeyMapFunctionString];
  overview: [number, KeyMapFunctionArray];
  cycleTime: [number, KeyMapFunctionArray];
  waitTime: [number, KeyMapFunctionArray];
  roleNote: [number];
  comment: [number, KeyMapFunctionArray];
  repeats: [number, KeyMapFunctionString];
  dataElements: [number, KeyMapFunctionArray];
  inputs: [number, KeyMapFunctionArray];
  itSystems: [number, KeyMapFunctionArray];
  outputs: [number, KeyMapFunctionArray];
  painPoints: [number, KeyMapFunctionArray];
}

export const getDecisionFramrworkFromDiagram = (diagram: DiagramFile) => {
  return _.chain(diagram)
    .get("assignables", [])
    .map("symbol")
    .map((c) => c.toUpperCase())
    .join("")
    .value();
};

const appendTimeUnit = (unit: string) =>
  unit
    ? " " +
      i18n.t(unit, {
        ns: "timeConversion",
      })
    : "";

// Powerpoint attributes object template
const attributesToPowerpointObject = (
  operation: DiagramComponentOperation,
  roles: DiagramComponentRole[],
  assignables: DiagramComponentFrameworkValues
) => {
  // Map data keys to ordered key-value pair array
  const keyMap: KeyMap = {
    type: [0, (attr) => attr.substr(0, 1).toUpperCase() + attr.substr(1)],
    overview: [1, (attr) => attr.toString()],
    cycleTime: [2, (attr) => attr.toString()],
    waitTime: [3, (attr) => attr.toString()],
    roleNote: [4],
    comment: [5, (attr) => attr.toString()],
    repeats: [6, (attr) => (attr ? "Yes" : "N/A")],
    dataElements: [7, (attr) => attr.join(", ")],
    inputs: [8, (attr) => attr.join(", ")],
    itSystems: [9, (attr) => attr.join(", ")],
    outputs: [10, (attr) => attr.join(", ")],
    painPoints: [11, (attr) => attr.join(", ")],
  };

  const obj: PPTTaskNote[] = [
    { key: "Type", value: _.capitalize(operation.type || "") },
    { key: "Overview", value: "" },
    {
      key: "Duration",
      value: operation.cycleTime[0] + appendTimeUnit(operation.cycleTime[1]),
    },
    {
      key: "Wait Time",
      value: operation.waitTime[0] + appendTimeUnit(operation.waitTime[1]),
    },
    {
      key: "Responsibility Assignments",
      value: roles.map((role) => {
        const roleNote = role.roleNotes.find(
          (rn) => rn.operationId === operation.id
        );
        const roleNoteText = (roleNote && roleNote.text) || "N/A";
        const values = operation.assignments
          .filter((a) => a.roleId === role.id)
          .map((a) =>
            a.accountabilityMethodologyValues.map((id) => {
              const value = assignables.find((a) => a.id === id);
              return ((value && value.symbol) || "").toUpperCase();
            })
          )
          .join(",");

        return {
          key: `${role.name} (${values})`,
          value: roleNoteText,
        };
      }),
    },
    { key: "Comments", value: operation.comment || "" },
    { key: "Repeating Process", value: operation.repeats ? "Yes" : "N/A" },
    { key: "Data Elements", value: operation.dataElements.join(", ") },
    { key: "Inputs", value: operation.inputs.join(", ") },
    { key: "IT Systems", value: operation.itSystems.join(", ") },
    { key: "Outputs", value: operation.outputs.join(", ") },
    { key: "Pain Points", value: operation.painPoints.join(", ") },
  ];

  if (operation.type === "task") {
    if (obj[keyMap.repeats[0]].value === "N/A") {
      obj[keyMap.repeats[0]].value = "No";
    }
  }

  // Ew.
  if (operation.subProcess) {
    obj[keyMap.type[0]].value = "Subprocess";
    obj[keyMap.repeats[0]].value = "N/A"; // Make sure "repeats" is "N/A" for subprocesses.
  }

  return obj;
};

const roleAssignablesToRows = (
  path: DiagramComponentOperation[],
  role: DiagramComponentRole,
  assignables: DiagramComponentFrameworkValues
) => {
  return path.map((p) => {
    let allSymbols = "";
    p.assignments.forEach((a) => {
      if (role.id === a.roleId) {
        const symbols = a.accountabilityMethodologyValues
          .map((id) => {
            const assignable = assignables.find((a) => a.id === id);
            return assignable && _.capitalize(assignable.symbol);
          })
          .filter((s) => Boolean(s))
          .join(",");
        allSymbols = symbols;
      }
    });
    return allSymbols;
  });
};

const getHeadingLabels = (
  path: DiagramComponentOperation[],
  diagramRoles: DiagramComponentRole[],
  frameworkAssignables: DiagramComponentFrameworkValues
) => {
  return path
    .map((p, i) => {
      const nextOperation = path[i + 1];
      const pathType = nextOperation
        ? getNextPathType(p, nextOperation.id)
        : NextOperationTypes.nextOperation;

      let taskNotes: PPTTaskNote[] = [];
      taskNotes = attributesToPowerpointObject(
        p,
        diagramRoles,
        frameworkAssignables
      );
      const empties = [];
      for (let i = 0; taskNotes.length > i; i++) {
        if (taskNotes[i].value === "" || taskNotes[i].value === []) {
          empties.unshift(i);
        }
      }
      for (let i = 0; empties.length > i; i++) {
        taskNotes.splice(empties[i], 1);
      }

      const heading: PPTHeadingLabel = {
        text: p.name || "",
        textColor: "002960",
        backgroundColor: "CCCCCC",
        stepNumbertextColor: "FFFFFF",
        stepNumberBackgroundColor: "808080",
        taskNotes,
        shrinkText: false,
        decisionBubbleBackgroundColor: 'ECEFF1',
        decisionBubbleTextColor: '00B8D4',
        decisionBubbleBorderColor: '00B8D4',
      };

      // Links to a subprocess?
      if (
        p.type === "task" &&
        p.hasOwnProperty("subProcess") &&
        p.subProcess !== null &&
        typeof p.subProcess === "string" &&
        p.subProcess.length > 0
      ) {
        heading.subProcess = true;
      }

      if (p.type === "decision") {
        heading.shape = "DIAMOND";
        if (pathType === NextOperationTypes.nextOperationYes) {
          heading.conditionText = "Y";
        } else {
          heading.conditionText = "N";
          heading.decisionBubbleBorderColor = 'CFD8DC'
        }
      }
      return heading;
    })
    .slice(1, -1);
};

export const toPowerpointRequestStructure = (
  diagram: DiagramComponentState,
  designSessions: DesignSessionsLookup,
  orglabRoles: RoleResponse[],
  path: DiagramComponentOperation[]
): PPTFile => {
  const assignables =
    _.sortBy(diagram.accountabilityMethodology.accountabilityMethodologyValues, ['order']);

  const columnDividerLineColor = "00ADEF";
  const rows: PPTRow[] = diagram.roles.map((r) => {
    const orglabRoleData = orglabRoles.find(
      (orglabRole) => r.orglabID === orglabRole.id
    );
    const employeeName = _.get(orglabRoleData, `employee.name`);
    const roleLabel = [_.isEmpty(r.name) ? "-" : r.name];

    if (employeeName) {
      roleLabel.push(`(${employeeName})`);
    }

    return {
      label: roleLabel.join(" "),
      labelTextColor: "002960",
      rowBackgroundColor: "F2F2F2",
      roleShapeBackgroundColor: "002960",
      roleShapeTextColor: "FFFFFF",
      fieldBackgroundColor: r.orglabID
        ? "8DC63F"
        : r.name
          ? "00B8D4"
          : "F2F2F2",
      roles: roleAssignablesToRows(path.slice(1, -1), r, assignables),
      roleNotes: orglabRoleData
        ? getRoleFieldData(updateRoleWithPaths(orglabRoleData)).map((f) => ({
            key: f.label,
            value: f.value,
          }))
        : [],
    };
  });

  const mainSubTitleText = getStructurePathFromLookup(
    designSessions,
    diagram.structureId
  );

  return {
    title: {
      text: diagram.name || "",
      coords: [0.13, 0.25, 9.43, 0.34],
      textColor: "002960",
      mainSubTitleText,
      taskNotesSubTitleText: "Process Details",
      roleNotesSubTitleText: "Role Information",
    },
    headingLabels: getHeadingLabels(path, diagram.roles, assignables),
    legend: assignables.map((a) => ({
      symbol: a.symbol.toUpperCase(),
      text: a.name || "",
      symbolTextColor: "FFFFFF",
      symbolBackgroundColor: (a.color|| "002960").replace("#", ""),
      roleShapeBackgroundColor: (a.color || "002960").replace("#", ""),
      textColor: "000000",
    })),
    columnDividerLineColor,
    rows,
  };
};