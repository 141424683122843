import { scaleLinear, stack, stackOffsetNone } from "d3";
import { TIME_UNITS_NUM } from "../../../../config/constants";
import { OperationsState } from "../../../../hooks/useToggleDiagramPath";

export interface PathWithDays {
  operation: OperationsState;
  waitDays: number;
  cycleDays: number;
}

export type GanttPathData = PathWithDays & {
  waitWidth: number;
  cycleWidth: number;
  offset: number;
};

export default function useDecisionTimeDistribution(
  currentPath: OperationsState[],
) {
  const layerKeys = ["cycleDays", "waitDays"];
  const pathWithDays = currentPath
    .map((operation) => ({
      operation,
      waitDays: convertTimeUnitsToDays(operation.waitTime),
      cycleDays: convertTimeUnitsToDays(operation.cycleTime),
    }))
    .slice(1, currentPath.length - 1);
  const totals = calculateTimeTotals(pathWithDays);

  const xScale = scaleLinear()
    .rangeRound([0, 100])
    .domain([0, totals.totalDays])
    .nice();

  const [cycleLayers, waitLayers] = stack<PathWithDays>()
    .keys(layerKeys)
    .offset(stackOffsetNone)(pathWithDays);

  const plotData = pathWithDays.reduce<GanttPathData[]>((acc, item, i) => {
    const previousItem = acc[i - 1];
    const cycleLayer = cycleLayers[i];
    const waitLayer = waitLayers[i];
    const waitWidth = xScale(waitLayer[1]) - xScale(waitLayer[0]);
    const cycleWidth = xScale(cycleLayer[1]) - xScale(cycleLayer[0]);
    const offset = previousItem
      ? previousItem.cycleWidth + previousItem.waitWidth + previousItem.offset
      : 0;
    acc[i] = {
      ...item,
      waitWidth,
      cycleWidth,
      offset,
    };
    return acc;
  }, []);

  return {
    ...totals,
    plotData,
    xAxisScale: xScale.ticks(10),
  };
}

export function convertTimeUnitsToDays(timeObj: string[]): number {
  const unit = timeObj[1];
  const value = parseInt(timeObj[0]) || 0;

  switch (unit) {
    case TIME_UNITS_NUM[0]:
      return value / 86400;
    case TIME_UNITS_NUM[1]:
      return value / 1440;
    case TIME_UNITS_NUM[2]:
      return value / 24;
    case TIME_UNITS_NUM[3]:
      return value;
    case TIME_UNITS_NUM[4]:
      return value * 7;
    case TIME_UNITS_NUM[5]:
      return value * 30;
  }
  return 0;
}

export function calculateTimeTotals(
  path: Array<
    Partial<PathWithDays> & {
      cycleDays: number;
      waitDays: number;
    }
  >,
) {
  let totalDays = 0;
  let totalCycle = 0;
  let totalWait = 0;

  path.forEach(({ waitDays, cycleDays }) => {
    totalDays += cycleDays;
    totalDays += waitDays;
    totalCycle += cycleDays;
    totalWait += waitDays;
  });

  return {
    totalDays: Math.round(totalDays),
    totalCycle: Math.round(totalCycle),
    totalWait: Math.round(totalWait),
    totalCyclePercent: calculatePercent(totalCycle, totalDays),
    totalWaitPercent: calculatePercent(totalWait, totalDays),
  };
}

function calculatePercent(partial: number, total: number) {
  if (partial > 0) {
    return Math.round((partial / total) * 100);
  }
  return 0;
}
