import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './components/App';

const render = () => ReactDOM.render(<App />, document.getElementById('root'));

render();

if (module.hot && process.env.NODE_ENV !== 'production') {
  module.hot.accept('./components/App', render);
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then((regs) => {
    for (const reg of regs) reg.unregister();
  });
}

if ('caches' in window) {
  window.caches.keys().then((keys) => {
    for (const key of keys) caches.delete(key);
  });
}
