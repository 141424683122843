import { ClickAwayListener, Popover } from "@material-ui/core";
import { FieldProps } from "formik";
import React, { Fragment, SFC } from "react";
import { SketchPicker } from "react-color";

interface ColorPickerOuterProps {
  toggleOpen: boolean;
  handleShowColorPicker: () => void;
  anchor?: HTMLElement;
}

type ColorPickerProps = ColorPickerOuterProps & FieldProps;

const FormColorField: SFC<ColorPickerProps> = ({
  anchor,
  handleShowColorPicker,
  toggleOpen,
  field,
  form,
}) => {
  return (
    <Fragment>
      <Popover
        anchorEl={anchor}
        open={toggleOpen}
        anchorOrigin={{
          horizontal: "right",
          vertical: "center",
        }}
        transformOrigin={{
          horizontal: "left",
          vertical: "center",
        }}
      >
        <ClickAwayListener
          mouseEvent={"onClick"}
          onClickAway={() => handleShowColorPicker()}
          touchEvent={false}
        >
          <SketchPicker
            color={field.value}
            onChangeComplete={({ hex }) => form.setFieldValue(field.name, hex)}
            disableAlpha={true}
          />
        </ClickAwayListener>
      </Popover>
    </Fragment>
  );
};

export default FormColorField;
