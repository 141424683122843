import gql from "graphql-tag";

export const UPDATE_ROLE_SORT_ORDER = gql`
  mutation UpdateRoleSortOrder($processID: ID!, $roleOrder: [ID]!) {
    ChangeRoleOrder(processID: $processID, roleOrder: $roleOrder) {
      id
      roleOrder
    }
  }
`;
