import {
  TableColumnResizing,
  TableColumnWidthInfo,
} from "@devexpress/dx-react-grid";
import React from "react";

interface ColumnStateContainerProps {
  initialColumnWidths: ColumnStateContainerState["columnWidths"];
  localStorageKey: string;
}

interface ColumnStateContainerState {
  columnWidths: TableColumnWidthInfo[];
}

class ColumnStateContainer extends React.Component<
  ColumnStateContainerProps,
  ColumnStateContainerState
> {
  constructor(props: ColumnStateContainerProps) {
    super(props);

    const columnWidths = localStorage.getItem(props.localStorageKey);

    this.state = {
      columnWidths: columnWidths
        ? JSON.parse(columnWidths)
        : props.initialColumnWidths,
    };
  }

  public changeWidths = (
    columnWidths: ColumnStateContainerState["columnWidths"]
  ) => {
    const { localStorageKey } = this.props;
    this.setState({ columnWidths });
    localStorage.setItem(localStorageKey, JSON.stringify(columnWidths));
  };

  public render() {
    const { columnWidths } = this.state;

    return (
      <TableColumnResizing
        minColumnWidth={60}
        onColumnWidthsChange={this.changeWidths}
        columnWidths={columnWidths}
      />
    );
  }
}

export default ColumnStateContainer;
