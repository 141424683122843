import { find, memoize, range } from "lodash";
import { DiagramComponentState } from "../../hooks/useGetDiagram/types";

type Path = string[];

type Graph = string[][];

interface PathsProps {
  graph: Graph;
  from: string;
  to: string;
}

export default function findAllDiagramPaths(
  diagram: DiagramComponentState
): string[][] {
  const graph = findAllDiagramEdges(diagram);
  const startNode = find(diagram.operation, { type: "start" });
  const endNode = find(diagram.operation, { type: "end" });
  return startNode && endNode
    ? possiblePaths({ graph, from: startNode.id, to: endNode.id })
    : [];
}

export function findAllDiagramEdges(
  diagram: DiagramComponentState
): string[][] {
  const edges: string[][] = [];
  const endNode = find(diagram.operation, { type: "end" });

  (diagram.operation || []).forEach(operation => {
    const id = (operation && operation.id) || "";
    if (operation) {
      // console.log("operation", operation.type, operation.nextOperation);
      if (
        (operation.type === "task" || operation.type === "start") &&
        operation.nextOperation
      ) {
        edges.push([id, operation.nextOperation]);
      }

      if (operation.type === "decision") {
        if (operation.nextOperationYes) {
          edges.push([id, operation.nextOperationYes]);
        }
        if (operation.nextOperationNo) {
          edges.push([id, operation.nextOperationNo]);
        }

        if (endNode) {
          // console.log("end");
          if (!operation.nextOperationYes || !operation.nextOperationNo) {
            edges.push([id, endNode.id]);
          }
        }
      }
    }
  });
  return edges;
}

// https://codereview.stackexchange.com/questions/125331/finding-all-paths-between-nodes-in-a-graph

export function possiblePaths(
  { graph = [], from, to }: PathsProps,
  path: Path = []
) {
  const linkedNodes = memoize(nodes.bind(null, graph));
  return explore(from, to);

  function explore(currNode: string, to: string, paths: string[][] = []) {
    path.push(currNode);
    for (const linkedNode of linkedNodes(currNode)) {
      if (linkedNode === to) {
        const result: Path = path.slice(); // copy values
        result.push(to);
        paths.push(result);
        continue;
      }
      // do not re-explore edges
      if (!hasEdgeBeenFollowedInPath(currNode, linkedNode, path)) {
        explore(linkedNode, to, paths);
      }
    }
    path.pop(); // sub-graph fully explored
    return paths;
  }
}

/**
 * Get all nodes linked
 * to from `node`.
 */
function nodes(graph: Graph, node: string) {
  return graph.reduce((p, c) => {
    c[0] === node && p.push(c[1]);
    return p;
  }, []);
}

/**
 * Has an edge been followed
 * in the given path?
 */
function hasEdgeBeenFollowedInPath(from: string, to: string, path: Path) {
  return allIndices(path, from).some(i => path[i + 1] === to);
}

/**
 * Utility to get all indices of
 * values matching `val` in `arr`.
 */
function allIndices(arr: Path, val: string): number[] {
  return range(arr.length).filter(i => arr[i] === val);
}
