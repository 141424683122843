import { AppBar, Tab, Tabs } from "@material-ui/core";
import React from "react";
import { useLocation } from "react-router";
import { Link, Route, Switch } from "react-router-dom";
import PageDiagrams from "../PageDiagrams";
import PageFrameworks from "../PageFrameworks";

const PageHome: React.SFC = () => {
  const location = useLocation();

  return (
    <>
      <AppBar position="static" color="default">
        <Tabs
          value={location.pathname}
          indicatorColor="secondary"
          textColor="primary"
        >
          <Tab value={process.env.PUBLIC_URL + "/"} label="Diagrams" component={Link} to={process.env.PUBLIC_URL + "/"} />
          <Tab
            value={process.env.PUBLIC_URL + "/frameworks"}
            label="Frameworks"
            component={Link}
            to={process.env.PUBLIC_URL + "/frameworks"}
          />
        </Tabs>
      </AppBar>
      <Switch>
        <Route path={process.env.PUBLIC_URL + "/frameworks"} exact={true} component={PageFrameworks} />
        <Route path={process.env.PUBLIC_URL + "/"} component={PageDiagrams} />
      </Switch>
    </>
  );
};

export default PageHome;
