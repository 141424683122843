import gql from "graphql-tag";

const UPDATE_ROLE_NOTE = gql`
  mutation UpdateRoleNote(
    $roleNotes: [CreateBatchRoleNoteInput]!
    $operationID: ID!
  ) {
    UpdateRoleNote(roleNotes: $roleNotes, operationID: $operationID) {
      id
    }
  }
`;

export default UPDATE_ROLE_NOTE;
