import { useEffect, useState } from "react";

export default function useKeyPress() {
  // State for keeping track of whether key is pressed
  const [keysPressed, setKeysPressed] = useState<{ [key: string]: boolean }>(
    {}
  );

  // If pressed key is our target key then set to true
  function downHandler({ key }: KeyboardEvent) {
    setKeysPressed(state => ({
      ...state,
      [key]: true,
    }));
  }

  // If released key is our target key then set to false
  const upHandler = ({ key }: KeyboardEvent) => {
    setKeysPressed(state => ({
      ...state,
      [key]: false,
    }));
  };

  // Add event listeners
  useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return keysPressed;
}
