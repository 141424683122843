import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      // position: "absolute",
      // bottom: 32,
      // left: 32,
      position: "sticky",
      left: 0,
      padding: theme.spacing(1) * 3,
      paddingLeft: 40,
    },
    feedbackLinkText: {
      color: "rgba(38, 50, 56, 0.38)",
    },
    feedbackLink: {
      paddingLeft: 3,
      color: "rgba(38, 50, 56, 0.38)",
      "&:hover": {
        color: theme.palette.grey[500],
      },
    },
  });

type FeedbackLinkProps = WithStyles<typeof styles>;

class FeedbackLink extends React.Component<FeedbackLinkProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Typography component="div" className={classes.feedbackLinkText}>
          Have a question or suggestion? Share feedback by
          <a
            className={classes.feedbackLink}
            href="mailto:decisionlab-support@mckinsey.com?Subject=DecisionLab%20Feedback"
          >
            contacting us
          </a>
          .
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(FeedbackLink);
