import { Box } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import React, { Component } from "react";
import { useHistory } from "react-router";
import { Route, Switch } from "react-router-dom";
import useGetCurrentDiagramId from "../../hooks/useGetCurrentDiagramId";
import useGetDiagram from "../../hooks/useGetDiagram";
import Breadcrumbs from "../Breadcrumbs";
import { ButtonInsights } from "../Button";
import DiagramNudges from "../DiagramNudges";
import MainMenu from "../MainMenu";
import DiagramMenu from "../MenuDiagram/MenuDiagram";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      zIndex: 2,
    },
    appName: {
      color: theme.palette.primary.contrastText,
      textDecoration: "none",
    },
    logo: {
      display: "block",
      height: "48px",
      width: "64px",
    },
    main: {
      flex: 1,
      overflow: "hidden",
      paddingLeft: 32,
    },
    toolbar: {
      height: 56,
      marginTop: 0,
      width: "100%",
    },
  });

export type AppNavBarInnerProps = WithStyles<typeof styles>;

export type AppNavBarProps = AppNavBarInnerProps;

function InsightsNav() {
  const history = useHistory();
  const diagramID = useGetCurrentDiagramId();
  const { diagram } = useGetDiagram(diagramID);
  return diagram ? (
    <ButtonInsights
      onClick={() => history.push(process.env.PUBLIC_URL + `/rolematrix/${diagram.id}/insights`)}
    />
  ) : null;
}

class AppNavBar extends Component<AppNavBarProps> {
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar className={classes.toolbar} disableGutters={true}>
            <MainMenu />
            <div className={classes.main}>
              <Route
                path={process.env.PUBLIC_URL + "/rolematrix/:id/:slug?"}
                render={({ match }) => <Breadcrumbs key={match.params.id} />}
              />
            </div>
            <Switch>
              <Route
                exact={true}
                path={process.env.PUBLIC_URL + "/rolematrix/:id"}
                render={() => (
                  <>
                    <Box pr={2}>
                      <InsightsNav />
                    </Box>
                    <Box pr={2}>
                      <DiagramNudges />
                    </Box>
                    <DiagramMenu />
                  </>
                )}
              />
            </Switch>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default withStyles(styles)(AppNavBar);
