import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { NotificationsState } from "../../store/notifications/types";
import Notification from "../Notification";

const styles = () =>
  createStyles({
    margin: {
      margin: 2,
    },
    root: {
      "& .fade-enter": {
        opacity: 0.01,
        transition: "all 300ms",
      },
      "& .fade-enter.fade-enter-active": {
        opacity: 1,
      },
      "& .fade-exit": {
        height: 100,
        opacity: 1,
        transition: "all 300ms",
      },
      "& .fade-exit-active": {
        height: 0,
      },
      "& .fade-exit.fade-exit-active": {
        opacity: 0.01,
      },
      bottom: 0,
      left: "50%",
      position: "fixed",
      transform: "translateX(-50%)",
      width: 360,
      zIndex: 1000,
    },
  });

interface Props extends WithStyles<typeof styles> {
  messages: NotificationsState;
  handleClose: (uid: string) => any;
}

class Notifications extends React.Component<Props> {
  public render() {
    const { classes, messages, handleClose } = this.props;
    return (
      <div className={classes.root}>
        <TransitionGroup>
          {messages.map(
            ({ message, level, uid, actionButton, actionButtonText }) => (
              <CSSTransition
                key={uid}
                classNames="fade"
                timeout={{ enter: 300, exit: 300 }}
              >
                <Notification
                  onClose={handleClose(uid)}
                  variant={level}
                  className={classes.margin}
                  message={message}
                  key={uid}
                  actionButton={actionButton}
                  actionButtonText={actionButtonText}
                />
              </CSSTransition>
            )
          )}
        </TransitionGroup>
      </div>
    );
  }
}

export default withStyles(styles)<any>(Notifications);
