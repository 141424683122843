import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { ReduxActionType, State, ThunkExtraArguments } from "../types";
import { UserSetting, UserSettingsTypes } from "./types";

export const userSettingsSet = <
  K extends keyof UserSetting,
  V extends UserSetting[K]
>(
  userId: string,
  key: K,
  value: V
): ReduxActionType<
  UserSettingsTypes.USER_SETTINGS_SET,
  {
    userId: string;
    value: V;
    key: K;
  }
> => ({
  payload: {
    userId,
    value,
    key,
  },
  type: UserSettingsTypes.USER_SETTINGS_SET,
});

export const userSet = <K extends keyof UserSetting, V extends UserSetting[K]>(
  key: K,
  value: V
): ThunkAction<void, State, ThunkExtraArguments, AnyAction> => (
  dispatch,
  getState
) => {
  const { username } = getState().login;
  if (username) {
    dispatch(userSettingsSet(username, key, value));
  }
};
