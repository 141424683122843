import React from "react";
import { Offline, Online } from "react-detect-offline";
import { Route, Switch } from "react-router-dom";
import { DiagramInteractionsProvider } from "../../hooks/useDiagramInteractions/provider";
import { GlobalDiagramPathProvider } from "../../hooks/useToggleDiagramPath";
import AppNavBar from "../AppNavBar";
import AuthSessionOffline from "../AuthSessionOffline";
import AuthSessionTimer from "../AuthSessionTimer";
import PageDiagram from "../PageDiagram";
import PageError from "../PageError";
import PageHome from "../PageHome";
import PageLogin from "../PageLogin";
import RouteAuth from "../RouteAuth";

// Do not use polling as the npm package is buggy. It assumes
// that the browser doesn't support navigator.onLine. We don't
// need polling because it can use window.onoffline events
// const POLLING_CONFIG = { url: process.env.REACT_APP_API_URL };
const BASE_URL = process.env.PUBLIC_URL

function SessionManager() {
  return (
    <div>
      <Offline polling={false}>
        <AuthSessionOffline />
      </Offline>
      <Online polling={false}>
        <AuthSessionTimer />
      </Online>
    </div>
  );
}

export default function Routes() {
  return (
    <Switch>
      <Route path={BASE_URL + "/404"} exact={true} component={PageError} />
      <Route path={BASE_URL + "/login"}>
        <SessionManager />
        <PageLogin />
      </Route>
      <RouteAuth path={BASE_URL + "/"}>
        <GlobalDiagramPathProvider>
          <DiagramInteractionsProvider>
            <div
              style={{
                bottom: 0,
                display: "flex",
                flexDirection: "column",
                height: "100%",
                left: 0,
                position: "absolute",
                right: 0,
                top: 0,
                zIndex: 1,
              }}
            >
              <SessionManager />
              <AppNavBar />
              <Switch>
                <Route path={BASE_URL + "/rolematrix/:id"} component={PageDiagram} />
                <Route path={BASE_URL + "/"} component={PageHome} />
              </Switch>
            </div>
          </DiagramInteractionsProvider>
        </GlobalDiagramPathProvider>
      </RouteAuth>
    </Switch>
  );
}
