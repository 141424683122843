import { Tooltip } from "@material-ui/core";
import ButtonBase from "@material-ui/core/ButtonBase";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import React from "react";
import Truncate from "../Truncate";

interface RoleSearchItemOuterProps {
  roleName: string;
  employeeName: string;
  loading?: boolean;
  selected?: boolean;
  onClick?: () => void;
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      width: "100%",
      borderTop: `1px solid ${theme.palette.grey[100]}`,
      padding: theme.spacing(1),
      textAlign: "left",
      height: 40,
      justifyContent: "flex-start",
    },
    primaryText: {
      flex: 1,
      fontWeight: 700,
      width: "56%",
    },
    secondaryText: {
      flex: "none",
      color: theme.palette.grey[300],
      width: "40%",
    },
    selected: {
      backgroundColor: theme.palette.grey[50],
    },
  });

type RoleSearchItemProps = RoleSearchItemOuterProps & WithStyles<typeof styles>;

const RoleSearchItem: React.SFC<RoleSearchItemProps> = ({
  roleName,
  employeeName,
  classes,
  loading,
  selected = false,
  onClick,
}) => (
  <Tooltip
    disableFocusListener={true}
    disableTouchListener={true}
    title={
      <div>
        <strong>{roleName}</strong>
        <br />
        {employeeName}
      </div>
    }
  >
    <ButtonBase
      className={classNames(classes.root, {
        [classes.selected]: selected,
      })}
      disabled={loading}
      onClick={onClick}
    >
      {loading ? (
        <CircularProgress size={16} color="secondary" />
      ) : (
        <React.Fragment>
          <Typography component="div" className={classes.primaryText}>
            <Truncate maxWidth="100%">{roleName}</Truncate>
          </Typography>
          <Typography component="div" className={classes.secondaryText}>
            <Truncate maxWidth="100%">{employeeName}</Truncate>
          </Typography>
        </React.Fragment>
      )}
    </ButtonBase>
  </Tooltip>
);

export default withStyles(styles)(RoleSearchItem);
