import { makeStyles, styled, Typography } from "@material-ui/core";
import { scaleLinear } from "d3";
import { find } from "lodash";
import React from "react";
import { orgGraphColors } from "../../../config/theme";
import { InsightsInvolvedRole } from "../../../store/insights/type";
import TextOverflow from "../../TextOverflow";
import { ChartBar, ChartBarSpacer } from "../ChartBar";
import XAxis from "../DecisionTimeDistribution/XAxis";

interface Props {
  involvedRoles: InsightsInvolvedRole[];
}

interface DiagramLegend {
  processId: string;
  processName: string;
  color: string;
}

const useStyles = makeStyles({
  chart: {
    overflow: "hidden",
    width: 400,
    height: 300,
    borderLeft: "1px solid black",
    borderBottom: "1px solid black",
  },
  barContainer: {
    marginRight: 10,
    height: 50,
    width: 300,
    display: "flex",
  },
  bar: {
    height: 40,
    backgroundColor: "red",
  },
  tbody: {
    "& > tr > td:nth-child(2)": {
      transition: "all .2s",
    },
    "&:hover > tr > td:nth-child(2)": {
      opacity: 0.6,
    },
    "& > tr:hover > td:nth-child(2)": {
      opacity: 1,
    },
  },
});

export default function CapacityDistribution({ involvedRoles }: Props) {
  const classes = useStyles();
  let diagramArr: DiagramLegend[] = [];
  let capacityRealTotal = 100;
  let totalCapacityForVisualThing = 0;
  var colorCount = 0;

  const plotData = involvedRoles
    .filter((role) => {
      if (role.selected && role.orglabID) {
        return true;
      }
      return false;
    })
    .map((role) => {
      let capacityTotal = 0;
      return {
        ...role,
        associatedRoles: role.associatedRoles.map((assocRole, i) => {
          if (!find(diagramArr, { processId: assocRole.processId })) {
            diagramArr.push({
              processId: assocRole.processId,
              processName: assocRole.processName ? assocRole.processName : "",
              color: orgGraphColors[colorCount],
            });
            colorCount++;
          }
          capacityTotal += (assocRole && assocRole.capacity) || 0;
          totalCapacityForVisualThing += (assocRole && assocRole.capacity) || 0;
          if (capacityTotal > capacityRealTotal) {
            capacityRealTotal = capacityTotal;
          }
          return {
            ...assocRole,
          };
        }),
      };
    });

  const xScale = scaleLinear()
    .rangeRound([0, 100])
    .domain([0, capacityRealTotal])
    .nice();
  const xTicks = xScale.ticks();

  return (
    <div style={{ position: "relative", width: "100%" }}>
      {plotData.length > 0 ? (
        totalCapacityForVisualThing !== 0 ? (
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <tr>
              <StackedBarTD />
              <OuterProcesses>
                <InnerProcessesColumns>
                  {diagramArr.map((diagram, idx) => (
                    <div style={{ marginBottom: 4 }} key={idx}>
                      <StackedBarTD style={{ display: "flex" }}>
                        <div
                          style={{
                            width: 16,
                            height: 16,
                            backgroundColor: diagram.color,
                          }}
                        />
                        <div style={{ marginLeft: 4 }}>
                          {diagram.processName}
                        </div>
                      </StackedBarTD>
                    </div>
                  ))}
                </InnerProcessesColumns>
              </OuterProcesses>
            </tr>
            <tbody className={classes.tbody}>
              {plotData.map((fullRole, idx) => {
                var rowTotal = 0;
                return fullRole && fullRole.orglabID ? (
                  <tr key={idx}>
                    <StackedBarRoleViewTD>
                      <StackedBarRoleViewContainer>
                        <TextOverflow
                          maxLines={3}
                          style={{ textAlign: "right", flex: 1 }}
                        >
                          {fullRole.name}
                        </TextOverflow>
                        <div style={{ width: 16 }} />
                        <div
                          style={{
                            width: 5,
                            height: 1,
                            background: "black",
                          }}
                        />
                      </StackedBarRoleViewContainer>
                    </StackedBarRoleViewTD>
                    <StackedBarBarViewTD
                      style={{
                        backgroundSize: `${100 / (xTicks.length - 1)}% 10%`,
                      }}
                    >
                      {capacityRealTotal > 100 ? (
                        <StackedMaxHighlight
                          style={{
                            left: `${xScale(100)}%`,
                          }}
                          key={"hatch" + idx}
                        />
                      ) : null}
                      <StackedBarBars>
                        {fullRole.associatedRoles.map((role, i) => {
                          const diagramExists = find(diagramArr, {
                            processId: role.processId,
                          });

                          rowTotal +=
                            role && role.capacity
                              ? parseInt(role.capacity.toString())
                              : 0;

                          return role.capacity ? (
                            <ChartBar
                              height={20}
                              width={`${xScale(role.capacity)}%`}
                              backgroundColor={
                                diagramExists ? diagramExists.color : "#999"
                              }
                              key={i}
                            >
                              <StackedBarText variant={"subtitle2"}>
                                {role.capacity ? `${role.capacity}%` : null}
                              </StackedBarText>
                            </ChartBar>
                          ) : null;
                        })}
                      </StackedBarBars>
                    </StackedBarBarViewTD>
                    <StackedBarTotalTD>
                      <Typography
                        variant="caption"
                        style={{ fontWeight: "bold" }}
                      >
                        TOTAL: {rowTotal}%
                      </Typography>
                    </StackedBarTotalTD>
                  </tr>
                ) : null;
              })}
              <tr>
                <StackedBarTD />
                <StackedBarTD>
                  <XAxis ticks={xTicks} />
                </StackedBarTD>
              </tr>
            </tbody>
          </table>
        ) : (
          <div>
            <Typography>
              Add capacities for selected roles to view distribution.
            </Typography>
          </div>
        )
      ) : (
        <div>
          <Typography>
            Select one or more involved roles above to populate capacity
            distribution.
          </Typography>
        </div>
      )}
    </div>
  );
}

const OuterProcesses = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  marginBottom: 16,
});

const InnerProcessesColumns = styled("div")({
  columns: "2",
  padding: 8,
});

const StackedBarTD = styled("td")({
  padding: 0,
});

const StackedBarTotalTD = styled(StackedBarTD)(({ theme }) => ({
  width: 100,
  paddingLeft: 5,
}));

const StackedBarRoleViewTD = styled(StackedBarTD)(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: 5,
  paddingLeft: 0,
  paddingRight: 0,
  fontSize: 11,
  width: 160,
  borderRight: `1px solid ${theme.palette.grey[50]}`,
}));

const StackedBarRoleViewContainer = styled("div")({
  display: "flex",
  alignItems: "center",
});

const StackedBarBarViewTD = styled(StackedBarTD)(({ theme }) => ({
  padding: 0,
  backgroundPositionX: -1,
  backgroundImage: `linear-gradient(to right, ${
    theme.palette.grey[50]
  } 1px, transparent 1px)`,
  position: "relative",
}));

const StackedBarBars = styled("div")({
  display: "flex",
  alignItems: "center",
  transition: "all .1s",
  marginBottom: 8,
  position: "relative",
});

const StackedMaxHighlight = styled("div")({
  position: "absolute",
  top: 0,
  bottom: 0,
  right: 0,
  background: `url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAL0lEQVQYV2NkYGBg+P//vy+IRgaMIEFGRsbN6JJgCZBKdEm4BLokigSyJIYETBIABeokeXRNZHcAAAAASUVORK5CYII=)`,
  zIndex: 1,
  borderLeft: "1px solid red",
});

const StackedBarText = styled(Typography)({
  color: "white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  fontSize: 12,
});
