import createSvgIcon from "@material-ui/icons/utils/createSvgIcon";
import React from "react";

export default createSvgIcon(
  <g
    id="icon-chevron"
    stroke="none"
    strokeWidth="2"
    fill="none"
    fillRule="evenodd"
  >
    <g id="activity_active" stroke="currentColor">
      <path
        d="M4.97620873,6.75 L6.37620873,12 L4.97620873,17.25 L17.8237913,17.25 L19.2237913,12 L17.8237913,6.75 L4.97620873,6.75 Z"
        id="Rectangle"
      />
    </g>
  </g>,
  "Chevron"
);
