import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { OVERLAY_VALUES } from "../../config/constants";
import useDiagramInteractions from "../../hooks/useDiagramInteractions";
import { DiagramComponentOperation } from "../../hooks/useGetDiagram/types";
import { DiagramFiltersState } from "../../store/diagramFilters/types";
import { State } from "../../store/types";
import ActionButton from "../ActionButton";
import { IconChevron, IconDelete, IconDiamond } from "../Icons";
import ProcessElementDecision from "../ProcessElementDecision";
import ProcessElementTask from "../ProcessElementTask";
import {
  ShapeActionPath,
  ShapeGatewayNo,
  ShapeGatewayOff,
  ShapeGatewayYes,
} from "../Shapes";

const useStyles = makeStyles({
  actionPaths: {
    position: "absolute",
    height: 57,
    width: 14,
    top: 15,
    right: -14,
    zIndex: 3,
  },
  container: {
    position: "relative",
    display: "inline-block",
    marginTop: "50px",
    userSelect: "none",
    fontSize: 16,
  },
  taskButton: {
    position: "absolute",
    top: 8,
    right: "-27px",
    zIndex: 4,
  },
  decisionButton: {
    position: "absolute",
    top: 50,
    right: "-27px",
    zIndex: 4,
  },
  closeButton: {
    position: "absolute",
    top: "-40px",
    left: "50%",
    marginLeft: "-25px",
    zIndex: 4,
  },
  gateway: {
    position: "absolute",
    top: "44px",
    right: "-2px",
    marginTop: "-17px",
  },
  gatewayNo: {
    position: "absolute",
    right: 0,
    zIndex: 1,
  },
  gatewayYes: {
    position: "absolute",
    right: 0,
    zIndex: 1,
  },
  gatewayOff: {
    position: "absolute",
    right: 22,
    zIndex: 3,
  },
  gatewayOn: {
    zIndex: 2,
  },
  overlays: {
    display: "flex",
    height: "36px",
    justifyContent: "center",
    alignItems: "flex-end",
    marginLeft: "-30px",
  },
  sorting: {
    opacity: 0,
  },
});

interface ProcessElementProps {
  active?: boolean;
  operation: DiagramComponentOperation;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  onChangeDecision?: () => void;
  onAddDecision?: () => void;
  onAddTask?: () => void;
  onRemove?: () => void;
  countNumber: string;
  onSetAnalyticsTab?: () => void;
  isSorting?: boolean;
  processIndex: number;
  decisionHasYes: boolean;
  decisionHasNo: boolean;
  decisionIsYes: boolean;
  decisionIsNo: boolean;
  operationCount: number;
}

const ProcessElement: React.SFC<ProcessElementProps> = props => {
  const {
    active,
    operation,
    onClick,
    onChangeDecision,
    onAddDecision,
    onAddTask,
    onRemove,
    countNumber,
    onSetAnalyticsTab,
    isSorting,
    processIndex,
    decisionHasYes,
    decisionHasNo,
    decisionIsYes,
    decisionIsNo,
    operationCount,
  } = props;

  const classes = useStyles();
  const { selectedOverlays } = useSelector<
    State,
    {
      selectedOverlays: DiagramFiltersState["selectedOverlays"];
    }
  >(state => ({
    selectedOverlays: state.diagramFilters.selectedOverlays,
  }));
  const { highlightColumn } = useDiagramInteractions();
  const subprocessId = operation.subProcess || "";

  return (
    <div
      className={classNames(classes.container, {
        inactiveProcess: !active,
        [classes.sorting]: isSorting,
      })}
    >
      <div className="processElementClick">
        {operation.type === "task" ? (
          <ProcessElementTask
            text={operation.name || ""}
            active={active}
            countNumber={countNumber}
            onClick={onClick}
            linkTo={subprocessId && `${process.env.PUBLIC_URL}/rolematrix/${subprocessId}`}
            // subtle={processElement.parentState === "no"}
            subtle={false}
            repeats={Boolean(operation.repeats)}
            highlight={highlightColumn === processIndex}
          />
        ) : (
          <div>
            <ProcessElementDecision
              text={operation.name || ""}
              active={active}
              countNumber={countNumber}
              onClick={onClick}
              subtle={decisionIsNo}
              highlight={highlightColumn === processIndex}
            />
            <div onClick={onChangeDecision} className={classes.gateway}>
              {decisionHasYes &&
                decisionIsNo && (
                  <ShapeGatewayOff
                    className={classes.gatewayOff}
                    decisionType="yes"
                    style={{
                      top: -5,
                    }}
                  />
                )}
              {decisionHasNo &&
                decisionIsYes && (
                  <ShapeGatewayOff
                    className={classes.gatewayOff}
                    decisionType="no"
                    style={{
                      top: 27,
                    }}
                  />
                )}
              <ShapeGatewayNo
                className={classNames(classes.gatewayNo, {
                  [classes.gatewayOn]: decisionIsNo,
                })}
              />
              <ShapeGatewayYes
                className={classNames(classes.gatewayYes, {
                  [classes.gatewayOn]: decisionIsYes,
                })}
              />
            </div>
          </div>
        )}
      </div>
      <div className={classes.overlays} onClick={onSetAnalyticsTab}>
        {!isSorting &&
          OVERLAY_VALUES.map(({ id, Icon, color }) => {
            return selectedOverlays[id] && operation[id].join("").length > 0 ? (
              <Icon
                key={id}
                style={{
                  fontSize: "16px",
                  display: "block",
                  margin: "0 2px",
                  color,
                }}
              />
            ) : null;
          })}
      </div>
      {active && (
        <div>
          <ShapeActionPath className={classes.actionPaths} />
          {operationCount > 3 ? (
            <div className={classes.closeButton}>
              <ActionButton
                className="removeAssignmentButton"
                onClick={onRemove}
                style={{ width: 24, height: 24, marginTop: 10, marginLeft: 6 }}
              >
                <IconDelete />
              </ActionButton>
            </div>
          ) : null}

          <div className={classes.taskButton}>
            <ActionButton
              className="addTaskButton"
              onClick={onAddTask}
              style={{ width: 24, height: 24, marginRight: 2 }}
            >
              <IconChevron stroke="white" preserveAspectRatio="none" />
            </ActionButton>
          </div>
          <div className={classes.decisionButton}>
            <ActionButton
              onClick={onAddDecision}
              className="addDecisionButton"
              style={{ width: 24, height: 24, marginTop: 6, marginRight: 2 }}
            >
              <IconDiamond stroke="white" preserveAspectRatio="none" />
            </ActionButton>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProcessElement;
