import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { DiagramComponentFrameworkValues } from "../../../hooks/useGetDiagram/types";
import { GetInsightDensityMapResult } from "../../../hooks/useGetInsightDensityMapData";
import LoadingWindow from "../../LoadingWindow";
import DecisionDensityMapTable from "../DecisionDensityMapTable";
import DensityMapSlider from "../DensityMapSlider";

interface Props {
  data: GetInsightDensityMapResult[];
  loading: boolean;
  maxCount: number;
  accountabilityValues: DiagramComponentFrameworkValues;
}

export default function DecisionDensityMap({
  data,
  loading,
  maxCount,
  accountabilityValues,
}: Props) {
  const [densityMapThreshold, setDensityMapThreshold] = useState<number[]>([
    0,
    0,
  ]);

  useEffect(
    () => {
      const defaultMin = Math.round(maxCount / 3);
      const defaultMax = defaultMin * 2;
      setDensityMapThreshold([defaultMin, defaultMax]);
    },
    [data]
  );

  return data.length > 0 ? (
    <>
      {loading && <LoadingWindow />}
      <div style={{ width: "100%" }}>
        <Grid container={true} spacing={3}>
          <Grid item={true} xs={8} />
          <Grid item={true} xs={4}>
            <DensityMapSlider
              value={densityMapThreshold}
              onChange={setDensityMapThreshold}
              max={maxCount}
            />
          </Grid>
          <Grid item={true} xs={12}>
            <DecisionDensityMapTable
              colorCountThreshold={densityMapThreshold}
              data={data}
              accountabilityValues={accountabilityValues}
            />
          </Grid>
        </Grid>
      </div>
    </>
  ) : (
    <Typography>
      No data available. Please select one or more involved roles and click
      “Refresh Insights”.
    </Typography>
  );
}
