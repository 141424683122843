import { DataProxy } from "apollo-cache";
import { FetchResult } from "apollo-link";
import gql from "graphql-tag";
import idx from "idx";
import { GetDiagram } from "../../../generated/GetDiagram";
import GET_DIAGRAM from "../../query/getDiagram";

export const CREATE_ROLE = gql`
  mutation CreateRole($processID: ID!) {
    CreateNewRole(processID: $processID) {
      id
      name
      orglabID
      roleNotes {
        id
        text
        operation {
          id
          name
        }
      }
    }
  }
`;

export const handleCreateRoleUpdate = (diagramId: string) => (
  store: DataProxy,
  result: FetchResult
) => {
  const data = store.readQuery<GetDiagram>({
    query: GET_DIAGRAM,
    variables: {
      id: diagramId,
    },
  });

  const roles = idx(data, _ => _.Process[0].roles);
  const roleOrder = idx(data, _ => _.Process[0].roleOrder);

  if (roles && roleOrder && result.data) {
    const newRole = result.data.CreateNewRole;
    roles.push(newRole);
    roleOrder.push(newRole.id);
  }
  store.writeQuery({
    query: GET_DIAGRAM,
    data: { ...data, roles, roleOrder },
    variables: {
      id: diagramId,
    },
  });
};
