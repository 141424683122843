import LinearProgress from "@material-ui/core/LinearProgress";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { ReactComponent as Logo } from "../App/logos/logo_full_dark.svg";

const styles = () =>
  createStyles({
    loadingScreen: {
      background: "linear-gradient( #ffffff, #efefef)",
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    contentWrapper: {
      width: 300,
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    flex: {
      flex: 1,
    },
    logo: {
      maxWidth: 330,
      height: "auto",
      margin: "0 auto",
      marginBottom: 32,
      width: "100%",
    },
    progress: {
      marginBottom: 24,
    },
    subtitle1: {
      color: "#0B1E28",
    },
  });

type LoadingScreenProps = WithStyles<typeof styles>;

class LoadingScreen extends React.Component<LoadingScreenProps> {
  public state = {
    open: true,
  };
  public render() {
    const { classes } = this.props;
    return (
      <div className={classes.loadingScreen}>
        <div className={classes.contentWrapper}>
          <Logo className={classes.logo} />
          <LinearProgress color="secondary" className={classes.progress} />
          <Typography
            align="center"
            variant="subtitle1"
            className={classes.subtitle1}
          >
            Loading DecisionLab
          </Typography>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(LoadingScreen);
