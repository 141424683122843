import { ButtonBase, styled } from "@material-ui/core";
import React, { ReactNode } from "react";
import { NextOperationTypes } from "../../../generated/globalTypes";
import { OperationsState } from "../../../hooks/useToggleDiagramPath";
import { ShapeGatewayOff } from "../../Shapes";
import TextOverflow from "../../TextOverflow";

interface Props {
  operation: OperationsState;
  children?: ReactNode;
  reverse?: boolean;
  textAlign?: "right" | "left" | "center";
}
export default function ChartOperation({
  operation,
  children,
  reverse,
  textAlign = "right",
}: Props) {
  return (
    <OperationContainer
      style={{
        flexDirection: reverse ? "column-reverse" : "row",
      }}
    >
      <TextOverflow maxLines={3} style={{ textAlign, flex: 1 }}>
        {operation.name || "-"}
      </TextOverflow>
      <div style={{ width: 16, height: 16 }}>
        {operation.type === "decision" && (
          <GatewayButton
            onClick={() =>
              operation.toggleDecisionState && operation.toggleDecisionState()
            }
          >
            <ShapeGatewayOff
              decisionType={
                operation.nextPathType === NextOperationTypes.nextOperationYes
                  ? "yes"
                  : "no"
              }
            />
          </GatewayButton>
        )}
      </div>
      {children}
    </OperationContainer>
  );
}

const GatewayButton = styled(ButtonBase)({
  opacity: 0.8,
  transform: "scale(.8)",
});

const OperationContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  position: "relative",
});
