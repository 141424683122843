import gql from "graphql-tag";

export const CREATE_DIAGRAM = gql`
  mutation CreateDiagram(
    $accountabilityMethodologyID: ID!
    $name: String!
    $structureId: String
    $description: String
    $organizationSize: String
    $processArchetypeID: ID
    $processArchetypeOther: String
    $structurePrimaryAxisID: ID
    $structurePrimaryAxisOther: String
    $industryID: ID
    $industryOther: String
    $industryOrglabID: String
    $geographyID: ID
    $primaryOwningFunctionID: ID
    $primaryOwningFunctionOther: String
    $parentOperationID: ID
  ) {
    CreateNewDiagram(
      accountabilityMethodologyID: $accountabilityMethodologyID
      name: $name
      optional: {
        structureID: $structureId
        description: $description
        structurePrimaryAxisID: $structurePrimaryAxisID
        structurePrimaryAxisOther: $structurePrimaryAxisOther
        processArchetypeID: $processArchetypeID
        processArchetypeOther: $processArchetypeOther
        industryID: $industryID
        industryOther: $industryOther
        industryOrglabID: $industryOrglabID
        geographyID: $geographyID
        organizationSize: $organizationSize
        primaryOwningFunctionID: $primaryOwningFunctionID
        primaryOwningFunctionOther: $primaryOwningFunctionOther
        parentOperationID: $parentOperationID
      }
    ) {
      id
      name
    }
  }
`;
