import { State } from "../types";
import { getSelectedRACIFilter } from "./";

const getAssignmentRACIConnections = (state: State, props: any) => {
  const connects: string[] = [];
  const raciValue = getSelectedRACIFilter(state);
  props.currentPath.forEach(({ assignments }: any) => {
    if (assignments) {
      (assignments || []).forEach(
        ({ accountabilityMethodologyValues, operationId, roleId }: any) => {
          accountabilityMethodologyValues.forEach((assignmentId: any) => {
            if (assignmentId === raciValue) {
              connects.push(`${roleId}${operationId}_${raciValue}`);
            }
          });
        }
      );
    }
  });
  return connects;
};

export default getAssignmentRACIConnections;
