import { Box } from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import React, { ReactElement } from "react";
import LightTooltip from "../LightTooltip";

interface OwnProps {
  header?: string | ReactElement;
  content?: ReactElement;
  defaultExpanded?: boolean;
  info?: string | ReactElement;
}

const CustomExpansionPanel: React.SFC<OwnProps> = ({
  header,
  content,
  defaultExpanded,
  info,
}) => {
  return (
    <Box paddingTop={2}>
      <ExpansionPanel
        square={true}
        elevation={2}
        defaultExpanded={defaultExpanded}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            variant={"h6"}
            color="secondary"
            style={{ display: "flex", alignItems: "center" }}
          >
            {header}
            {info && (
              <LightTooltip title={info}>
                <Box pl={1}>
                  <InfoOutlinedIcon />
                </Box>
              </LightTooltip>
            )}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>{content}</ExpansionPanelDetails>
      </ExpansionPanel>
    </Box>
  );
};

export default CustomExpansionPanel;
