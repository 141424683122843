import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAuthToken } from "../../lib/authToken";
import { login, logout } from "../../store/login/actions";
import { getUserNameFromJWT } from "../../store/login/utils";
import { confirm } from "../../store/modal/actions";
import { State } from "../../store/types";
import { userSet } from "../../store/userSettings/actions";
import useReduxDispatch from "../useReduxDispatch";

export function useLogin() {
  const dispatch = useReduxDispatch();
  const username = getUserNameFromJWT(getAuthToken());
  const history = useHistory();
  const { dismissConfirmation } = useSelector<
    State,
    {
      dismissConfirmation: boolean;
    }
  >(({ userSettings, appVersion }) => ({
    dismissConfirmation: Boolean(
      userSettings[username] && userSettings[username].dismissSignInConfirmation
    ),
  }));

  return {
    submitLogin: async (clientName: string) => {
      return dispatch(login(clientName)).then(() => {
        if (dismissConfirmation) {
          history.push(process.env.PUBLIC_URL + "/", { welcome: true });
        } else {
          dispatch(
            confirm({
              i18nKey: "signIn",
              noShowAgainOption: true,
              onCancel: () => {
                dispatch(logout());
              },
              onConfirm: ({ noShowAgain }: any) => {
                history.push(process.env.PUBLIC_URL + "/", { welcome: true });
                if (noShowAgain) {
                  dispatch(userSet("dismissSignInConfirmation", true));
                }
              },
            })
          );
        }
      }).catch(() => {
        dispatch(logout())
      });
    },
  };
}
