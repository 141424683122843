// import { split } from "apollo-link";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { HttpLink } from "apollo-link-http";
// import { WebSocketLink } from "apollo-link-ws";
// import { getMainDefinition } from "apollo-utilities";
import { matchPath } from "react-router";
import { airbrake } from "../../lib/airbrakeLogging";
import { store } from "../../store";
import { notifyError } from "../../store/notifications/actions";

// Disable WS for now because we don't have it setup server side
// const wsurl = `${process.env.REACT_APP_WS_URL}`;
const httpurl = `${process.env.REACT_APP_API_URL}/graphql`;

// const wsLink = new WebSocketLink({
//   uri: wsurl,
//   options: {
//     reconnect: true,
//     lazy: true,
//     connectionParams: () => ({
//       authorization: "Bearer " + localStorage.getItem("dlToken"),
//       client: localStorage.getItem("client"),
//     }),
//   },
// });

const httpLink = new HttpLink({
  uri: httpurl,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("dlToken");
  const client = localStorage.getItem("client");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      client: client ? client : "",
      diagramId: getDiagramIdForHeaders(),
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    // graphql errors here?
  }
  if (networkError) {
    airbrake.notify({
      error: "Error fetching from api",
      params: { error: networkError },
    });
    store.dispatch(notifyError("Failed to load data from Orglab."));
  }
});

// const link = split(
//   ({ query }) => {
//     const definition = getMainDefinition(query);
//     return (
//       definition.kind === "OperationDefinition" &&
//       definition.operation === "subscription"
//     );
//   },
//   wsLink,
//   httpLink
// );

export default authLink.concat(httpLink).concat(errorLink);

function getDiagramIdForHeaders(): string | undefined {
  const match = matchPath<{ id: string }>(window.location.pathname, {
    path: process.env.PUBLIC_URL + "/rolematrix/:id",
  });
  if (match) {
    return match.params.id;
  }
  return;
}
