import { Reducer } from "redux";
import {
  RoleSearchAction,
  RoleSearchActionTypes,
  RoleSearchState,
} from "./types";

export const initialState: RoleSearchState = {};

const reducer: Reducer<RoleSearchState, RoleSearchAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case RoleSearchActionTypes.ROLE_SEARCH_START:
      return action.payload;
    case RoleSearchActionTypes.ROLE_SEARCH_END:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
