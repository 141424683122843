import { Reducer } from "redux";
import {
  DiagramFilterAction,
  DiagramFiltersActionTypes,
  DiagramFiltersState,
} from "./types";

export const initialState: DiagramFiltersState = {
  selectedOverlays: {},
  selectedRACIValue: null,
};

const diagramFilters: Reducer<DiagramFiltersState, DiagramFilterAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case DiagramFiltersActionTypes.DIAGRAM_FILTER_TOGGLE_OVERLAY:
      return {
        ...state,
        selectedOverlays: {
          ...state.selectedOverlays,
          [action.payload]: state.selectedOverlays[action.payload]
            ? null
            : true,
        },
      };

    case DiagramFiltersActionTypes.DIAGRAM_FILTER_TOGGLE_RACI:
      return {
        ...state,
        selectedRACIValue:
          action.payload === state.selectedRACIValue ? null : action.payload,
      };

    case DiagramFiltersActionTypes.DIAGRAM_FILTER_RESET:
      return {
        selectedOverlays: {},
        selectedRACIValue: null,
      };

    default:
      return state;
  }
};

export default diagramFilters;
