import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { SelectProps } from "@material-ui/core/Select";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { truncate } from "lodash";
import React from "react";
import { InsightDiagrams } from "../../../hooks/useGetInsightDiagrams";
import Chip from "../../Chip";

interface Props {
  selectedDiagrams: string[];
  allDiagrams: InsightDiagrams;
  onChange?: SelectProps["onChange"];
  onDelete?: (id: string) => void;
  currentDiagramId: string;
}

export default function PageInsightsSelectDiagrams({
  allDiagrams,
  selectedDiagrams,
  onChange,
  onDelete,
  currentDiagramId,
}: Props) {
  return (
    <>
      <p>
        Select <strong>multiple processes*</strong> to uncover helpful insights
        that reveal if the appropriate people are assigned with the right
        accountability in the proper roles in key decision-making processes.
      </p>
      <p>
        *Only diagrams with the same framework will be available for
        selection/aggregation.
      </p>
      <FormControl fullWidth={true}>
        <InputLabel id="insights-diagrams">Selected Diagrams</InputLabel>
        <Select
          id="insights-diagrams"
          value={selectedDiagrams}
          fullWidth={true}
          multiple={true}
          onChange={onChange}
          IconComponent={() => <ExpandMoreIcon />}
          renderValue={(values) => (
            <Grid container={true} wrap="wrap">
              {(values as string[]).map((value) => {
                const diagram = allDiagrams.find((_) => _.id === value);
                return diagram ? (
                  <Chip
                    color="secondary"
                    key={value}
                    label={truncate(diagram.name, {
                      length: 20,
                    })}
                    onDelete={
                      diagram.id === currentDiagramId
                        ? undefined
                        : () => {
                            onDelete && onDelete(value);
                          }
                    }
                  />
                ) : null;
              })}
            </Grid>
          )}
          MenuProps={{
            MenuListProps: {
              dense: true,
            },
          }}
        >
          {allDiagrams.map(({ id, name }) => (
            <MenuItem key={id} value={id} disabled={id === currentDiagramId}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
