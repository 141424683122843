import gql from "graphql-tag";

const GET_FRAMEWORKS = gql`
  query GetFrameworks {
    FrameworkList(orderBy: [custom_asc, id_asc]) {
      id
      name
      creationDate
      createdBy {
        id
        login
      }
      updatedDate
      updatedBy {
        id
        login
      }
      diagramCount
      custom
      children: accountabilityMethodologyValues(orderBy: order_asc) {
        id
        symbol
        color
        name
        description
        exclusive
        order
      }
    }
  }
`;

export default GET_FRAMEWORKS;
