import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import IconRefresh from "@material-ui/icons/Refresh";
import classNames from "classnames";
import React from "react";

const styles = () =>
  createStyles({
    "@keyframes rotation": {
      from: { transform: "rotate(0deg)" },
      to: { transform: "rotate(360deg)" },
    },
    saving: {
      animation: "$rotation 1.5s linear infinite",
    },
  });

interface Props extends WithStyles<typeof styles> {
  className?: string;
}

const IconSaving: React.SFC<Props> = ({ classes, className, ...props }) => (
  <IconRefresh {...props} className={classNames(classes.saving, className)} />
);

export default withStyles(styles)(IconSaving);
