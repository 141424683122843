import _ from "lodash";
import { getAuthToken } from "../../lib/authToken";
import { DiagramFile } from "../../types/Diagram";
import { DiagramFiltersState } from "../diagramFilters/types";
import { getExpirationFromJWT } from "../login/utils";
import { State } from "../types";
import getAssignmentRACIConnections from "./getAssignmentRACIConnections";

const getLoginExpirationDate = (): number => {
  const session = getAuthToken();
  return session ? getExpirationFromJWT(session) * 1000 : 0;
};

export const getUserEmail = (state: State) => _.get(state, "login.userEmail");

export const getUserIsMcK = (state: State) =>
  state.login.userEmail &&
  (state.login.userEmail.indexOf("@example.com") > 0 ||
    state.login.userEmail.indexOf("@mckinsey.com") > 0)
    ? true
    : false;

export const getSelectedRACIFilter = (
  state: State
): DiagramFiltersState["selectedRACIValue"] =>
  state.diagramFilters.selectedRACIValue;

export const getDiagramIdFromRouteProps = <P>(props: P): string =>
  _.get(props, "match.params.id");

export const getDiagramFromRouteProps = <P>(
  state: State,
  props: P
): DiagramFile => {
  const diagramId = getDiagramIdFromRouteProps(props);
  return _.get(state, `rolematrix.diagrams.${diagramId}`);
};

export const getNotifications = (state: State) =>
  _.take(_.get(state, "notifications", []), 5);

export { getAssignmentRACIConnections, getLoginExpirationDate };
