import * as actions from "./actions";

export const LOGGING_IN = "login start";
export const LOGIN_SUCCESS = "login complete";
export const LOGIN_FAILED = "login failed";
export const LOGIN_GUEST = "login as guest";

export const LOGOUT = "session cleared";

export const SET_CLIENT = "set client name";

export const STRUCTURES_UPDATING = "structures updating";
export const STRUCTURES_UPDATED = "structures updated";

export const ORGLAB_API_ERROR = "Orglab API error";

export type LoginAction = ReturnType<typeof actions.logout>;
