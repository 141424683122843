import gql from "graphql-tag";

const GET_DIAGRAMS_LIST = gql`
  query GetDiagramsList {
    DiagramList {
      id
      name
      description
      updatedBy {
        login
      }
      updatedDate
      creationDate
      createdBy {
        login
      }
      structure {
        id
        orglabID
        name
      }
      client {
        id
        name
        orglabID
      }
      accountabilityMethodology {
        id
        name
      }
      subprocesses: operation(filter: { subProcess_not_in: "" }) {
        processId: subProcess
      }
    }
  }
`;

export default GET_DIAGRAMS_LIST;
