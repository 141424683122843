import { blueGrey } from "@material-ui/core/colors";
import Divider from "@material-ui/core/Divider";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { AccessTime } from "@material-ui/icons";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import useDeleteRole from "../../hooks/useDeleteRole";
import useDiagramInteractions from "../../hooks/useDiagramInteractions";
import useReduxDispatch from "../../hooks/useReduxDispatch";
import useUpdateRole from "../../hooks/useUpdateRole";
import { confirm } from "../../store/modal/actions";
import { roleSearchOpen } from "../../store/roleSearch/actions";
import { State } from "../../store/types";
import { BaseStructureWithPath } from "../../types/Clients";
import { FileRole } from "../../types/Diagram";
import { RoleResponse, RoleSearchResponse } from "../../types/Roles";
import { ButtonMenu } from "../Button";

interface RolePanelItemMenuProps {
  diagramId: string;
  role: FileRole;
  canSearchRoles?: boolean;
  orglabRole?: RoleResponse;
  onRoleSelect: (orglabRole: RoleSearchResponse) => void;
  canDeleteRole: boolean;
  structureId?: string;
}

const RolePanelItemMenu: React.SFC<RolePanelItemMenuProps> = (props) => {
  const {
    role,
    onRoleSelect,
    canSearchRoles,
    diagramId,
    canDeleteRole,
    structureId,
  } = props;

  const dispatch = useReduxDispatch();
  const { detachRole } = useUpdateRole();
  const [deleteRole] = useDeleteRole();
  const {
    actions: { showRoleInfo, editRoleCapacity },
  } = useDiagramInteractions();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { structure } = useSelector<
    State,
    {
      structure?: BaseStructureWithPath;
    }
  >((state) => {
    return {
      structure: structureId
        ? state.designSessions.lookup[structureId]
        : undefined,
    };
  });

  const handleDeleteRole = () => {
    dispatch(
      confirm({
        i18nKey: "deleteRole",
        onConfirm: () => deleteRole(role.id, diagramId),
      })
    );
  };

  const handleDetachOrglabRole = () => {
    dispatch(
      confirm({
        i18nKey: "detachRole",
        onConfirm: () => detachRole(role.id, role.name),
      })
    );
  };

  const handleOpenRoleSearch = () => {
    dispatch(roleSearchOpen(role.id)).then((orglabRole) => {
      onRoleSelect(orglabRole);
    });
  };

  const handleOpenRoleCard = () => {
    showRoleInfo(role);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const connectedToOrglab = role.orglabRoleId ? true : false;

  return (
    <>
      {role.capacity ? (
        <AccessTime
          style={{
            color: blueGrey[300],
            position: "relative",
            left: "15%",
            top: "-45%",
            fontSize: 16,
          }}
        />
      ) : null}
      <ButtonMenu
        disabled={!canDeleteRole && !canSearchRoles}
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted={true}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
        {canSearchRoles &&
          connectedToOrglab && (
            <MenuItem
              onClick={() => {
                handleOpenRoleCard();
                handleCloseMenu();
              }}
              disabled={
                structure === undefined ? true : structure && structure.archived
              }
            >
              Info
            </MenuItem>
          )}
        {canSearchRoles ? (
          <MenuItem
            onClick={() => {
              handleOpenRoleSearch();
              handleCloseMenu();
            }}
            disabled={
              structure === undefined ? true : structure && structure.archived
            }
          >
            Search
          </MenuItem>
        ) : null}
        {canSearchRoles && canDeleteRole && <Divider />}
        {canSearchRoles &&
          connectedToOrglab && [
            <MenuItem
              key="detach"
              onClick={() => {
                handleDetachOrglabRole();
                handleCloseMenu();
              }}
            >
              Detach
            </MenuItem>,
            <MenuItem
              key="capacity"
              onClick={() => {
                editRoleCapacity(role);
                handleCloseMenu();
              }}
            >
              Add Capacity
            </MenuItem>,
          ]}
        {canDeleteRole ? (
          <MenuItem
            onClick={() => {
              handleDeleteRole();
              handleCloseMenu();
            }}
            onTouchStart={() => {
              handleDeleteRole();
              handleCloseMenu();
            }}
          >
            Delete
          </MenuItem>
        ) : null}
      </Menu>
    </>
  );
};

export default RolePanelItemMenu;
