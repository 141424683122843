import _ from "lodash";

const arrayHasEmptyValues = (testArray: Array<string | null>): boolean => {
  if (!testArray) {
    return true;
  }
  return _.chain(testArray)
    .map(_.isEmpty)
    .some()
    .value();
};

export default arrayHasEmptyValues;
