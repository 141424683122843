import React from "react";
import FormTextField, { FormTextFieldProps } from "../FormTextField";

const FieldComment: React.SFC<FormTextFieldProps> = props => {
  return (
    <FormTextField {...props} multiline={true} rowsMax="8" fullWidth={true} margin="normal" />
  );
};

export default FieldComment;
