import { SvgIconProps } from "@material-ui/core/SvgIcon";
import React from "react";

const ShapePowerpoint: React.SFC<SvgIconProps> = ({ fill }) => (
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="v10-RACI"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="annotation_option4"
        transform="translate(-802.000000, -87.000000)"
        fill={fill}
      >
        <g id="raci_final">
          <g id="menu-&amp;-drop-downs-">
            <g
              id="download-drop-down"
              transform="translate(778.000000, 1.000000)"
            >
              <g id="on">
                <g id="PPT" transform="translate(24.000000, 86.000000)">
                  <path
                    d="M1.45454545,2.90909091 L0,2.90909091 L0,14.5454545 C0,15.3454545 0.654545455,16 1.45454545,16 L13.0909091,16 L13.0909091,14.5454545 L1.45454545,14.5454545 L1.45454545,2.90909091 L1.45454545,2.90909091 Z M14.5454545,0 L4.36363636,0 C3.56363636,0 2.90909091,0.654545455 2.90909091,1.45454545 L2.90909091,11.6363636 C2.90909091,12.4363636 3.56363636,13.0909091 4.36363636,13.0909091 L14.5454545,13.0909091 C15.3454545,13.0909091 16,12.4363636 16,11.6363636 L16,1.45454545 C16,0.654545455 15.3454545,0 14.5454545,0 L14.5454545,0 Z M14.5454545,11.6363636 L4.36363636,11.6363636 L4.36363636,1.45454545 L14.5454545,1.45454545 L14.5454545,11.6363636 L14.5454545,11.6363636 Z"
                    id="Shape"
                  />
                  <path
                    d="M6.19566761,6.84730114 L6.19566761,8 L5.65660511,8 L5.65660511,4.89772727 L6.84339489,4.89772727 C7.18998753,4.89772727 7.46519784,4.98792523 7.66903409,5.16832386 C7.87287034,5.34872249 7.97478693,5.58735647 7.97478693,5.88423295 C7.97478693,6.18821175 7.875001,6.42471506 7.67542614,6.59375 C7.47585127,6.76278494 7.19637964,6.84730114 6.83700284,6.84730114 L6.19566761,6.84730114 Z M6.19566761,6.41477273 L6.84339489,6.41477273 C7.03515721,6.41477273 7.18146256,6.36967375 7.28231534,6.27947443 C7.38316812,6.18927512 7.43359375,6.05894972 7.43359375,5.88849432 C7.43359375,5.72087984 7.3824579,5.58700334 7.28018466,5.4868608 C7.17791142,5.38671825 7.03728783,5.33522729 6.85830966,5.33238636 L6.19566761,5.33238636 L6.19566761,6.41477273 Z M8.98473011,6.84730114 L8.98473011,8 L8.44566761,8 L8.44566761,4.89772727 L9.63245739,4.89772727 C9.97905003,4.89772727 10.2542603,4.98792523 10.4580966,5.16832386 C10.6619328,5.34872249 10.7638494,5.58735647 10.7638494,5.88423295 C10.7638494,6.18821175 10.6640635,6.42471506 10.4644886,6.59375 C10.2649138,6.76278494 9.98544214,6.84730114 9.62606534,6.84730114 L8.98473011,6.84730114 Z M8.98473011,6.41477273 L9.63245739,6.41477273 C9.82421971,6.41477273 9.97052506,6.36967375 10.0713778,6.27947443 C10.1722306,6.18927512 10.2226562,6.05894972 10.2226562,5.88849432 C10.2226562,5.72087984 10.1715204,5.58700334 10.0692472,5.4868608 C9.96697392,5.38671825 9.82635033,5.33522729 9.64737216,5.33238636 L8.98473011,5.33238636 L8.98473011,6.41477273 Z M13.4762074,5.33238636 L12.5088778,5.33238636 L12.5088778,8 L11.9740767,8 L11.9740767,5.33238636 L11.0152699,5.33238636 L11.0152699,4.89772727 L13.4762074,4.89772727 L13.4762074,5.33238636 Z"
                    fill-opacity="0.87"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

ShapePowerpoint.defaultProps = {
  fill: "black",
};

export default ShapePowerpoint;
