import { Collapse, FormControl } from "@material-ui/core";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import classNames from "classnames";
import { Field, FieldProps, getIn } from "formik";
import { isEmpty } from "lodash";
import React from "react";
import { SortableElement, SortableHandle } from "react-sortable-hoc";
import ConfirmationTooltip from "../ConfirmationTooltip/index";
import FormCheckboxField from "../FormCheckboxField";
import FormColorField from "../FormColorField";
import FormTextField from "../FormTextField";
import { IconArrowDropDown, IconDelete, IconInfo } from "../Icons";
import RACIButton from "../RACIButton";
import { ShapeDragHandle } from "../Shapes";
import FormTextFieldUpdateSymbol from "./FormTextFieldUpdateSymbol";

const SortableAssignment = SortableElement<any>(
  ({ children, ...props }: any) => <div {...props}>{children}</div>
);

const SortableAssignmentHandle = SortableHandle<any>(
  ({ children, ...props }: any) => <ShapeDragHandle {...props} />
);

const styles = (theme: Theme) =>
  createStyles({
    iconDeleteBackground: {
      backgroundColor: theme.palette.secondary.main,
      borderRadius: "50%",
      color: "white",
      height: 28,
      marginRight: 12,
      width: 28,
    },
    lightTooltip: {
      background: "transparent",
      backgroundClip: "padding-box",
      color: "black",
      maxWidth: "none",
    },
    metaFieldContainer: {
      marginLeft: 12,
      marginRight: 32,
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1) * 2,
      paddingRight: theme.spacing(1) * 2,
      paddingTop: theme.spacing(1),
    },
    nameField: {
      flex: 1,
      margin: "0 12px 0 4px",
    },
    nameFieldActive: {
      margin: "0 12px 0 4px",
    },
    paper: {
      margin: theme.spacing(1),
    },
    root: {
      backgroundColor: theme.palette.common.white,
    },
    rootActive: {
      backgroundColor: theme.palette.grey[50],
    },
    symbol: {
      marginRight: theme.spacing(1) * 2,
      marginTop: theme.spacing(1),
    },
    symbolError: {
      border: `2px dotted ${theme.palette.error.main}`,
    },
    toggleArrow: {
      margin: `18px 12px 14px 4px`,
      transform: "rotate(-90deg)",
    },
    toggleArrowActive: {
      margin: `18px 12px 14px 4px`,
      transform: "rotate(0deg)",
    },
    toggleLine: {
      alignItems: "center",
      display: "flex",
    },
    sortableHandle: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      cursor: "move",
    },
    placeholder: {
      marginRight: "52px",
    },
  });

interface OwnProps extends WithStyles<typeof styles> {
  onToggleClick?: () => void;
}

interface Assignment {
  index: number;
  errors: any;
  open: boolean;
  remove: (index: number) => void;
  isEdit?: boolean;
}

type Props = Assignment & OwnProps;

class FormEditFrameworkAssignment extends React.Component<Props> {
  public state = {
    anchorEl: undefined,
  };

  public handleShowColorPicker = (event: Event) => {
    if (this.state.anchorEl === undefined && event) {
      this.setState({ anchorEl: event.currentTarget });
    } else {
      this.setState({ anchorEl: undefined });
    }
  };

  public render() {
    const { classes, index, open, onToggleClick, remove, isEdit } = this.props;
    const { anchorEl } = this.state;

    const showColorPicker = Boolean(anchorEl);

    return (
      <SortableAssignment
        className={classNames(classes.root, {
          [classes.rootActive]: open,
        })}
        index={index}
        key={index}
      >
        <div className={classes.toggleLine}>
          <IconArrowDropDown
            onClick={onToggleClick}
            aria-label="Collapse"
            className={classNames(classes.toggleArrow, {
              [classes.toggleArrowActive]: open,
            })}
          />
          <SortableAssignmentHandle className={classes.sortableHandle} />
          <Field>
            {({ form }: FieldProps) => {
              const name = `assignables.${index}.color`;
              const assignment = form.values.assignables[index];
              const color = assignment.color;
              const symbol = assignment.symbol;
              const error = getIn(form.errors, name);
              const touched = getIn(form.touched, name);

              return (
                <RACIButton
                  color={isEmpty(color) ? "#999999" : color}
                  outline={isEmpty(symbol)}
                  aria-owns={showColorPicker ? "simple-popper" : null}
                  aria-haspopup="true"
                  onClick={this.handleShowColorPicker}
                  className={classNames(classes.symbol, {
                    [classes.symbolError]: error && touched,
                  })}
                >
                  {isEmpty(symbol) ? "?" : symbol}
                </RACIButton>
              );
            }}
          </Field>
          <Field
            name={`assignables.${index}.color`}
            component={FormColorField}
            anchor={anchorEl}
            toggleOpen={showColorPicker}
            handleShowColorPicker={this.handleShowColorPicker}
          />

          <Field
            component={FormTextField}
            name={`assignables.${index}.name`}
            label={" "}
            placeholder="name"
            className={
              isEdit
                ? classNames(classes.nameField, classes.placeholder, {
                    [classes.nameFieldActive]: open,
                  })
                : classNames(classes.nameField, {
                    [classes.nameFieldActive]: open,
                  })
            }
            charMax={isEdit ? null : 15}
            disabled={isEdit}
          />
          <Field
            name={`assignables.${index}.name`}
            component={FormTextFieldUpdateSymbol}
            index={index}
          />
          {isEdit ? null : (
            <ConfirmationTooltip
              noButtonText="Cancel"
              yesButtonText="Delete"
              title="Delete Assignment"
              description="Confirm assignment deletion?"
              onYes={() => remove(index)}
              icon={<IconInfo style={{ marginRight: 6 }} />}
            >
              <div className={classes.iconDeleteBackground}>
                <IconDelete
                  style={{ marginLeft: 4, marginTop: 4, fontSize: 20 }}
                />
              </div>
            </ConfirmationTooltip>
          )}
        </div>
        <div className={classes.metaFieldContainer}>
          <Collapse in={open} timeout={200}>
            <Field
              component={FormTextField}
              name={`assignables.${index}.description`}
              charMax={75}
              label={"Description"}
              fullWidth={true}
              multiline={true}
              margin="dense"
            />
            <FormControl margin="dense" fullWidth={true}>
              <Field
                component={FormCheckboxField}
                label={"Allow only one assignment per activity"}
                name={`assignables.${index}.exclusive`}
                checkboxProps={{
                  color: "default",
                }}
              />
            </FormControl>
          </Collapse>
        </div>
      </SortableAssignment>
    );
  }
}

export default withStyles(styles)(FormEditFrameworkAssignment);
