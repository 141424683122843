import { ModalProps } from "../../types/ModalProps";
import { ReduxActionCreator } from "../types";
import * as actions from "./actions";

export enum ModalActionTypes {
  CREATE_MODAL = "MODAL/CREATE",
  SHOW_MODAL = "MODAL/SHOW",
  HIDE_MODAL = "MODAL/HIDE",
  DESTROY_MODAL = "MODAL/DESTROY",
}

export interface ModalState {
  open: boolean;
  modalProps: ModalProps;
}

export type ModalActionCreatorDestroy = ReduxActionCreator<
  ModalActionTypes.DESTROY_MODAL,
  null
>;

export type ModalActionCreatorCreate = ReduxActionCreator<
  ModalActionTypes.CREATE_MODAL,
  {
    modalProps: ModalProps;
  },
  [ModalProps]
>;

export type ModalAction =
  | ReturnType<typeof actions.confirm>
  | ReturnType<typeof actions.hideModal>;
