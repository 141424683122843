import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import { get } from "lodash";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { State } from "../../store/types";
import { userSet } from "../../store/userSettings/actions";
import { UserSettingsSetHandler } from "../../store/userSettings/types";
import UserStatus from "../UserStatus";
import WelcomeActions from "./WelcomeActions";
import WelcomeContent from "./WelcomeContent";

interface OwnProps {
  dismissWelcome: boolean;
  handleUserSet: UserSettingsSetHandler;
}

type WelcomeDialogInner = OwnProps & RouteComponentProps<any>;

class WelcomeDialog extends React.Component<WelcomeDialogInner> {
  public state = {
    dismissWelcome: false,
    open: !this.props.dismissWelcome,
  };

  public handleDismiss = () => {
    this.setState({ open: false }, () => {
      if (this.state.dismissWelcome) {
        this.props.handleUserSet("dismissWelcome", true);
      }
    });
  };

  public handleDontShowAgain = () => {
    this.setState({ dismissWelcome: !this.state.dismissWelcome });
  };

  public render() {
    const shouldRender = get(this.props, "location.state.welcome");

    if (!shouldRender) {
      return null;
    }

    return (
      <UserStatus
        render={({ isMcMuffin }) => {
          return isMcMuffin ? (
            <Dialog
              maxWidth={"md"}
              open={this.state.open}
              style={{
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
              PaperProps={{
                role: "welcomeDialog",
              }}
            >
              <DialogContent style={{ padding: 0 }}>
                <DialogContentText component="div">
                  <WelcomeContent />
                </DialogContentText>
              </DialogContent>
              <WelcomeActions
                showAgain={this.state.dismissWelcome}
                onDismiss={this.handleDismiss}
                onDontShowAgain={this.handleDontShowAgain}
              />
            </Dialog>
          ) : null;
        }}
      />
    );
  }
}

const mapStateToProps = ({ login: { username }, userSettings }: State) => ({
  dismissWelcome:
    username && userSettings[username] && userSettings[username].dismissWelcome,
});

const enhance = compose<WelcomeDialogInner, {}>(
  compose(
    withRouter,
    connect(
      mapStateToProps,
      {
        handleUserSet: userSet,
      }
    )
  )
);

export default enhance(WelcomeDialog);
