import { ReduxActionCreator } from "../types";
import {
  InsightsActionTypes,
  InsightsIncomingDiagram,
  InsightsIncomingDiagrams,
  InsightsInvolvedRole,
} from "./type";

export const insightsAddDiagrams: ReduxActionCreator<
  InsightsActionTypes.INSIGHTS_ADD_DIAGRAMS,
  { diagrams: InsightsIncomingDiagrams },
  [InsightsIncomingDiagrams]
> = diagrams => ({
  type: InsightsActionTypes.INSIGHTS_ADD_DIAGRAMS,
  payload: {
    diagrams,
  },
});

export const insightsLoadRoleEmployee: ReduxActionCreator<
  InsightsActionTypes.INSIGHTS_LOAD_ROLE_EMPLOYEE,
  {
    roleIndex: number;
    employeeName?: string;
    layer: number;
    location?: string;
    businessUnit?: string;
    function?: string;
  },
  [
    number,
    string,
    number,
    string | undefined,
    string | undefined,
    string | undefined
  ]
> = (roleIndex, employeeName, layer, location, businessUnit, _function) => ({
  type: InsightsActionTypes.INSIGHTS_LOAD_ROLE_EMPLOYEE,
  payload: {
    roleIndex,
    employeeName,
    layer,
    location,
    businessUnit,
    function: _function,
  },
});

export const insightsAddRoles: ReduxActionCreator<
  InsightsActionTypes.INSIGHTS_ADD_ROLES,
  { roles: InsightsInvolvedRole[] },
  [InsightsInvolvedRole[]]
> = roles => ({
  type: InsightsActionTypes.INSIGHTS_ADD_ROLES,
  payload: {
    roles,
  },
});

export const insightsRemoveDiagram: ReduxActionCreator<
  InsightsActionTypes.INSIGHTS_REMOVE_DIAGRAM,
  { diagram: InsightsIncomingDiagram },
  [InsightsIncomingDiagram]
> = diagram => ({
  type: InsightsActionTypes.INSIGHTS_REMOVE_DIAGRAM,
  payload: {
    diagram,
  },
});

export const insightsSelectRole: ReduxActionCreator<
  InsightsActionTypes.INSIGHTS_SELECT_ROLE,
  { roleIndex: number },
  [number]
> = roleIndex => ({
  type: InsightsActionTypes.INSIGHTS_SELECT_ROLE,
  payload: {
    roleIndex,
  },
});

export const insightsToggleAllRoles: ReduxActionCreator<
  InsightsActionTypes.INSIGHTS_TOGGLE_ALL_ROLES,
  { on: boolean },
  [boolean]
> = on => ({
  type: InsightsActionTypes.INSIGHTS_TOGGLE_ALL_ROLES,
  payload: {
    on,
  },
});

export const insightsReset: ReduxActionCreator<
  InsightsActionTypes.INSIGHTS_RESET,
  undefined,
  []
> = () => ({
  type: InsightsActionTypes.INSIGHTS_RESET,
  payload: undefined,
});
