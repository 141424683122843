import { useMutation } from "@apollo/react-hooks";
import { UpdateOperationVariables } from "../../generated/UpdateOperation";
import { UpdateRoleNoteVariables } from "../../generated/UpdateRoleNote";
import UPDATE_OPERATION from "../../graphql/mutation/updateOperation";
import UPDATE_ROLE_NOTE from "../../graphql/mutation/updateRoleNote";
import useGetCurrentDiagramId from "../useGetCurrentDiagramId";
import { DiagramComponentOperation } from "../useGetDiagram/types";

export default function useUpdateOperation() {
  const diagramID = useGetCurrentDiagramId();
  const [updateOperation] = useMutation<any, UpdateOperationVariables>(
    UPDATE_OPERATION,
    { fetchPolicy: "no-cache" }
  );

  const [updateRoleNote] = useMutation<any, UpdateRoleNoteVariables>(
    UPDATE_ROLE_NOTE,
    { fetchPolicy: "no-cache" }
  );

  function _updateOperation(
    operationId: string,
    values: DiagramComponentOperation
  ) {
    if (values.roleNotes) {
      updateRoleNote({
        variables: {
          roleNotes: values.roleNotes.map(rn => ({
            roleID: rn.roleID,
            text: rn.text,
            noteID: rn.noteID,
          })),
          operationID: operationId,
        },
      });
    }
    return updateOperation({
      variables: {
        id: operationId,
        diagramID,
        optional: {
          name: values.name,
          cycleTime: values.cycleTime,
          waitTime: values.waitTime,
          repeats: values.repeats,
          comment: values.comment,
          inputs: values.inputs,
          subProcess: values.subProcess,
          dataElements: values.dataElements,
          outputs: values.outputs,
          itSystems: values.itSystems,
          painPoints: values.painPoints,
        },
      },
    });
  }

  return [_updateOperation];
}
