import _ from "lodash";
import { State } from "../types";
import { UserSetting } from "../userSettings/types";

const getUserSettings = (state: State): UserSetting | undefined => {
  const {
    login: { username },
    userSettings,
  } = state;
  return (username && userSettings[username]) || undefined;
};

export default getUserSettings;
