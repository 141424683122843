import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import React, { Component } from "react";

const styles = () =>
  createStyles({
    drawerContainer: {
      borderRadius: 0,
      height: "100%",
      opacity: 0,
      overflow: "hidden",
      transition: "opacity 150ms ease, width 173ms ease;",
      width: 0,
      position: "relative",
      zIndex: 2,
    },
    open: {
      backgroundColor: "#fff",
      opacity: 1,
      width: 404,
      zIndex: 2,
    },
    inner: {
      height: "100%",
      width: 404,
    },
  });

export type AnnotationPanelInnerProps = WithStyles<typeof styles>;

export interface AnnotationPanelOuterProps {
  open: boolean;
}

export type AnnotationPanelProps = AnnotationPanelInnerProps &
  AnnotationPanelOuterProps;

class AnnotationPanel extends Component<AnnotationPanelProps> {
  public render() {
    const { classes, children, open } = this.props;
    return (
      <Paper
        className={classNames(classes.drawerContainer, {
          [classes.open]: open,
        })}
      >
        <div className={classes.inner}>{children}</div>
      </Paper>
    );
  }
}

export default withStyles(styles)(AnnotationPanel);
