import { get } from "lodash";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";
import useRouter from "use-react-router";
import useCreateDiagram from "../../hooks/useCreateDiagram";
import useGetDiagramFormData from "../../hooks/useGetDiagramFormData";
import useGetFrameworks from "../../hooks/useGetFrameworks";
import useUpdateDiagram from "../../hooks/useUpdateDiagram";
import { State } from "../../store/types";
import { PhaseResponse } from "../../types/Clients";
import { DiagramFormValues } from "../../types/DiagramForm";
import FormDiagramOrglab from "../FormDiagramOrglab";
import LoadingInline from "../LoadingInline";

interface FormDiagramProps {
  onAfterSubmit?: () => void;
  onAfterCreate?: (id: string, values: DiagramFormValues) => void;
  onCancel: () => void;
  initialValues?: DiagramFormValues;
  subprocessValues?: {
    parentId: string;
    processElementId: string;
  };
  isEdit?: boolean;
  diagramId?: string;
}

const FormDiagram: React.SFC<FormDiagramProps> = ({
  onCancel,
  isEdit = false,
  subprocessValues,
  onAfterSubmit,
  onAfterCreate,
  diagramId,
  initialValues,
}) => {
  // const dispatch = useReduxDispatch();
  const { history } = useRouter();
  const { loading: formDataLoading, initialFormValues } = useGetDiagramFormData(
    diagramId || ""
  );
  const { createDiagram } = useCreateDiagram();
  const { editDiagram } = useUpdateDiagram();
  const { designSessions, clientId } = useSelector<
    State,
    {
      designSessions: PhaseResponse[];
      clientId?: string;
    }
  >((state) => ({
    designSessions: state.designSessions.source,
    clientId: state.login.clientId,
  }));
  const { frameworks, loading: frameworksLoading } = useGetFrameworks();
  const [saving, setSaving] = useState(false);
  const [formStep, setFormStep] = useState(0);

  if (formDataLoading || frameworksLoading) {
    return <LoadingInline />;
  }

  const handleSubmit = (values: DiagramFormValues) => {
    if (formStep === 2) {
      setSaving(true);
      if (diagramId) {
        editDiagram(values, diagramId).then(() => {
          onAfterSubmit && onAfterSubmit();
        });
      } else {
        createDiagram(
          values,
          subprocessValues && subprocessValues.processElementId
        ).then((result) => {
          const diagramId = get(result, "data.CreateNewDiagram.id");
          if (!subprocessValues) {
            history.push(process.env.PUBLIC_URL + `/rolematrix/${diagramId}`);
          }
          onAfterCreate && onAfterCreate(diagramId, values);
          onAfterSubmit && onAfterSubmit();
        });
      }
    } else {
      setFormStep(formStep + 1);
    }
  };

  return (
    <FormDiagramOrglab
      onCancel={onCancel}
      onSubmit={handleSubmit}
      frameworks={frameworks}
      designSessions={designSessions}
      isEdit={isEdit}
      initialValues={initialValues || initialFormValues}
      onChangeStep={setFormStep}
      formStep={formStep}
      saving={saving}
      isSubprocess={Boolean(subprocessValues && subprocessValues.parentId)}
    />
  );
};

export default FormDiagram;
