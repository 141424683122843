import { createStyles, withStyles, WithStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { ProcessElementOuterProps } from "../../types/ProcessElement";
import ProcessElementText from "../ProcessElementText";
import { ShapeDiamond } from "../Shapes";
import TextOverflow from "../TextOverflow";
import WithProcessColors from "../WithProcessColors";

const styles = () =>
  createStyles({
    container: {
      width: "11.6em",
      height: "7em",
      position: "relative",
      fontSize: ".8em",
      marginRight: "22px",
      "-webkit-tap-highlight-color": "rgba(0,0,0,0)",
    },
    svg: {
      width: "11.6em",
      position: "absolute",
    },
    textOverflow: {
      position: "absolute",
      top: "1.2em",
      left: "2.8em",
      right: "2.8em",
      height: "4.6em",
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    badge: {
      position: "absolute",
      left: "100%",
      top: "50%",
      marginTop: "-19px",
      marginLeft: "-15px",
    },
    badgeText: {
      transform: "rotate(45deg)",
    },
    number: {
      position: "absolute",
      bottom: ".2em",
      left: "51%",
      fontSize: "10px",
      textAlign: "center",
      width: "4em",
      marginLeft: "-2em",
    },
  });

type ProcessElementDecisionProps = WithStyles<typeof styles> &
  ProcessElementOuterProps;

const ProcessElementDecision: React.SFC<ProcessElementDecisionProps> = ({
  active = false,
  size = 1,
  text = "",
  classes,
  countNumber,
  subtle,
  highlight,
  ...props
}) => {
  return (
    <WithProcessColors
      subtle={subtle}
      text={text}
      active={active}
      highlight={highlight}
      render={({
        colors: { fill, stroke, textColor, numberColor, strokeWidth },
      }: any) => (
        <div className={classes.container} {...props}>
          <ShapeDiamond
            className={classes.svg}
            strokeWidth={strokeWidth}
            stroke={stroke}
            fill={fill}
          />
          <div className={classes.textOverflow}>
            <TextOverflow maxLines={4}>
              <ProcessElementText textColor={textColor}>
                {text}
              </ProcessElementText>
            </TextOverflow>
          </div>
          <Typography className={classes.number} style={{ color: numberColor }}>
            {countNumber}
          </Typography>
        </div>
      )}
    />
  );
};

export default withStyles(styles)(ProcessElementDecision);
