import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import Select, { SelectProps } from "@material-ui/core/Select";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FieldProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props extends FieldProps, SelectProps {
  disableOnSubmitting?: boolean;
  label?: string;
}

const FormSelectField: React.SFC<Props> = ({
  field,
  children,
  disableOnSubmitting,
  form: { touched, errors, isSubmitting },
  disabled = false,
  label,
  ...props
}) => {
  const { name } = field;
  const { t } = useTranslation("formFields");
  return (
    <FormControl
      fullWidth={true}
      margin="dense"
      error={touched[name] && !!errors[name]}
    >
      <InputLabel htmlFor={name}>{label || t(name)}</InputLabel>
      <Select
        disabled={disableOnSubmitting ? disabled : isSubmitting || disabled}
        IconComponent={() => <ExpandMoreIcon />}
        {...field}
        {...props}
      >
        {children}
      </Select>
      {touched[name] &&
        !!errors[name] && <FormHelperText>{errors[name]}</FormHelperText>}
    </FormControl>
  );
};

export default FormSelectField;
