import { Reducer } from "redux";
import {
  UserSetting,
  UserSettingsAction,
  UserSettingsState,
  UserSettingsTypes,
} from "./types";

const initialState = {};

export const initialUserState: UserSetting = {
  dismissSignInConfirmation: false,
  dismissWelcome: false,
  hasConfirmedDecision: false,
  nudgeMultipleAssignments: {},
};

const reducer: Reducer<UserSettingsState, UserSettingsAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case UserSettingsTypes.USER_SETTINGS_SET:
      return {
        ...state,
        [action.payload.userId]: {
          ...initialUserState,
          ...state[action.payload.userId],
          [action.payload.key]: action.payload.value,
        },
      };

    default:
      return state;
  }
};

export default reducer;
