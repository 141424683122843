import {
  AppBar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import React from "react";
import { ButtonDismiss } from "../Button";
import KnowledgeList from "./KnowledgeList";

const DialogKnowledgeCenter: React.SFC<{
  open: boolean;
  onShowDialog: () => void;
}> = ({ open, onShowDialog }) => {
  const [activeTab, setActiveTab] = React.useState<number>(0);
  return (
    <Dialog
      open={open}
      onClose={onShowDialog}
      aria-labelledby="dialog-title-kc"
    >
      <DialogTitle disableTypography={true} id="dialog-title-kc">
        <Typography color="secondary" variant="h6">
          Best Practices Knowledge Center
        </Typography>
      </DialogTitle>

      <Tabs
        value={activeTab}
        onChange={(_e, value) => setActiveTab(value)}
        indicatorColor="secondary"
        textColor="primary"
      >
        <Tab value={0} label="Know Docs" />
        <Tab value={1} label="McKinsey Publications" />
      </Tabs>
      <DialogContent dividers={true} style={{ padding: 0 }}>
        {activeTab === 0 && (
          <KnowledgeList
            articles={[
              {
                title: "DecisionLab CXO/Intro Doc",
                link:
                  "https://home.intranet.mckinsey.com/ks/research/summary/document?id=867767",
                desc:
                  "The purpose of this document is to offer a CXO-level introduction to the DecisionLab application, which enables fast and quality decision-making, unlocking tremendous value for organizations.",
                role: "CXO",
              },
              {
                title: "DecisionLab EM Guide",
                link:
                  "https://home.intranet.mckinsey.com/ks/research/summary/document?id=862845",
                desc:
                  "This document acts as guide to driving process design and decision-making engagements. It also offers a detailed look at leveraging DecisionLab to enhance these engagements.",
                role: "EM",
              },
              {
                title: "Best Practice: Corporate Resource Allocation",
                link:
                  "https://home.intranet.mckinsey.com/ks/research/summary/document?id=868529",
                desc:
                  "This document has been created to assist CSTs on what a good corporate resource allocation process looks like – including the critical steps, the key roles involved and their decision rights.",
                role: "CRA",
              },
            ]}
          />
        )}
        {activeTab === 1 && (
          <KnowledgeList
            articles={[
              {
                title:
                  "Streamline decision-making for a better customer journey",
                link:
                  "https://www.mckinsey.com/business-functions/organization/our-insights/the-organization-blog/streamline-decision-making-for-a-better-customer-journey",
                desc:
                  "With DecisionLab, we have helped many clients drill down into their processes to ensure optimal internal operations and a better external experience for those they serve.",
                role: "streamline",
              },
              {
                title: "Decision-making: avoiding turf wars",
                desc:
                  "In unlocking great decision-making, cross-cutting decisions rank among the hardest to get right in most organizations.",
                link:
                  "https://www.mckinsey.com/business-functions/organization/our-insights/the-organization-blog/decision-making-avoiding-turf-wars",
                role: "turfwars",
              },
              {
                title: "Good decisions don’t have to be slow ones",
                desc:
                  "Executives often assume that speed comes at the cost of quality in good decision making. A survey challenges this belief and offers tips on getting the best of both worlds.",
                link:
                  "https://www.mckinsey.com/business-functions/organization/our-insights/good-decisions-dont-have-to-be-slow-ones?cid=other-eml-alt-mcq-mck&hlkid=b653d1606cb448488b3e74ad846a15ca&hctky=10297835&hdpid=171b7d61-5d69-496a-ae4a-328e505ee4e1",
                role: "gooddecisions",
              },
              {
                title: "Three keys to faster, better decisions",
                desc:
                  "Frustrated decision makers take heart! Three practices can help companies make better decisions more quickly.",
                link:
                  "https://www.mckinsey.com/business-functions/organization/our-insights/three-keys-to-faster-better-decisions?cid=other-eml-alt-mcq-mck&hlkid=851e1a3d2fbd4c3fb15f7245457166e3&hctky=10297835&hdpid=171b7d61-5d69-496a-ae4a-328e505ee4e1",
                role: "threekeys",
              },
              {
                title: "Keys to unlocking great decision-making",
                desc:
                  "For agile organizations, getting decision-making right is critical since their foundation rests on an action-oriented decision architecture.",
                link:
                  "https://www.mckinsey.com/business-functions/organization/our-insights/the-organization-blog/keys-to-unlocking-great-decision-making",
                role: "unlockinggreat",
              },
            ]}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Box px={3} py={1}>
          <ButtonDismiss type="submit" onClick={onShowDialog} />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DialogKnowledgeCenter;
