import { Reducer } from "redux";
import {
  NotificationsAction,
  NotificationsActionTypes,
  NotificationsState,
} from "./types";

const reducer: Reducer<NotificationsState, NotificationsAction> = (
  state = [],
  action
) => {
  switch (action.type) {
    case NotificationsActionTypes.NOTIFY_SHOW:
      return [action.payload, ...state];

    case NotificationsActionTypes.NOTIFY_HIDE:
      return state.filter(notification => {
        return notification.uid !== action.payload.uid;
      });
    case NotificationsActionTypes.NOTIFY_REMOVE_ALL:
      return [];

    default:
      return state;
  }
};

export default reducer;
