import { makeStyles } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import React from "react";
import { DiagramComponentState } from "../../../hooks/useGetDiagram/types";
import { useLocalToggleDiagramPath } from "../../../hooks/useToggleDiagramPath";
import displayTime from "../../../lib/displayTime";
import LightTooltip from "../../LightTooltip";
import { ChartBar, ChartBarSpacer } from "../ChartBar";
import ChartOperation from "../ChartOperation";
import TimeHeader from "./TimeHeader";
import useDecisionTimeDistribution from "./useDecisionTimeDistribution";
import XAxis from "./XAxis";

interface Props {
  diagram: DiagramComponentState;
}

const useStyles = makeStyles({
  bars: {
    display: "flex",
    alignItems: "center",
    transition: "all .1s",
  },
  tbody: {
    "& > tr > td:nth-child(2)": {
      transition: "all .2s",
    },
    "&:hover > tr > td:nth-child(2)": {
      opacity: 0.6,
    },
    "& > tr:hover > td:nth-child(2)": {
      opacity: 1,
    },
  },
});

export default function DecisionTimeDistribution({ diagram }: Props) {
  const classes = useStyles();

  const { currentPath } = useLocalToggleDiagramPath(diagram.operation);

  const {
    totalDays,
    plotData,
    xAxisScale,
    totalCycle,
    totalWait,
    totalCyclePercent,
    totalWaitPercent,
  } = useDecisionTimeDistribution(currentPath);

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <GanttTD />
            <GanttTD>
              <TimeHeader
                totalDays={totalDays}
                totalCycle={totalCycle}
                totalCyclePercent={totalCyclePercent}
                totalWait={totalWait}
                totalWaitPercent={totalWaitPercent}
              />
            </GanttTD>
          </tr>
        </thead>
        <tbody className={classes.tbody}>
          {plotData.map(({ operation, cycleWidth, waitWidth, offset }, i) => {
            return (
              <tr key={i}>
                <GanttRoleViewTD>
                  <ChartOperation operation={operation}>
                    <div
                      style={{
                        width: 5,
                        height: 1,
                        background: "black",
                      }}
                    />
                  </ChartOperation>
                </GanttRoleViewTD>
                <GanttBarViewTD
                  style={{
                    backgroundSize: `${100 / (xAxisScale.length - 1)}% 10%`,
                  }}
                >
                  <div className={classes.bars}>
                    <LightTooltip
                      placement="top"
                      title={
                        <>
                          <div>
                            Duration: {displayTime(operation.cycleTime)}
                          </div>
                          <div>
                            Wait Time: {displayTime(operation.waitTime)}
                          </div>
                        </>
                      }
                    >
                      <ChartBarSpacer
                        showPlaceholder={Boolean(
                          cycleWidth <= 0 &&
                            waitWidth <= 0 &&
                            (displayTime(operation.cycleTime) ||
                              displayTime(operation.waitTime)),
                        )}
                        style={{
                          width: `${offset}%`,
                        }}
                      />
                    </LightTooltip>
                    <LightTooltip
                      placement="top"
                      title={`Duration: ${displayTime(operation.cycleTime)}`}
                    >
                      <ChartBar
                        height={20}
                        width={`${cycleWidth}%`}
                        backgroundColor={"#FA9F82"}
                      />
                    </LightTooltip>
                    <LightTooltip
                      placement="top"
                      title={`Wait Time: ${displayTime(operation.waitTime)}`}
                    >
                      <ChartBar
                        height={20}
                        width={`${waitWidth}%`}
                        backgroundColor={"#8C59CB"}
                      />
                    </LightTooltip>
                  </div>
                </GanttBarViewTD>
              </tr>
            );
          })}
        </tbody>
        <tfoot>
          <tr>
            <GanttTD />
            <GanttTD style={{ padding: 0 }}>
              <XAxis ticks={xAxisScale} />
            </GanttTD>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

const GanttTD = styled("td")({
  padding: 0,
});

const GanttRoleViewTD = styled(GanttTD)(({ theme }) => ({
  paddingTop: 5,
  paddingBottom: 5,
  paddingLeft: 0,
  paddingRight: 0,
  fontSize: 11,
  width: 160,
  borderRight: `1px solid ${theme.palette.grey[50]}`,
}));

const GanttBarViewTD = styled(GanttTD)(({ theme }) => ({
  padding: 0,
  backgroundPositionX: -1,
  backgroundImage: `linear-gradient(to right, ${
    theme.palette.grey[50]
  } 1px, transparent 1px)`,
}));
