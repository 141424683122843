import Checkbox from "@material-ui/core/Checkbox";
import DialogActions from "@material-ui/core/DialogActions";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import { CheckBoxOutlined } from "@material-ui/icons";
import React from "react";
import { ButtonCancel, ButtonSubmit } from "../Button";

interface OwnProps {
  onSubmit: (includeSubproccess: boolean) => void;
  onCancel?: () => void;
  hasSubprocesses: boolean;
  submitting?: boolean;
}
type Props = OwnProps;

export class FormDuplicateDiagram extends React.Component<Props> {
  public state = {
    allSubprocesses: false,
  };

  public handleCheckbox = () => {
    this.setState({ allSubprocesses: !this.state.allSubprocesses });
  };

  public render() {
    return (
      <div>
        <Typography>
          Are you sure you want to duplicate the selected diagram?
        </Typography>
        {/* <FormControlLabel
          control={
            <Checkbox
              checked={this.state.allSubprocesses}
              onChange={this.handleCheckbox}
              disabled={!this.props.hasSubprocesses}
              color="default"
              checkedIcon={<CheckBoxOutlined />}
            />
          }
          label="Include all subprocesses"
        /> */}
        <DialogActions>
          <ButtonCancel
            onClick={this.props.onCancel}
            disabled={this.props.submitting}
          />
          <ButtonSubmit
            onClick={() => this.props.onSubmit(this.state.allSubprocesses)}
            text="Duplicate"
            type="submit"
            disabled={this.props.submitting}
            loading={this.props.submitting}
          />
        </DialogActions>
      </div>
    );
  }
}

export default FormDuplicateDiagram;
