import { SvgIconProps } from "@material-ui/core/SvgIcon";
import React from "react";

const ShapeDiamond: React.SFC<SvgIconProps> = ({
  stroke,
  fill,
  strokeWidth,
  ...props
}) => (
  <svg viewBox="0 0 660 400" {...props}>
    <path
      id="svg_1"
      d="m343.53198,2.49999l-340.53202,197.00566l338.83219,198.49436l316.16784,-198.49436l-314.46801,-197.00566z"
      strokeWidth={strokeWidth}
      stroke={stroke}
      fill={fill}
      className="shapeFill"
    />
  </svg>
);

ShapeDiamond.defaultProps = {
  stroke: "black",
  fill: "transparent",
  strokeWidth: 20,
};

export default ShapeDiamond;
