import { styled } from "@material-ui/styles";
import React from "react";
import { InsightDiagrams } from "../../../hooks/useGetInsightDiagrams";
import AnalysisTooltip from "./AnalysisTooltip";
import useGetAsterPlotData, { AsterPlotData } from "./useGetAsterPlotData";

const AsterPlotContainer = styled("div")({
  width: "100%",
  position: "relative",
  paddingBottom: "100%",
  marginLeft: 8,
  userSelect: "none",
});

const AsterPlotSVG = styled("svg")({
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
});

const ArcPath = styled("path")({
  stroke: "none",
  transition: "all .3s",
  "&:hover": {
    opacity: 0.6,
  },
});

interface Props {
  width: number;
  height: number;
  data: AsterPlotData[];
  maxAssignments: number;
  selectedProcesses: InsightDiagrams;
}

export default function AsterPlot({
  width,
  height,
  data,
  maxAssignments,
  selectedProcesses,
}: Props) {
  const { plotData, radius } = useGetAsterPlotData(data, width, height);
  return (
    <AsterPlotContainer>
      <AsterPlotSVG viewBox={`0 0 ${width} ${height}`}>
        <g transform={`translate(${radius} ${radius}), scale(0.98)`}>
          {plotData.map((item, i) => (
            <g key={i}>
              <AnalysisTooltip
                label={item.label}
                orglab={item.orglab}
                employeeName={item.employeeName}
                data={item.splitByProcess}
                selectedProcesses={selectedProcesses}
              >
                <ArcPath fill={item.color} d={item.arcData} />
              </AnalysisTooltip>
              <path fill="none" stroke="#ccc" d={item.outlineArcData} />
            </g>
          ))}
        </g>
        <text
          x="50%"
          y="50%"
          dominant-baseline="middle"
          text-anchor="middle"
          style={{
            fontSize: 22,
            fontWeight: "bold",
          }}
          transform="translate(0 -10)"
        >
          {maxAssignments}
        </text>
        <text
          x="50%"
          y="50%"
          dominant-baseline="middle"
          text-anchor="middle"
          style={{
            fontSize: 12,
          }}
          transform="translate(0 10)"
        >
          max assignments
        </text>
      </AsterPlotSVG>
    </AsterPlotContainer>
  );
}
