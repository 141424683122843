import { default as formatDate } from "date-fns/format";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector).init({
  defaultNS: "translations",
  fallbackLng: "en",
  // we use content as keys
  interpolation: {
    escapeValue: false,
    // not needed for react!!
    formatSeparator: ",",

    format(value, format) {
      if (value) {
        return formatDate(new Date(value), format);
      }

      return value;
    },
  },
  keySeparator: false,
  // have a common namespace used around the full app
  ns: ["translations", "confirmations", "formFields"],
  react: {
    wait: true,
  },
  // we init with resources
  resources: {
    en: {
      confirmations: {
        "deleteDiagram.confirmText": "Delete",
        "deleteDiagram.message":
          "Are you sure you want to delete this diagram?",
        "deleteDiagram.title": "Delete Diagram",
        "deleteProcess.message":
          "Are you sure you want to delete this process operation?",
        "deleteProcess.title": "Delete Operation",
        "deleteProcess.confirmText": "Delete",
        "deleteRole.confirmText": "Delete",
        "deleteRole.message":
          "Are you sure you want to delete this matrix row?",
        "deleteRole.title": "Delete Row",

        "detachRole.confirmText": "Detach",
        "detachRole.message":
          "Are you sure you want to detach this role from OrgLab?",
        "detachRole.title": "Detach Role",
        "delinkProcessFromParent.confirmText": "Delink",
        "delinkProcessFromParent.message":
          'Do you want to delink "{{diagramName}}" from it\'s parent "{{parentDiagramName}}"? (Delinking the diagram will not delete it from your workspace.)',
        "delinkProcessFromParent.title": "Delink Diagram",
        "deleteCustomFramework.confirmText": "Delete",
        "deleteCustomFramework.message":
          'Do you want to delete this custom framework?',
        "deleteCustomFramework.title": "Delete Framework",    
        "exportPPTX.message": `Exporting to PowerPoint will temporarily transmit diagram data to a service hosted in Europe. If your information cannot be transmitted outside the continental US, please click "Cancel".`,
        "exportPPTX.title": "Warning: Powerpoint Export",
        "exportPPTX.confirmText": "Export",
        "guestLogout.confirmText": "Logout",
        "guestLogout.message":
          "While in guest mode, your data is saved locally to your device. To ensure you do not lose your process diagrams make sure to Export them before logging out.",
        "guestLogout.title": "Guest Logout",
        "guestSignIn.acknowledge":
          "Click here to acknowledge that you have read the notice above",
        "guestSignIn.confirmText": "Continue",
        "guestSignIn.message":
          "While in guest mode, your data is saved locally to your device. To ensure you do not lose your process diagrams make sure to Export them before logging out.",
        "guestSignIn.title": "Warning - Continue as Guest",
        "sessionExpire.cancelText": "Log Out",
        "sessionExpire.confirmText": "Continue",
        "sessionExpire.title": "Warning - Continue Session?",
        "sessionOffline.confirmText": "Log Out",
        "sessionOffline.title": "Warning - Network connectivity lost",
        "signIn.acknowledge":
          "Click here to acknowledge that you have read the notice above",
        "signIn.message": `If your information cannot be stored in Europe, please reach out to your DecisionLab liaison for information on accessing our US-specific instance.`,
        "signIn.title": "Sign In",
        "signIn.confirmText": "Sign In",
        "unlinkProcessOperation.message":
          "Are you sure you want to delink this process operation?",
        "unlinkProcessOperation.title": "Delink Operation",
        "unlinkProcessOperation.confirmText": "Delink",
      },
      formFields: {
        clientId: "Client name",
        decisionFrameworkId: "Decision Framework",
        name: "Diagram Name",
        email: "Username",
        password: "Password",
        structureId: "Structure",
        functionFilter: "Functions",
        locationFilter: "Locations",
        businessUnitFilter: "Business Units",
        username: "Username",
        processArchetype: "Process Archetype",
        processArchetypeOther: "Custom Process Archetype",
        structurePrimaryAxis: "Structure Primary Axis",
        structurePrimaryAxisOther: "Custom Structure Primary Axis",
        industry: "Industry",
        industryOrglabID: "Industry",
        industryOther: "Custom Industry",
        geography: "Geography",
        description: "Description",
        organizationSize: "Organization Size",
        primaryOwningFunction: "Primary Owning Function",
        primaryOwningFunctionOther: "Custom Primary Owning Function",
        "cycleTime.0": "Duration",
        "waitTime.0": "Wait Time",
        "cycleTime.1": "",
        "waitTime.1": "",
        selectType: "Type",
        comment: "Comment",
        subProcessName: "Subprocess",
      },
      welcomeContent: {
        whatsNew: [
          `Powerful and visually-stunning suite of decision-making insights encompassing decision rights distribution, decision speed & time, as well as linkages and handoffs between decision-makers.`,
          `Seamless, real-time collaboration on the same diagram – see your colleagues’ updates to the diagram as they are made.`,
          `Many design enhancements, bugfixes, and stability improvements.`,
        ],

        underTheHood: [
          `More robust API backbone that utilizes a cutting edge technology stack to offer speed and reliability.`,
          `Secure and modern graph database which is tuned for performance.`,
          `Seamless integration with OrgLab and the OrgAPI that enables integrated insights that blend structure and process information.`,
        ],

        additionalResources: [
          `Much-improved PPT exports!`,
          `Up-to-date knowledge resource center to get you the latest on the firm’s decision-making thinking.`,
          `Benchmarking for your critical processes against industry, function and process archetypes.`,
          `...and much more!`,
        ],
      },
      timeConversion: {
        "1": "Secs",
        "60": "Mins",
        "3600": "Hrs",
        "86400": "Days",
        "604800": "Weeks",
        "2592000": "Months",
      },
      translations: {
        function: "Function",
        location: "Location",
        businessUnit: "Business Unit",
        diagramSetupProcessType:
          "Tell us a bit about the type of process you are about to design:",
        copyright: "&copy; McKinsey &amp; Company {{date, YYYY}}",
        bandwidthMessage:
          "DecisionLab works best on non-cellular networks and networks with little to no congestion.",
        days: "Days",
        diagramFormTitle: "New Diagram",
        diagramFormTitle_edit: "Rename Diagram",
        elementLabel: "Element Label",
        elementLabel_decision: "Decision Point Label",
        elementLabel_task: "Activity/Task Label",
        formatDate: "{{date, MM/DD/YYYY hh:mma}}",
        frameworkFormTitle: "New Framework",
        frameworkFormTitle_edit: "Edit Framework",
        gatewayInstructions:
          "Toggled decision point to N. Click again to toggle back to Y.",
        hrs: "Hrs",
        jsonImportError:
          "There was an error importing this file. Please try exporting and importing it again.",
        jsonInputTypeError: "File not supported, .json files only",
        legalDisclaimer:
          "This software application contains confidential and proprietary information of McKinsey &amp; Company and is intended solely for your internal use. You may not share access to this software application, or redistribute, reproduce, or disclose any screen shots, results, information, or other elements related to this software application without McKinsey &amp; Company's express prior written consent.",
        accessibilityStatement:
          "We strive to provide individuals with disabilities equal access to our services, including through an accessible website. If you have questions, comments, or encounter any difficulty in using our site, please contact: McKinsey_Website_Accessibility@mckinsey.com",
        loginInfo: `
                    <p>
                      If you have not received your login details or have
                      forgotten them, please&nbsp;
                      <a role="contactUsLogin" href="mailto:decisionlab-support@mckinsey.com?Subject=DL%20Login%20Details">
                        contact us
                      </a>
                      .
                    </p>
                    <p>
                      If you are not a member but would like to know more about DecisionLab and
                      its features, please&nbsp;
                      <a role="contactUsFeatures" href="mailto:decisionlab-support@mckinsey.com?Subject=DL%20Feature%20Request">
                        contact us
                      </a>
                      .
                    </p>
                  `,
        loginInvalidCredentials:
          "Your username, password, or client is incorrect.",
        loginNoStructures: "No structures exist for this client.",
        loginInvalidClient: "Invalid client name. Please try again.",
        mins: "Mins",
        powerpointExportError: "Error exporting diagram to PowerPoint.",
        frameworkDeleteAttachedError: "Could not delete framework associated to diagrams.",
        frameworkDeleteError: "Error deleting framework.",
        assignmentSaveError: "Error saving assignment(s).",
        repeatingOperation:
          "Repeating Operation: Task repeats until success parameters are met",
        secs: "Secs",
      },
    },
  },
});

export default i18n;
